<template>
    <div class="oneMix">
        <div class="oneMixHeader">
            <div class="oneMixHeaderTop w-100">

                <h4 class="w-100">
                    Замес №{{ mix.doc }} объемом {{ formatNumber(mix.total, 2) }}
                    <good-unit :unit="mix.good ? getData(mix.good, 'good', 'unit') : ''"/>

                    <el-button
                        @click="createMixOrderMixDialogToTrue"
                        :icon="icons.edit"
                        style="margin-left: 5px"
                        circle
                        plain
                    />

                    <el-button
                        @click="deleteOneMix"
                        :icon="icons.delete"
                        style="margin-left: 30px"
                        circle
                        plain
                    />
                </h4>

                <span class="w-100 text-right color-grey">{{ getDateFromUnix(mix.dateStart, 'DD.MM', tz) }} {{ getDateFromUnix(mix.dateStart, 'HH:mm', tz) }} -
                    {{ getDateFromUnix(mix.dateEnd, 'HH:mm', tz) }}</span>
            </div>
            <div class="w-100 oneMixHeaderIndicators">
                <div class="w-100">
                    <span>Рецепт</span>
                    <p>{{ mix.good ? getData(mix.good, 'good') : '-' }}</p>
                </div>
                <div class="w-100">
                    <span>Ток смесителя</span>
                    <p>{{ mix.amperage ?? '0' }} A</p>
                </div>
                <div class="w-100">
                    <span>Влажность фактическая</span>
                    <p>{{ mix.moistureFact ?? '0' }} %</p>
                </div>
                <div class="w-100">
                    <span>Влажность рецептурная</span>
                    <p>{{ mix.moistureFormula ?? '0' }} %</p>
                </div>
            </div>
        </div>
    </div>

    <div v-if="mix.materials.length > 0">

        <div class="oneMixMaterialsTable mt-15">
            <MixOrderMaterialExpenseTable
                :materials="mix.materials"
            />
        </div>

    </div>
    <div v-else>
        <div class="no-data-block">
            <NoDataIcon/>
            <p>
                Нет информации о расходах материалов<br>для данного замеса
            </p>
        </div>
    </div>

    <el-dialog
        v-model="createMixOrderMixDialog"
        :title="'Замес №' + mix.doc"
        :width="mobile ? '100%' : '65%'"
        :destroy-on-close="true"
    >

        <MixPageOrderMixCreate
            :order-id="orderId"
            :create-mix="createMix"
            :mix="mix"
            @closeDialog="createMixOrderMixDialogToFalse"
            @reset="setCreateMix(false)"
            @moduleGet="$emit('moduleGet')"
        />

        <template #footer>
          <span class="dialog-footer">
            <el-button
                @click="createMixOrderMixDialogToFalse">
                Отмена
            </el-button>
            <el-button
                type="primary"
                @click="setCreateMix(true)"
            >
                Сохранить изменения
            </el-button>
          </span>
        </template>
    </el-dialog>

</template>

<script>
import functionsMixin from '../../../../../mixins/functionsMixin'
import MixOrderMaterialExpenseTable from '../MixOrderMaterialExpenseTable'
import NoDataIcon from '@/views/components/NoDataIcon'
import GoodUnit from '@/views/components/goodUnit'
import MixPageOrderMixCreate from '@/views/components/MixPage/Orders/OrderMix/MixPageOrderMixCreate'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'MixPageOrderDetailsOneMix',
    components: { MixPageOrderMixCreate, GoodUnit, NoDataIcon, MixOrderMaterialExpenseTable },
    props: [
        'mix',
        'goodId',
        'orderId',
        'tz'
    ],
    data() {
        return {
            createMixOrderMixDialog: false,
            createMix: false
        }
    },
    mixins: [
        functionsMixin, mobileCheckMixin, iconsMixin
    ],
    inject: [
        'api'
    ],
    methods: {
        createMixOrderMixDialogToTrue() {
            this.createMixOrderMixDialog = true;
        },
        createMixOrderMixDialogToFalse() {
            this.createMixOrderMixDialog = false;
        },
        setCreateMix(value) {
            this.createMix = value;
        },
        editOneMix() {
            console.log('edit')
        },
        deleteOneMix() {
            this.$confirm(
                'Вы действительно хотите удалить замес?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loading = true

                    httpService().post(this.api.mix.removeOrderBatch, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'mix',
                        batchId: this.mix.id,
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Замес удален',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('moduleGet')
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
