<template>
    <Auth title="Список пользователей" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    create-button-label="Пригласить пользователя"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="$router.push({name: 'UsersCreate', params: { id: $route.params.id }})"
                />

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Имя"
                            :body="item.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Телефон"
                            :body="item.phone ?? '-'"
                        />

                        <MobileTableInfo
                            title="E-mail"
                            :body="item.email ?? '-'"
                        />

                        <MobileTableInfo
                            title="Роль"
                            :body="item.role ?? '-'"
                        />

                        <MobileTableInfo
                            title="Был в сети"
                            :body="getDateFromUnix(item.activity_date)"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="$router.push({name: 'UsersEdit', params: { id: item.id}})"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    ref="table"
                    v-if="!mobile"
                    :data="displayData"
                    class="mt-15 w-100"
                >
                    <el-table-column
                        prop="activity"
                        label=""
                        width="20">
                        <template v-slot="scope">
                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 style="top: -1px; position: relative;">
                                <circle cx="4" cy="4" r="4" fill="#00d0a3" v-if="scope.row.activity === 2"/>
                                <circle cx="4" cy="4" r="4" fill="#ff5253" v-if="scope.row.activity === 1"/>
                                <circle cx="4" cy="4" r="4" fill="#a2a5bf" v-if="scope.row.activity === 0 || scope.row.banned"/>
                            </svg>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Имя">
                        <template v-slot="scope">
                            <span class="textMedium neutral700">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="phone"
                        label="Телефон">
                    </el-table-column>
                    <el-table-column
                        prop="email"
                        label="E-mail">
                    </el-table-column>
                    <el-table-column
                        prop="role"
                        label="Роль">
                        <template v-slot="scope">
                            <span class="textMedium primary600" v-if="!scope.row.banned">{{ scope.row.role }}</span>
                            <span class="textMedium neutral300" v-else>Заблокирован</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="activity_date"
                        label="Был в сети">
                        <template v-slot="scope">
                            <span v-if="scope.row.activity_date" class="textMedium success400">
                                {{ getDateFromUnix(scope.row.activity_date) }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                @click="$router.push({name: 'UsersEdit', params: { id: scope.row.id}})">Редактировать
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import { httpService } from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'UsersIndex',
    components: {
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TablePagination,
        TableHeadBlock,
        Auth
    },
    data() {
        return {
            loading: true,
            search: '',
            filtered: [],
            page: 1,
            pageSize: 10,
            valueLength: 0,
            tabs: [{
                route: 'UsersIndex',
                name: 'Пользователи'
            }, {
                route: 'UsersLogs',
                name: 'Действия'
            }]
        }
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.users

            })
        },
        setPage(val) {
            this.page = val
        },
        filterValues(values, search) {
            let filtered = values

            if (search) filtered = filtered.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))

            this.valueLength = filtered.length
            return filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
}
</script>

<style scoped>

</style>
