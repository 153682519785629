<template>
    <Auth title="Интеграции">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    create-button-label="Добавить интеграцию"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="$router.push({name: 'IntegrationCreate'})"
                />

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.integration.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Модуль получатель"
                            :body="item.account_module?.name ?? '-'"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Настроить"
                            @action="openIntegrationDrawer(index, item)"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile"
                    class="mt-15 w-100">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration.key"
                        label=""
                        width="180">
                        <template v-slot="scope">
                            <div class="integrationIndexIcon">
                                <VesySoftLogo v-if="scope.row.integration.key === 'VesySoft'"/>
                                <TTSSmartWeightLogo v-if="scope.row.integration.key === 'SmartWeight'"/>
                                <TTSSmartMixLogo v-if="scope.row.integration.key === 'SmartMix'"/>
                                <OneCBuhLogo v-if="scope.row.integration.key === '1C'"/>
                                <KIPConceptLogo v-if="scope.row.integration.key === 'ConceptBCY'"/>
                                <WialonLogo v-if="scope.row.integration.key === 'WialonMix'"/>
                                <GlonassSoftIcon v-if="scope.row.integration.key === 'GlonassSoft'"/>
                                <WhatsAppLogo v-if="scope.row.integration.key === 'Wappi'" />
                                <h3 v-if="scope.row.integration.key === 'Trekerserver'" style="text-transform:uppercase;">Trekerserver</h3>
                                <h3 v-if="scope.row.integration.key === 'Traccar'" style="text-transform:uppercase;">GPS</h3>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration.name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{ scope.row.integration.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="account_module.name"
                        label="Модуль получатель">
                        <template v-slot="scope">
                            <span class="textSM textMedium primary600" v-if="scope.row.account_module">{{ scope.row.account_module.name }}</span>
                            <span class="textSM textMedium neutral300" v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration.product"
                        label="Продукт"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{ scope.row.product ? getInfo(scope.row.product, TTSProducts) : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration.company"
                        label="Компания"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{ scope.row.company?.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button @click="openIntegrationDrawer(scope.$index, scope.row)" size="mini">
                                Настроить
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-drawer
            title=""
            v-model="integrationDrawer"
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">

            <IntegrationDetails
                @closeDrawer="integrationDrawerToFalse"
                @moduleGet="moduleGet"
                :integration="integrationDetails"
            />

        </el-drawer>

        <el-dialog
            v-model="integrationDialog"
            title="Интеграция с 1С Бухгалтерия"
            :width="mobile ? '100%' : '60%'"
            :destroy-on-close="true"
        >

            <IntegrationDetailsOneC
                @closeDrawer="integrationDrawerToFalse"
                @moduleGet="moduleGet"
                :integration="integrationDetails"
            />

        </el-dialog>

    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import IntegrationDetails from '@/views/components/SettingsPage/IntegrationsPage/IntegrationDetails'
import tableMixin from '@/mixins/tableMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import TablePagination from '@/views/components/Table/TablePagination'
import { httpService } from '@/services/http.service'
import VesySoftLogo from "@/views/components/Logo/VesySoftLogo.vue";
import GlonassSoftIcon from "@/views/components/Icons/GlonassSoftIcon.vue";
import TTSSmartWeightLogo from "@/views/components/Logo/TTSSmartWeightLogo.vue";
import KIPConceptLogo from "@/views/components/Icons/KIPConceptLogo.vue";
import TTSSmartMixLogo from "@/views/components/Logo/TTSSmartMixLogo.vue";
import OneCBuhLogo from "@/views/components/Logo/OneCBuhLogo.vue";
import WialonLogo from "@/views/components/Icons/WialonLogo.vue";
import WhatsAppLogo from "@/views/components/Logo/WhatsAppLogo.vue";
import IntegrationDetailsOneC from "@/views/components/SettingsPage/IntegrationsPage/IntegrationDetailsOneC.vue";
import functionsMixin from "@/mixins/functionsMixin";
import TTSProducts from "@/mixins/TTSProducts";

export default {
    name: 'IntegrationsIndex',
    data() {
        return {
            integrations: [],
            loading: true,
            integrationDrawer: false,
            integrationDialog: false,
            integrationDetails: [],
        }
    },
    components: {
        IntegrationDetailsOneC,
        WhatsAppLogo,
        WialonLogo,
        OneCBuhLogo,
        TTSSmartMixLogo,
        KIPConceptLogo,
        TTSSmartWeightLogo,
        GlonassSoftIcon,
        VesySoftLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        IntegrationDetails,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin, TTSProducts
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        integrationDrawerToFalse() {
            this.integrationDialog = false
            this.integrationDrawer = false
        },
        moduleGet() {
            this.loading = true
            httpService().post(this.api.settings.getIntegrations).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.integrations

            })
        },
        openIntegrationDrawer(index, row) {
            this.integrationDetails = []
            if (row.integration.key === '1C') {
                this.integrationDialog = true
            } else {
                this.integrationDrawer = true
            }
            this.integrationDetails = row
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.account_module?.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
