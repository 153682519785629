<template>
    <el-form-item
        :label="label"
        v-bind:class="{ 'is-required': required }"
    >
        <el-input
            :type="type ?? 'text'"
            v-model="itemValue"
            @input="$emit('update:modelValue', itemValue)"
            :placeholder="placeholder"
            :show-password="type === 'password'"
            :disabled="disabled"
            :readonly="readonly"
            v-maska
            :data-maska="inputMask"
            :data-maska-tokens="maskTokens"
            @change="$emit('changeAction')"
            @focusout="$emit('focusoutAction')"
        />
    </el-form-item>
</template>

<script>

import {vMaska} from "maska";

export default {
    name: "FormInput",
    props: [
        'modelValue',
        'label',
        'placeholder',
        'type',
        'disabled',
        'readonly',
        'required',
        'inputMask',
        'maskTokens'
    ],
    data () {
        return {
            itemValue: this.modelValue
        }
    },
    directives: {
        maska: vMaska
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
