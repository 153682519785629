<template>
    <Auth title="Создание заказа" :tabs="tabs">

        <div class="card">
            <div class="card-body">

                <el-form ref="form" class="mt-30" v-loading="loading" :model="form" size="small" label-position="top">

                    <div v-if="step === 1">

                        <el-row :gutter="20">

                            <el-col :sm="8">

                                <div class="p-15">

                                    <div class="primary500 textLG textMedium mb-15">
                                        Основные данные по заказу
                                    </div>

                                    <el-row :gutter="20">
                                        <el-col :sm="14">
                                            <FormDateTimePicker
                                                v-model="form.date_shipment"
                                                label="Дата доставки"
                                                :required="true"
                                                size="default"
                                                @changeAction="resetOrder()"
                                            />
                                        </el-col>
                                        <el-col :sm="10">
                                            <el-form-item class="is-required"
                                                          :label="form.delivery_type==='delivery' ? 'Время первой доставки' : 'Время первой загрузки'">
                                                <el-input
                                                    v-model="form.first_order_time_delivery"
                                                    @focusout="resetOrder()"
                                                    placeholder="00:00"
                                                    type="time"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-form-item label="Тип клиента">
                                        <el-radio-group v-model="form.individual" @change="resetOrder()">
                                            <el-radio :label="false">Юр.лицо</el-radio>
                                            <el-radio :label="true">Физ.лицо</el-radio>
                                        </el-radio-group>
                                    </el-form-item>

                                    <FormSelect
                                        v-if="!form.individual"
                                        v-model="form.buyer_company_id"
                                        label="Контрагент"
                                        placeholder="Выберите контрагента"
                                        new-item="company"
                                        :required="true"
                                        :new-item-data="form.buyer_company_id"
                                        :items="companies"
                                        @changeValue="changeBuyer()"
                                    />

                                    <FormNumber
                                        v-model="form.total"
                                        label="Объем продукции"
                                        placeholder="30"
                                        precision="2"
                                        :required="true"
                                        @focusoutAction="resetOrder()"
                                    />

                                    <p v-if="form.comment" class="textRegular textSM neutral400"><span
                                        class="neutral900 textMedium">Комментарий:</span> {{ form.comment }}</p>

                                </div>

                            </el-col>

                            <el-col :sm="8"
                                    :style="!form.total ? 'opacity: 0.2' : 'opacity: 1' + '; transition: 0.6s all;'">
                                <div class="p-15 pb-0">

                                    <div class="primary500 textLG textMedium mb-15">
                                        Контрагент и адрес доставки
                                    </div>

                                    <el-form-item label="Тип доставки">
                                        <el-radio-group v-model="form.delivery_type" @change="resetOrder()">
                                            <el-radio label="delivery">Доставка</el-radio>
                                            <el-radio label="take-away">Самовывоз</el-radio>
                                        </el-radio-group>
                                    </el-form-item>

                                    <template v-if="!form.individual">

                                        <FormEditShortData v-model="form.delivery_address_id" label="Адрес доставки"
                                                           :required="true"
                                                           :auto-edit-false="true"
                                                           v-if="form.delivery_type==='delivery'">
                                            <template v-slot:edit>
                                                <FormSelect
                                                    v-model="form.delivery_address_id"
                                                    :additional-info="selectedBuyerName"
                                                    :disabled="!form.buyer_company_id"
                                                    label-key="address"
                                                    label="Адрес доставки"
                                                    placeholder="Выберите адрес доставки"
                                                    new-item="companyAddress"
                                                    :required="form.delivery_type==='delivery'"
                                                    :new-item-data="form.buyer_company_id"
                                                    @changeValue="resetOrder()"
                                                    :items="form.buyer_company_id ? getData(form.buyer_company_id, 'company', 'addresses') : []"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.delivery_address_id">
                                                    <template v-if="!form.buyer_company_id">
                                                        Выберите контрагента
                                                    </template>
                                                    <template v-else>
                                                        Адрес не указан
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    {{
                                                        getData(form.buyer_company_id, 'company', 'addresses').find(el => el.id === form.delivery_address_id).name
                                                    }}
                                                    <template v-if="timeInfo.toObject"> - {{ timeInfo.toObject }} мин. /
                                                        {{ dist.distToObject }} км.
                                                    </template>
                                                </template>
                                            </template>
                                        </FormEditShortData>

                                        <FormEditShortData v-model="form.buyer_company_manager_id"
                                                           label="Представитель контрагента"
                                                           v-if="form.delivery_type==='delivery'">
                                            <template v-slot:edit>
                                                <FormSelect
                                                    v-model="form.buyer_company_manager_id"
                                                    :disabled="!form.buyer_company_id"
                                                    label="Представитель контрагента"
                                                    placeholder="Укажите ФИО представителя"
                                                    new-item="companyManager"
                                                    :new-item-data="form.buyer_company_id"
                                                    :items="form.buyer_company_id ? getData(form.buyer_company_id, 'company', 'managers') : []"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.buyer_company_manager_id">
                                                    <template v-if="!form.buyer_company_id">
                                                        Выберите контрагента
                                                    </template>
                                                    <template v-else>
                                                        Представитель не выбран
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    {{
                                                        getData(form.buyer_company_id, 'company', 'managers').find(el => el.id === form.buyer_company_manager_id).name
                                                    }}
                                                </template>
                                            </template>
                                        </FormEditShortData>

                                        <el-form-item label="Сложность дороги" v-if="form.delivery_type==='delivery'">
                                            <el-radio-group v-model="form.delivery_difficulty_new"
                                                            @change="changeDeliveryDifficulty()" size="default">
                                                <el-radio-button label="1">Обычная</el-radio-button>
                                                <el-radio-button label="2">Непросто</el-radio-button>
                                                <el-radio-button label="3">Вызов</el-radio-button>
                                                <el-radio-button label="4">Экстрим</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>

                                    </template>
                                    <template v-else>
                                        <IndividualCreate
                                            v-model="form"
                                        />
                                    </template>

                                </div>

                                <hr class="mt-0 pt-0"/>

                                <div class="p-15">

                                    <div class="d-flex-full">
                                        <div class="primary500 textLG textMedium mb-15 pointer"
                                             @click="form.pump = !form.pump">
                                            <div>Насос</div>
                                            <span
                                                class="neutral400 textSM textRegular">Заполните. если необходим насос</span>
                                        </div>
                                        <div>
                                            <el-switch v-model="form.pump"/>
                                        </div>
                                    </div>

                                    <template v-if="form.pump">

                                        <el-form-item label="Контрагент">
                                            <el-select v-model="form.pumpInfo.company_id"
                                                       filterable>
                                                <el-option v-for="(item, index) in companies" :key="index"
                                                           :label="item.name"
                                                           :value="item.id"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <FormInputSuggestions
                                            v-model="form.pumpInfo.vehicle_number"
                                            :items="vehicles.filter(el => el.type === 'pump')"
                                            label="Авто"
                                            placeholder="Укажите Номер ТС"
                                        />

                                        <FormInput
                                            v-model="form.pumpInfo.company_phone"
                                            label="Телефон контрагента"
                                            placeholder="Укажите телефон"
                                            inputMask="+7 ### ###-##-##"
                                        />

                                        <FormInput
                                            v-model="form.pumpInfo.driver_name"
                                            label="ФИО водителя"
                                            placeholder="Укажите ФИО водителя"
                                        />

                                        <FormInput
                                            v-model="form.pumpInfo.driver_phone"
                                            label="Телефон водителя"
                                            placeholder="Укажите телефон"
                                            inputMask="+7 ### ###-##-##"
                                        />

                                    </template>
                                </div>

                            </el-col>

                            <el-col :sm="8"
                                    :style="!form.total ? 'opacity: 0.2' : 'opacity: 1' + '; transition: 0.6s all;'">
                                <div class="p-15 pb-0">

                                    <div class="primary500 textLG textMedium mb-15">
                                        Поставщик и перевозчик
                                    </div>

                                    <FormEditShortData v-model="form.seller_company_id" label="Поставщик"
                                                       :required="true">
                                        <template v-slot:edit>
                                            <FormSelect
                                                v-model="form.seller_company_id"
                                                label="Поставщик"
                                                placeholder="Выберите поставщика"
                                                new-item="company"
                                                :new-item-data="form.seller_company_id"
                                                company-type="seller"
                                                :items="companies.filter(el => el.type === 'seller')"
                                                @changeValue="resetOrder()"
                                                :required="true"
                                            />
                                        </template>
                                        <template v-slot:info>
                                            <template v-if="!form.seller_company_id">
                                                Поставщик не выбран
                                            </template>
                                            <template v-else>
                                                {{ getData(form.seller_company_id, 'company') }}
                                            </template>
                                        </template>
                                    </FormEditShortData>

                                    <template v-if="!moreInfo">

                                        <el-button class="mt-0 pt-0 neutral500 text-underline" type="text"
                                                   @click="moreInfo = !moreInfo">Дополнительные данные
                                        </el-button>

                                    </template>
                                    <template v-else>

                                        <FormEditShortData v-model="form.carrier_company_id" label="Перевозчик">
                                            <template v-slot:edit>
                                                <FormSelect
                                                    v-model="form.carrier_company_id"
                                                    label="Перевозчик"
                                                    placeholder="Выберите перевозчика"
                                                    new-item="company"
                                                    :carrier-company="true"
                                                    :new-item-data="form.carrier_company_id"
                                                    :items="companies.filter(el => el.carrier)"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.carrier_company_id">
                                                    Перевозчик не выбран
                                                </template>
                                                <template v-else>
                                                    {{ getData(form.carrier_company_id, 'company') }}
                                                </template>
                                            </template>
                                        </FormEditShortData>

                                        <FormEditShortData v-model="form.manager_id" label="Менеджер">
                                            <template v-slot:edit>

                                                <el-form-item label="Менеджер">
                                                    <el-select v-model="form.manager_id"
                                                               filterable>
                                                        <el-option v-for="(item, index) in users" :key="index"
                                                                   :label="item.name"
                                                                   :value="item.id"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.manager_id">
                                                    Менеджер не выбран
                                                </template>
                                                <template v-else>
                                                    {{ users.find(el => el.id === form.manager_id).name }}
                                                </template>
                                            </template>
                                        </FormEditShortData>

                                    </template>

                                </div>

                                <hr class="mt-0 pt-0"/>

                                <div class="p-15">

                                    <div class="primary500 textLG textMedium mb-15">
                                        Продукция и доставка
                                    </div>

                                    <FormEditShortData
                                        v-model="form.good_id"
                                        label="Продукция"
                                        :required="true"
                                    >
                                        <template v-slot:edit>

                                            <el-form-item
                                                label="Продукция"
                                                class="is-required"
                                            >
                                                <el-select
                                                    v-model="form.good_id"
                                                    @change="getOrderGroupInfoCreate"
                                                    filterable
                                                >
                                                    <el-option
                                                        v-for="(item, index) in mixGoods"
                                                        :key="index"
                                                        :label="item.name"
                                                        :value="item.id"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>

                                        </template>
                                        <template v-slot:info>
                                            <template v-if="!form.good_id">
                                                Продукция не выбрана
                                            </template>
                                            <template v-else>
                                                {{ mixGoods.find(el => el.id === form.good_id).name }}
                                            </template>
                                        </template>
                                    </FormEditShortData>

                                    <el-button v-if="!goodInfo" @click="showGoodInfo" type="text">Показать расширенные
                                        настройки
                                    </el-button>

                                    <template v-if="goodInfo">

                                        <FormEditShortData v-model="form.good_price" :auto-edit-false="true"
                                                           label="Стоимость продукции">
                                            <template v-slot:edit>
                                                <FormNumber
                                                    v-model="form.good_price"
                                                    label="Стоимость продукции"
                                                    placeholder="Укажите стоимость за 1 м3"
                                                    precision="2"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.good_price">
                                                    Стоимость не указана
                                                </template>
                                                <template v-else>
                                                    {{ formatNumber(form.good_price) }} руб.
                                                </template>
                                            </template>
                                        </FormEditShortData>

                                        <FormEditShortData v-model="form.delivery_price"
                                                           label="Стоимость доставки одного миксера"
                                                           :auto-edit-false="true"
                                                           v-if="form.delivery_type === 'delivery'">
                                            <template v-slot:edit>
                                                <FormNumber
                                                    v-if="form.delivery_type !== 'take-away'"
                                                    v-model="form.delivery_price"
                                                    label="Стоимость доставки одного миксера"
                                                    placeholder="Укажите стоимость доставки"
                                                    precision="2"
                                                    @focusoutAction="deliveryPriceEditTrue"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.delivery_price">
                                                    Стоимость не указана
                                                </template>
                                                <template v-else>
                                                    {{ formatNumber(form.delivery_price) }} руб.
                                                </template>
                                            </template>
                                        </FormEditShortData>

                                        <FormEditShortData v-model="form.payment_method" label="Метод оплаты">
                                            <template v-slot:edit>
                                                <FormSelect
                                                    v-model="form.payment_method"
                                                    label="Метод оплаты"
                                                    placeholder="Выберите метод"
                                                    :items="paymentMethods"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.payment_method">
                                                    Метод не выбран
                                                </template>
                                                <template v-else>
                                                    {{ paymentMethods.find(el => el.id === form.payment_method).name }}
                                                </template>
                                            </template>
                                        </FormEditShortData>


                                        <FormEditShortData v-model="form.prepayment" label="Предоплата"
                                                           v-if="form.payment_method==='cash'">
                                            <template v-slot:edit>
                                                <FormNumber
                                                    v-model="form.prepayment"
                                                    label="Предоплата"
                                                    placeholder="5000"
                                                />
                                            </template>
                                            <template v-slot:info>
                                                <template v-if="!form.prepayment">
                                                    Предоплата не указана
                                                </template>
                                                <template v-else>
                                                    {{ formatNumber(form.prepayment) }} руб.
                                                </template>
                                            </template>
                                        </FormEditShortData>
                                    </template>
                                </div>

                            </el-col>

                        </el-row>

                        <hr/>

                        <div class="mt-30 text-right">

                            <button @click="nextStep()"
                                    style="display: inline"
                                    :disabled="!form.date_shipment || !form.buyer_company_id || !form.total || !form.good_id"
                                    class="ci-btn ci-btn_blue">
                                Планирование
                            </button>

                        </div>

                    </div>
                    <div v-if="step === 2">

                        <div :style="!form.total ? 'opacity: 0.2' : 'opacity: 1' + '; transition: 0.6s all;'">

                            <div class="p-15">
                                <div class="primary500 textLG textMedium mb-15">
                                    Распределение заявок
                                </div>

                                <MixOrderGroupCreateDeliveryTime
                                    v-model="form"
                                    @resetOrder="resetOrder()"
                                />
                            </div>

                            <template
                                v-if="!form.date_shipment || !form.buyer_company_id || !form.good_id || !form.total || errorOrderInfo || !form.time_unloading">
                                <div class="p-15 pt-0">
                                    <div id="emptyStateMix">
                                        <div>
                                            <h3 class="neutral700 mb-5">Недостаточно данных</h3>
                                            <p class="neutral700 mt-0 mb-30">Заполните все обязательные поля для
                                                распределения заказа</p>
                                        </div>
                                        <MixerNoDataIcon/>
                                    </div>
                                </div>
                            </template>
                            <template v-else>

                                <div class="p-15">
                                    <div class="gantt-card">
                                        <div class="gantt-card-body">
                                            <el-button @click="openDialog()" type="text"
                                                       class="neutral300 textXS textRegular mb-15 mt-0">Загруженность
                                                автомобилей на сегодня
                                            </el-button>

                                            <MixPageGantt
                                                v-if="ganttLoad"
                                                :rows="gantt.rows"
                                                :tasks="gantt.tasks"
                                                :orders="orders"
                                                :label-new-orders="getData(form.buyer_company_id, 'company')"
                                                :key="gantt"
                                                :time-info="timeInfo"
                                                :date="form.date_shipment"
                                                :disable-modal="true"
                                                :time-from="timeFrom"
                                                :time-to="timeTo"
                                            />

                                        </div>
                                    </div>

                                    <el-table :data="displayData" style="width: 100%">
                                        <el-table-column prop="vehicle_id" label="Авто">
                                            <template v-slot="scope">
                                                <template v-if="form.delivery_type !== 'take-away'">
                                                    <el-select
                                                        v-model="scope.row.vehicle_id"
                                                        class="mix-page-order-group-create__select"
                                                        filterable>
                                                        <el-option
                                                            v-for="(item, index) in mixers.filter(el => el.id !== 999999999)"
                                                            :key="index"
                                                            :label="item.number"
                                                            :value="item.id"></el-option>
                                                    </el-select>

                                                    <FormInputSuggestions
                                                        v-if="scope.row.rent_mixer"
                                                        v-model="scope.row.vehicle_number"
                                                        :items="vehicles.filter(el => el.rent === true && el.type === 'mix')"
                                                        label="Авто"
                                                        placeholder="Укажите Номер ТС"
                                                    />

                                                </template>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="total" label="Объем">
                                            <template v-slot="scope">

                                                <div class="d-flex">
                                                    <el-input
                                                        class="custom-input"
                                                        v-model="scope.row.total"
                                                        type="number"
                                                        placeholder="30"
                                                        precision="2"
                                                        step="0.01"
                                                    />
                                                    <el-button
                                                        @click="openAddClientTotalDialog(scope.row)"
                                                        :class="'label-icon ' + (scope.row.clientTotal !== scope.row.total && scope.row.clientTotal > 0 ? 'label-icon-active' : '')"
                                                        :icon="icons.edit"/>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="time_load" label="Загрузка">
                                            <template v-slot="scope">
                                                <el-time-select
                                                    @change="changedOrder(scope.row, scope.row.id,'load',scope.row.time_load)"
                                                    v-model="scope.row.time_load"
                                                    class="mix-page-order-group-create__select"
                                                    start="00:00"
                                                    step="00:15"
                                                    end="23:45"
                                                    placeholder="00:00"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="time_object" label="На объекте">
                                            <template v-slot="scope">
                                                <el-time-select
                                                    @change="changedOrder(scope.row, scope.row.id,'load',scope.row.time_object)"
                                                    v-model="scope.row.time_object"
                                                    class="mix-page-order-group-create__select"
                                                    start="00:00"
                                                    step="00:15"
                                                    end="23:45"
                                                    placeholder="00:00"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="id" label="" width="120">
                                            <template v-slot="scope">
                                                <CloseIcon @click="changeCountVehicles('subtract', scope.row.id)"
                                                           color="#8a909a"
                                                           class="pointer"/>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                    <TablePagination
                                        v-model="pageSize"
                                        :total="valueLength"
                                        @action="setPage"
                                    />

                                    <el-button class="mt-30" @click="changeCountVehicles('add', null)">Добавить авто
                                    </el-button>

                                    <p class="textRegular textMedium textXS mb-0">Распределено: {{ getOrdersSum() }} из {{ form.total }}</p>

                                </div>

                                <hr/>

                                <div class="p-15">
                                    <div
                                        :style="!form.total ? 'opacity: 0.2' : 'opacity: 1' + '; transition: 0.6s all;'">

                                        <div class="primary500 textLG textMedium mb-15 mt-15">
                                            Данные для операторов и водителей
                                        </div>

                                        <el-row :gutter="20" class="mt-15">
                                            <el-col :sm="12">
                                                <FormInput
                                                    v-model="form.comment_operator"
                                                    label="Комментарий оператору"
                                                    placeholder="Напишите комментарий"
                                                />
                                            </el-col>
                                            <el-col :sm="12" v-if="form.delivery_type === 'delivery'">
                                                <FormInput
                                                    v-model="form.comment_driver"
                                                    label="Комментарий водителю"
                                                    placeholder="Напишите комментарий"
                                                />
                                            </el-col>

                                        </el-row>

                                    </div>
                                </div>
                            </template>
                        </div>


                        <hr/>

                        <div class="p-15">
                            <div class="mb-30 mt-20"
                                 v-if="form.date_shipment && form.buyer_company_id && form.good_id && form.total && !errorOrderInfo">
                                <div
                                    style=" display: flex; -webkit-box-pack: end!important; -ms-flex-pack: end!important; justify-content: flex-end!important; ">
                                    <div class="ci-btn ci-btn_default mr-10"
                                         style="display: inline"
                                         type="button"
                                         @click="previousStep()">
                                        К настройкам заказа
                                    </div>
                                    <div v-if="form.doc" class="ci-btn ci-btn_default"
                                         style="display: inline"
                                         type="button"
                                         @click="$router.push({ name: 'MixIndex', params: { id: $route.params.id } })">
                                        Отмена
                                    </div>
                                    <button @click="createOrder()" :disabled="disableCreateButton"
                                            style="display: inline"
                                            type="button"
                                            class="ci-btn ci-btn_blue mr-10">
                                        Создать заказ
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </el-form>

            </div>
        </div>

        <el-dialog
            v-model="addClientTotal"
            title="Корректировка объема отгрузки"
            :width="mobile ? '100%' : '35%'"
            :destroy-on-close="true">

            <el-form label-position="top" size="small">

                <FormNumber
                    v-model="clientTotal"
                    label="Запрошенный объем (для клиента)"
                    placeholder="8"
                    step="0.1"
                />

                <FormNumber
                    v-model="factTotal"
                    label="Запланированный объем (для завода)"
                    placeholder="8.2"
                    step="0.1"
                />

                <FormAddButton
                    @action="changeClientTotal()"
                    title="Внести корректировку"
                />

                <hr/>

                <p class="neutral300 textSM textRegular">Корректировка помогает учесть в заявке различия между данными
                    для оборудования и для дальнейшей учетной документации</p>
                <p class="neutral300 textSM textRegular">Например: клиент заказал 8 м.куб, однако, в миксер вы
                    планируете залить на 0.3 куба больше, чтобы был запас. В этом случае в запрошенный объем идет 8
                    кубов, а в запларониванный 8.3. Аналогично работают расхождения и в обратную сторону</p>

            </el-form>

        </el-dialog>

        <el-dialog
            v-model="changeMixersDialog"
            title="Изменить доступный транспорт"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <el-checkbox @change="updateActual(mixer)" v-for="(mixer, index) in mixersData" :key="index"
                         v-model="mixer.actual"
                         :label="mixer.number" :disabled="loading"/>
        </el-dialog>

    </Auth>
</template>

<script>
import {
    mapState,
    mapStores
} from 'pinia';
import {useCommonStore} from "@/store/common";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import Auth from "@/views/layouts/Auth.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import CloseIcon from "@/views/components/Icons/CloseIcon.vue";
import FormInputSuggestions from "@/views/components/Form/FormInputSuggestions.vue";
import moment from "moment";
import MixerNoDataIcon from "@/views/components/Icons/MixerNoDataIcon.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import MixPageGantt from "@/views/components/MixPage/OrdersGroup/MixPageGantt.vue";
import iconsMixin from "@/mixins/iconsMixin";
import FormAddButton from "@/views/components/Form/FormAddButton.vue";
import IndividualCreate from "@/views/components/MixPage/OrdersGroup/IndividualCreate.vue";
import MixOrderGroupCreateDeliveryTime
    from "@/views/components/MixPage/OrdersGroup/MixOrderGroupCreateDeliveryTime.vue";
import {useMixStore} from '@/store/mix';
import FormEditShortData from "@/views/components/Form/FormEditShortData.vue";
import paymentMethods from "@/mixins/paymentMethods";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";

export default {
    name: "MixPageOrderGroupCreate",
    components: {
        TablePagination,
        FormEditShortData,
        MixOrderGroupCreateDeliveryTime,
        IndividualCreate,
        FormAddButton,
        MixPageGantt,
        FormSelect,
        MixerNoDataIcon,
        FormInputSuggestions, CloseIcon, FormInput, Auth, FormNumber, FormDateTimePicker
    },
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, paymentMethods, tableMixin],
    inject: ['api'],
    data() {
        return {
            step: 1,
            loading: false,
            docNext: 0,
            mixGoods: [],
            addClientTotal: false,
            clientTotal: null,
            factTotal: null,
            selectedOrderId: 0,
            mixers: [],
            moreInfo: true,
            form: {
                good_id: null,
                good_price: null,
                individual: false,
                delivery_address_id: null,
                delivery_difficulty: 1,
                delivery_difficulty_new: 1,
                delivery_difficulty_old: 1,
                delivery_price_manual_edit: false,
                delivery_type: 'delivery',
                delivery_price: null,
                delivery_price_first: null,
                seller_company_id: null,
                buyer_company_id: null,
                buyer_company_manager_id: null,
                buyer_individual_name: null,
                buyer_individual_phone: null,
                buyer_individual_address: null,
                manager_id: null,
                total: null,
                doc: null,
                time_unloading: null, // время разгрузки
                first_order_time_delivery: null, // время первой доставки
                comment: null,
                comment_operator: null,
                comment_driver: null,
                date_shipment: moment().utcOffset(0, true).startOf('day'),
                orders_count: 0,
                carrier: true,
                carrier_company_id: null,
                payment_method: null,
                prepayment: null,
                pump: false,
                pumpInfo: {
                    company_id: null,
                    vehicle_number: null,
                    company_phone: null,
                    driver_name: null,
                    price: null,
                    driver_phone: null
                },
                type_delivery: 'withoutInterval',
                type_mixers_count: 'many',
                first_load: true
            },
            loadingPhone: true,
            orders: [],
            time: 0,
            timeInfo: {
                toObject: 0,
                return: 0,
                loading: 0,
                unloading: 0
            },
            dist: {
                all: 0,
                distToObject: 0,
                distReturn: 0
            },
            vehicles: [],
            disableCreateButton: true,
            users: [],
            errorOrderInfo: true,
            gantt: {
                rows: [],
                tasks: []
            },
            ganttLoad: false,
            orderDeleteId: null,
            changeMixersDialog: false,
            mixersData: [],
            tabs: [{
                route: 'MixOrdersGroups',
                name: 'Заказы'
            }, {
                route: 'MixOrdersGroupsOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixOrdersMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGroupGoods',
                name: 'Рецепты'
            }],
            goodInfo: false,
            timeFrom: moment().startOf('day').add(6, 'hours'),
            timeTo: moment().endOf('day')
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            user: 'getUser',
        }),
        ...mapStores(useMixStore),
        selectedBuyerName: function () {
            return this.companies?.find(({id}) => id === this.form.buyer_company_id)?.name;
        }
    },
    created() {
        this.moduleGet()
        httpService().post(this.api.vehicle.get).then((response) => {
            let data = response.data

            if (data.success)
                this.vehicles = data.vehicles
        })
        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success) {
                this.users = data.users

                if (this.user && !this.form.manager_id) this.form.manager_id = this.user.id
            }
        })

        this.getOrderGroupInfoCreate()
    },
    methods: {
        deliveryPriceEditTrue() {
            this.form.delivery_price_manual_edit = true
        },
        openDialog() {
            this.changeMixersDialog = true
            this.loading = true
            this.getMixers()
            this.loading = false
        },
        getMixers() {
            httpService().post(this.api.mix.getMixers, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix'
            }).then((response) => {
                let data = response.data

                if (data.success) this.mixersData = data.mixers
            })
        },
        showGoodInfo() {
            this.goodInfo = !this.goodInfo
        },
        updateActual(mixer) {
            this.loading = true

            httpService().post(this.api.mix.updateActual, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                mixerId: mixer.id,
                actual: mixer.actual
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.getMixers()
                    this.loading = false
                    this.resetOrder()
                } else {
                    this.loading = false
                }
            })
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.mix.getGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.mixGoods = data.values.filter(el => el.actual)
            })

            httpService().post(this.api.mix.getOrderGroups, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                filter: this.filter
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.orders
                this.doc = data.doc
                this.loading = false
            })

        },
        resetOrder() {
            this.orders = []
            this.timeInfo = {
                toObject: 0,
                return: 0,
                loading: 0,
                unloading: 0
            }
            this.form.orders_count = null

            window.setTimeout(() => {
                this.getOrderGroupInfoCreate()
            }, 150)
        },
        getOrdersSum() {
            let sum = 0
            this.orders.filter(el => el.new).forEach(el => sum = Math.floor(sum) + Math.floor(el.total))
            return sum
        },
        createOrder() {
            this.loading = true

            httpService().post(this.api.mix.createOrderGroup, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                form: this.form,
                orders: this.orders,
                timeInfo: this.timeInfo,
                dist: this.dist
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })

                    window.setTimeout(() => {
                        this.$router.push({name: 'MixIndex', params: {id: this.$route.params.id}})
                    }, 90)
                } else {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        previousStep() {
            this.step--
        },
        nextStep() {
            this.step++
        },
        openAddClientTotalDialog(order) {
            var idx = this.orders.indexOf(order)
            this.selectedOrderId = idx
            this.clientTotal = order.clientTotal !== 0 ? order.clientTotal : order.total
            this.factTotal = order.total
            this.addClientTotal = true
        },
        changeClientTotal() {
            this.orders[this.selectedOrderId].clientTotal = this.clientTotal
            this.orders[this.selectedOrderId].total = this.factTotal

            this.addClientTotal = false
            this.selectedOrderId = 0
            this.clientTotal = null
            this.factTotal = null
        },
        checkOrders() {
            this.orders.forEach((el) => {
                if (!el.total || !el.time_load || !el.time_object) {
                    this.disableCreateButton = true
                } else {
                    this.disableCreateButton = false
                }
            })
        },
        changeDeliveryDifficulty() {
            this.form.delivery_difficulty_old = Math.floor(this.form.delivery_difficulty)
            this.form.delivery_difficulty = Math.floor(this.form.delivery_difficulty_new)

            this.getOrderGroupInfoCreate()
        },
        changeBuyer() {
            this.form.delivery_address_id = null
            this.form.buyer_company_manager_id = null
        },
        changeCountVehicles(action, orderId) {
            if (action === 'add') this.form.orders_count++
            else this.form.orders_count--

            if (this.form.orders_count === 0) this.form.orders_count = 1
            this.orderDeleteId = orderId
            this.getOrderGroupInfoCreate()
        },
        getOrderGroupInfoCreate() {

            if (!this.errorOrderInfo) this.errorOrderInfo = true

            httpService().post(this.api.mix.getOrderGroupInfoCreate, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                form: this.form,
                orders: this.orders,
                orderDeleteId: this.orderDeleteId,
                time: this.time,
                dist: this.dist,
                timeInfo: this.timeInfo,
                orderId: this.$route.params.orderId ?? null
            }).then((response) => {
                let data = response.data

                if (this.$route.params.orderId && this.form.first_load) {
                    this.form.buyer_company_id = data.form.buyer_company_id
                    this.form.good_id = data.form.good_id
                    this.form.delivery_type = data.form.delivery_type
                    this.form.total = data.form.total
                    this.form.good_price = data.form.good_price
                    this.form.date_shipment = data.form.date_shipment
                    this.form.first_order_time_delivery = data.form.first_order_time_delivery
                    this.form.delivery_address_id = data.form.delivery_address_id
                    this.form.buyer_company_manager_id = data.form.buyer_company_manager_id
                    this.form.comment = data.form.comment
                    this.form.payment_method = data.form.payment_method
                    this.form.doc = data.form.doc

                    this.form.first_load = false
                }

                if (data.success) {
                    this.orders = this.updateOrdersMinutesDiffLoadObject(data.orders)
                    this.time = data.time
                    this.timeInfo = data.timeInfo
                    this.dist = data.dist
                    this.form.orders_count = data.ordersCount
                } else {
                    this.errorOrderInfo = true
                }

                this.gantt = data.gantt
                this.mixers = data.gantt.rows
                this.ganttLoad = true
                this.orderDeleteId = null
                this.loading = false
                this.errorOrderInfo = false
                this.form.time_unloading = data.time_unloading
                this.form.delivery_price = data.delivery_price
                if (!this.form.delivery_price_first) this.form.delivery_price_first = data.delivery_price
                if (!this.form.delivery_address_id) this.form.delivery_address_id = data.oldOrderInfo.delivery_address_id
                if (!this.form.carrier_company_id) this.form.carrier_company_id = data.oldOrderInfo.carrier_company_id
                if (!this.form.good_id) this.form.good_id = data.oldOrderInfo.good_id
                if (!this.form.seller_company_id) this.form.seller_company_id = data.oldOrderInfo.seller_company_id
                if (!this.form.buyer_company_manager_id) this.form.buyer_company_manager_id = data.oldOrderInfo.buyer_company_manager_id
                if (!this.form.first_order_time_delivery) this.form.first_order_time_delivery = data.form.first_order_time_delivery

                if (this.form.first_load && this.form.buyer_company_id && this.form.good_id && this.form.seller_company_id && this.form.total && this.orders.length === 0) {
                    this.form.first_load = false

                    this.getOrderGroupInfoCreate()
                }

                if (!this.form.first_order_time_delivery) this.form.first_order_time_delivery = moment().format('HH:mm')
                if (data.timeFrom && data.timeTo) {
                    this.timeFrom = moment(data.timeFrom).subtract(1,'hours')
                    this.timeTo = moment(data.timeTo).add(3,'hours')
                    console.log('getOrderGroupInfoCreate this.timeFrom', this.timeFrom)
                    console.log('getOrderGroupInfoCreate this.timeTo', this.timeTo)
                }
            }).catch(() => {
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.orders

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        updateOrdersMinutesDiffLoadObject(array) {
            return array.map((el) => {
                const timeLoad = moment(el.time_load, "HH:mm");
                const timeObject = moment(el.time_object, "HH:mm");
                const minutesDiff = timeObject.diff(timeLoad, "minutes");
                return {
                    ...el,
                    minutes_diff_object_load: minutesDiff,
                };
            });
        },
        changedOrder(order, id, time_type, newValue) {
            let timeLoad = moment(order.details.time_start, "HH:mm")
            let timeObject = moment(order.details.time_object, "HH:mm")

            let newTime = moment(newValue, "HH:mm")
            let minutesDiffLoad = newTime.diff(timeLoad, "minutes")

            let timeDone = moment(order.details.time_done, "HH:mm")

            let minutesDiffObject = newTime.diff(timeObject, "minutes")

            if (time_type === "load") {

                this.orders.find(el => el.id === id).time_object = moment(timeObject, "HH:mm")
                    .add(minutesDiffLoad, "minutes")
                    .format("HH:mm")
                this.orders.find(el => el.id === id).time_done = moment(timeDone, "HH:mm")
                    .add(minutesDiffLoad, "minutes")
                    .format("HH:mm")

            }
            if (time_type === "object") {

                this.orders.find(el => el.id === id).time_load = moment(timeLoad, "HH:mm")
                    .add(minutesDiffObject, "minutes")
                    .format("HH:mm");
                this.orders.find(el => el.id === id).time_done = moment(timeDone, "HH:mm")
                    .add(minutesDiffObject, "minutes")
                    .format("HH:mm")
            }
        },
        findSeller() {
            if (!this.form.seller_company_id && this.companies.length > 0) {
                if (this.companies.filter(el => el.type === 'seller').length === 1) {
                    this.form.seller_company_id = this.companies.filter(el => el.type === 'seller')[0].id
                }
            }
        }
    },
    watch: {
        'orders': {
            handler: function () {
                this.checkOrders()
            },
            deep: true,
            immediate: true
        },
        'form': {
            handler: function () {
                if (!this.form.first_load) {
                    if (this.form.buyer_company_id && this.form.buyer_company_manager_id) {
                        this.pullCompanies()
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'mixStore.filterDate.value': {
            handler: function (date) {
                console.log(1, date);
                if (date && date !== 'Invalid date') {
                    this.form.date_shipment = date;
                }
            },
            deep: true,
            immediate: true
        },
        'form.delivery_address_id': {
            handler: function () {
                if (!this.form.first_load) {
                    this.getOrderGroupInfoCreate()
                }
            },
            deep: true,
            immediate: true
        },
        'form.good_id': {
            handler: function () {
                if (!this.form.first_load) {
                    this.form.good_price = null
                    if (this.form.good_id) {
                        let elem = this.mixGoods.find((el => el.id === this.form.good_id))
                        if (elem) {
                            if (elem.priceSell) this.form.good_price = elem.priceSell
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'form.buyer_company_id': {
            handler: function () {
                if (!this.form.first_load) {
                    if (this.form.buyer_company_id) this.getOrderGroupInfoCreate()
                }
            },
            deep: false,
            immediate: true
        },
        'form.individual': {
            handler: function () {
                this.form.buyer_individual_name = null
                this.form.buyer_individual_address = null
                this.form.delivery_address_id = null
                this.form.buyer_company_id = null
                this.form.buyer_company_manager_id = null
                this.form.buyer_company_manager_phone = null

                if (this.form.individual) {
                    this.form.payment_method = 'cash'
                } else {
                    this.form.payment_method = 'bankWithVAT'
                }
            },
            deep: true,
            immediate: true
        },
        'form.carrier': {
            handler: function () {
                if (this.form.carrier && this.companies.length > 0) {
                    if (this.companies.filter(el => el.carrier).length === 1) {
                        this.form.carrier_company_id = this.companies.filter(el => el.carrier)[0].id
                    }
                } else {
                    this.form.carrier_company_id = null
                }
            },
            deep: false,
            immediate: true
        },
        'form.delivery_type': {
            handler: function () {
                if (this.form.delivery_type === 'take-away' && !this.form.first_load) {
                    this.form.carrier_company_id = null
                    this.form.carrier = false
                }
            },
            deep: false,
            immediate: true
        }
    }
}
</script>

<style>
.neutral800 {
    margin-bottom: 4px;
}

.el-input--small {
    --el-input-height: 36px;
}


.mix-page-order-group-create__select {
    .el-select__wrapper {
        height: 38px !important;
    }

    .el-select__placeholder {
        display: flex;
        align-items: center;
        height: 38px !important;
    }
}
</style>
