<template>
    <div v-if="order">

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="5">
                <span class="textSM textRegular neutral300">Контрагент:</span>
            </el-col>
            <el-col :sm="19">
                <span class="textSM textRegular neutral900">{{ order.buyer_company?.name ?? '-' }}</span>
            </el-col>
        </el-row>

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="5">
                <span class="textSM textRegular neutral300">Адрес доставки:</span>
            </el-col>
            <el-col :sm="19">
                <span class="textSM textRegular neutral900">{{
                        order.delivery_type === 'delivery' ? (order.delivery_address?.name ?? '-') : 'Самовывоз'
                    }}</span>
            </el-col>
        </el-row>

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="5">
                <span class="textSM textRegular neutral300">Объем:</span>
            </el-col>
            <el-col :sm="19">
                        <span class="textSM textRegular neutral900">{{
                                order.total ? formatNumber(order.total) : 'Не указан'
                            }}</span>
                <good-unit unit="м3."/>
            </el-col>
        </el-row>

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="5">
                <span class="textSM textRegular neutral300">Конструктив:</span>
            </el-col>
            <el-col :sm="19">
                <span class="textSM textRegular neutral900">{{ order.constructive ?? 'Не указан' }}</span>
            </el-col>
        </el-row>

        <el-row :gutter="15" class="mb-10">
            <el-col :sm="5">
                <span class="textSM textRegular neutral300">Комментарий:</span>
            </el-col>
            <el-col :sm="19">
                <span class="textSM textRegular neutral900">{{ order.comment ?? 'Не указан' }}</span>
            </el-col>
        </el-row>

        <hr/>

        <div v-for="(mix, index) in mixesData" :key="index">
            <div class="d-flex-full lab-spec-item mb-5" v-for="(mixer, index) in mix.mixers" :key="index">
                <div>
                    <el-checkbox v-model="mixer.checked"/>
                </div>
                <div>
                <span class="cutOverflow">{{
                        mix.name ?? '-'
                    }} - <span :class="mixer.checked ? 'primary600' : 'neutral200'">{{ mixer.name }}</span></span>
                </div>
                <div class="w-100">
                    <FormSelect
                        v-model="mixer.good_id"
                        label=""
                        placeholder="Выберите спецификацию"
                        new-item="good"
                        :disabled="!mixer.checked"
                        :good-ids="[details.good_id]"
                    />
                </div>
            </div>
        </div>

        <hr class="mt-15 mb-15"/>

        <button
            @click="saveSpec"
            class="ci-btn ci-btn_blue"
            :disabled="hasEmptyGoodId || loading"
        >
            Подтвердить выбор спецификации
        </button>
    </div>
</template>

<script>
import FormSelect from "@/views/components/Form/FormSelect.vue";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import GoodUnit from "@/views/components/goodUnit.vue";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "LabSpecCreate",
    components: {GoodUnit, FormSelect},
    mixins: [functionsMixin, iconsMixin],
    props: ['order', 'mixes'],
    computed: {
        hasEmptyGoodId() {
            return this.mixesData.some(mix => mix.mixers.some(mixer => mixer.checked && !mixer.good_id));
        }
    },
    inject: ['api'],
    data() {
        return {
            loading: false,
            goodId: null,
            details: {
                id: null,
                name: null,
                mixers: []
            },
            mixesData: []
        }
    },
    created() {
        this.details = this.order
        this.mixesData = this.mixes.map(mix => {
            return {
                ...mix,
                mixers: mix.mixers.map(mixer => {
                    const matchingDetailMixer = this.details.mixers.find(detailMixer => detailMixer.id === mixer.id)
                    return {
                        ...mixer,
                        checked: !!matchingDetailMixer,
                        good_id: matchingDetailMixer ? matchingDetailMixer.pivot.spec_good_id : null
                    }
                })
            }
        })
    },
    methods: {
        saveSpec() {
            this.loading = true

            httpService().post(this.api.lab.saveSpec, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'lab',
                orderId: this.order.id,
                mixers: this.mixesData.reduce((acc, mix) => {
                    mix.mixers.forEach(mixer => {
                        if (mixer.checked) {
                            acc.push(mixer);
                        }
                    });
                    return acc;
                }, [])
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Спецификация добавлена',
                        showClose: true,
                        type: 'success'
                    })
                    window.setTimeout(() => {
                        this.$emit('moduleGet')
                    }, 50)
                    window.setTimeout(() => {
                        this.$emit('closeDialog')
                    }, 150)
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
