<template>
    <div v-loading="loading">
        <div v-if="details">
            <div class="group-orders-status-text group-orders-status-text-small mb-15"
                 :class="'group-orders-status-text-' + details?.status">
                {{ getInfo(details?.status, documentStatuses) }}
            </div>

            <div class="d-flex-full-stroke">
                <h2 class="neutral900 mr-15 mb-0 mt-0">{{ details.name }}</h2>

                <el-button @click="$emit('edit', details)" :loading="loadingButton" type="text" :icon="icons.edit"
                           size="default"/>
            </div>
            <p class="textSM textRegular neutral300">Номер договора: <span
                class="textSM textRegular neutral900">{{ details.doc ?? '-' }}</span></p>
            <p class="textSM textRegular neutral300">Контрагент: <span
                class="textSM textRegular neutral900">{{ details.company?.name }}</span></p>
            <p class="textSM textRegular neutral300">Дата договора: <span
                class="textSM textRegular neutral900">{{ details.date ?getDateMoment(details.date, "DD.MM.yyyy") : 'не указана' }}</span></p>
            <div class="crmContractTable">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    :disable-create-button="true"
                />

                <el-table
                    :data="displayData"
                    class="w-100 mt-15 serviceTable"
                    :default-sort="{prop: 'id', order: 'descending'}"
                >
                    <el-table-column
                        prop="type"
                        label="Продукция"
                    >
                        <template v-slot="scope">
                            <span class="cutOverflow textSM textMedium neutral900">
                                {{ scope.row.good?.name ?? '-' }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="price"
                        label="Цена (за ед.)"
                        width="115"
                    >
                        <template v-slot="scope">
                            <span class="cutOverflow textSM textMedium"
                                  :class="scope.row.price ? 'success400' : 'neutral200'">
                                {{ scope.row.price ? formatNumber(scope.row.price, 2) : '-' }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="total"
                        label="Кол-во"
                        width="85"
                    >
                        <template v-slot="scope">
                            <span class="cutOverflow textSM textMedium"
                                  :class="scope.row.price ? 'neutral900' : 'neutral200'">
                                {{ scope.row.total ? formatNumber(scope.row.total, 2) : '-' }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="vat"
                        label="НДС (%)"
                        width="70"
                    >
                        <template v-slot="scope">
                            <span class="cutOverflow textSM textRegular"
                                  :class="scope.row.price ? 'neutral900' : 'neutral200'">
                                {{ scope.row.vat ? formatNumber(scope.row.vat) : 0 }}%
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="full_price"
                        label="Итого"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span class="cutOverflow textSM textMedium"
                                  :class="scope.row.price ? 'primary600' : 'neutral200'">
                                {{ scope.row.full_price ? formatNumber(scope.row.full_price, 2) : '-' }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>

            <div class="d-flex-full w-100 mt-30 mb-15 crm-buttons">
                <div class="textSM textMedium neutral900">
                    Итого: <span class="primary600">{{ formatNumber(full_price) }} руб.</span>
                </div>

                <div v-if="details.status === 'not-confirmed'">
                    <el-button @click="changeStatus('confirmed')" :icon="icons.check" type="success">Подтвердить</el-button>
                    <el-button @click="$emit('remove', details.id)" :icon="icons.delete"/>
                </div>
                <div v-else-if="details.status === 'confirmed'">
                    <el-button @click="changeStatus('archived')" :icon="icons.remove">В архив</el-button>
                    <el-button @click="changeStatus('done')" :icon="icons.check" type="success">Завершить</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";

export default {
    name: "CRMContractDetails",
    components: {TableHeadBlock, TablePagination},
    props: ['id'],
    mixins: [functionsMixin, documentStatusMixin, iconsMixin, tableMixin],
    data() {
        return {
            loading: false,
            loadingButton: false,
            details: {
                id: null,
                doc: null,
                name: null,
                company_id: null,
                date: null,
                integration_unit_id: null,
                goods: [],
                document_goods: [],
                type: 'contract',
                status: null
            },
            full_price: 0
        }
    },
    created() {
        if (this.id) this.getContract()
    },
    methods: {
        changeStatus(status) {
            this.loading = true
            httpService().post(this.api.document.changeStatus, {
                id: this.id,
                status: status
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.getContract()
                    this.$message({
                        message: 'Статус обновлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('update')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getContract() {
            this.loading = true
            httpService().post(this.api.document.getOne + '/' + this.id).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                    this.details = data.document
                    this.values = data.document.document_goods
                    this.calculateFullPriceInDetails()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.good.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        calculateFullPriceInDetails() {
            if (this.details.document_goods) {
                this.full_price = 0
                for (let i = 0; i < this.details.document_goods.length; i++) {
                    this.full_price += Math.abs(this.details.document_goods[i].full_price)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
