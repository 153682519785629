<template>
    <el-button-group
        class="group"
    >
        <el-button
            v-for="(item, index) in getDays"
            class="day-btn"
            :class="{ active: checkCurrent(item.date), today: checkToday(item.date) }"
            :key="index"
            :size="'large'"
            :disabled="loading"
            :type="'default'"
            @click="$emit('onChangeDate', item.date)"
        >
            <div class="inner">
                <span class="day">{{ item.day }}</span>
                <span class="num">{{ item.number }}</span>
            </div>
        </el-button>
    </el-button-group>
</template>

<script>

import moment from 'moment';
import { isSameDate } from '@/utils/common';

export default {
    name: "DayListFilter",
    components: {
    },
    props: [
        'date',
    ],
    data() {
        return {
            dayWeeks: [
                'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вc'
            ],
        }
    },
    watch: {
    },
    computed: {
        getDays() {
            let days = []
            for (let i = 1; i >= -5; i--) {
                let date = moment().subtract(i, "days").startOf('day')
                let number = date.format('DD')
                let day = date.format('e')

                days.push({
                    number: number,
                    day: this.dayWeeks[day],
                    date: date
                })
            }

            return days
        },
    },
    methods: {
        checkCurrent(date) {
            let current = false


            if (this.date) {
                const currentFormat = moment(this.date).format('DD.MM.YYYY');
                const dateFormat = moment(date).format('DD.MM.YYYY');
                current = currentFormat === dateFormat;
            } else {
                current = moment().startOf('day').isSame(moment(date))
            }

            return current
        },
        checkToday(date) {
            return isSameDate(moment(), date);
        }
    },
}
</script>

<style lang="scss" scoped>

@import "@/sass/_variables.scss";

.day {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #393f4a;
}
.num {
    font-size: 12px;
    font-weight: 400;
    color: #393f4a;
}

.group {
    .el-button {
        width: 49px!important;
        height: 44px!important;
        padding: 0;
        &:first-child {
            border-radius: 8px 0 0 8px;
        }
        &:last-child {
            border-radius: 0 8px 8px 0;
        }

        &.today {
            .day {
                color: $primary600;
                display: block;
            }

            .num {
                color: $primary600;
            }
        }

        &.active {
            position: relative;
            z-index: 1;
            border: 1px solid $primary600 !important;
            background-color: $primary50 !important;

            .day {
                color: $primary600;
                display: block;
            }

            .num {
                color: $primary600;
            }
        }

        span:first-child {
            display: block;
        }
    }
}

</style>
