<template>
    <div class="manage-deliveries">
        <div class="manage-deliveries__header">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <circle cx="7" cy="7.5" r="7" fill="#1984E6"/>
            </svg>
            <div class="manage-deliveries__header__number">
                Заказ № {{ orderGroupItem.id }}
            </div>
        </div>
        <div style="display: flex">
            <div class="manage-deliveries__info">

                <div class="manage-deliveries__info__item">
                    <div class="manage-deliveries__info__item__label">
                        Тип интервала
                    </div>
                    <div class="manage-deliveries__info__item__data">
                        {{ intervalType.get(orderGroupItem.type_delivery) }}
                    </div>
                </div>

                <div class="manage-deliveries__info__item">
                    <template v-if="orderGroupItem.type_delivery !== 'withoutInterval'">
                        <div class="manage-deliveries__info__item__label">
                            Интервал
                        </div>
                        <div v-if="orderGroupItem.type_delivery == 'withInterval'"
                             class="manage-deliveries__info__item__data">
                            {{ orderGroupItem.time_unloading }} минут
                        </div>
                        <div v-else-if="orderGroupItem.type_delivery == 'withoutInterval'"
                             class="manage-deliveries__info__item__data">
                            -
                        </div>
                        <div v-else-if="orderGroupItem.type_delivery == 'withIntervalClient'"
                             class="manage-deliveries__info__item__data">
                            {{ orderGroupItem.time_interval_client }} минут
                        </div>
                    </template>
                </div>

                <div class="manage-deliveries__info__item">
                    <template v-if="orderGroupItem?.mixer_max_volume">
                        <div class="manage-deliveries__info__item__label">
                            Мак. объём доставки
                        </div>
                        <div class="manage-deliveries__info__item__data">
                            {{ orderGroupItem.mixer_max_volume }} м³
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <hr/>

        <div class="mb-15">
            <el-scrollbar height="270px">
                <ManageDeliveriesOrders :order-group-item="orderGroupItem" :orders="orders"/>
            </el-scrollbar>
        </div>

        <div class="manage-deliveries__footer" v-if="fromAction">
            <div class="manage-deliveries__footer__navigation">
                <div @click="setActiveAction('add-shipping')" class="manage-deliveries__footer__add-delivery">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path
                            d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                            stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M5.3335 8.5H10.6668" stroke="white" stroke-width="1.2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M8 11.1666L8 5.83325" stroke="white" stroke-width="1.2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    Добавить доставку
                </div>
                <div style="display:flex; align-items: center;">
                    <div @click="goBack()" class="manage-deliveries__footer__navigation__go-back">
                        Вернуться назад
                    </div>
                </div>
            </div>
        </div>

        <div class="manage-deliveries__footer" v-else>
            <div class="manage-deliveries__footer__navigation">
                <div @click="$emit('addShipping')" class="manage-deliveries__footer__add-delivery">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path
                            d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                            stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M5.3335 8.5H10.6668" stroke="white" stroke-width="1.2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M8 11.1666L8 5.83325" stroke="white" stroke-width="1.2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    Добавить доставку
                </div>
                <div style="display:flex; align-items: center;">
                    <div @click="$emit('close')" class="manage-deliveries__footer__navigation__go-back">
                        Вернуться назад
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import ManageDeliveriesOrders from "@/views/dispatch/components/actions/ManageDeliveriesOrders.vue";
import {httpService} from "@/services/http.service";

export default {
    name: 'ManageDeliveries',
    components: {
        ManageDeliveriesOrders,
    },
    props: {
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
        fromAction: {
            default: true
        },
    },
    inject: ['api'],
    data() {
        return {
            intervalType: new Map([
                ['withInterval', 'Интервал на разгрузку'],
                ['withoutInterval', 'Без интервала на разгрузку'],
                ['withIntervalClient', 'Интервал от клиента'],
            ]),
            orders: []
        }
    },
    created() {
        this.getOrder()
    },
    methods: {
        async getOrder() {
            await httpService().post(this.api.mixOrderGroup.getOrder, {
                account_module_reserve_id: this.$route.params.id,
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.orderGroupItem.id,
                type: 'full'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.orders = data.order.orders
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        setActiveAction(name) {
            this.$emit('setActiveAction', name)
        },
        goBack() {
            this.$emit('setActiveAction', 'main')
        },
    },
}
</script>
<style scoped lang="scss">
.manage-deliveries {
    &__header {
        display: flex;
        align-items: center;

        &__number {
            color: #393F4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 8px;
        }
    }

    &__orders {
        min-height: 332px;

        &--empty {
            min-height: 332px;
            color: #393F4A;
            font-size: 16px;
            font-style: normal;
            align-items: center;
            display: flex;
            justify-content: center;
            font-weight: 700;
            line-height: 24px;
        }
    }

    &__info {

        width: 791px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        margin-bottom: 12px;

        &__item {

            display: flex;
            margin-bottom: 12px;
            min-width: 389px;

            &__label {
                color: #8A909A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 174px;
            }

            &__data {
                color: #22252C;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 215px;
            }
        }
    }

    &__edit-action {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        &__button {
            padding: 8px 20px;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #C9CCD0;
            background: #FFF;

            color: #49515F;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            cursor: pointer;
            height: fit-content;
        }
    }

    &__filter {
        display: flex;
        justify-content: end;
        margin: 24px 0;

        color: var(--Neutral-600, #49515F);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        align-items: center;

        svg {
            margin-left: 8px;
        }
    }

    &__footer {

        &__add-delivery {
            cursor: pointer;
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border-radius: 8px;
            background: #1984E6;
            color: #FFF;
            width: fit-content;

            svg {
                margin-right: 6px;
            }
        }

        &__navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__go-back {
                cursor: pointer;
                color: #393F4A;
                font-size: 14px;
                font-style: normal;
                margin-right: 16px;
                font-weight: 500;
                line-height: 20px;
            }

            &__complete {

            }
        }
    }
}
</style>
