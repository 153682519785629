<template>
    <Auth title="Отгрузки" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-if="!isEdit"
                    :new="true"
                    v-model="search"
                    :disable-search="true"
                    :loading="loading"
                    :show-date-filter="true"
                    :current-date="mixStore.filterDate.value"
                    :disable-create-button="true"
                    :filterShow="filterShow"
                    :disable-update-button="true"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @changeDate="changeDate"
                >
                    <template #afterSearchAndFilter>
                        <!--                        <Button v-if="values?.some(i => i.is_inaccurate === true)" @click="toggleEdit" theme="warning" style="margin-left: 12px">-->
                        <!--                            <IconWarning />-->
                        <!--                            Заполнить отгрузки-->
                        <!--                        </Button>-->
                        <!--                        <Button v-else-if="values?.length" @click="toggleEdit" theme="secondary" style="margin-left: 12px">-->
                        <!--                            <IconEdit />-->
                        <!--                            Редактировать отгрузки-->
                        <!--                        </Button>-->
                        <Button @click="toggleEdit" theme="secondary">
                            <IconEdit/>
                            {{ loadingButton ? 'Загрузка...' : 'Редактировать отгрузки' }}
                        </Button>
                    </template>
                    <template v-slot:searchAndFilter>
                        <el-button-group class="dateFilter ml-0 mr-12">
                            <!--                            <el-button :class="this.new ? 'group-btn' : ''" :disabled="loading"-->
                            <!--                                       @click="changeActiveTab('kanban')"-->
                            <!--                                       :type="activeTab === 'kanban' ? 'info' : 'default'">-->
                            <!--                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"-->
                            <!--                                     xmlns="http://www.w3.org/2000/svg">-->
                            <!--                                    <path-->
                            <!--                                        d="M15.8337 15.8335L15.8337 4.16683C15.8337 3.70659 15.4606 3.3335 15.0003 3.3335L12.5003 3.3335C12.0401 3.3335 11.667 3.70659 11.667 4.16683L11.667 15.8335C11.667 16.2937 12.0401 16.6668 12.5003 16.6668H15.0003C15.4606 16.6668 15.8337 16.2937 15.8337 15.8335Z"-->
                            <!--                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"-->
                            <!--                                        stroke-linejoin="round"/>-->
                            <!--                                    <path-->
                            <!--                                        d="M8.33366 15.8335L8.33366 4.16683C8.33366 3.70659 7.96056 3.3335 7.50033 3.3335L5.00033 3.3335C4.54009 3.3335 4.16699 3.70659 4.16699 4.16683L4.16699 15.8335C4.16699 16.2937 4.54009 16.6668 5.00033 16.6668H7.50033C7.96056 16.6668 8.33366 16.2937 8.33366 15.8335Z"-->
                            <!--                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"-->
                            <!--                                        stroke-linejoin="round"/>-->
                            <!--                                </svg>-->
                            <!--                            </el-button>-->
                            <el-button :class="this.new ? 'group-btn' : ''" :disabled="loading"
                                       @click="changeActiveTab('table')"
                                       :type="activeTab === 'table' ? 'info' : 'default'">
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path d="M12 4V20" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                    <path d="M20 15L4 15" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                    <path d="M20 9L4 9" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </el-button>
                            <el-button :class="this.new ? 'group-btn' : ''" class="group-btn" :disabled="loading"
                                       @click="changeActiveTab('timeline')"
                                       :type="activeTab === 'timeline' ? 'info' : 'default'">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.833 7.5V3.33333C10.833 2.8731 10.4599 2.5 9.99967 2.5L3.33301 2.5L3.33301 7.5L10.833 7.5Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M16.6663 11.6667V8.33333C16.6663 7.8731 16.2932 7.5 15.833 7.5L3.33301 7.5L3.33301 12.5L15.833 12.5C16.2932 12.5 16.6663 12.1269 16.6663 11.6667Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M13.333 16.6667V12.5L3.33301 12.5L3.33301 17.5L12.4997 17.5C12.9599 17.5 13.333 17.1269 13.333 16.6667Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </el-button>
                        </el-button-group>
                    </template>
                    <template v-if="!this.new" v-slot:buttonsBlock>
                        <el-button @click="printerDialogShow=true"
                                   v-if="!mobile"
                                   class="whiteButton" :disabled="values.length === 0"
                                   :type="printerDialogShow ? 'info' : 'default'"
                                   size="mini" plain>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 17H4C3.44772 17 3 16.5523 3 16L3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8V16C21 16.5523 20.5523 17 20 17H18"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M7 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15L7 15C6.44772 15 6 15.4477 6 16L6 20C6 20.5523 6.44772 21 7 21Z"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10 11H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M7 7L17 7C17.5523 7 18 6.55229 18 6V4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44772 6 4L6 6C6 6.55229 6.44772 7 7 7Z"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span class="textSM textMedium">Печать</span>
                        </el-button>
                        <el-button class="whiteButton" @click="openCreateMixOrderDrawer()">
                            Добавить отгрузку
                        </el-button>
                    </template>
                    <template v-else v-slot:buttonsBlock>
                        <button
                            @click="printerDialogShow=true"
                            v-if="!mobile"
                            class="ci-btn ci-btn_default"
                            :disabled="values.length === 0"
                        >
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path stroke="currentColor"
                                      d="M6 17H4C3.44772 17 3 16.5523 3 16L3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8V16C21 16.5523 20.5523 17 20 17H18"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path stroke="currentColor"
                                      d="M7 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15L7 15C6.44772 15 6 15.4477 6 16L6 20C6 20.5523 6.44772 21 7 21Z"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path stroke="currentColor" d="M10 11H14" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path stroke="currentColor"
                                      d="M7 7L17 7C17.5523 7 18 6.55229 18 6V4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44772 6 4L6 6C6 6.55229 6.44772 7 7 7Z"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span v-if="windowWidth > 1200">Печать</span>
                        </button>

                        <button class="ci-btn ci-btn_light" @click="openCreateMixOrderDrawer()">
                            Добавить отгрузку
                        </button>
                    </template>
                </TableHeadBlock>

                <Filter
                    v-if="!isEdit"
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                    :filter-items1="companyIndividualTypes"
                    :filter-items2="vehicles"
                />

                <div class="edit-head-block" v-else>
                    <Edit/>
                    <p>Редактирование</p>
                    <span v-show=isChanged>Изменения не сохранены</span>
                </div>

                <EmptyState
                    v-if="displayData && !isEdit && activeTab !== 'table'"
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="заявок"
                />

                <template v-if="activeTab === 'kanban' && !isEdit">


                    <el-row :gutter="20" class="orderGroupsTable" v-if="displayData.length > 0">

                        <el-col :sm="12">

                            <MixPageOrdersGroupOrderCol
                                status-name="Создан"
                                status="new"
                                :values="displayData.filter(el => el.status === 'new').sort((a, b) => a.date - b.date)"
                                :mix-goods="mixGoods"
                                @openDetails="openMixOrderDetails"
                            />

                            <MixPageOrdersGroupOrderCol
                                status-name="Завершен"
                                status="done"
                                :values="displayData.filter(el => el.status === 'done').sort((a, b) => b.date - a.date)"
                                :mix-goods="mixGoods"
                                @openDetails="openMixOrderDetails"
                            />

                        </el-col>
                        <el-col :sm="12">

                            <MixPageOrdersGroupOrderCol
                                status-name="В работе"
                                status="work"
                                :values="displayData.filter(el => (el.status !== 'new' && el.status !== 'done')).sort((a, b) => b.date - a.date)"
                                :mix-goods="mixGoods"
                                @openDetails="openMixOrderDetails"
                            />

                        </el-col>

                    </el-row>


                </template>

            </div>
        </div>
        <template v-if="isEdit">
            <MixOrderGroupEditOrders :data="data" :goods="mixGoods" :vehicles="vehicles" :companies="companies"
                                     :users="users" @submit="moduleGet" @changed="handleChanges" @close="toggleEdit"/>
        </template>
        <template v-if="activeTab === 'timeline' && !isEdit">
            <MixOrderGroupBigTimelineOrdersOld
                :date="filter.date.value"
                :filter="filter"
                :tab="activeTab"
            />
        </template>
        <template v-if="activeTab === 'table' && !isEdit">
            <div class="card">
                <div class="card-body" style="padding-top: 0 !important;">
                    <MixPageOrdersTable :no-header="false" :date="filter.date.value"/>
                </div>
            </div>
        </template>
        <el-dialog
            title="Создание отгрузки"
            v-model="createMixOrderDrawer"
            direction="rtl"
            :size="!mobile ? '60%' : '100%'"
            :destroy-on-close="true">

            <MixPageOrderCreate
                :goods="goods"
                :companies="companies"
                :doc="nextDoc"
                @closeDrawer="closeMixOrderDrawer"
                @moduleGet="moduleGet"
            />

        </el-dialog>

        <el-drawer
            title=""
            v-model="orderDetailsDrawer"
            direction="rtl"
            :size="!mobile ? '60%' : '100%'"
            :destroy-on-close="true">

            <MixPageOrderDetails
                :order-id="orderDetails.id"
                :vehicles="vehicles"
                :companies="companies"
                :tz="tz"
                @closeDrawer="handleCloseOrderDetailsDrawer"
                @moduleGet="moduleGet"
                @openEdit="openEdit"
            />

        </el-drawer>

        <el-dialog
            v-model="printerDialogShow"
            title="Печать документов"
            :width="drawerSize"
            :destroy-on-close="true"
        >

            <MixOrderGroupOrdersPrinter
                :table-data="values.filter(el => el.delivery !== 'take-away')"
                :date="this.filter.date.value"
                :moduleId="$route.params.id"
                :moduleKey="$route.meta.moduleKey"
            />

        </el-dialog>

        <el-dialog
            title="Редактирование отгрузки"
            v-model="editMixOrderDrawer"
            direction="rtl"
            :size="!mobile ? '60%' : '100%'"
            :destroy-on-close="true"
        >
            <MixPageOrderCreate
                :order="orderEdit"
                @closeDrawer="handleCloseEditMixOrderDrawer"
                @moduleGet="moduleGet()"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Filter from '../../Table/Filter/Filter'
import TableHeadBlock from '../../Table/TableHeadBlock'
import tableMixin from '../../../../mixins/tableMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import MixPageOrderCreate from './../Orders/MixPageOrderCreate'
import MixPageOrderDetails from './../Orders/OrderDetails/MixPageOrderDetails'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'
import {
    mapState,
    mapStores
} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import EmptyState from "@/views/components/EmptyState.vue";
import MixPageOrdersGroupOrderCol from "@/views/components/MixPage/OrdersGroup/MixPageOrdersGroupOrderCol.vue";
import moment from "moment";
import MixOrderGroupBigTimelineOrdersOld from "@/views/components/MixPage/OrdersGroup/MixOrderGroupBigTimelineOrdersOld.vue";
import MixOrderGroupEditOrders from "@/views/components/MixPage/OrdersGroup/MixOrderGroupEditOrders.vue";
import MixOrderGroupOrdersPrinter from "@/views/components/MixPage/OrdersGroup/MixOrderGroupOrdersPrinter.vue";
import {useMixStore} from '@/store/mix';
import Auth from "@/views/layouts/Auth.vue";
import MixPageOrdersTable from "@/views/components/MixPage/Orders/MixPageOrdersTable.vue";
import Button from "@/components/Button.vue";
import IconEdit from "@/assets/icons/IconEdit.vue";
import Edit from "@/views/components/Icons/Edit.vue";
import {useEchoStore} from "@/store/Echo";
import mixTabs from "@/mixins/tabs/mixTabs";

export default {
    name: 'MixPageOrdersGroupOrders',
    components: {
        MixPageOrdersTable,
        Auth,
        MixOrderGroupOrdersPrinter,
        MixOrderGroupBigTimelineOrdersOld,
        MixPageOrdersGroupOrderCol,
        EmptyState,
        MixPageOrderDetails,
        MixPageOrderCreate,
        Filter,
        TableHeadBlock,
        Button,
        IconEdit,
        MixOrderGroupEditOrders,
        Edit
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin, getUnitFromGoodsMixin, iconsMixin, mixTabs
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: true,
            loadingButton: false,
            createMixOrderDrawer: false,
            editMixOrderDrawer: false,
            createMixOrderMixDialog: false,
            orderDetailsDrawer: false,
            orderEdit: null,
            orderDetails: {
                createMix: false,
            },
            new: true,
            nextDoc: null,
            isEdit: false,
            isChanged: false,
            filterKanban: {
                date: {
                    value: null,
                    type: 'date'
                },
                vehicle: {
                    value: null,
                    type: 'select',
                    label: 'Транспорт',
                    placeholder: 'Выберите ТС',
                    items: '2'
                },
                companyType: {
                    label: 'Тип клиента',
                    placeholder: 'Выберите тип',
                    value: null,
                    type: 'select',
                    items: '1'
                },
            },
            filterTimeline: {
                date: {
                    value: null,
                    type: 'date'
                },
            },
            filter: {
                date: {
                    value: null,
                    type: 'date'
                },
                vehicle: {
                    value: null,
                    type: 'select',
                    label: 'Транспорт',
                    placeholder: 'Выберите ТС',
                    items: '2'
                },
                companyType: {
                    label: 'Тип клиента',
                    placeholder: 'Выберите тип',
                    value: null,
                    type: 'select',
                    items: '1'
                },
            },
            vehicles: [],
            companies: [],
            users: [],
            categories: [],
            mixGoods: [],
            activeTab: 'table',
            printerDialogShow: false,
            companyIndividualTypes: [{
                'id': 'company',
                'name': 'Юр.лицо'
            }, {
                'id': 'individual',
                'name': 'Физ.лицо'
            }],
            moduleId: null,
            data: [],
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
            factories: 'getFactories',
        }),
        ...mapStores(useMixStore),
        ...mapStores(useEchoStore),
    },
    async created() {
        await this.mixModuleGet()
        // this.moduleGet()
        // this.getVehicles()
        // this.getGoods()
        // this.getCompanies()
        //
        // clearInterval(this.counterInterval)

        // this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderGroupCreate.${this.moduleId}`);
        // this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderCreate.${this.moduleId}`);
        //
        // this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderGroupCreate.${this.moduleId}`)
        //     .listen('.MixOrderGroupCreate', () => {
        //         this.moduleGet()
        //     });
        //
        // this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderCreate.${this.moduleId}`)
        //     .listen('.MixOrderCreate', () => {
        //         this.moduleGet()
        //     });
        // this.counterInterval = setInterval(
        //     function () {
        //         this.moduleGet(true)
        //     }.bind(this), 5000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        async mixModuleGet() {
            await httpService().post(this.api.mix.getModule, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.moduleId = data.moduleId
                }
            })
        },
        async toggleEdit() {
            if (!this.isEdit) {
                this.loadingButton = true
                await this.moduleGet()
                await this.getVehicles()
                await this.getUsers()
                this.loadingButton = false
                this.isChanged = false
            } else {
                await this.moduleGet()
            }
            this.isEdit = !this.isEdit
        },
        openEdit(order) {
            this.orderEdit = order
            this.orderDetailsDrawer = false
            this.editMixOrderDrawer = true
            // window.setTimeout(() => {
            //
            // }, 15)
        },
        handleCloseOrderDetailsDrawer() {
            this.orderDetailsDrawer = false;
        },
        handleCloseEditMixOrderDrawer() {
            this.editMixOrderDrawer = false;
        },
        handleChanges() {
            this.isChanged = true;
        },
        changeActiveTab(tab) {
            // if (tab === 'kanban') {
            //     this.filter = this.filterKanban
            // } else {
            //     this.filter = this.filterTimeline
            // }
            this.activeTab = tab
        },
        closeMixOrderDrawer() {
            this.createMixOrderDrawer = false
        },
        async moduleGet(background) {
            if (!background) this.loading = true

            const filter = {
                ...this.filter,
                ...this.mixStore.filterDate,
            };

            if (this.filter.date.value && this.mixStore.filterDate.value) {
                try {
                    const response = await httpService().post(this.api.mix.getOrderGroupsOrders, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'mix',
                        filter,
                    });

                    let data = response.data;
                    if (data.success) {
                        this.data = data.values;
                        this.valueLength = data.values.length;
                        if (!background) this.loading = false
                    }

                } catch (error) {
                    console.log(error);
                }

                if (!background) await this.getGoods();
            } else {
                this.loading = false;
            }
        },
        async getGoods() {
            this.loading = true

            try {
                const {data} = await httpService().post(this.api.mix.getGoods, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'mix'
                })

                this.mixGoods = data.success ? data.values : []
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async getVehicles() {
            try {
                this.loading = true
                const response = await httpService().post(this.api.vehicle.get);
                const data = response.data;

                if (data.success) {
                    this.vehicles = data.vehicles;
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async getCompanies() {
            try {
                this.loading = true
                const response = await httpService().post(this.api.company.get);
                const data = response.data;

                if (data.success) {
                    this.companies = data.companies;
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async getUsers() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.users = data.users
            })
        },
        getUnits(goods) {
            let goodsList = []

            if (goods.length > 0) {
                goods.forEach(element => goodsList.push(this.getInfo(element, this.mixGoods, 'goodId')))
            }

            return this.getUnitFromIds(goodsList)
        },
        openCreateMixOrderDrawer() {
            this.createMixOrderDrawer = true
        },
        openMixOrderDetails(row) {
            this.orderDetails = []
            this.orderDetailsDrawer = true
            this.orderDetails = row
        },
        openMixOrderCreateMix(index, row) {
            this.orderDetails = []
            this.createMixOrderMixDialog = true
            this.orderDetails = row
        },
        changeDate(date) {
            const value = moment(date).utcOffset(0, true).startOf('day');
            this.mixStore.setFilterDate(value);
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        }
    },
    watch: {
        // values(order){
        //     order.forEach((item)=>{
        //         this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderDelete.${item.id}`);
        //         this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderUpdate.${item.id}`);
        //
        //         this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderDelete.${item.id}`)
        //             .listen('.MixOrderDeleted', data => {
        //                 console.log('MixOrderDeleted', data)
        //                 this.moduleGet()
        //             })
        //         this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderUpdate.${item.id}`)
        //             .listen('.MixOrderUpdate', () => {
        //                 this.moduleGet()
        //             });
        //     })
        // },
        activeTab() {
            if (this.activeTab === 'kanban') {
                this.moduleGet()
                clearInterval(this.counterInterval)
                this.counterInterval = setInterval(
                    function () {
                        this.moduleGet(true)
                    }.bind(this), 5000)
            } else {
                clearInterval(this.counterInterval)
            }
        },
        // 'filter': {
        //     handler: function (value) {
        //         this.moduleGet()
        //
        //         if (value.date.value && value.date.value !== 'Invalid date') {
        //             this.mixStore.setFilterDate(value.date.value);
        //         }
        //     },
        //     deep: true,
        //     immediate: true
        // },
        'mixStore.filterDate.value': {
            handler: function (date) {
                if (date) {
                    this.filter.date.value = date;
                }
            },
            deep: true,
            immediate: true
        },
        // search() {
        //     this.moduleGet()
        // },
        $route(to, from) {
            if (to.name === from.name) {
                this.moduleGet()
                clearInterval(this.counterInterval)
                this.counterInterval = setInterval(
                    function () {
                        this.moduleGet(true)
                    }.bind(this), 5000)
            } else {
                clearInterval(this.counterInterval)
            }
        }
    },
    mounted() {
        if (!this.filter.date.value || this.filter.date.value === 'Invalid date') {
            if (!this.mixStore.filterDate.value || this.mixStore.filterDate.value === 'Invalid date') {
                this.mixStore.setFilterDate(moment().startOf('day'));
            }
            this.filter.date.value = this.mixStore.filterDate.value;
        }
    }
}
</script>

<style scoped lang="scss">

@import "@/sass/_variables.scss";

.group-btn {
    width: 44px !important;
    height: 44px !important;

    &.el-button--info {
        position: relative;
        z-index: 2;
        border: 1px solid $primary600 !important;
        background-color: $primary50 !important;

        path {
            stroke: $primary600 !important;
        }
    }
}

.btn {
    display: inline-block;
    background: #efefef;
    color: $grey-dark;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.ci-btn {
    @extend .btn;
    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 44px;
    padding: 0 20px;
    font-weight: 600;
    border-radius: 8px;

    &_light {
        background-color: $primary50;
        color: $primary600;

        &:hover {
            background-color: $primary100;
            color: $primary700;
        }
    }

    &_blue {
        background-color: $primary600;
        color: $white !important;

        &:hover {
            color: $white !important;
            background-color: $primary700 !important;
        }
    }

    &_default {
        background-color: $white;
        color: $neutral600;
        border: 1px solid $neutral100
    }

    svg {
        height: 20px;
        width: 20px;
    }
}

.card {
    margin-bottom: 0;

    .card-body {
        padding: 24px;
    }
}

.edit-head-block {
    display: flex;
    align-items: center;
    gap: 24px;

    svg {
        width: 1rem;
        height: 1rem;
    }

    & p {
        margin: 0;

        color: $neutral600;
        font-size: 18px;
        font-weight: 500;
        line-height: 133%;
    }

    & span {
        color: $warning400;
    }
}

</style>
