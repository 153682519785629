<template>
    <hr class="add-shipping__content__hr"/>
    <div>
        <el-row :gutter="40">
            <el-col :sm="4">
                <div class="addDeliveryFormItem addDeliveryFormTotal">
                    <div>
                        <el-tooltip effect="dark" content="Объём" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.125 4.71646L10.8833 1.8915C10.3333 1.5915 9.66667 1.5915 9.10833 1.8915L3.86668 4.71646C3.48335 4.9248 3.25 5.33315 3.25 5.78315C3.25 6.23315 3.48335 6.64146 3.86668 6.84979L9.10833 9.67484C9.38333 9.82484 9.69167 9.89984 9.99167 9.89984C10.2917 9.89984 10.6 9.82484 10.875 9.67484L16.1167 6.84979C16.5 6.64146 16.7333 6.23315 16.7333 5.78315C16.75 5.33315 16.5083 4.9248 16.125 4.71646Z"
                                        fill="#49515F"/>
                                    <path
                                        d="M8.24993 10.6585L3.37493 8.21682C2.99993 8.02515 2.56659 8.05015 2.20826 8.26682C1.84993 8.48349 1.6416 8.86682 1.6416 9.28349V13.8918C1.6416 14.6918 2.08325 15.4085 2.79992 15.7668L7.67493 18.2002C7.84159 18.2835 8.02494 18.3252 8.20828 18.3252C8.42496 18.3252 8.64163 18.2668 8.83329 18.1418C9.19163 17.9252 9.39996 17.5418 9.39996 17.1252V12.5168C9.40829 11.7335 8.96663 11.0168 8.24993 10.6585Z"
                                        fill="#49515F"/>
                                    <path
                                        d="M11.7667 10.658L16.6417 8.21633C17.0167 8.02466 17.45 8.04966 17.8083 8.26633C18.1667 8.483 18.375 8.86634 18.375 9.283V13.8913C18.375 14.6913 17.9333 15.408 17.2167 15.7663L12.3417 18.1997C12.175 18.283 11.9917 18.3247 11.8083 18.3247C11.5916 18.3247 11.375 18.2663 11.1833 18.1413C10.825 17.9247 10.6166 17.5413 10.6166 17.1247V12.5163C10.6083 11.733 11.05 11.0163 11.7667 10.658Z"
                                        fill="#49515F"/>
                                </svg>
                            </div>
                        </el-tooltip>
                    </div>
                    <el-input-number
                        v-model="form.total"
                        placeholder="0"
                        :step="0.1"
                        controls-position="right"
                        @change="getTimeInfo()"
                    />
                </div>
            </el-col>

            <el-col :sm="orderGroupItem.delivery_type !== 'take-away' ? 6 : 5" v-if="mixes.length > 1">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('mix')">
                    <div>
                        <el-tooltip effect="dark" content="Завод" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.91683 6.89474C3.91683 6.48779 4.26505 6.15789 4.69461 6.15789H7.80572C8.23527 6.15789 8.5835 6.48779 8.5835 6.89474C8.5835 7.30168 8.23527 7.63158 7.80572 7.63158H4.69461C4.26505 7.63158 3.91683 7.30168 3.91683 6.89474Z"
                                          fill="#49515F"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M9.36127 6.89474C9.36127 6.08084 10.0577 5.42105 10.9168 5.42105H14.0279C14.8871 5.42105 15.5835 6.08084 15.5835 6.89474V14.2632C15.5835 14.6701 15.2353 15 14.8057 15H10.1391C9.7095 15 9.36127 14.6701 9.36127 14.2632V6.89474ZM14.0279 6.89474H10.9168V13.5263H14.0279V6.89474Z"
                                          fill="#49515F"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.91683 4.68421C3.91683 4.27726 4.26505 3.94737 4.69461 3.94737L7.80572 3.94737C8.23527 3.94737 8.5835 4.27726 8.5835 4.68421C8.5835 5.09116 8.23527 5.42105 7.80572 5.42105H4.69461C4.26505 5.42105 3.91683 5.09116 3.91683 4.68421Z"
                                          fill="#49515F"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1.5835 2.47368C1.5835 1.65979 2.27994 1 3.13905 1H9.36127C10.2204 1 10.9168 1.65979 10.9168 2.47368V14.2632C10.9168 14.6701 10.5686 15 10.1391 15H2.36127C1.93172 15 1.5835 14.6701 1.5835 14.2632V2.47368ZM9.36127 2.47368H3.13905V13.5263H9.36127V2.47368Z"
                                          fill="#49515F"/>
                                </svg>
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.mix_id" class="neutral200">Выберите завод</div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('mix') }}
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :sm="orderGroupItem.delivery_type !== 'take-away' ? 8 : 5">
                <el-row gutter="10">
                    <el-col :span="orderGroupItem.delivery_type !== 'take-away' ? 24 : ''">
                        <div class="addDeliveryFormItem addDeliveryFormTotal">
                            <div>
                                <el-tooltip effect="dark" content="Время загрузки" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.2503 1.50443C13.9631 1.50443 13.6876 1.61853 13.4845 1.82162C13.2814 2.02472 13.1673 2.30018 13.1673 2.5874V10.8071L4.18955 1.81849C3.98563 1.61456 3.70904 1.5 3.42065 1.5C3.13225 1.5 2.85567 1.61456 2.65174 1.81849C2.44782 2.02242 2.33325 2.299 2.33325 2.5874C2.33325 2.87579 2.44782 3.15237 2.65174 3.3563L11.6403 12.3341H3.42065C3.13343 12.3341 2.85797 12.4482 2.65488 12.6513C2.45178 12.8544 2.33768 13.1298 2.33768 13.417C2.33768 13.7043 2.45178 13.9797 2.65488 14.1828C2.85797 14.3859 3.13343 14.5 3.42065 14.5H14.2503C14.3918 14.4983 14.5316 14.4689 14.6618 14.4134C14.9264 14.3035 15.1367 14.0932 15.2466 13.8286C15.3021 13.6984 15.3315 13.5586 15.3333 13.417V2.5874C15.3333 2.30018 15.2192 2.02472 15.0161 1.82162C14.813 1.61853 14.5375 1.50443 14.2503 1.50443Z"
                                                fill="#49515F"/>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </div>
                            <el-input
                                v-model="form.start_at"
                                @change="changedOrder('start_at')"
                                placeholder="00:00"
                                type="time"
                                :disabled="!form.total"
                            />
                        </div>
                    </el-col>
                    <el-col :span="2" v-if="orderGroupItem.delivery_type !== 'take-away'" v-show="false">
                        <div class="p-relative">
                            <div class="addDeliveryFormItemSeparator"></div>
                        </div>
                    </el-col>
                    <el-col :span="11" v-if="orderGroupItem.delivery_type !== 'take-away'" v-show="false">
                        <div class="addDeliveryFormItem addDeliveryFormTotal">
                            <div>
                                <el-tooltip effect="dark" content="Время прибытия на объект" placement="top">
                                    <div class="addDeliveryFormItemIcon">
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M4.16659 6.89474C4.16659 6.48779 4.51481 6.15789 4.94436 6.15789H8.05547C8.48503 6.15789 8.83325 6.48779 8.83325 6.89474C8.83325 7.30168 8.48503 7.63158 8.05547 7.63158H4.94436C4.51481 7.63158 4.16659 7.30168 4.16659 6.89474Z"
                                                  fill="#505968"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M9.61103 6.89474C9.61103 6.08084 10.3075 5.42105 11.1666 5.42105H14.2777C15.1368 5.42105 15.8333 6.08084 15.8333 6.89474V14.2632C15.8333 14.6701 15.485 15 15.0555 15H10.3888C9.95925 15 9.61103 14.6701 9.61103 14.2632V6.89474ZM14.2777 6.89474H11.1666V13.5263H14.2777V6.89474Z"
                                                  fill="#505968"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M4.16659 4.68421C4.16659 4.27726 4.51481 3.94737 4.94436 3.94737L8.05547 3.94737C8.48503 3.94737 8.83325 4.27726 8.83325 4.68421C8.83325 5.09116 8.48503 5.42105 8.05547 5.42105H4.94436C4.51481 5.42105 4.16659 5.09116 4.16659 4.68421Z"
                                                  fill="#505968"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M1.83325 2.47368C1.83325 1.65979 2.5297 1 3.38881 1H9.61103C10.4701 1 11.1666 1.65979 11.1666 2.47368V14.2632C11.1666 14.6701 10.8184 15 10.3888 15H2.61103C2.18147 15 1.83325 14.6701 1.83325 14.2632V2.47368ZM9.61103 2.47368H3.38881V13.5263H9.61103V2.47368Z"
                                                  fill="#505968"/>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </div>
                            <el-input
                                v-model="form.arrive_at"
                                @change="changedOrder('arrive_at')"
                                placeholder="00:00"
                                type="time"
                                :disabled="!form.total"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :sm="orderGroupItem.delivery_type !== 'take-away' ? 6 : 5">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('vehicle')">
                    <div>
                        <el-tooltip effect="dark" content="ТС" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.62176 12.2835C6.55362 14.572 3.13646 14.5724 3.06832 12.2835C3.13609 9.99019 6.55362 9.98947 6.62176 12.2835ZM14.2026 12.2835C14.1338 14.5731 10.721 14.571 10.6525 12.2835C10.7207 9.99199 14.133 9.98839 14.2026 12.2835Z"
                                        fill="#505968"/>
                                    <path
                                        d="M5.60125 8.49326C5.69708 8.49298 5.78891 8.45565 5.85667 8.38944C5.92444 8.32322 5.96264 8.23349 5.96293 8.13984V5.37439C5.96274 5.28068 5.92458 5.19086 5.8568 5.12457C5.78903 5.05827 5.69715 5.0209 5.60125 5.02061H2.13474L2.38445 4.37748C2.47061 4.16153 2.62124 3.9759 2.81678 3.84471C3.01231 3.71352 3.24371 3.64284 3.4809 3.64185H7.3997C7.55368 3.64185 7.70615 3.67149 7.84841 3.72908C7.99067 3.78667 8.11992 3.87109 8.22879 3.9775C8.33765 4.08391 8.424 4.21024 8.48289 4.34926C8.54178 4.48829 8.57207 4.63729 8.57202 4.78776V12.2837C8.57183 12.3774 8.53367 12.4672 8.4659 12.5335C8.39812 12.5998 8.30624 12.6372 8.21034 12.6375H7.31646C7.3361 12.5205 7.34583 12.4022 7.34556 12.2837C7.16877 8.81573 2.02498 9.18499 2.3734 12.6375C2.20076 12.6666 2.02373 12.659 1.85437 12.6152C1.68501 12.5714 1.5273 12.4924 1.39202 12.3837C1.25673 12.2749 1.14703 12.1389 1.07042 11.985C0.9938 11.831 0.952063 11.6627 0.94805 11.4916V8.49326H5.60125Z"
                                        fill="#505968"/>
                                    <path
                                        d="M5.23988 7.78592H1.06401C1.06713 7.77012 1.07194 7.75468 1.07837 7.73985L1.85992 5.72803H5.23988V7.78592ZM19.1531 12.2836C19.1078 14.5912 15.5702 14.5394 15.6055 12.2364C15.6866 9.98489 19.1185 10.0385 19.1531 12.2836Z"
                                        fill="#505968"/>
                                    <path
                                        d="M9.448 3.0229L12.3171 0.954565C12.3355 1.01107 14.6695 8.03339 14.7745 8.34794C14.8371 8.35298 15.4779 8.34794 15.538 8.35154C15.3534 7.78722 13.1359 1.13667 12.9941 0.699757L19.0623 0.0065948C19.3022 -0.0200633 19.5441 0.0346021 19.7477 0.16144C19.9513 0.288279 20.104 0.479592 20.1805 0.703357L21.523 4.74141C21.5962 4.96637 21.5884 5.20908 21.5008 5.42911C21.4133 5.64914 21.2512 5.83321 21.0416 5.95066L20.253 6.38218V8.35154H20.9116C21.0074 8.35182 21.0992 8.38915 21.167 8.45537C21.2348 8.52158 21.273 8.61131 21.2733 8.70496V11.5762C21.2725 11.8573 21.1579 12.1267 20.9545 12.3255C20.7511 12.5243 20.4755 12.6363 20.1879 12.6372H19.8475C19.8672 12.5203 19.8769 12.4019 19.8766 12.2834C19.8078 9.35565 15.5921 8.94573 14.9266 11.7886C14.8971 11.9512 14.9082 11.9829 14.8794 11.7994C14.7508 11.1755 14.3779 10.6254 13.8403 10.2662C13.3027 9.90703 12.6428 9.76716 12.0012 9.87639C11.3596 9.98562 10.787 10.3353 10.4054 10.851C10.0238 11.3667 9.86328 12.0076 9.95811 12.6372H9.2307C9.34598 12.6771 9.28226 8.47174 9.29589 8.35154H10.4682C10.4645 8.34434 10.4608 8.33354 10.4572 8.3267L9.29589 4.82634L9.07491 4.16161C9.00491 3.95772 9.00335 3.73732 9.07046 3.5325C9.13757 3.32768 9.26983 3.14915 9.448 3.0229ZM19.5293 8.35154V6.77807L16.6632 8.35154H19.5293Z"
                                        fill="#505968"/>
                                </svg>
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.vehicle_id" class="neutral200">
                            <span v-if="orderGroupItem.delivery_type !== 'take-away'">Выберите авто</span>
                            <span v-else>Авто</span>
                        </div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('vehicle') }}
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :sm="orderGroupItem.delivery_type !== 'take-away' ? 7 : 5">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('driver')">
                    <div>
                        <el-tooltip effect="dark" content="Водитель" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="16" height="16" x="0" y="0"
                                     viewBox="0 0 24 24" xml:space="preserve"
                                     class=""><g><path d="M7.25 7c0-2.619 2.131-4.75 4.75-4.75S16.75 4.381 16.75 7s-2.131 4.75-4.75 4.75S7.25 9.619 7.25 7zM15 13.25H9c-3.17 0-5.75 2.58-5.75 5.75A2.752 2.752 0 0 0 6 21.75h12A2.752 2.752 0 0 0 20.75 19c0-3.17-2.58-5.75-5.75-5.75z" fill="#505968" opacity="1" data-original="#505968" class=""></path></g></svg>
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.driver_id" class="neutral200">
                            <span v-if="orderGroupItem.delivery_type !== 'take-away'">Выберите водителя</span>
                            <span v-else>Водитель</span>
                        </div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('driver') }}
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :sm="7">
                <div class="addDeliveryFormItem pointer" @click="openSelectDialog('good')">
                    <div>
                        <el-tooltip effect="dark" content="Рецепт" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.125 4.71646L10.8833 1.8915C10.3333 1.5915 9.66667 1.5915 9.10833 1.8915L3.86668 4.71646C3.48335 4.9248 3.25 5.33315 3.25 5.78315C3.25 6.23315 3.48335 6.64146 3.86668 6.84979L9.10833 9.67484C9.38333 9.82484 9.69167 9.89984 9.99167 9.89984C10.2917 9.89984 10.6 9.82484 10.875 9.67484L16.1167 6.84979C16.5 6.64146 16.7333 6.23315 16.7333 5.78315C16.75 5.33315 16.5083 4.9248 16.125 4.71646Z"
                                        fill="#49515F"/>
                                    <path
                                        d="M8.24993 10.6585L3.37493 8.21682C2.99993 8.02515 2.56659 8.05015 2.20826 8.26682C1.84993 8.48349 1.6416 8.86682 1.6416 9.28349V13.8918C1.6416 14.6918 2.08325 15.4085 2.79992 15.7668L7.67493 18.2002C7.84159 18.2835 8.02494 18.3252 8.20828 18.3252C8.42496 18.3252 8.64163 18.2668 8.83329 18.1418C9.19163 17.9252 9.39996 17.5418 9.39996 17.1252V12.5168C9.40829 11.7335 8.96663 11.0168 8.24993 10.6585Z"
                                        fill="#49515F"/>
                                    <path
                                        d="M11.7667 10.658L16.6417 8.21633C17.0167 8.02466 17.45 8.04966 17.8083 8.26633C18.1667 8.483 18.375 8.86634 18.375 9.283V13.8913C18.375 14.6913 17.9333 15.408 17.2167 15.7663L12.3417 18.1997C12.175 18.283 11.9917 18.3247 11.8083 18.3247C11.5916 18.3247 11.375 18.2663 11.1833 18.1413C10.825 17.9247 10.6166 17.5413 10.6166 17.1247V12.5163C10.6083 11.733 11.05 11.0163 11.7667 10.658Z"
                                        fill="#49515F"/>
                                </svg>
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="addDeliveryFormItemInputText">
                        <div v-if="!form.good_id" class="neutral200">
                            Выберите рецепт
                        </div>
                        <div class="cutOverflow" v-else>
                            {{ getLabel('good') }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

    </div>

    <hr class="add-shipping__content__hr mt-0"/>

    <div class="d-flex justify-between">
        <MixPageOrderTimeline :order="order" v-show="false"/>

        <div class="add-shipping__content__order-terms__title__item"
             v-if="orderGroupItem.delivery_type === 'take-away'">
            <svg class="add-shipping__content__order-terms__title__item__icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3341 3.9998C11.1573 3.9998 10.9877 4.07004 10.8627 4.19506C10.7377 4.32008 10.6674 4.48965 10.6674 4.66646V9.72646L5.14077 4.19313C5.01524 4.0676 4.84498 3.99707 4.66744 3.99707C4.48991 3.99707 4.31964 4.0676 4.19411 4.19313C4.06857 4.31867 3.99805 4.48893 3.99805 4.66646C3.99805 4.844 4.06857 5.01426 4.19411 5.1398L9.72744 10.6665H4.66744C4.49063 10.6665 4.32106 10.7367 4.19604 10.8617C4.07101 10.9868 4.00077 11.1563 4.00077 11.3331C4.00077 11.5099 4.07101 11.6795 4.19604 11.8045C4.32106 11.9296 4.49063 11.9998 4.66744 11.9998H11.3341C11.4212 11.9987 11.5073 11.9806 11.5874 11.9465C11.7503 11.8788 11.8798 11.7494 11.9474 11.5865C11.9816 11.5063 11.9997 11.4202 12.0008 11.3331V4.66646C12.0008 4.48965 11.9305 4.32008 11.8055 4.19506C11.6805 4.07004 11.5109 3.9998 11.3341 3.9998Z" fill="#8a909a"/>
            </svg>
            <div class="neutral900"><span class="neutral300">Время на загрузку:</span> {{ timeInfo.loading }} мин</div>
        </div>
    </div>

    <div class="add-shipping__content__order-terms" v-if="orderGroupItem.delivery_type !== 'take-away'">
        <div class="add-shipping__content__order-terms__title">
            <div class="add-shipping__content__order-terms__title__item">
                <svg class="add-shipping__content__order-terms__title__item__icon"
                     width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.3341 3.9998C11.1573 3.9998 10.9877 4.07004 10.8627 4.19506C10.7377 4.32008 10.6674 4.48965 10.6674 4.66646V9.72646L5.14077 4.19313C5.01524 4.0676 4.84498 3.99707 4.66744 3.99707C4.48991 3.99707 4.31964 4.0676 4.19411 4.19313C4.06857 4.31867 3.99805 4.48893 3.99805 4.66646C3.99805 4.844 4.06857 5.01426 4.19411 5.1398L9.72744 10.6665H4.66744C4.49063 10.6665 4.32106 10.7367 4.19604 10.8617C4.07101 10.9868 4.00077 11.1563 4.00077 11.3331C4.00077 11.5099 4.07101 11.6795 4.19604 11.8045C4.32106 11.9296 4.49063 11.9998 4.66744 11.9998H11.3341C11.4212 11.9987 11.5073 11.9806 11.5874 11.9465C11.7503 11.8788 11.8798 11.7494 11.9474 11.5865C11.9816 11.5063 11.9997 11.4202 12.0008 11.3331V4.66646C12.0008 4.48965 11.9305 4.32008 11.8055 4.19506C11.6805 4.07004 11.5109 3.9998 11.3341 3.9998Z"
                        fill="#8a909a"/>
                </svg>
                <div class="neutral900"><span class="neutral300">Время на загрузку:</span> {{ timeInfo.loading }} мин</div>
            </div>
            <div class="add-shipping__content__order-terms__title__item" v-if="orderGroupItem.delivery_type !== 'take-away'">
                <svg class="add-shipping__content__order-terms__title__item__icon"
                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                     fill="none">
                    <path
                        d="M5.91969 3.06667C5.38636 0.760002 1.91302 0.753335 1.37969 3.06667C1.06636 4.42 1.92636 5.56667 2.67302 6.28C3.21969 6.79334 4.07969 6.79334 4.62636 6.28C5.37302 5.56667 6.22636 4.42 5.91969 3.06667ZM3.67302 4.13334C3.30636 4.13334 3.00636 3.83333 3.00636 3.46667C3.00636 3.1 3.29969 2.8 3.66636 2.8H3.67302C4.04636 2.8 4.33969 3.1 4.33969 3.46667C4.33969 3.83333 4.04636 4.13334 3.67302 4.13334Z"
                        fill="#8a909a"/>
                    <path
                        d="M4.34017 3.46665C4.34017 3.83332 4.04684 4.13332 3.6735 4.13332C3.30684 4.13332 3.00684 3.83332 3.00684 3.46665C3.00684 3.09999 3.30017 2.79999 3.66684 2.79999H3.6735C4.04684 2.79999 4.34017 3.09999 4.34017 3.46665Z"
                        fill="white"/>
                    <path
                        d="M14.6067 11.0667C14.0733 8.76 10.5867 8.75334 10.0467 11.0667C9.73333 12.42 10.5933 13.5667 11.3467 14.28C11.8933 14.7933 12.76 14.7933 13.3067 14.28C14.06 13.5667 14.92 12.42 14.6067 11.0667ZM12.3533 12.1333C11.9867 12.1333 11.6867 11.8333 11.6867 11.4667C11.6867 11.1 11.98 10.8 12.3467 10.8H12.3533C12.72 10.8 13.02 11.1 13.02 11.4667C13.02 11.8333 12.72 12.1333 12.3533 12.1333Z"
                        fill="#8a909a"/>
                    <path
                        d="M13.0199 11.4667C13.0199 11.8333 12.7199 12.1333 12.3532 12.1333C11.9865 12.1333 11.6865 11.8333 11.6865 11.4667C11.6865 11.1 11.9799 10.8 12.3465 10.8H12.3532C12.7199 10.8 13.0199 11.1 13.0199 11.4667Z"
                        fill="white"/>
                    <path
                        d="M7.99963 13.1666H6.21297C5.43963 13.1666 4.7663 12.7 4.49963 11.98C4.2263 11.26 4.4263 10.4666 5.0063 9.95331L10.333 5.29331C10.653 5.01331 10.6596 4.63331 10.5663 4.37331C10.4663 4.11331 10.213 3.83331 9.7863 3.83331H7.99963C7.7263 3.83331 7.49963 3.60665 7.49963 3.33331C7.49963 3.05998 7.7263 2.83331 7.99963 2.83331H9.7863C10.5596 2.83331 11.233 3.29998 11.4996 4.01998C11.773 4.73998 11.573 5.53331 10.993 6.04665L5.6663 10.7066C5.3463 10.9866 5.33963 11.3666 5.43297 11.6266C5.53297 11.8866 5.7863 12.1666 6.21297 12.1666H7.99963C8.27297 12.1666 8.49963 12.3933 8.49963 12.6666C8.49963 12.94 8.27297 13.1666 7.99963 13.1666Z"
                        fill="#8a909a"/>
                </svg>
                <div class="neutral900"><span class="neutral300">До объекта:</span> {{ timeInfo.dist }} км</div>
            </div>
            <div class="add-shipping__content__order-terms__title__item">
                <svg class="add-shipping__content__order-terms__title__item__icon"
                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                     fill="none">
                    <path
                        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                        stroke="#8a909a" stroke-width="1.5" stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path d="M8 4.66669V8.00002H11.3333" stroke="#8a909a" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="neutral900"><span class="neutral300">Время на дорогу:</span> {{ timeInfo.toObject }} мин</div>
            </div>
        </div>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="selectDialog"
        :title="selectDialogTitle"
        width="992"
        :destroy-on-close="true"
    >
        <template v-if="selectType === 'mix'">
            <EditSelectMixes
                :items="mixers"
                @updateItem="updateMixes"
                @close="() => selectDialog = false"
            />
        </template>
        <template v-else-if="selectType === 'vehicle'">
            <EditSelectVehicles
                :vehicles="schedules.map(el => el.vehicle)"
                @update="updateVehicle"
            />
        </template>
        <template v-else-if="selectType === 'driver'">
            <EditSelectDrivers
                v-model="form.driver_id"
                :items="drivers"
                @close="() => selectDialog = false"
            />
        </template>
        <template v-else-if="selectType === 'good'">
            <EditSelectGoods
                v-model="form.good_id"
                @update="updateGood"
                @close="() => selectDialog = false"
            />
        </template>
    </el-dialog>
</template>
<script>
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import {httpService} from "@/services/http.service";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import EditSelectMixes from "@/views/dispatch/components/editSelects/EditSelectMixes.vue";
import EditSelectVehicles from "@/views/dispatch/components/editSelects/EditSelectVehicles.vue";
import EditSelectDrivers from "@/views/dispatch/components/editSelects/EditSelectDrivers.vue";
import MixPageOrderTimeline from "@/views/components/MixPage/OrdersGroup/MixPageOrderTimeline.vue";
import EditSelectGoods from "@/views/dispatch/components/editSelects/EditSelectGoods.vue";

export default {
    name: "AddShippingContentDeliveryEdit",
    components: {EditSelectGoods, MixPageOrderTimeline, EditSelectDrivers, EditSelectVehicles, EditSelectMixes},
    mixins: [functionsMixin, mobileCheckMixin],
    inject: ['timezone', 'api'],
    props: {
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
        total: [Number],
        mix: [Object],
        vehicle: [Object],
        vehicles: [Object],
        driver: [Object],
        mixId: [Number],
        start_at: {
            type: String,
            default: '00:00'
        },
        arrive_at: {
            type: String,
            default: '00:00'
        },
        editForm: [Object],
        mixOrder: [Object]
    },
    emits: ['setEditData'],
    data() {
        return {
            form: {
                total: null,
                mix_id: null,
                mixer_id: null,
                start_at: null,
                arrive_at: null,
                vehicle_id: null,
                driver_id: null,
                vehicle: null,
                good_id: null
            },
            timeLoading: 0,
            timeLoadingOld: 0,
            mixes: [],
            timeInfo: {
                loading: 0,
                toObject: 0,
                unloading: 0,
                return: 0,
                dist: 0
            },
            selectDialog: false,
            selectType: null,
            selectDialogTitle: null,
            drivers: [],
            schedules: [],
        }
    },
    created() {
        this.setData()
        this.getDrivers()
        this.getVehicles()
        this.getMixes(this.$route.params.id)
        if (this.mixOrder) {
            this.form.mix_id = this.mixOrder.mix_id
        }
    },
    computed: {
        mixers(){
            const mixers = [];
            this.mixes.forEach((mix) => {
                mix.mixers.forEach((m) => {
                    mixers.push(
                        {
                            'id': m.id,
                            'mix_id': mix.id,
                            'name': this.tableTitle(mix, m),
                        }
                    )
                })
            })
            return mixers
        },
        order() {
            return {
                start_at: this.convertTimeToDateTime(this.form.start_at),
                load_at: this.form.start_at ? this.convertTimeToDateTime(this.form.start_at, 'load_at') : null,
                arrive_at: this.convertTimeToDateTime(this.form.arrive_at),
                unload_at: this.form.arrive_at ? this.convertTimeToDateTime(this.form.arrive_at, 'unload_at') : null,
                return_at: this.form.arrive_at ? this.convertTimeToDateTime(this.form.arrive_at, 'return_at') : null,
                status: this.mixOrder?.status ?? 'wait',
                type: this.orderGroupItem.delivery_type
            }
        }
    },
    watch: {
        form: {
            handler(val) {
                this.$emit('setEditData', val)
            },
            deep: true
        },
        'form.mix_id': {
            handler: function () {
                this.getTimeInfo()
            },
            deep: true,
            immediate: true
        },
        'form.total': {
            handler: function (newTotal, oldTotal) {
                if (newTotal !== oldTotal && newTotal && oldTotal) {

                    this.timeLoading = newTotal * 2.5 * 60
                    this.timeLoadingOld = oldTotal * 2.5 * 60

                    let arrive_at

                    arrive_at = moment(this.form.arrive_at, 'HH:mm:ss').subtract(this.timeLoadingOld, 'seconds').add(this.timeLoading, 'seconds')
                    this.form.arrive_at = arrive_at.format("HH:mm:ss")
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        updateMixes(mix){
            this.form.mix_id = mix.mix_id
            this.form.mixer_id = mix.id
        },
        updateGood(goodId){
            this.form.good_id = goodId
        },
        tableTitle(mix, mixer){
            if (mix?.mixers.length > 1) return mix.name + ' - ' + mixer.name
            return mix.name
        },
        convertTimeToDateTime(time, type) {
            const now = moment(this.orderGroupItem.date_shipment);
            const timeMoment = moment(`${now.format('YYYY-MM-DD')}T${time}`);

            let addMinutes = 0

            if (type === 'load_at') {
                addMinutes = this.timeInfo.loading
            } else if (type === 'unload_at') {
                addMinutes = this.timeInfo.unloading
            } else if (type === 'return_at') {
                addMinutes = Math.abs(this.timeInfo.unloading) + Math.abs(this.timeInfo.return)
            } else if (type === 'arrive_at') {
                addMinutes = Math.abs(this.timeInfo.loading) + Math.abs(this.timeInfo.toObject)
            }

            return timeMoment.add(addMinutes, 'minutes').format();
        },
        setData() {
            if (this.total) this.form.total = this.total ?? 0
            if (!this.mixOrder) this.form.mix_id = this.mix?.module_reserve_id
            if (this.start_at) this.form.start_at = moment.parseZone(this.start_at).format('HH:mm')
            if (this.arrive_at) this.form.arrive_at = moment.parseZone(this.arrive_at).format('HH:mm')
            if (this.vehicle) this.form.vehicle_id = this.vehicle?.id
            if (this.driver) this.form.driver_id = this.driver?.id
            if (this.mixOrder?.time_info) this.timeInfo = JSON.parse(this.mixOrder.time_info)
        },
        async getMixes(accountModulesId) {
            await httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: accountModulesId,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                const {data} = response.data
                this.mixes = data

                if (this.mixes.length === 1) {
                    this.form.mix_id = this.mixes[0].id
                    this.form.mixer_id = this.mixes[0].mixers[0].id

                    if (!this.mixOrder) this.getTimeInfo()
                }
            })
        },
        async getTimeInfo() {
            if (!this.form.start_at) {
                this.form.start_at = moment().tz(this.orderGroupItem.timezone).add(5,'minutes').format('HH:mm')
                this.changedOrder('start_at')
            }
            if (this.form.mix_id) {
                await httpService().post(this.api.dispatch.orderGroups.getTimeInfo, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    order_group_item_id: this.orderGroupItem.id,
                    mixId: this.form.mix_id,
                    total: this.form.total
                }).then((response) => {
                    let data = response.data
                    if (data.success) {
                        this.timeInfo = data.timeInfo
                    }
                    this.form.arrive_at = moment(this.convertTimeToDateTime(this.form.start_at, 'arrive_at')).format("HH:mm:ss")
                })
            }
        },
        getDrivers() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.drivers = data.users.filter(el => el.roleKey === 'mixDriver')
            })
        },
        getVehicles(){
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.orderGroupItem.date
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.schedules = data.schedules
                }
            })
        },
        getLabel(type) {
            let label = null

            if (type === 'mix') {
                label = this.mixes.find(el => el.id === this.form.mix_id)?.name ?? '-'
            }
            if (type === 'vehicle') {
                if (this.form.vehicle) {
                    label = this.form.vehicle?.number
                } else {
                    if (this.form.vehicle_id) {
                        label = this.vehicles.find(el => el.id === this.form.vehicle_id)?.number
                    } else if (this.vehicle) {
                        label = this.vehicle.number
                    }
                }
            }
            if (type === 'driver') {
                label = this.drivers.find(el => el.id === this.form.driver_id)?.name
                if (label) label = this.formatName(label)
            } else if (type === 'good') {
                label = this.getData(this.form.good_id, 'good')
            }

            return label ?? '-'
        },
        async changedOrder(time_type) {
            await this.getTimeInfo()
            if (this.start_at !== this.arrive_at && this.total) {
                const timeLoading = this.total * 2.5 * 60

                let timeLoad = moment(this.start_at)
                let timeObject = moment(this.arrive_at).subtract(timeLoading, 'seconds').add(this.timeLoading, 'seconds')

                // let minutesDiffLoad = timeObject.diff(timeLoad, "minutes") + 1
                let minutesDiffLoad = Math.round(timeObject.diff(timeLoad, "minutes"))


                if (time_type === "start_at") {

                    this.form.arrive_at = moment(this.form.start_at, 'HH:mm')
                        .add(minutesDiffLoad, "minutes")
                        .format("HH:mm")
                }


                if (time_type === "arrive_at") {
                    this.form.start_at = moment(this.form.arrive_at, 'HH:mm')
                        .subtract(minutesDiffLoad, "minutes")
                        .format("HH:mm");
                }
            } else {
                if (this.form.start_at || this.form.arrive_at) {
                    const timeLoading = this.timeInfo.loading ?? 0
                    const timeDelivery = this.timeInfo.toObject ?? 0

                    const addTime = Math.round(timeLoading + timeDelivery)
                    if (time_type === "start_at") {
                        this.form.arrive_at = moment(this.form.start_at, 'HH:mm')
                            .add(addTime, "minutes")
                            .format("HH:mm")
                    }

                    if (time_type === "arrive_at") {
                        this.form.start_at = moment(this.form.arrive_at, 'HH:mm')
                            .subtract(addTime, "minutes")
                            .format("HH:mm");
                    }
                }
            }
        },
        openSelectDialog(type) {
            this.selectType = type

            if (type === 'mix') {
                this.selectDialogTitle = 'Выберете бетонный завод'
            } else if (type === 'vehicle') {
                this.selectDialogTitle = 'Выберите транспортное средство'
            } else if (type === 'driver') {
                this.selectDialogTitle = 'Выберите водителя'
            } else if (type === 'good') {
                this.selectDialogTitle = 'Выберите рецепт'
            }

            this.selectDialog = true
        },
        updateVehicle(vehicle) {
            let driver_id = this.schedules.find((f) => f.vehicle_id === vehicle.id)

            this.form.vehicle = vehicle
            this.form.vehicle_id = vehicle?.id

            if (driver_id) {
                this.form.driver_id = driver_id.driver_id
            } else if(!this.form.driver_id && vehicle?.driver_id) {
                this.form.driver_id = vehicle.driver_id
            }

            this.selectDialog = false
        }
    }
}
</script>
<style lang="scss">
.content__delivery {
    &__item {

        .el-input-number {
            width: 100px;
        }

        .el-input-number__increase {
            border: none;
            border-radius: 4px 4px 2px 2px;
            background-color: #fff;
            height: 20px !important;
            width: 20px !important;
            right: 3px !important;
            border: none !important;
            border-radius: 4px 4px 0 0 !important;
        }

        .el-input-number__decrease {
            border: none;
            border-radius: 2px 2px 4px 4px;
            background-color: #fff;
            height: 20px !important;
            width: 20px !important;
            right: 3px !important;
            border: none !important;
            border-radius: 0 0 4px 4px !important;
        }

        &__input-number {
            height: 40px;
            width: 100px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #393f4a;

            .el-input {
                .el-input__wrapper {
                    height: 40px;
                    display: flex;
                    border-radius: 6px;
                    box-shadow: none;
                    padding: 0 0 0 15px !important;
                    background-color: #F7F7F8 !important;
                }
            }

            .el-input__inner {
                height: 40px;
                color: #393F4A;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-align: left !important;
            }
        }
    }
}

.content__delivery__item__data__select {
    .el-select__wrapper {
        padding: 0;
        border: none;
        box-shadow: none;
        align-items: flex-end;
        height: 21px !important;
        min-height: 0 !important;

        .el-select__suffix {
            display: none;
        }
    }

    .el-select__wrapper.is-hovering:not(.is-focused) {
        box-shadow: none;
    }

    .el-select__wrapper.is-focused {
        box-shadow: none;
    }

    .el-select__placeholder {
        top: unset;
        transform: translateY(-50%);
        color: #1984E6;
        text-decoration-line: underline;
    }

    .el-select__placeholder.is-transparent {
        color: #1984E6;
    }
}
</style>
<style scoped lang="scss">
.el-form-item {
    margin-bottom: 0 !important;
}

.content__delivery {
    display: flex;
    align-items: center;
    width: 100%;

    &__item {
        margin-right: 21px;
        min-width: 120px;

        &__total {
            width: 100px;
            min-width: 100px;
            height: 40px;
        }

        &__label {
            color: #2c3139;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-right: 4px;
        }

        &__data {

            height: 26px;
            display: flex;
            margin-top: 4px;
            justify-content: start;
            align-items: center;

            svg {
                margin-right: 5px;
            }

            &__select {

            }
        }
    }

    &__footer {
        justify-content: flex-end;
        margin-top: 25px;
        display: flex;
    }
}
</style>
