<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle :title="integration.integration.name"/>

            <DrawerTextLineItem name="Модуль"
                                :desc="integration.account_module?.name"/>

            <template v-if="integration.integration.key !== 'Wappi'">

                <h4 class="mb-10">Особые настройки:</h4>

                <el-form ref="form" :model="settings" size="small"
                         label-position="top">

                    <div
                        v-for="(item, index) in settings"
                        :key="index">

                        <template v-if="item.key === 'id'"/>

                        <FormSelect
                            v-else-if="item.key === 'cashboxId'"
                            v-model="item.value"
                            label="Модуль для финансовых операций"
                            placeholder="Выберите модуль"
                            :items="modules.filter(el => el.module_key === 'cashbox')"
                        />

                        <template v-else-if="item.key === 'mixIds'">
                            <el-form-item label="Модули бетонных заводов:"
                                          v-if="modules.filter(el => el.module_key === 'mix').length > 0">
                                <el-checkbox-group v-model="item.value" size="large" class="mb-15">
                                    <el-checkbox
                                        v-for="(module, index) in modules.filter(el => el.module_key === 'mix')"
                                        :key="index"
                                        :label="module.module_reserve_id">{{ module.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </template>

                        <FormInput
                            v-else
                            v-model="item.value"
                            :label="item.label"
                            :placeholder="item.placeholder"
                        />

                    </div>

                </el-form>

                <template v-if="integration.integration.key === 'SmartMix'">
                    <hr/>
                    <h4>Переобход данных</h4>
                    <el-form label-position="top">

                        <FormDateTimePicker
                            v-model="dataSmartMix.start"
                            label="Начало периода"
                            placeholder="Выберите дату"
                        />

                        <FormDateTimePicker
                            v-model="dataSmartMix.end"
                            label="Начало периода"
                            placeholder="Выберите дату"
                        />

                        <el-button
                            @click="getDataSmartMix()"
                            :disabled="!dataSmartMix.start || !dataSmartMix.end">
                            Получить данные за период
                        </el-button>

                    </el-form>
                </template>

            </template>

            <div style="display: flex; justify-content: center" v-else>
                <h4 class="mb-10">QR-код:</h4>
                <img style="width: 50%;" :src="qrCode"/>
            </div>

        </template>

        <template v-slot:footer>

            <DrawerCreateButton
                :loading="loading"
                title="Сохранить изменения"
                @action="editIntegration"
            />

            <DrawerDeleteButton
                :loading="loading"
                @action="deleteIntegration"
            />

        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import FormInput from '@/views/components/Form/FormInput'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import DrawerDeleteButton from '@/views/components/Drawer/Buttons/DrawerDeleteButton'
import {httpService} from '@/services/http.service'
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";

export default {
    name: 'IntegrationDetails',
    props: [
        'integration'
    ],
    components: {
        FormSelect,
        FormDateTimePicker,
        DrawerDeleteButton,
        DrawerCreateButton,
        FormInput,
        DrawerTextLineItem,
        DrawerHeadTitle,
        DrawerFramework
    },
    inject: [
        'api'
    ],
    data() {
        return {
            loading: false,
            settings: [],
            qrCode: '',
            dataSmartMix: {
                start: null,
                end: null
            },
            modules: []
        }
    },
    created() {
        if (this.integration.integration.key === 'Wappi') {
            this.loading = true;
            httpService().post(this.api.whatsapp.getQrCode).then((response) => {
                let data = response.data;

                if (data.qrCode) this.qrCode = data.qrCode;
            }).finally(() => {
                this.loading = false
            });
        } else if (this.integration.integration.key === '1C') {
            this.getModules()
        }
        if (this.integration.settings) {
            const obj = JSON.parse(this.integration.settings)

            for (var key in obj) {
                // этот код будет вызван для каждого свойства объекта

                let label = null
                let placeholder = null

                if (key === 'objectId') {
                    label = 'Идентификатор объекта'
                    placeholder = 'Н-р: 111111 Рога и Ко Автовесы'
                }

                if (key === 'user') {
                    label = 'Имя пользователя БД'
                    placeholder = 'user_db'
                }

                if (key === 'login') {
                    label = 'Логин'
                    placeholder = 'login'
                }

                if (key === 'password') {
                    label = 'Пароль'
                    placeholder = '12341234'
                }

                if (key === 'host') {
                    label = 'Хостинг'
                    placeholder = 'https://hosting.glonasssoft.ru/'
                }

                if (key === 'db_name') {
                    label = 'Имя базы данных'
                    placeholder = 'data'
                }

                if (key === 'secretKey') {
                    label = 'Секретный ключ'
                    placeholder = '16 символов'
                }

                if (key === 'name') {
                    label = 'Название'
                    placeholder = 'Введите название'
                }

                if (key === 'warehouse') {
                    label = 'Название склада для реализации'
                    placeholder = 'Введите название'
                }

                this.settings.push({
                    value: obj[key],
                    label: label,
                    placeholder: placeholder,
                    key: key
                })
            }
        }
    },
    methods: {
        editIntegration() {
            this.loading = true
            httpService().post(this.api.settings.editIntegration, {
                integration: this.integration,
                settings: this.settings,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Изменения приняты',
                        showClose: true,
                        type: 'success'
                    })
                this.$emit('moduleGet')
                this.$emit('closeDrawer')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        getDataSmartMix() {
            this.loading = true
            httpService().post(this.api.settings.getDataSmartMix, {
                integration: this.integration,
                form: this.dataSmartMix,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Переобход данных запущен',
                        showClose: true,
                        type: 'success'
                    })
                this.$emit('moduleGet')
                this.$emit('closeDrawer')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        deleteIntegration() {
            this.loading = true

            this.$confirm(
                'Вы действительно хотите удалить интеграцию?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.settings.removeIntegration, {
                        integration: this.integration,
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Интеграция удалена',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('moduleGet')
                        this.$emit('closeDrawer')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            type: 'error',
                            showClose: true,
                        })
                    }).finally(() => {
                        this.loading = false
                    })
                })

            this.loading = false
        },
        getModules() {
            this.loading = true
            httpService().post(this.api.account_module.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.modules = data.modules

            })
        },
    }
}
</script>

<style scoped>

</style>
