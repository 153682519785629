<template>
    <div class="group-orders-summary">
        <div class="group-orders-summary-body">
            <el-row class="summary">
                <el-col class="date-picker-container">
                    <div class="current-day">{{ currentDayName }}</div>
                    <el-date-picker
                        class="summary-date-picker pointer"
                        v-model="filters['filter[date]']"
                        :type="'date'"
                        :format="'D MMMM'"
                        :clearable="false"
                        :editable="false"
                        :size="'default'"
                        @change="$emit('changeDate', filters['filter[date]'])"
                    />
                </el-col>
                <el-col>
                    <div class="order-groups">
                        <img :src="require('@/assets/open_box.svg')" class="order-groups__icon"/>
                        <div class="order-groups__info">
                            <div class="order-groups__info__title">
                                {{ `${summary.orders_count ?? 0} ${declension(summary.orders_count ?? 0, ['заказ', 'заказа', 'заказов'])}` }}
                            </div>
                            <div class="order-groups__info__desc">
                                Всего
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col>
                    <div class="order-groups">
                        <img :src="require('@/assets/box.svg')" class="order-groups__icon"/>
                        <div class="order-groups__info">
                            <div class="order-groups__info__title">
                                {{ `${formatNumber(summary.volume_for_period, 1) ?? 0} м³` }}
                            </div>
                            <div class="order-groups__info__desc">
                                Объем на день
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col>
                    <div class="order-groups">
                        <img :src="require('@/assets/warning-2.svg')" class="order-groups__icon"/>
                        <div class="order-groups__info">
                            <div class="order-groups__info__title">
                                {{ `${formatNumber(summary.unallocated, 1) ?? 0} м³` }}
                            </div>
                            <div class="order-groups__info__desc--yellow">
                                Нераспределено
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col>
                    <div class="order-groups">
                        <img :src="require('@/assets/calendar-alt.svg')" class="order-groups__icon"/>
                        <div class="order-groups__info">
                            <div class="order-groups__info__title">
                                {{ `${summary.delivery_count ?? 0} ${declension(summary.delivery_count ?? 0, ['доставка', 'доставки', 'доставок'])}` }}
                            </div>
                            <div class="order-groups__info__desc">
                                Запланировано
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col>
                    <div class="order-groups">
                        <img :src="require('@/assets/truck.svg')" class="order-groups__icon"/>
                        <div class="order-groups__info">
                            <div class="order-groups__info__title">
                                {{ `${summary.running_cars_count ?? 0} авто` }}
                            </div>
                            <div class="order-groups__info__desc">
                                На линии
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col>
                    <div class="order-groups">
                        <img :src="require('@/assets/building.svg')" class="order-groups__icon"/>
                        <div class="order-groups__info">
                            <div class="order-groups__info__title">
                                {{ summary.plant_loading ?? 0 }} %
                            </div>
                            <div class="order-groups__info__desc">
                                Загрузка заводов
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment/moment'
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import mixOrderGroupStatusDeclension from "@/mixins/mixOrderGroupStatusDeclension";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'Summary',
    props: {
        summary: Object,
    },
    data() {
        return {}
    },
    mixins: [mixOrderGroupStatusDeclension, functionsMixin],
    computed: {
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
            getDate: 'getDate',
        }),
        currentDayName() {
            const format = "YYYY-MM-DD";
            const date = moment(this.getDate).toDate()
            const currentMomentDate = moment(date).format(format)
            const today = moment().format(format);

            if (currentMomentDate === today) {
                return 'Сегодня'
            }

            const tomorrow = moment().add({day: 1}).format(format);

            if (currentMomentDate === tomorrow) {
                return 'Завтра'
            }

            const lowerDate = moment(date, null, 'ru').format('dddd')
            return lowerDate.substring(0, 1).toUpperCase() + lowerDate.substring(1)
        }
    },
    methods: {
        declension(number, words) {
            number = Math.abs(number) % 100;
            const n1 = number % 10;
            if (number > 10 && number < 20) {
                return words[2];
            }
            if (n1 > 1 && n1 < 5) {
                return words[1];
            }
            if (n1 == 1) {
                return words[0];
            }
            return words[2];
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";

.order-groups {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;

    &__icon {
        margin-right: 9px;
        margin-top: auto;
        margin-bottom: auto;
        width: 20px;
    }

    &__info {
        margin-top: auto;
        margin-bottom: auto;

        &__title {
            color: #393F4A;
            // text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }

        &__desc {
            color: #1984E6;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */

            &--yellow {
                color: #FFB500;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}
</style>
