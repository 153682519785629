<template>
    <div class="map-container">
        <div class="p-relative">
            <div id="map" style="height: 70vh; width: 100%; border-radius: 15px"></div>
        </div>
    </div>
</template>


<style>
.hideMap {
    opacity: 0;
    position: absolute;
    top: -10000px;
    left: -10000px;
}

.map-container {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>


<script>
/* eslint-disable */
import functionsMixin from '@/mixins/functionsMixin'
import {GoogleMap, Marker, Polyline} from "vue3-google-map";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";


export default {
    name: "MixOrderDetailsGroupMap",
    props: ['coorLatFrom', 'coorLonFrom', 'coorLatTo', 'coorLonTo'],
    emits: ['hideWindow'],
    mixins: [functionsMixin],
    components: {GoogleMap, Marker, Polyline},
    data() {
        return {
            glonass_active: true,
            isLoading: false,
            map: null,
            googleMapApiKey: "AIzaSyDkcV88IudVAW2DdCBkCl7Ilk9ddKRxpQQ",
            directionsService: null,
            directionsDisplay: null,
        }
    },
    async mounted() {
        this.isLoading = true
        this.scriptElement = document.createElement('script');
        this.scriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapApiKey}&language=ru&callback=initMap`;
        this.scriptElement.defer = true;
        this.scriptElement.async = true;
        document.head.appendChild(this.scriptElement);
        window.initMap = this.initMapCallback;
    },
    computed: {
        getFromPosition() {
            return {lat: Number(this.coorLatFrom), lng: Number(this.coorLonFrom)}
        },
        getToPosition() {
            return {lat: Number(this.coorLatTo), lng: Number(this.coorLonTo)}
        },
        mapCenter() {
            if (this.modules.length > 0) {
                let key = this.$route.meta.moduleKey
                let id = this.$route.params.id
                let module = this.modules.find((m) => m.id == id)

                if (key === 'dispatch' || key === 'crm') {
                    module = this.modules.find((m) => m.accountModuleId == id)
                }

                if (module) {
                    if (module.factory) {
                        console.log(module.factory)
                        return { lat: parseFloat(module.factory.latitude), lng: parseFloat(module.factory.longitude)};
                    }
                }
            }

            return [55.75165053565251, 37.61758420869977];
        },
        ...mapState(useCommonStore, {
            modules: 'getModules'
        })
    },
    methods: {
        initMapCallback() {
            // eslint-disable-next-line no-undef
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.mapCenter,
                zoom: 10,
                disableDoubleClickZoom: true,
                fullscreenControl: false,
                rotateControl: false,
                panControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                language: "ru",
                region: "RU",
            });

            // eslint-disable-next-line no-undef
            this.directionsService = new google.maps.DirectionsService();
            // eslint-disable-next-line no-undef
            this.directionsDisplay = new google.maps.DirectionsRenderer({
                polylineOptions: {
                    strokeOpacity: 0.8,
                    strokeColor: '#1984e6',
                    strokeWeight: 5,
                },
            });
            this.directionsDisplay.setMap(this.map);
            this.drawRouteTwoPoints(this.getFromPosition, this.getToPosition)
        },
        drawRouteTwoPoints(startPoint, endPoint) {
            const request = {
                origin: startPoint,
                destination: endPoint,
                travelMode: 'DRIVING',
            };

            this.directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    this.directionsDisplay.setDirections(result);
                } else {
                    console.error('Ошибка: ' + status);
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
