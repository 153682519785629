<template>
    <div class="delivery">
        <div class="delivery__title">
            Новая доставка
        </div>
        <div v-loading="loading" class="delivery__form">
            <div class="delivery__form__header">
                <div class="delivery__form__header__title">
                    Указать параметры вручную
                </div>
                <div class="delivery__form__header__cancel" @click="goBack()">
                    Отмена
                </div>
            </div>
            <div class="delivery__form__content">
                <AddShippingContentDeliveryEdit
                    v-if="!loading"
                    :total="total"
                    :mix="mix"
                    :start_at="start_at"
                    :arrive_at="arrive_at"
                    :vehicle="vehicle"
                    :driver="driver"
                    :mixes="mixes"
                    :vehicles="vehicles"
                    :drivers="drivers"
                    :order-group-item="orderGroupItem"
                    @setEditData="(editData)=>editForm = editData"
                />
            </div>
        </div>
        <div class="delivery__footer">
            <div
                class="ci-btn ci-btn_default mr-10 delivery__form__footer__cancel"
                style="display:inline-flex;"
                type="button"
                @click="goBack()"
            >
                Отмена
            </div>
            <button
                class="ci-btn ci-btn_blue"
                @click="save()"
            >
                Продолжить
            </button>
        </div>
    </div>
</template>
<script>
import AddShippingContentDeliveryEdit from "@/views/dispatch/components/AddShippingContentDeliveryEdit.vue";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    name: "AddManualDelivery",
    components: {
        AddShippingContentDeliveryEdit,
    },
    mixins: [functionsMixin],
    props:{
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
    },
    data(){
        return{
            total: 0,
            mix: null,
            vehicle: null,
            start_at: '',
            arrive_at: '',
            driver: null,
            loading: true,
            specify: false,
            requirements: null,
            distance: 0,
            time_to_object: 0,
            mixes:[],
            vehicles:[],
            drivers:[],
            editForm:{},
            bestDeliveryError:{
                code: null,
                message: null,
                show: false,
            }
        }
    },
    async mounted() {
        await this.getBestDelivery(this.orderGroupItem.id)
        this.getMixes(this.$route.params.id)
        this.getVehicles()
        this.getDrivers()
    },
    methods:{
        save(){

            let orders = []
            let startDate = moment(this.start_at).format('DD-MM-YYYY')
            let arriveDate = moment(this.arrive_at).format('DD-MM-YYYY')

            orders.push({
                mix_id: this.editForm.mix_id,
                total: this.editForm.total,
                vehicle_id: this.editForm.vehicle_id,
                driver_id: this.editForm?.driver_id,
                start_at: this.dateTimeToUtc(`${startDate} ${this.editForm.start_at}`, this.timezone, 'DD-MM-YYYY HH:mm'),
                arrive_at: this.dateTimeToUtc(`${arriveDate} ${this.editForm.arrive_at}`, this.timezone, 'DD-MM-YYYY HH:mm'),
            })

            httpService().post(this.api.dispatch.orders.create, {
                orderGroupId: this.orderGroupItem.id,
                orders: orders,
                timeInfo:{
                    loading: 0,
                    toObject: 0,
                    unloading: 0,
                    return: 0,
                }
            }).then(() => {
                this.$message({
                    message: 'Отгрузка успешно создана',
                    type: 'success'
                })
            }).catch((error) => {
                const { errors } = error.response.data
                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            })
        },
        goBack(){
            this.$emit('goBack')
        },
        getDrivers(){
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.drivers = data.users.filter(el => el.roleKey === 'mixDriver')
            })
        },
        getVehicles(){
            httpService().post(this.api.vehicle.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.vehicles = data.vehicles
            })
        },
        getMixes(accountModulesId) {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: accountModulesId,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                const {data} = response.data
                this.mixes = data
            })
        },
        async getBestDelivery(orderGroupsId){
            this.loading = true
            this.bestDeliveryError.show = false

            await httpService().post(this.api.dispatch.orderGroups.getBestDelivery, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_groups_id: orderGroupsId,
            }).then((response) => {
                const { start_at, total, arrive_at, vehicle, mix, driver, requirements, distance, time_to_object } = response.data.data
                this.total = total
                this.mix = mix
                this.start_at = start_at
                this.arrive_at = arrive_at
                this.vehicle = vehicle
                this.driver = driver
                this.requirements = requirements
                this.distance = distance
                this.time_to_object = time_to_object
            }).catch(() => {

                this.start_at = this.orderGroupItem.date_shipment
                this.arrive_at = this.orderGroupItem.date_shipment

                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>
<style scoped lang="scss">
.delivery{
    &__title{
        color: #393F4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }
    &__form{
        margin-top: 24px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #EEEEF0;

        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &__title{
                color: #393F4A;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            &__cancel{
                color: #49515F;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }

        &__content{
            display: flex;
            height: 44px;
        }
    }
    &__footer{
        justify-content: flex-end;
        margin-top: 186px;
        display: flex;
    }
}
</style>
