<template>
    <el-select
        placeholder="Контрагенты"
        size="large"
        class="filterSelect"
        style="width: 220px"
        @click="$emit('openDialog')"
        @clear="$emit('resetFilter')"
        clearable
        v-model="contragentsListString"
    >
    </el-select>
</template>

<script>
export default {
    name: "FormSelectContragents",
    props: ["placeholder", "contragents"],
    computed: {
        contragentsListString() {
            return this.contragents?.map((el) => el.name).join(", ");
        },
    },
    watch: {
        contragents(newValue) {
            this.contragentsListString = newValue.map((el) => el.name).join(", ");
        },
    },
};
</script>
