<template>
    <div class="distribute">
            <div v-for="(mixer, mixerIndex) in mixers" :key="mixerIndex">
                <div class="w-100 bg-neutral30" style="padding: 5px;">
                    <span class="textMedium neutral900">
                        {{ mixer.count > 1 ? mixer.module_name + ' - ' + mixer.name : mixer.module_name }}
                    </span>
                </div>
                <div :id="'mix-gantt' + mixer.id"></div>
            </div>
        </div>
</template>
<script>
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import {MomentSvelteGanttDateAdapter, SvelteGantt, SvelteGanttDependencies, SvelteGanttTable} from "svelte-gantt";
import { nextTick } from 'vue'

export default {
    name: 'DistributeOrderGroupTable',
    mixins: [functionsMixin],
    props:[
        'distribute',
        'extraOrders',
        'mixers',
        'time',
        'date',
        'vehicles',
    ],
    data(){
        return{
            gantt: null
        }
    },
    async mounted() {
        this.drawGantt()

        await nextTick()
        this.removeHeader()
    },
    watch: {
        'distribute': {
            handler: function () {
                this.drawGantt()
            },
            immediate: false,
            deep: true
        },
    },
    computed:{
        diffTime() {
            const localTime = moment();  // Текущее время в локальном часовом поясе
            const timezoneTime = moment().tz(this.time.timezone);  // Время в указанном часовом поясе

            // Смещение для локального времени (в минутах)
            const localOffset = localTime.utcOffset();

            // Смещение для времени в другой временной зоне (в минутах)
            const timezoneOffset = timezoneTime.utcOffset();

            // Вычисляем разницу в смещениях (в минутах)
            return (timezoneOffset - localOffset) / 60;  // Переводим в часы
        },
        timeRanges() {
            if (this.date) {

                if (this.date.isSame(moment(), 'day')) {
                    return [
                        {
                            id: 0,
                            from: moment().add(-30, 'seconds').add(this.diffTime, 'hours'),
                            to: moment().add(30, 'seconds').add(this.diffTime, 'hours'),
                            classes: null,
                            label: ''
                        }
                    ]
                }
            }

            return []
        },
    },
    methods: {
        drawGantt() {
            this.mixers.forEach((mixer) => {
                document.getElementById('mix-gantt' + mixer.id).innerHTML = ""
                const options = this.options(mixer.id, mixer.mix_id)

                window.gantt = this.gantt = new SvelteGantt({
                    target: document.getElementById('mix-gantt' + mixer.id),
                    props: options
                })
            })
        },
        options(mixer_id, mix_id) {
            const rows = this.rows(mix_id)

            rows.push({
                id: null,
                label: 'Самовывоз',
                number: 'Самовывоз',
            })

            const ids = rows.map(obj => obj['id'])

            const formattedData = this.formattedData(mixer_id, rows).filter((f)=>{
                return ids.includes(f.resourceId)
            })


            return {
                dateAdapter: new MomentSvelteGanttDateAdapter(moment),
                rows: rows,
                tasks: formattedData,
                timeRanges: this.timeRanges,
                dependencies: [],
                from: moment(this.time.start_time_utc).add(this.diffTime, 'hours'),
                to:  moment(this.time.end_time_utc).add(this.diffTime + 1, 'hours'), //добавляем час, так как таблица обрезает последний час
                columnUnit: "hour",
                columnOffset: 1,
                magnetUnit: "second",
                magnetOffset: 1,
                rowHeight: 50,
                rowPadding: 2,
                columnStrokeColor: '#F9F9F9',
                columnStrokeWidth: 1,
                headers: [
                    {unit: 'hour', format: 'H:mm'}
                ],
                fitWidth: true,
                minWidth: 500,
                tableHeaders: [
                    {title: "", property: "label", width: 60, type: "tree"}
                ],
                tableWidth: 145,
                ganttTableModules: [SvelteGanttTable],
                ganttBodyModules: [SvelteGanttDependencies],
                classes: 'position-relative svelte-gantt-table',
            }
        },
        getDifferenceInHours(timezone1, timezone2) {
            let date1 = new Date().toLocaleString("en-US", {timeZone: timezone1});
            let date2 = new Date().toLocaleString("en-US", {timeZone: timezone2});

            date1 = new Date(date1);
            date2 = new Date(date2);

            let differenceInMilliseconds = Math.abs(date1 - date2);
            let differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

            return differenceInHours;
        },
        removeHeader(){
            let headers = document.querySelectorAll('.sg-gantt .sg-header');
            for (let i = 1; i < headers.length; i++) {
                headers[i].style.display = 'none';
            }
        },
        getVehicleHeaderHtml(vehicle){

            return `<div class="d-flex sg-cell-inner vehicle-item">
                      <span class="textSM textMedium ${vehicle.vehicle.rent ? 'neutral700' : 'neutral50' } el-tooltip__trigger el-tooltip__trigger">
                        ${vehicle.vehicle.rent ? 'А' : ''}
                      </span>
                      <span class="textSM textMedium ${vehicle.vehicle.gidrolotok ? 'neutral700' : 'neutral50' } el-tooltip__trigger el-tooltip__trigger ml-10">
                        ${vehicle.vehicle.gidrolotok ? 'Г' : ''}
                      </span>
                      <div class="vehicle-item__number ml-10">${vehicle.vehicle.number}</div>
                    </div>`
        },
        rows(mix_id){
            let rows = []

            this.vehicles.forEach((vehicle)=>{
                if (vehicle.mixes.includes(mix_id)){
                    rows.push({
                        'id': vehicle.vehicle_id,
                        'label': vehicle.vehicle.number,
                        'number': vehicle.vehicle.number,
                        'headerHtml': this.getVehicleHeaderHtml(vehicle),
                        'enableDragging': true,
                    })
                }
            })

            rows = rows.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            })

            return rows
        },
        formattedData(mixer_id, rows) {
            const formatTime = (time, tz) => {
                return moment(time).utc().tz(tz).format('HH:mm');
            }

            const getTaskConfig = (item, index, count, mixer_id) => {
                let itemTZ = this.moduleTimezone

                const getDifferenceInHours = this.getDifferenceInHours(Intl.DateTimeFormat().resolvedOptions().timeZone, itemTZ)

                return {
                    id: index + '-mix-' + mixer_id,
                    amountDone: 0,
                    buttonClasses: '',
                    buttonHtml: '',
                    class: null,
                    details: {
                        total: item.total,
                        id: item.type === 'delivery' ? item.vehicle_id : null,
                        vehicle_id: item.vehicle_id,
                        time_start: formatTime(item.start_at, itemTZ),
                        time_load: formatTime(item.load_at, itemTZ),
                        time_object: formatTime(item.unload_at, itemTZ),
                        time_unloading: formatTime(item.unload_at, itemTZ),
                        time_return: formatTime(item.return_at, itemTZ),
                        time_done: formatTime(item.arrive_at, itemTZ)
                    },
                    doc: 0,
                    enableDragging: false,
                    enableResize: false,
                    showButton: false,
                    new: true,
                    status: 'new',
                    resourceId: item.type === 'delivery' ? item.vehicle_id : null,
                    label: 'label',
                    classCount: count,
                    classes: `bg-task-default-${count}`,
                    html: this.getHtmlOrder(
                        moment(item.start_at).utc().tz(itemTZ),
                        moment(item.return_at).utc().tz(itemTZ),
                        formatTime(item.load_at, itemTZ),
                        formatTime(item.start_at, itemTZ),
                        formatTime(item.unload_at, itemTZ),
                        formatTime(item.arrive_at, itemTZ),
                        formatTime(item.return_at, itemTZ),
                        count,
                        item.total,
                        this.getData(item.company_id, 'company'),
                        item.id
                    ),
                    from: moment(item.start_at).utc().add(getDifferenceInHours, 'hours'),
                    to: moment(item.return_at).utc().add(getDifferenceInHours, 'hours')
                };
            };

            const distributeTasks = Object.values(this.distribute).map((distItem, index) => {
                if (distItem.mixer_id === mixer_id) {

                    if ('status' in distItem){
                        if (distItem.status === 'done') return getTaskConfig(distItem, index, 1);
                        if (distItem.status !== 'new') return getTaskConfig(distItem, index, 3);
                    }

                    return getTaskConfig(distItem, index, 2);
                } else if(rows.find(r => r.id == distItem.vehicle_id)) {
                    return getTaskConfig(distItem, index, 5);
                }
                return null;
            }).filter(item => item !== null);

            const extraTasks = Object.values(this.extraOrders).map((extraOrder, index) => {
                if (extraOrder.mixer_id === mixer_id) {
                    return getTaskConfig(extraOrder, `extra-${index}`, 1);
                } else if(rows.find(r => r.id == extraOrder.vehicle_id)) {
                    return getTaskConfig(extraOrder, `extra-${index}`, 5);
                }
                return null;
            }).filter(item => item !== null);

            return distributeTasks.concat(extraTasks);
        },
        getHtmlOrder(from, to, time_load, time_start, time_unloading, arrive_at, time_return, classCount, total, vehicle_number, id) {

            let minutesInRange = this.getMinutesInRange(from, to)
            const minutesToPercent = minutesInRange / 100
            let moreOrderStart1,
                moreOrderEnd1,
                moreOrderStart2,
                moreOrderEnd2

            moreOrderStart1 = 0
            moreOrderEnd1 = this.getMinutesInRange(time_load, time_return) / minutesToPercent

            moreOrderStart2 = this.getMinutesInRange(time_start, arrive_at) / minutesToPercent
            moreOrderEnd2 = Math.abs(this.getMinutesInRange(time_unloading, time_return) / minutesToPercent)

            return `<div class="custom_task-main custom_task-main_${classCount}">
                      <div class="task_content">
                        <div class="task_content_time">
                          ${id ? '#' + id + ' ' : ''}${time_start} - ${time_return}
                        </div>
                        <div class="task_content_vehicle">${total} м³ ${vehicle_number}</div>
                      </div>
                      <div style="width: auto;left:${moreOrderStart1}%;right:${moreOrderEnd1}%" class="custom_task-block_${classCount} startEnd1" title="Загрузка">
                      </div>
                      <div style="width: auto;left:${moreOrderStart2}%;right:${moreOrderEnd2}%" class="custom_task-block_${classCount} startEnd2" title="Разгрузка">
                      </div>
                    </div>`;
        },
        getMinutesInRange(fromTime, toTime) {
            const format = "HH:mm"
            const fromMoment = moment(fromTime, format).utc()
            const toMoment = moment(toTime, format).utc()

            const duration = moment.duration(toMoment.diff(fromMoment))
            const minutes = duration.asMinutes()

            if (minutes) {
                return minutes
            } else {
                return 0
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.position-relative {
    position: relative;
}
</style>

<style lang="scss">
@import "@/sass/variables";

.vehicle-item{
    justify-content: space-between;
    align-items: center;

    .el-tooltip__trigger{
        cursor: pointer;
        //color: #393F4A;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        min-width: 10px;
        margin-left: 16px;
    }
    &__number{
        width: 79px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.sg-gantt .sg-table-body-cell.sg-table-cell{
    //padding: 0 16px;
    padding-right: 16px;
}
.svelte-gantt-table{
    .sg-table-header-cell{
        background: #fcfcfc;
    }
}
.sg-gantt .custom_task-main_5 {
    color: #AFB3BA !important;
}

.sg-gantt .custom_task-block_5 {
    height: 4px;
    position: absolute;
    background: #C9CCD0 !important;
    transition: 0.3s all;
    z-index: 200;
}

.sg-gantt .sg-table-body-cell{
    background: #fcfcfc;
}

.sg-gantt .bg-task-default-5{
    background-color: #F9F9F9!important;

    .sg-task-content{
        padding: 0;
    }
}

.sg-task-content{
    padding: 0;
}

.sg-gantt .custom_task-main_5::after {
    background: #e8e8e8;
}

.sg-popup {
    z-index: 10;

    &.context {
        height: 40px;
        border: 1px solid $neutral50;
        border-radius: 12px;
        background-color: $neutral30;
        box-shadow: 0px 2px 8px 0px rgba(16, 24, 40, 0.10);
        overflow: hidden;

        display: flex;
        align-items: stretch;

        button {
            border: none;
            background-color: $white;

            padding: 12px 16px;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 10px;

            cursor: pointer;
            transition: color .2s, background-color .2s;

            svg {
                transition: color .2s;
            }

            &:hover {
                color: $primary600;
                background-color: $neutral30;

                svg {
                    color: $primary600;
                }
            }
        }
    }

    &.edit {
        width: 280px;
        border-radius: 8px;
        border: 1px solid $neutral50;
        background-color: $white;
        box-shadow: 0px 4px 6px 0px rgba(16, 24, 40, 0.03), 0px 12px 16px 0px rgba(16, 24, 40, 0.08);
        overflow: hidden;
    }

    &__content {
        padding: 12px 16px 16px;

        h3 {
            padding: 0;
            margin: 0;

            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $neutral700;
        }

        .form {
            padding: 12px 0 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            & > div {
                display: grid;
                grid-template-columns: 1.1fr 0.9fr;
                gap: 8px;
                align-items: center;
                align-content: center;

                & > span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $neutral500;

                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                .correct {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 107.1px;

                    svg {
                        cursor: pointer;
                        transition: color .2s;

                        &:hover {
                            color: $primary600;
                        }
                    }
                }

                & > div:not(.correct) {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }

            & > a {
                display: flex;
                align-items: center;
                gap: 4px;
                width: fit-content;

                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: $primary600;
                text-decoration: none;
            }
        }
    }

    &__buttons {
        display: flex;

        button {
            padding: 12px 0;
            width: 100%;
            border: unset;

            cursor: pointer;
            transition: background-color .2s, color .2s;
        }

        &-cancel {
            background-color: $white;
            border-top: 1px solid $neutral50;

            &:hover {
                color: $primary600;
                background-color: $neutral30;
            }
        }

        &-accept {
            color: $white;
            background-color: $primary600;

            &:hover {
                background-color: $primary500;
            }
        }
    }

    .link-to-order {
        width: fit-content;
        display: flex;
        align-items: flex-end;
        gap: 4px;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        cursor: pointer;
        color: $primary600;
        transition: color .2s;

        svg {
            transition: color .2s;
        }

        &:not(.dark):hover {
            color: $primary500;

            svg {
                color: $primary500;
            }
        }

        &.dark {
            color: $neutral700;

            svg {
                color: $neutral700;
            }
        }
    }

    &__time {
        height: 28px !important;

        &.small {
            max-width: 50px;

            .el-input__wrapper {
                max-width: 50px;
            }
        }

        .el-input__wrapper {
            padding: 0 6px !important;
            flex-grow: unset !important;
            box-shadow: unset !important;
            border-radius: 6px !important;

            background-color: #F7F7F8 !important;

            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
            color: $neutral700 !important;
        }

        .el-input__inner {
            width: 100%;
            flex-grow: unset !important;
            height: 28px !important;

            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
            color: $neutral700 !important;
        }
    }

    &__select {
        height: 28px !important;

        .el-input__wrapper {
            padding: 0 6px !important;
            height: 28px !important;
            border-radius: 6px !important;
            box-shadow: none !important;
            border: 1px solid $neutral50;
        }

        .el-input__inner {
            height: 28px !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
            color: $neutral700 !important;
        }
    }

    &__input-number {
        height: 28px;

        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        color: $neutral700 !important;

        .el-input-number__increase {
            width: 16px !important;
            height: 11px !important;
            border: none !important;
            border-radius: 4px 4px 2px 2px !important;
            background-color: $white;
        }

        .el-input-number__decrease {
            width: 16px !important;
            height: 11px !important;
            border: none !important;
            border-radius: 2px 2px 4px 4px !important;
            background-color: $white;
        }

        .el-input {
            .el-input__wrapper {
                height: 28px !important;
                padding: 2px !important;
                display: unset !important;

                display: flex !important;
                border-radius: 6px;
                box-shadow: none;
                background-color: #F7F7F8;
            }
        }

        .el-input__inner {
            height: 28px !important;

            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
            color: $neutral700 !important;
            text-align: left !important;
            padding-left: 8px !important;
        }
    }

    &__timepicker {
        height: 28px !important;
        box-shadow: none !important;
        padding: 0 !important;

        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        color: $neutral700 !important;

        & > .el-icon {
            display: none;
        }

        .el-range-input {
            border-radius: 6px !important;
            background-color: #F7F7F8 !important;

            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
            color: $neutral700 !important;
        }

        .el-range-separator {
            padding: 0 4px !important;
        }
    }

    &__datepicker {
        height: 28px;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $neutral700;

        .el-input__wrapper {
            border-radius: 6px;
            box-shadow: none;
            background-color: #F7F7F8;
            padding: 0 2px;

            .el-input__prefix {
                border-radius: 4px;
                background-color: $white;

                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                .el-icon.el-input__icon {
                    margin: 0;

                    svg {
                        color: $neutral500;
                    }
                }
            }

            .el-input__inner {
                height: 28px !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 16px !important;
                color: $neutral700 !important;
                padding: 0 6px 0 8px !important;
            }

            .el-input__suffix {
                display: none !important;
            }
        }
    }
}

.task_content {
    padding: 5px 1px;
    width: 100%;
    overflow: hidden;
}

.task_content_time {
    margin-bottom: 4px;
    font-size: 9px;
}

.task_content_vehicle {
    font-size: 11px;
}

.opacity {
    opacity: .5;
}
</style>
