<template>
    <div class="widget-card widget-shipments-companies">
        <div class="widget-card-title">{{ title }}
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" :icon="icons.refresh"
                       circle></el-button><span class="widget-card-title_subTitle">Отгрузки контрагентов</span>
        </div>
        <div class="widget-card-body" v-loading="loading">
            <div v-if="data.length > 0">
            <div class="item" v-for="(value, index) in data" :key="index">
                <el-row class="mb-10">
                    <el-col :xs="18" :md="20">
                        <span class="item-top">{{ value.companyId ? getData(value.companyId, 'company') : '-' }}</span>
                    </el-col>
                    <el-col :xs="6" :md="4">
                        <span class="item-right colored">
                            <AnimateNumber :count="value.total"/> {{ getUnit(value.goods) }}</span>
                    </el-col>
                </el-row>

                <el-row class="widget-shipments-companies-good" v-for="(good, index) in value.goods" :key="index">
                    <el-col :xs="18" :md="20">
                        <span class="item-top">{{ good.id ? getData(good.id, 'good') : '-' }}</span>
                    </el-col>
                    <el-col :xs="6" :md="4">
                        <span class="item-right">
                            <AnimateNumber :count="good.total" fraction="1"/> <good-unit :unit="getData(good.id, 'good', 'unit')"/></span>
                    </el-col>
                </el-row>
            </div>
            </div>
            <div v-if="data.length === 0 && !loading">
                <div class="no-data-block">
                    <NoDataIcon/>
                    <p>
                        Сегодня заявок не было<br>Информация актуальна с 00:00 до {{ time }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoDataIcon from '../../components/NoDataIcon'
import functionsMixin from '@/mixins/functionsMixin'
import GoodUnit from "@/views/components/goodUnit";
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import AnimateNumber from "@/views/components/AnimateNumber.vue";

export default {
    name: 'MixCompaniesWidgetRight',
    props: [
        'id',
    ],
    inject: [
        'api'
    ],
    components: {
        AnimateNumber,
        GoodUnit,
        NoDataIcon
    },
    mixins: [
        functionsMixin, iconsMixin
    ],
    data() {
        return {
            data: [],
            loadingButton: true,
            loading: true,
            time: null,
            title: 'Отгрузки за сегодня'
        }
    },
    created() {
        this.loadingButton = true
        this.widgetGet(this.id)
        window.setInterval(() => {
            this.loadingButton = false
        }, 800);
        this.counterInterval =  setInterval(
            function()
            {
                this.updateWidget();
            }.bind(this), Math.floor(Math.random() * (10000 - 8000 + 1)) + 8000);
    },
    beforeUnmount: function () {
        clearInterval( this.counterInterval )
    },
    methods: {
        widgetGet(id) { // метод получения данных виджета с api
            // обращаемся в api, передаем id виджета
            httpService().post(this.api.widget.get, {
                id: id
            }).then((response) => {
                    // получаем массив с api
                    let data = response.data
                    if (data.success)
                        // кладем массив в виджет
                        this.data = data.data
                        this.time = data.time
                        this.title = data.widgetName
                        this.loading = false
                }
            )
        },
        onUpdate() {
            this.loadingButton = true
            this.updateWidget()
            window.setInterval(() => {
                this.loadingButton = false
            }, 800);
        },
        updateWidget() // метод обновления виджета
        {
            this.widgetGet(this.id)
        },
        getUnit(arr) {
            let units = []

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index
            }

            arr.forEach(element => units.push( this.getData(element.id, 'good','unit') ))
            units = units.filter(onlyUnique)

            if (units.length === 1) {
                return units[0]
            } else {
                return 'ед.'
            }
        }
    },
}
</script>

<style scoped>
</style>
