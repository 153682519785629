import { defineStore } from 'pinia'
import { httpService } from '@/services/http.service'
import { API_ROUTES } from '@/const'

export const useCommonStore = defineStore('common', {
    state: () => {
        return {
            user: null,
            token: null,
            widgets: [],
            modules: [],
            integrations: [],
            nav: [],
            goods: [],
            units: [],
            companies: [],
            factories: [],
            selectedFactory: null,
            notAvailableModuleAlertStatus: false,
            notAvailableModuleAlertType: null,
            removeDataDate: null,
            paymentModalModalStatus: false,
            paymentModalModalAmount: 0,
            bankRequisites: [],
            mix: [],
            goodsFirstRequestArray: [],
            companiesFirstRequestArray: []
        }
    },
    getters: {
        getUser: (state) => state.user,
        isAuth: (state) => state.user !== null && state.token !== null,
        getToken: (state) => state.token,
        getNav: (state) => state.nav,
        getModules: (state) => state.modules,
        getReports: (state) => state.reports,
        getReportFunctions: (state) => state.reportFunctions,
        getIntegrations: (state) => state.integrations,
        getGoodsFirstRequest: (state) => state.goodsFirstRequestArray,
        getCompaniesFirstRequest: (state) => state.companiesFirstRequestArray,
        getWidgets: (state) => state.widgets,
        getGoods: (state) => state.goods,
        getUnits: (state) => state.units,
        getCompanies: (state) => state.companies,
        getFactories: (state) => state.factories,
        getSelectedFactory: (state) => state.selectedFactory,
        getNotAvailableModuleAlertStatus: (state) => state.notAvailableModuleAlertStatus,
        getNotAvailableModuleAlertType: (state) => state.notAvailableModuleAlertType,
        getAnalyticsAvailable: (state) => state.analyticsAvailable,
        getReportsAvailable: (state) => state.reportsAvailable,
        getUserModuleAccess: (state) => state.userModuleAccess,
        getUserReportAccess: (state) => state.userReportAccess,
        getRemoveDataDate: (state) => state.removeDataDate,
        getPaymentModalModalStatus: (state) => state.paymentModalModalStatus,
        getPaymentModalModalAmount: (state) => state.paymentModalModalAmount,
        getBankRequisites: (state) => state.bankRequisites,
        getMix: (state) => state.mix,
        isDriver: state => state.user.role_id === 5,
        isOperator: state => state.user.role_id === 6
    },
    actions: {
        setUser(user) {
            this.user = user
        },
        setToken(token) {
            localStorage.setItem('api_token', token)
            this.token = token
        },
        clearToken() {
            localStorage.removeItem('api_token')
            this.token = null
        },
        setNotAvailableModuleAlert(status) {
            this.notAvailableModuleAlertStatus = status;
        },
        //не используется
        setNotAvailableModuleAlertType(type) {
            this.notAvailableModuleAlertType = type;
        },
        setAnalyticsAvailable(analyticsAvailable) {
            this.analyticsAvailable = analyticsAvailable;
        },
        setReportsAvailable(reportsAvailable) {
            this.reportsAvailable = reportsAvailable;
        },
        setUserModuleAccess(userModuleAccess) {
            this.userModuleAccess = userModuleAccess;
        },
        setUserReportAccess(userReportAccess) {
            this.userReportAccess = userReportAccess;
        },
        //не используется
        setRemoveDataDate(date) {
            this.removeDataDate = date;
        },
        setNav(nav) {
            this.nav = nav
        },
        setModules(modules) {
            this.modules = modules
        },
        setReports(reports) {
            this.reports = reports
        },
        setReportFunctions(reportFunctions) {
            this.reportFunctions = reportFunctions
        },
        setIntegrations(integrations) {
            this.integrations = integrations
        },
        setPaymentModalModalStatus(status) {
            this.paymentModalModalStatus = status;
        },
        setPaymentModalModalAmount(amount) {
            this.paymentModalModalAmount = amount;
        },
        setWidgets(widgets) {
            this.widgets = widgets
        },
        setGoods(goods) {
            this.goods = goods
        },
        setGoodsFirstRequest(goods) {
            this.goodsFirstRequestArray = goods
        },
        setUnits(units) {
            this.units = units
        },
        setCompanies(companies) {
            this.companies = companies
        },
        setCompaniesFirstRequest(companies) {
            this.companiesFirstRequestArray = companies
        },
        setBankRequisites(bankRequisites) {
            this.bankRequisites = bankRequisites;
        },
        setFactories(factories) {
            this.factories = factories
        },
        setSelectedFactory(factory) {
            this.selectedFactory = factory
        },
        setMix(mix) {
            this.mix = mix
        },
        pullIntegrations() {
            return httpService().post(API_ROUTES.settings.getIntegrations).then((response) => {
                let data = response.data
                if (data.success) {
                    this.setIntegrations(data.integrations)
                }
            })
        },
        pullGoods() {
            return httpService().post(API_ROUTES.good.get, {
                allGoods: false
            }).then((response) => {
                if (response.data.success) {
                    this.setGoods(response.data.goods)
                }
                this.setUnits(response.data.units)
            })
        },
        pullGoodsFirstRequest() {
            return httpService().post(API_ROUTES.good.get, {
                paginate: true,
                perPage: 10,
                page: 1,
                allData: true
            }).then((response) => {
                if (response.data.success) {
                    this.setGoodsFirstRequest(response.data)
                }
            })
        },
        pullCompaniesFirstRequest() {
            return httpService().post(API_ROUTES.company.get, {
                paginate: true,
                perPage: 10,
                page: 1,
                allData: true
            }).then((response) => {
                if (response.data.success) {
                    this.setCompaniesFirstRequest(response.data)
                }
            })
        },
        pullCompanies() {
            return httpService().post(API_ROUTES.company.get, {
                simple: true
            }).then((response) => {
                if (response.data.success) {
                    this.setCompanies(response.data.companies)
                }
            })
        },
        pullBankRequisites() {
            // return httpService().post(API_ROUTES.payment.getBankRequisites).then((response) => {
            //     if (response.data.success) {
            //         this.setBankRequisites(response.data.requisites)
            //     }
            // })
        },

        pullFactories() {
            return httpService().post(API_ROUTES.settings.getFactories).then((response) => {
                if (response.data.success) {
                    this.setFactories(response.data.factories)
                }
            })
        },

        reset() {
            this.$reset(); // Сброс до пустого хранилища
        }
    }
})
