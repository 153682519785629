<template>
    <Modal v-model="showModal" :loading="loading" @onClose="closeModal">
        <template #header-title>
            {{ headerTitle }}
        </template>
        <template #header-description>
            {{ headerDescription }}
        </template>
        <template v-if="step === 3" #header-icon>
            <InvoiceSuccessIcon v-if="payIsSuccess && form.method === 'invoice'"/>
            <CardSuccessIcon v-else-if="payIsSuccess && form.method === 'card'"/>
            <PayErrorIcon v-else/>
        </template>
        <template #body>
            <div v-if="step === 1">
                <div class="select-method">
                    <FormRadioButtonGroup v-model="form.method" :options="paymentMethods"/>
                </div>
                <div class="form">
                    <div class="form-input-row">
                        <label>
                            Укажите сумму платежа
                        </label>
                        <el-input
                            v-model="formattedAmount"
                            @focus="handleFocusAmountInput"
                            @blur="handleBlurAmountInput"
                            @input="handleInputAmountInput"
                            :readonly="modalAmount > 0 && form.method === 'card'"
                        />
                        <div class="description">
                            Сумма платежа указана на основании выбранного тарифа и модулей
                        </div>
                    </div>

                    <div class="form-input-row form-input-row-promo"
                         :class="'form-input-row-promo__' + promoCodeMessageType">
                        <div>
                            <label>
                                {{ promoCodeIsApplied ? 'Промокод применен' : 'Введите промокод' }}
                            </label>
                            <div>
                                <div class="d-flex">
                                    <el-input
                                        placeholder="Например, 0000-0000"
                                        v-model="form.promoCode"
                                        :readonly="promoCodeIsApplied"
                                        :class="{
                                        'promo-code-is-valid': promoCodeIsValid && form.promoCode,
                                        'promo-code-is-invalid': !promoCodeIsValid && form.promoCode
                                    }"
                                        :clearable="true"
                                        @blur="checkPromoCode"
                                        @focus="handlePromoCodeFocus"
                                        @clear="() => promoCodeIsValid = true"
                                    />
                                    <el-button
                                        v-if="promoCodeIsApplied && !user.promoCode"
                                        @click="cancelPromoCode"
                                        class="cancel-promo-code"
                                        type="danger"
                                        plain
                                    >
                                        Отменить
                                    </el-button>
                                </div>
                                <div v-if="promoCodeMessage" class="promo-message">
                                    {{ promoCodeMessage }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="step > 1">
                <PaymentModalInvoiceMethod
                    v-if="form.method === 'invoice'"
                    :step="step"
                    @setStep="setStep"
                    @setPayStatus="setPayStatus"
                    @setHeaderTitle="(title) => headerTitle = title"
                    @setHeaderDescription="(description) => headerDescription = description"
                    @setButtonLabel="(label) => buttonLabel = label"
                    @setLoading="(status) => loading = status"
                    ref="invoiceMethod"
                />
            </div>
        </template>
        <template #footer>
            <el-button
                v-loading="promoCodeCheckLoading"
                class="next-to-second"
                type="primary"
                :disabled="!nextButtonIsActive"
                @click.prevent="nextStep"
            >
                <template v-if="step === 1">
                    {{ form.method === 'card' ? 'Перейти выбору карты' : 'Продолжить' }}
                </template>
                <template v-else>
                    {{ buttonLabel }}
                </template>
            </el-button>
        </template>
    </Modal>
</template>

<script>
import {useCommonStore} from "@/store/common";
import {mapActions, mapState} from "pinia";
import Modal from "@/views/components/Modal.vue";
import FormRadioButtonGroup from "@/views/components/Form/FormRadioButtonGroup.vue";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import PaymentModalInvoiceMethod from "@/views/components/PaymentModalInvoiceMethod.vue";
import InvoiceSuccessIcon from "@/views/components/Icons/InvoiceSuccessIcon.vue";
import PayErrorIcon from "@/views/components/Icons/PayErrorIcon.vue";
import CardSuccessIcon from "@/views/components/Icons/CardSuccessIcon.vue";

export default {
    name: "PaymentModal",
    components: {
        CardSuccessIcon,
        PayErrorIcon, InvoiceSuccessIcon, PaymentModalInvoiceMethod, FormRadioButtonGroup, Modal
    },
    data() {
        return {
            form: {
                amount: 0,
                promoCode: null,
                method: 'card'
            },
            promoCodeCheckLoading: false,
            promoCodeIsValid: true,
            formattedAmount: '',
            promoCodeMessage: null,
            loading: false,
            promoCodeMessageType: null,
            promoCodeIsApplied: false,
            payIsSuccess: true,
            buttonLabel: 'Сформировать счет',
            headerDescription: 'Выберите способ оплаты и сумму, на которую вы хотите пополнить баланс',
            headerTitle: 'Пополнение баланса',
            step: 1,
            paymentMethods: [
                {
                    value: 'card',
                    title: 'Оплатить картой',
                    description: 'Вы будете переправлены на страницу банка для ввода реквизитов карты',
                    badge: 'Выгоднее на 10%',
                    icon: 'PaymentCardIcon'
                },
                {
                    value: 'invoice',
                    title: 'Оплатить по счету',
                    description: 'Вы можете оплатить любую сумму в счет будущих платежей',
                    badge: null,
                    icon: 'PaymentBillIcon'
                }
            ]
        }
    },
    created() {
        if (this.user.promoCode) {
            this.form.promoCode = this.user.promoCode.code;
            this.promoCodeIsApplied = true;
        }
        this.formattedAmount = this.formatNumber(this.form.amount) + '₽';
        this.pullBankRequisites();
    },
    mixins: [functionsMixin],
    inject: ['api'],
    computed: {
        ...mapState(useCommonStore, {
            showModal: 'getPaymentModalModalStatus',
            modalAmount: 'getPaymentModalModalAmount',
            user: 'getUser',
        }),
        nextButtonIsActive() {
            return this.promoCodeIsValid && this.form.amount > 0
        }
    },
    watch: {
        showModal() {
            if (this.showModal && this.modalAmount > 0) {
                this.form.amount = this.modalAmount;
                this.formattedAmount = this.formatNumber(this.modalAmount) + '₽';
            }
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setPaymentModalModalStatus',
            'setPaymentModalModalAmount',
            'pullBankRequisites'
        ]),
        closeModal() {
            if (this.user.promoCode) {
                this.form.promoCode = this.user.promoCode.code;
            }

            this.setPaymentModalModalStatus(false);
            this.setPaymentModalModalAmount(0);
            this.$nextTick(() => {
                this.form = {
                    method: 'card',
                    amount: 0,
                    promoCode: null
                }
                this.formattedAmount = '0₽';
                this.loading = false;
                this.headerDescription = 'Выберите способ оплаты и сумму, на которую вы хотите пополнить баланс';
                this.headerTitle = 'Пополнение баланса';
                this.step = 1;
                this.cancelPromoCode();
            });
        },
        checkPromoCode() {
            this.promoCodeCheckLoading = true;
            this.promoCodeMessage = null;
            this.promoCodeMessageType = null;

            if (!this.form.promoCode || this.form.promoCode.length === 0) {
                this.promoCodeCheckLoading = false;
                return;
            }

            httpService().post(this.api.payment.checkPromoCode, {
                code: this.form.promoCode
            }).then(() => {
                this.promoCodeIsValid = true;
                this.promoCodeIsApplied = true;
                this.promoCodeCheckLoading = false;
            }).catch((error) => {
                this.promoCodeMessage = error.response.data.message;
                this.promoCodeMessageType = 'error';
                this.promoCodeIsValid = false;
                this.promoCodeCheckLoading = false;
            });
        },
        handleFocusAmountInput() {

            if (this.modalAmount > 0 && this.form.method === 'card') {
                return;
            }

            let value = this.formattedAmount.replace(/\D/g, '');

            if (!value || value === '') {
                this.formattedAmount = '';
                return;
            }

            value = parseFloat(value);

            if (value === 0) {
                this.formattedAmount = '';
            }

            this.formattedAmount = this.formatNumber(value);
        },

        handleInputAmountInput() {
            let amount = this.formattedAmount.replace(/\D/g, '');
            if (!amount || amount === '') {
                this.form.amount = 0;
                this.formattedAmount = '';
                return;
            }
            this.form.amount = amount;
            this.formattedAmount = this.formatNumber(parseFloat(amount));
        },
        handleBlurAmountInput() {
            if (this.formattedAmount == null) {
                return;
            }

            this.$nextTick(() => {
                let amount = this.formattedAmount.replace(/\D/g, '');
                if (!amount || amount === '') {
                    this.formattedAmount = '0₽';
                    this.form.amount = 0;
                    return;
                }
                this.form.amount = parseFloat(amount);
                this.formattedAmount = this.formatNumber(parseFloat(amount)) + "₽";
            })

        },
        cancelPromoCode() {
            this.promoCodeIsValid = true;
            this.promoCodeIsApplied = false;
            this.promoCodeCheckLoading = false;
            this.promoCodeMessage = null;
            this.promoCodeMessageType = null;
            this.form.promoCode = null;

            if (this.user.promoCode) {
                this.form.promoCode = this.user.promoCode.code;
                this.promoCodeIsApplied = true;
            }
        },
        nextStep() {
            if (this.step === 1) {
                if (this.nextButtonIsActive) {
                    this.step++;
                    return;
                }
            }

            if (this.step === 2) {
                if (this.form.method === 'invoice') {
                    this.$refs.invoiceMethod.pay(this.form);
                }
                return;
            }

            if (this.form.method === 'invoice') {
                this.$refs.invoiceMethod.next();
            }
        },
        handlePromoCodeFocus() {
            this.promoCodeMessage = null;
            this.promoCodeMessageType = null;
        },

        setStep(step) {
            this.step = step;
        },

        setPayStatus(isSuccess) {
            this.payIsSuccess = isSuccess;
        }
    }
}
</script>

<style scoped>
.form .form-input-row:not(:last-child) {
    margin-bottom: 16px;
}

.form {
    margin: 24px 0;
}

.form-input-row input {
    margin: 4px 0;
}

.form-input-row label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #393F4A;
}

.form-input-row .description {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #49515F;
}

.cancel-promo-code {
    height: auto;
    margin-left: 16px;
}

.next-to-second {
    padding: 12px 24px;
    background: #1984E6;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    line-height: 20px;
    height: auto;
    letter-spacing: unset !important;
}

.promo-message {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
}

.form-input-row-promo__error .promo-message {
    color: #f74539;
}
</style>

<style>
.form-input-row-promo__error .el-input .el-input__wrapper {
    box-shadow: 0 0 0 1px #f74539 inset;
}
</style>
