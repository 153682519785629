<template>
    <Auth :hideTitle="true">
        <template v-if="orderDetails">
            <div class="d-flex" style="padding: 17px 0">
                <router-link :to="{ name: 'dashboard' }" class="d-flex" style="text-decoration: none">
                    <ArrowLeft/>
                    <span style="margin-left: 20px" class="textMD textMedium color-primary">Отгрузка №
                        {{
                            orderDetails.id
                        }}</span>
                </router-link>
            </div>
            <div class="card rounded-12px" v-if="orderDetails">
                <div class="card-body">
                    <div class="d-flex justify-between align-items-center mix-drive-line">
                        <div class="textSM textMedium neutral900">
                            {{ orderDetails.company_name }}
                        </div>
                        <a
                            v-if="orderDetails.company_phone"
                            :href="'tel:+' + orderDetails.company_phone"
                        >
                            <PhoneIcon/>
                        </a>
                    </div>
                    <div class="d-flex justify-between align-items-center mix-drive-line"
                         @click.prevent="openMap()"
                         style="cursor: pointer;"
                    >
                        <div class="textSM textMedium neutral900">
                            {{ orderDetails.company_fact_address }}
                        </div>
                        <div v-if="orderDetails.latitude" class="d-block">
                            <RoutingIcon/>
                        </div>
                    </div>
                    <div class="mix-drive-line">
                        <div
                            class="textSM textMedium neutral900"
                            v-if="orderDetails.good_id"
                        >
                            {{ orderDetails.good_name}}
                            ({{ orderDetails.total }} м³)
                        </div>
                    </div>
                    <div v-if="orderDetails.pump_info" class="d-flex justify-between align-items-center mix-drive-line">
                        <div>
                            <div class="textSM textMedium neutral900">
                                Насос
                            </div>
                            <div class="textXS textRegular neutral400" style="margin-top: 2px">
                                {{ orderDetails.pump_info?.number ?? '-' }}
                            </div>
                            <div class="textXS textRegular neutral400" style="margin-top: 2px">
                                {{ orderDetails.pump_info?.pump_driver_name ?? '-' }}
                            </div>
                        </div>

                        <a v-if="orderDetails.pump_info?.pump_driver_phone"
                           :href="'tel:+' + orderDetails.pump_info.pump_driver_phone" style="margin-left: 5px">
                            <PhoneIcon/>
                        </a>
                    </div>
                    <div v-if="orderDetails.comment"
                         class="d-flex justify-between align-items-center mix-drive-line">
                        <div>
                            <div class="textSM textMedium neutral900">
                                Комментарий
                            </div>
                            <div class="textXS textRegular neutral400" style="margin-top: 2px">
                                {{ orderDetails.comment }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="orderDetails.status !== 'done'" class=" order-action" style="margin-top: 8px; ">
                <div class="card-body" v-show="false">
                    <div class="d-flex justify-between align-items-center">
                        <div>
                            <div class="textXS textRegular primary600">
                                {{ orderDetails.status ? getStatusLabel(orderDetails.status) : '' }}
                            </div>
                            <div class="textSM textMedium neutral900" v-if="orderDetails.plan_date_start_utc">
                                {{
                                    orderDetails.status === 'new' ? getDateFromUnix(orderDetails.plan_date_start_utc, 'HH:mm', orderDetails.tz) :
                                        getDateFromUnix(orderDetails.histories[orderDetails.histories?.length - 1].date,
                                            'HH:mm', orderDetails.tz)
                                }}
                            </div>
                        </div>
                        <Timer v-if="orderDetails.company_id" :status="timerStatus"/>
                    </div>
                </div>
                <div class="d-flex-full-stroke">
                    <el-button
                        @click="setIsVisibleModal(true)"
                        :loading="loading"
                        :disabled="checkDisableButton()"
                        class="w-100 button-order-height textXS textMedium"
                        style="font-family: inherit; font-size: 24px;"
                        type="primary"
                    >
                        {{ buttonText }}
                    </el-button>
                    <el-button v-show="false" @click="setAlertModal()" type="danger" class="button-order-height" style="width: 60px">
                        <span class="textLG">!</span></el-button>
                </div>

                <el-modal v-if="isVisibleModal" class="modal">
                    <InfoIcon/>
                    <div class="modal__title text neutral400 ">Смена статуса</div>
                    <p class="text neutral900 ">{{ buttonText }}</p>
                    <div class="modal__buttons">
                        <el-button @click="changeStatus">Подтвердить</el-button>
                        <el-button @click="setIsVisibleModal(false)">Отмена</el-button>
                    </div>
                </el-modal>
                <el-modal v-if="alertModal" class="modal">

                    <div class="modal__title text neutral400 ">Сообщите о проблеме</div>

                    <p class="text neutral900 ">Если с доставкой происходит внештатная ситуация, то выберите
                        причину:</p>

                    <div class="mb-10" v-for="(alert, index) in alerts" :key="index">
                        <el-button type="info" @click="setAlertModal()">{{ alert.name }}</el-button>
                    </div>


                    <div class="modal__buttons">
                        <el-button @click="setAlertModal()">Отмена</el-button>
                    </div>

                </el-modal>
            </div>
            <div class="w-100 text-center mb-20 mt-20">

            <span class="text neutral400 textXS"
                  style="background-color: white; border-radius: 12px; padding: 4px 10px">
                Завершенные этапы
            </span>

            </div>
            <div v-for="(item, index) in orderDetails.histories" :key="index" class="card rounded-12px">
                <div class="card-body">
                    <div class="textXS textRegular neutral400">{{ getStatusLabel(item.status) }}</div>
                    <div class="d-flex justify-between" style="margin-top: 2px">
                        <div class="textSM textMedium neutral900">{{
                                getDateFromUnix(item.date, 'HH:mm', orderDetails.tz)
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Auth>
</template>

<script>
import InfoIcon from "../Icons/Notifications/InfoIcon.vue";
import Auth from "../../layouts/Auth.vue";
import PhoneIcon from "../Icons/PhoneIcon.vue";
import RoutingIcon from "../Icons/RoutingIcon.vue";
import Timer from "./Timer.vue";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import ArrowLeft from "@/views/components/Icons/ArrowLeft.vue";
import iconsMixin from "@/mixins/iconsMixin";
import {mapActions, mapState, mapStores} from "pinia";
import {useMixDriverStore} from "@/store/mixDriver";
import {useEchoStore} from "@/store/Echo";

export default {
    name: 'MixDriveTaskDetail',
    components: {
        // GoodUnit,
        ArrowLeft,
        Auth,
        PhoneIcon,
        RoutingIcon,
        Timer,
        InfoIcon
    },
    mixins: [functionsMixin, iconsMixin],
    data() {
        return {
            timerStatus: false,
            loading: false,
            isVisibleModal: false,
            alertModal: false,
            alerts: [{
                name: 'Попал в ДТП',
                key: 'dtp'
            }, {
                name: 'Затор на дороге',
                key: 'probka'
            }, {
                name: 'На объекте нет клиента',
                key: 'nobody'
            }],
            order: {
                status: 'В работе',
                mixId: '121313',
                nasos: 'Насос',
                started_work_at: null
            },
            // orderDetails: null,
            statuses: [
                {
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    key: 'pouring',
                    name: 'Заливка'
                },
                {
                    key: 'return',
                    name: 'Возвращается на завод'
                },
                {
                    key: 'done',
                    name: 'Конец'
                }
            ]
        }
    },
    inject: ['api'],
    async created() {
        this.getOrder()
        await this.getDriverInfo(this.echoStore)
    },
    computed: {
        ...mapStores(useEchoStore),
        ...mapState(useMixDriverStore, {
            orders: 'getOrders',
            driverCanLoadOrder: 'getDriverCanLoadOrder',
        }),
        ...mapActions(useMixDriverStore, [
            'getDriverInfo',
        ]),
        orderDetails() {
            return this.orders[this.$route.params.id] ?? null
        },
        buttonText() {
            if (this.orderDetails.status === 'new') {
                return 'Начал загрузку'
            } else if (this.orderDetails.status === 'loading') {
                return 'Завершил загрузку'
            } else if (this.orderDetails.status === 'delivery') {
                return 'Прибыл к клиенту'
            } else if (this.orderDetails.status === 'object') {
                return 'Начал заливку'
            } else if (this.orderDetails.status === 'pouring') {
                return 'Возвращаюсь на завод'
            } else if (this.orderDetails.status === 'return') {
                return 'Вернулся на завод'
            }
            return 'Начал загрузку'
        }
    },
    methods: {
        ...mapActions(useMixDriverStore, [
            'pullOrder',
            'pushOrder',
        ]),
        setAlertModal() {
            this.alertModal = !this.alertModal
        },
        setIsVisibleModal(value) {
            this.isVisibleModal = value;
        },
        openMap() {
            const latitude = this.orderDetails.latitude; // Широта
            const longitude = this.orderDetails.longitude; // Долгота


            if (latitude) {
                window.location.href = `https://yandex.ru/maps/?ll=${longitude}%2C${latitude}&mode=whatshere&whatshere%5Bpoint%5D=${longitude}%2C${latitude}&whatshere%5Bzoom%5D=15.93&z=15`;
            }

            // window.location.href = `yandexmaps://maps.yandex.ru/?ll=${longitude},${latitude}&z=12`
            // Проверка на наличие приложения яндекс-навигатора
            // const isYandexNavAvailable = typeof window.navigator !== 'undefined' && typeof window.navigator.openApp !== 'undefined';

            // // URI для яндекс-навигатора
            // window.location.href = `yandexnavi://show_point_on_map?lat=${latitude}&lon=${longitude}`;

            // // URI для Google Maps
            // const googleMapsURI = `https://maps.google.com/maps?daddr=${latitude},${longitude}`;

            // // Открытие навигационного приложения
            // if (isYandexNavAvailable) {
            //     const navigationApp = isYandexNavAvailable ? 'yandexnavi' : 'googlemaps';
            //     const navigationURI = isYandexNavAvailable ? yandexNavURI : googleMapsURI;

            //     const openNavigationApp = () => {
            //         const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            //         if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //             window.location.href = `maps://${navigationURI}`;
            //         } else if (/Android/.test(userAgent)) {
            //             window.location.href = `intent://${navigationURI}#Intent;scheme=maps;package=${navigationApp};end`;
            //         } else {
            //             window.location.href = navigationURI;
            //         }
            //     };

            //     if (isYandexNavAvailable) {
            //         // Открытие яндекс-навигатора или предложение выбора приложения
            //         window.navigator.openApp(yandexNavURI, openNavigationApp);
            //     } else {
            //         // Открытие Google Maps или предложение выбора приложения
            //         window.navigator.openApp(googleMapsURI, openNavigationApp);
            //     }
            // } else {
            //     // Если ни одно навигационное приложение не установлено, открываем Google Maps через браузер
            //     window.location.href = googleMapsURI;
            // }

        },
        getStatusLabel(status) {
            let statusLabel = null
            this.statuses.forEach((element) => {
                if (element.key === status) {
                    statusLabel = element.name
                }
            })
            return statusLabel
        },
        truncateText(text, limit) {
            if (text?.length > limit) {
                text.substring(0, limit) + "...";
            } else {
                return text
            }
        },
        getOrder() {
            if (!this.orderDetails) {
                this.pullOrder(this.$route.params.id)
            }
            this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderGroupUpdate.${this.$route.params.id}`);
            this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderUpdate.${this.$route.params.id}`)
                .listen('.MixOrderUpdate', () => {
                    if (this.orderDetails) {
                        this.pullOrder(this.$route.params.id)
                    }
                })

            // httpService().get(this.api.mix.getOrderById+'/'+this.$route.params.id).then(({ data }) => {
            //     if (data.success) {
            //         this.orderDetails = data.order
            //         this.loading = false
            //     }
            // })

            // httpService().post(this.api.mix.getOneOrder, {
            //     module_key: "mix",
            //     orderId: this.$route.params.id,
            // }).then((response) => {
            //     let data = response.data
            //
            //     if (data.success) {
            //         this.orderDetails = data.order
            //         this.loading = false
            //     }
            //
            // })
        },
        changeStatus() {
            this.timerStatus = false;
            this.isVisibleModal = false
            this.loading = true
            httpService().post(this.api.mix.changeOrderStatus, {
                account_module_reserve_id: this.orderDetails.mix_id,
                module_key: 'mix',
                order: this.orderDetails
            }).then((response) => {
                let answer = response.data

                if (answer.success) {
                    this.buttonText = answer.buttonText
                    this.loading = false
                    this.pullOrder(answer.order.id)
                    this.timerStatus = true;
                }

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        checkDisableButton() {
            if (this.orderDetails.status === 'new' || this.orderDetails.status === 'loading') {
                return !this.driverCanLoadOrder
            }

            return false
        }
    }
}
</script>

<style>
#main.driver {
    height: 100vh;
}
</style>

<style lang="scss">
.modal {
    font-family: 'Ubuntu';
    position: absolute;
    background: #f5f6f8;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    text-align: center;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;

    &__title {
        margin: 20px 0;
        font-size: 16px;
        line-height: 24px;
    }

    p {
        font-size: 24px;
        margin: 0;
        margin-bottom: 40px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .el-button {
        width: 350px;
        font-size: 24px;
        height: 60px !important;
        margin: 0 !important;
        font-weight: 500;
        border-radius: 12px;

        @media (max-width: 425px) {
            width: 100%;
        }

        &:first-child {
            background: #1984E6;
            color: #fff;
        }

        &:last-child {
            background: #E8F4FF;
            color: #1984E6;
        }
    }
}


.mix-drive-line {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(47, 60, 78, 0.1);
    }

    padding: 12px 0;

    &:last-child {
        padding: 16px 0 4px 0;
    }

    &:first-child {
        padding: 2px 0 12px 0;
    }
}

.order-action {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.rounded-12px {
    border-radius: 12px !important;
}

.button-order {
    height: 60px !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.button-order-height {
    height: 60px !important;
}

.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none !important;
}
</style>
