<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <div v-loading="loading" class="distribute">

                    <div v-if="notConnectedCRM" class="distribute__error-section">
                        <NotConnectedCRM/>
                    </div>

                    <div v-else>
                        <div v-if="errorComponentsMap.has(error.code)" class="distribute__error-section">
                            <component
                                :is="errorComponentsMap.get(error.code)"
                            />
                        </div>

                        <div v-if="unallocated.length" class="distribute__error-section">
                            <NotEnoughVehicles :items="unallocated"/>
                        </div>


                        <DistributeOrderGroupTable
                            v-if="mixers.length && vehicles.length"
                            :distribute="distribute"
                            :mixers="mixers"
                            :time="time"
                            :date="date"
                            :extraOrders="extraOrders"
                            :vehicles="vehicles"
                        />
                    </div>

                    <div class="distribute__footer mt-30">
                        <div
                            class="ci-btn ci-btn_default mr-10 distribute__footer"
                            style="display:inline-flex;"
                            type="button"
                            @click="cancelOrders"
                        >
                            Изменить заказ
                        </div>
                        <button
                            class="ci-btn ci-btn_blue"
                            @click="createOrders"
                        >
                            Создать заказ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Auth>
</template>
<script>
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import DistributeOrderGroupTable from "@/views/dispatch/DistributeOrderGroupTable";
import Auth from "@/views/layouts/Auth.vue";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import paymentMethods from "@/mixins/paymentMethods";
import {mapActions, mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import moment from "moment";
import mixOrderGroupFormData from "@/mixins/mixOrderGroupFormData";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import NoVehiclesAvailable from "@/views/dispatch/components/distributeErrors/NoVehiclesAvailable.vue";
import NoMixesAvailable from "@/views/dispatch/components/distributeErrors/NoMixesAvailable.vue";
import NotEnoughVehicles from "@/views/dispatch/components/distributeErrors/NotEnoughVehicles.vue";
import NotConnectedCRM from "@/views/dispatch/components/distributeErrors/NotConnectedCRM.vue";

export default {
    name: 'CRMDistributeOrderGroup',
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, CRMTabs, paymentMethods, mixOrderGroupFormData],
    inject: ['api'],
    components: {
        NotEnoughVehicles,
        NotConnectedCRM,
        Auth,
        DistributeOrderGroupTable
    },
    data() {
        return {
            error: {
                code: 0,
                message: '',
            },
            errorComponentsMap: new Map([
                [1, NoVehiclesAvailable],
                [2, NoMixesAvailable],
            ]),
            distribute: [],
            extraOrders: [],
            loading: false,
            mixers: [],
            time: '',
            vehicles: [],
            unallocated: [],
            date: moment(),
            // hash: '',
            notConnectedCRM: false,
        }
    },
    async created() {
        // await this.getDistribute(this.$route.params.orderId)
        // await this.getDistributeData(this.$route.params.orderId, this.$route.params.hash)
        await this.getDistributeData(null, this.$route.params.hash)
        await this.getSchedules()
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['setOrderGroupCreateFormData', 'clearOrderGroupCreateFormData']),
        orders(distribute) {
            let orders = []
            distribute.forEach((item) => {
                orders.push({
                    mix_id: item.mix_id,
                    total: item.total,
                    vehicle_id: item.vehicle_id,
                    driver_id: item.driver_id,
                    arrive_at: item.arrive_at,
                    start_at: item.start_at,
                })
            })

            return orders
        },
        createOrders() {
            this.loading = true

            const form = this.orderGroupForm
            form.status = 'confirmed'

            httpService().post(this.api.dispatch.orderGroups.createOrderGroups, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: form
            }).then(() => {

                this.$message({
                    message: 'Заказ создан',
                    showClose: true,
                    type: 'success'
                })

                this.filters['filter[date]'] = moment(this.orderGroupForm.date_shipment).format('YYYY-MM-DD')

                this.clearOrderGroupCreateFormData()

                setTimeout(() => this.$router.push({name: 'CRMOrders', params: {id: this.$route.params.id}}), 50)

            }).catch((error) => {
                const {errors} = error.response.data
                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
                this.loadingButton = false
            })
        },
        cancelOrders() {

            this.filters['filter[date]'] = moment(this.orderGroupForm.date_shipment).format('YYYY-MM-DD');

            setTimeout(this.$router.push({
                name: 'CRMOrderCreate',
                params: {id: this.$route.params.id, change: true},
                meta: {moduleKey: 'crm'}
            }), 500)
        },
        async getDistribute(orderGroupsId) {
            this.loading = true
            // нет и не было пути
            await httpService().get(this.api.dispatch.getDistribute + '/' + orderGroupsId + '/' + 0).then((response) => {
                this.hash = response.data.jobHash
            }).finally(() => {
                this.loading = false
            })
        },
        async getDistributeData(orderGroupsId, hash) {
            this.loading = true;
            const response = await httpService().post(this.api.dispatch.orderGroups.getDistributeDataByHash, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                hash: hash,
            });

            if (response.data.status === 'distributed') {
                this.distribute = response.data.calculate;
                this.extraOrders = response.data.orders;
                this.vehicles = response.data.vehicles;

                this.date = moment(response.data.date);

                this.unallocated = response.data.unallocated;
                this.error.code = response.data.errorCode;
                this.error.message = response.data.error;

                this.notConnectedCRM = response.data.notConnectedCRM;

                this.loading = false;
            } else {
                await this.wait(1000);
                await this.getDistributeData(orderGroupsId, hash);
            }
        },
        wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async getSchedules() {
            await httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: moment(this.orderGroupForm.date_shipment).format('YYYY-MM-DD')
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.mixers = response.data.posts
                    this.time = response.data.time
                }
            })
        },
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
            getDate: 'getDate',
            orderGroupForm: 'getOrderGroupCreateForm',
        }),
    },
}
</script>
<style scoped lang="scss">
.distribute {
    &__footer {
        display: flex;
        height: 47px;
    }

    &__error-section {
        gap: 16px;
        display: flex;
        padding: 12px 24px 24px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px;
        background: rgba(247, 69, 57, 0.05);

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}
</style>
