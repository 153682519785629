<template>
    <div v-if="details">
        <div class="add-shipping">

            <div class="add-shipping__header__title neutral900 w-100">
                <div class="d-flex-full align-center">
                    <div class="cutOverflow">
                        {{
                            (details.status === 'new' && !details.confirm) ?
                                'Подтверждение отгрузки' :
                                'Подробности отгрузки'
                        }}
                        #{{ formatNumber(details.id) }} -
                        {{
                            orderGroup.company
                        }}
                    </div>
                    <div>
                        <template v-if="details.status === 'new'">
                            <div class="group-orders-status-text"
                                 :class="details.confirm ? 'group-orders-status-text-done ' : 'group-orders-status-text-unconfirmed'">
                                {{ details.confirm ? 'Подтверждена' : 'Неподтверждена' }}
                            </div>
                        </template>
                        <template v-else-if="details.status === 'done'">
                            <div class="group-orders-status-text group-orders-status-text-done">
                                Выполнена
                            </div>
                        </template>
                        <template v-else>
                            <div class="group-orders-status-text group-orders-status-text-confirmed">
                                {{ getStatusLabel(details.status) }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="add-shipping__content">
                <div class="d-flex documentPrints">

                    <el-checkbox-group v-model="selectedDocs">
                        <el-checkbox label="passport" value="passport">
                            <template #default>
                                <div class="document text-center">
                                    <img src="/images/docs/passport.jpg"/>
                                    <p class="textMedium textSM">Паспорт качества</p>
                                </div>
                            </template>
                        </el-checkbox>
                        <el-checkbox label="invoice" value="invoice">
                            <template #default>
                                <div class="document text-center">
                                    <img src="/images/docs/invoice.jpg"/>
                                    <p class="textMedium textSM">ТН</p>
                                </div>
                            </template>
                        </el-checkbox>
                        <el-checkbox label="ttn" value="ttn">
                            <template #default>
                                <div class="document text-center">
                                    <img src="/images/docs/TTN.png"/>
                                    <p class="textMedium textSM">ТТН</p>
                                </div>
                            </template>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>

            <div class="add-shipping__footer">
                <el-button
                    @click="() => $emit('print')"
                    class="ci-btn ci-btn_default add-shipping__footer__cancel">Отменить печать
                </el-button>

                <el-button
                    @click="printDocs('download')"
                    :disabled="!selectedDocs.length || loadingButton"
                    :icon="icons.download"
                    class="ci-btn"
                    type="default">Скачать
                </el-button>
                <el-button
                    @click="printDocs('print')"
                    :disabled="selectedDocs.length > 1 || loadingButton"
                    :icon="icons.print"
                    class="ci-btn ci-btn_blue"
                    type="primary">Распечатать
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";

export default {
    name: "MixOrderDetailsGroupPrint",
    props: ['orderGroup', 'mixOrderId', 'mixOrder', 'loading'],
    mixins: [functionsMixin, mixOrderInfo, iconsMixin],
    inject: ['api'],
    data() {
        return {
            details: null,
            selectedDocs: [],
            loadingButton: false
        }
    },
    created() {
        this.details = this.mixOrder
    },
    methods: {
        printDocs(type) {
            this.selectedDocs.forEach(template => {
                this.print(template, type)
            })
        },
        generateWord(){
            httpService().post(this.api.exportDocument.generateTTN, {
                orderId : this.mixOrder.id
            }, {
                responseType: 'blob'
            }).then((response) => {
                let fileName = 'Товарно-транспортная накладная для заявки №' + this.mixOrder.id + '.docx';
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            })
        },
        print(template, type) {
            if(template === 'ttn'){
                this.generateWord()
                return
            }
            this.loadingButton = true
            httpService().post(this.api.mix.printOrderDoc, {
                account_module_reserve_id: this.$route.params.id,
                module_key: "mix",
                orderId: this.details.id,
                template: template
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)

                this.loadingButton = false

                if (type === 'download') {
                    const link = document.createElement('a')
                    let type = 'Паспорт качества '
                    if (template === 'invoice') type = 'Накладная '
                    let doc = ' №' + (this.mixOrder.doc ?? this.mixOrder.id)
                    link.href = url
                    link.setAttribute('download', type + 'для отгрузки' + doc + '.html')
                    document.body.appendChild(link)
                    link.click()
                } else if (type === 'print') {
                    const printWindow = window.open(url, '_blank')
                    printWindow.print()
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
    }
}
</script>

<style scoped>

</style>
