<template>
    <div class="widget-card widget-shipments">
        <div class="widget-card-title">{{ title }}
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" :icon="icons.refresh"
                       circle></el-button>
            <span class="widget-card-title_subTitle">Отгрузки</span>
        </div>
        <div class="widget-card-body" v-loading="loading">
            <div v-if="data.length > 0">
                <div class="item" v-for="(value, index) in data" :key="index">
                    <el-row>
                        <el-col :xs="18" :md="20">
                            <span class="item-top">{{ getData(value.goodId, 'good') }}</span>
                            <span class="item-bottom">Последняя отгрузка в {{
                                    getDateByTz(value.date, 'HH:mm', this.tz)
                                }}</span>
                        </el-col>
                        <el-col :xs="6" :md="4">
                        <span class="item-right"><number
                            ref="count"
                            :to="value.value"
                            :format="formatNumber"
                            :duration="1"
                            :delay="0"
                            easing="Power4.easeOut"/> {{ getData(value.goodId, 'good', 'unit') }}</span>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div v-if="data.length === 0 && !loading">
                <div class="no-data-block">
                    <NoDataIcon/>
                    <p>
                        Сегодня отгрузки не производились<br>Информация актуальна с 00:00 до {{ time }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoDataIcon from '../../components/NoDataIcon'
import functionsMixin from '../../../mixins/functionsMixin'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    props: [
        'id',
    ],
    inject: [
        'api'
    ],
    components: {
        NoDataIcon
    },
    mixins: [
        functionsMixin, iconsMixin
    ],
    data() {
        return {
            data: [],
            loadingButton: true,
            loading: true,
            time: null,
            title: 'Отгрузки за сегодня',
            tz: null
        }
    },
    created() {
        this.loadingButton = true
        this.widgetGet(this.id)
        window.setInterval(() => {
            this.loadingButton = false
        }, 800)
        this.counterInterval = setInterval(
            function () {
                this.updateWidget()
            }.bind(this), Math.floor(Math.random() * (10000 - 8000 + 1)) + 8000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        widgetGet(id) { // метод получения данных виджета с api
            // обращаемся в api, передаем id виджета
            httpService().post(this.api.widget.get, {
                id: id
            }).then((response) => {
                    // получаем массив с api
                    let data = response.data
                    if (data.success)
                        // кладем массив в виджет
                        this.data = data.data.sort((a, b) => a.date < b.date ? 1 : -1)
                    this.time = data.time
                    this.title = data.widgetName
                    this.tz = data.timezone
                    this.loading = false
                }
            )
        },
        onUpdate() {
            this.loadingButton = true
            this.updateWidget()
            window.setInterval(() => {
                this.loadingButton = false
            }, 800)
        },
        updateWidget() // метод обновления виджета
        {
            this.widgetGet(this.id)
        },
    },
}
</script>

<style scoped>
</style>
