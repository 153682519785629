<template>
    <template v-if="order.lab">
        <template v-if="!order.specGoodId">
            <el-tooltip content="Ожидаем спецификацию от лаборатории" placement="left">
                <div class="d-flex-full-stroke pointer" style="gap: 10px">
                    <div style=" height: 16px; " class="tableGoodIcon">
                        <svg class="good-icon" width="16" height="16" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.16004 22C3.98004 22 3.14004 19.47 4.50004 16.39L8.75004 6.74H8.45004C7.80004 6.74 7.20004 6.48 6.77004 6.05C6.33004 5.62 6.07004 5.02 6.07004 4.37C6.07004 3.07 7.13004 2 8.44004 2H15.55C16.21 2 16.8 2.27 17.23 2.7C17.79 3.26 18.07 4.08 17.86 4.95C17.59 6.03 16.55 6.74 15.44 6.74H15.28L19.5 16.4C20.85 19.48 19.97 22 15.83 22H8.16004Z"
                                stroke-width="2" stroke="#BD2626" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M5.93994 13.1201C5.93994 13.1201 8.99994 13.0001 11.9999 14.0001C14.9999 15.0001 17.8299 13.1101 17.8299 13.1101"
                                stroke-width="2" stroke="#BD2626" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="textRegular textSM neutral900">
                        <Truncate
                            :inputText="getData(order.goodId, 'good')"
                        />
                    </div>
                </div>
            </el-tooltip>
        </template>
        <template v-else>
            <el-tooltip content="Спецификация прикреплена" placement="left">
                <template #content>
                    <div>Спецификация прикреплена:</div>
                    <div>{{ getData(order.specGoodId, 'good') }}</div>
                </template>
                <div class="d-flex-full-stroke pointer" style="gap: 10px">
                    <div style=" height: 16px; " class="tableGoodIcon">
                        <svg class="good-icon" width="16" height="16" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.16004 22C3.98004 22 3.14004 19.47 4.50004 16.39L8.75004 6.74H8.45004C7.80004 6.74 7.20004 6.48 6.77004 6.05C6.33004 5.62 6.07004 5.02 6.07004 4.37C6.07004 3.07 7.13004 2 8.44004 2H15.55C16.21 2 16.8 2.27 17.23 2.7C17.79 3.26 18.07 4.08 17.86 4.95C17.59 6.03 16.55 6.74 15.44 6.74H15.28L19.5 16.4C20.85 19.48 19.97 22 15.83 22H8.16004Z"
                                stroke-width="2" stroke="#009272" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M5.93994 13.1201C5.93994 13.1201 8.99994 13.0001 11.9999 14.0001C14.9999 15.0001 17.8299 13.1101 17.8299 13.1101"
                                stroke-width="2" stroke="#009272" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="textRegular textSM neutral900">
                        <Truncate
                            :inputText="getData(order.goodId, 'good')"
                        />
                    </div>
                </div>
            </el-tooltip>
        </template>
    </template>
    <template v-else>
        <div class="d-flex-full-stroke" style="gap: 10px">
            <div style=" height: 16px; " class="tableGoodIcon">
                <svg class="good-icon" width="16" height="16" viewBox="0 0 16 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.6665 6V10.3333C2.6665 11.9902 4.00965 13.3333 5.66651 13.3333H10.3332C11.99 13.3333 13.3332 11.9902 13.3332 10.3333V6"
                        stroke="#22252C" stroke-width="1.5"/>
                    <path
                        d="M8 2.66669H12.8419C13.0707 2.66669 13.2827 2.78955 13.4004 2.99036L14.5728 4.99036C14.8333 5.43471 14.5206 6.00002 14.0143 6.00002H10.6916C10.234 6.00002 9.80996 5.75429 9.57454 5.35268L8 2.66669Z"
                        stroke="#22252C" stroke-width="1.5"/>
                    <path
                        d="M8.00016 2.66669H3.15825C2.92946 2.66669 2.71744 2.78955 2.59973 2.99036L1.42732 4.99036C1.16684 5.43471 1.47956 6.00002 1.98584 6.00002H5.30859C5.76617 6.00002 6.1902 5.75429 6.42562 5.35268L8.00016 2.66669Z"
                        stroke="#22252C" stroke-width="1.5"/>
                </svg>
            </div>
            <div class="textRegular textSM neutral900">
                <Truncate
                    :inputText="getData(order.goodId, 'good')"
                />
            </div>
        </div>
    </template>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import Truncate from "@/views/components/Truncate.vue";

export default {
    name: "MixOrderGroupTableGood",
    components: {Truncate},
    props: ['order', 'cut'],
    mixins: [functionsMixin]
}
</script>

<style scoped>

</style>
