<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    :new="true"
                    v-model="search"
                    :loading="loading"
                    :disable-search="true"
                    :disable-create-button="true"
                    @moduleGet="moduleGet(true)"
                    @openFilterBlock="openFilterBlock"
                    :show-date-filter="true"
                    :current-date="date"
                    @changeDate="changeDate"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="specFilter"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="заказов"
                />

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile && displayData.length > 0"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="buyer_company_id"
                        label="Заказ"
                    >
                        <template v-slot="scope">
                            <div class="group-orders-status-text group-orders-status-text-small mb-5"
                                 :class="'group-orders-status-text-' + scope.row?.status">
                                {{ getInfo(scope.row?.status, mixOrderGroupStatuses) }}
                            </div>
                            <div class="textXSRegular textSM neutral900 d-flex-full-stroke" style="text-wrap: nowrap;">
                                <span>№ {{ scope.row?.id }} </span>
                                <svg class="buyer-circle" width="5" height="4" viewBox="0 0 5 4" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                                        fill="black"/>
                                </svg>
                                <span class="cutOverflow">
                                {{
                                        scope.row.buyer_company ? scope.row.buyer_company.name : '-'
                                    }}
                            </span>
                            </div>
                            <div class="address textXS textRegular mb-5 neutral400">
                                <Truncate
                                    :inputText="scope.row.delivery_type === 'delivery' ? (scope.row.delivery_address?.name ?? '-') : 'Самовывоз'"
                                />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="good_id"
                        label="Продукция"
                    >
                        <template v-slot="scope">

                            <div class="textMedium primary600 cutOverflow">
                                {{
                                    scope.row.good_id ? getData(scope.row.good_id, 'good') : '-'
                                }}
                            </div>

                            <div class="textRegular neutral400 cutOverflow" v-if="scope.row.constructive">
                                {{
                                    scope.row.constructive
                                }}
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="spec_good_id"
                        label="Спецификация"
                    >
                        <template v-slot="scope">
                            <template v-if="!scope.row.spec_good_id">
                                <el-button @click="openSpecDialog(scope.row)" type="text">Прикрепить спецификацию
                                </el-button>
                            </template>
                            <template v-else>
                                <span @click="openSpecDialog(scope.row)" class="textMedium success400 cutOverflow pointer">{{
                                        getData(scope.row.spec_good_id, 'good')
                                    }}</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Объем"
                        width="120"
                    >
                        <template v-slot="scope">

                            <div class="textMedium success400">{{
                                    scope.row.total ? formatNumber(scope.row.total) : '-'
                                }}
                                <good-unit unit="м3."/>
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="comment"
                        label="Комментарий"
                    >
                        <template v-slot="scope">
                    <span class="textRegular neutral700 cutOverflow maxLines3 counterparty-row">
                        {{
                            scope.row.comment ?? '-'
                        }}
                    </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mixers"
                        label="Заводы"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.mixers.length > 0">
                                <div class="textMedium neutral900 nowrap" v-for="(mix, index) in scope.row.mixers"
                                      :key="index">{{
                                        scope.row.mixes.find(el => el.module_reserve_id === mix.mix_id)?.name ?? '-'
                                    }} - <span class="primary600">{{ mix.name }}</span>
                                </div>
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

            </div>
        </div>

        <el-dialog
            :title="title"
            v-model="specDialog"
            direction="rtl"
            :size="dialogSize"
            :destroy-on-close="true">

            <LabSpecCreate
                :order="orderDetails"
                :mixes="mixes"
                @closeDialog="() => specDialog = false"
                @moduleGet="moduleGet(true)"
            />

        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import moment from "moment";
import {httpService} from "@/services/http.service";
import LabTabs from "@/mixins/tabs/labTabs";
import Filter from '../components/Table/Filter/Filter'
import LabSpecCreate from "@/views/lab/components/LabSpecCreate.vue";
import GoodUnit from "@/views/components/goodUnit.vue";
import {useEchoStore} from "@/store/Echo";
import {mapStores} from "pinia";
import Truncate from "@/views/components/Truncate.vue";
import mixOrderGroupStatus from "@/mixins/mixOrderGroupStatus";

export default {
    name: 'LabOrders',
    computed: {
        moment() {
            return moment
        },
        ...mapStores(useEchoStore),
    },
    mixins: [LabTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin, mixOrderGroupStatus],
    data() {
        return {
            loading: false,
            date: moment(),
            title: null,
            specFilter: [{
                id: 1,
                name: 'Все заказы'
            }, {
                id: 2,
                name: 'Есть спецификация'
            }, {
                id: 3,
                name: 'Нет спецификации'
            }],
            filter: {
                spec: {
                    value: null,
                    type: 'select',
                    label: 'Спецификация',
                    placeholder: 'Выберите',
                    items: '1'
                },
            },
            orderDetails: null,
            specDialog: false,
            mixes: []
        }
    },
    components: {
        Truncate,
        GoodUnit,
        LabSpecCreate,
        Filter,
        TablePagination, EmptyState,
        TableHeadBlock,
        Auth,
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet(true)
        this.getMixes()
        // this.counterInterval = setInterval(
        //     function () {
        //         this.moduleGet()
        //     }.bind(this), 30000)
    },
    beforeUnmount: function () {
        // clearInterval(this.counterInterval)
    },
    methods: {
        getMixes() {
            httpService().post(this.api.lab.getMixes, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'lab',
            }).then(({data}) => {
                this.mixes = data.data
                data.data.forEach((mix) => {
                    this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderGroupCreate.${mix.module_id}`);
                    this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderGroupCreate.${mix.module_id}`)
                        .listen('.MixOrderGroupCreate', () => {
                            this.moduleGet()
                        });
                })
            })
        },
        moduleGet(loading) {
            if (loading) this.loading = true;

            httpService().post(this.api.lab.getOrders, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'lab',
                date: this.date
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.orders
                    // console.log('this.values', this.values)
                    this.values.forEach((orderGroup) => {
                        this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderGroupUpdate.${orderGroup.id}`);
                        this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderGroupUpdate.${orderGroup.id}`)
                            .listen('.MixOrderGroupUpdate', () => {
                                this.moduleGet()
                            });
                    })
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            })
        },
        openSpecDialog(row) {
            this.orderDetails = row
            this.title = 'Заказ №' + row.id
            this.specDialog = true
        },
        changeDate(date) {
            this.date = moment(date).utcOffset(0, true).startOf('day')
            this.moduleGet(true)
        },
        filterValues() {
            let filtered = this.values;

            if (this.filter.spec.value) {
                switch (this.filter.spec.value) {
                    case 1:
                        filtered = this.values;
                        break;
                    case 2:
                        filtered = this.values.filter(data => data.spec_good_id);
                        break;
                    case 3:
                        filtered = this.values.filter(data => !data.spec_good_id);
                        break;
                }
            }

            this.valueLength = filtered.length;
            return filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        }
    }
}
</script>

<style scoped>

</style>
