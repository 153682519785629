<template>
    <div class="statistics">
        <div class="statistics__item">
<!--            <img :src="require('@/assets/box.svg')" class="statistics__item__icon" />-->
            <div class="statistics__item__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M8.27497 2.5667L3.82497 5.03336C2.81664 5.5917 1.99164 6.9917 1.99164 8.1417V12.85C1.99164 14 2.81664 15.4 3.82497 15.9584L8.27497 18.4334C9.22497 18.9584 10.7833 18.9584 11.7333 18.4334L16.1833 15.9584C17.1916 15.4 18.0166 14 18.0166 12.85V8.1417C18.0166 6.9917 17.1916 5.5917 16.1833 5.03336L11.7333 2.55836C10.775 2.03336 9.22497 2.03336 8.27497 2.5667Z" fill="#737A86" stroke="#737A86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.64166 6.70001L10 10.9583L17.3083 6.72501" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 18.5083V10.95" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="statistics__item__info">
                <div class="statistics__item__info__title">
                    {{ formatNumber(total, 1) }} м³
                </div>
                <div class="statistics__item__info__desc">
                    Общий объем
                </div>
            </div>
        </div>

        <div class="statistics__item">
            <img :src="require('@/assets/warning-2.svg')" class="statistics__item__icon" />
            <div class="statistics__item__info">
                <div class="statistics__item__info__title">
                    {{ formatNumber(statistics.unallocated, 1) }} м³
                </div>
                <div class="statistics__item__info__desc--unallocated">
                    Нераспределено
                </div>
            </div>
        </div>

        <div class="statistics__item">
            <img :src="require('@/assets/truck.svg')" class="statistics__item__icon" />
            <div class="statistics__item__info">
                <div class="statistics__item__info__title">
                    {{ formatNumber(statistics.process, 1) }} м³
                </div>
                <div class="statistics__item__info__desc--in-process">
                    В процессе
                </div>
            </div>
        </div>

        <div class="statistics__item">
            <img :src="require('@/assets/open-box-01.svg')" class="statistics__item__icon" />
            <div class="statistics__item__info">
                <div class="statistics__item__info__title">
                    {{ formatNumber(statistics.delivered, 1) }} м³
                </div>
                <div class="statistics__item__info__desc--delivered">
                    Доставлено
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'OrderStatistics',
    mixins:[functionsMixin],
    props:{
        total: Number,
        orders: Array,
    },
    computed: {
        statistics(){

            const deliveredTotal = this.orders.reduce((accumulator, currentItem) => {
                return accumulator + parseFloat(currentItem.done, 10);
            }, 0);

            const ordersTotal = this.orders.reduce((accumulator, currentItem) => {
                return accumulator + parseFloat(currentItem.total, 10);
            }, 0) - deliveredTotal;

            const unallocated = this.total - (ordersTotal + deliveredTotal)

            return {
                unallocated: unallocated,
                delivered: deliveredTotal,
                process: this.total - (unallocated + deliveredTotal),
            }
        }
    }
}
</script>
<style scoped lang="scss">
.statistics{
    display: flex;

    &__item{
        display: flex;
        margin-right: 48px;

        &__icon{
            margin-right: 5px;
            align-items: center;
            display: flex;
        }
        &__info{

            height: 38px;

            &__title{
                color: #393F4A;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            &__desc{
                color: #737A86;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                &--unallocated{
                    color: #FFB500;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                &--in-process{
                    color: #1984E6;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                &--delivered{
                    color: #2BD8B3;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }
        }
    }
}
</style>
