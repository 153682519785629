<template>
    <div v-if="loading" class="w-100 text-center pt-30 pb-30" :style="[
        { 'min-height': windowWidth < 1500 ? '160px' : '240px' }
    ]">
        <ReportLoader/>

        <h3>Загрузка данных об отгрузке</h3>
        <p class="textXS textRegular neutral300">Пожалуйта, подождите...</p>
    </div>
    <div v-else>
        <el-scrollbar :height="height">
            <el-timeline style="max-width: 98%; padding-left: 3px;">
                <el-timeline-item
                    v-for="(log, index) in logs"
                    :key="index"
                    :icon="log.icon"
                    :color="log.color"
                    :timestamp="log.timestamp"
                    placement="top"
                >
                    <MixOrderDetailsGroupLogConfirm
                        v-if="log.event === 'confirm'"
                        :log="log.content"
                    />
                    <MixOrderDetailsGroupLogUnConfirm
                        v-else-if="log.event === 'unConfirm'"
                    />
                    <MixOrderDetailsGroupLogEdit
                        v-else-if="log.event === 'edit'"
                        :log="log.content"
                    />
                    <MixOrderDetailsGroupLogChangeStatus
                        v-else-if="log.event === 'changeStatus'"
                        :log="log.content"
                    />
                    <template v-else>
                        {{ log.content }}
                    </template>
                </el-timeline-item>
            </el-timeline>
        </el-scrollbar>
    </div>
</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import MixOrderDetailsGroupLogConfirm
    from "@/views/components/MixOrderGroup/components/log/MixOrderDetailsGroupLogConfirm.vue";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment/moment";
import MixOrderDetailsGroupLogUnConfirm
    from "@/views/components/MixOrderGroup/components/log/MixOrderDetailsGroupLogUnConfirm.vue";
import MixOrderDetailsGroupLogEdit
    from "@/views/components/MixOrderGroup/components/log/MixOrderDetailsGroupLogEdit.vue";
import MixOrderDetailsGroupLogChangeStatus
    from "@/views/components/MixOrderGroup/components/log/MixOrderDetailsGroupLogChangeStatus.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "MixOrderDetailsGroupLog",
    components: {
        MixOrderDetailsGroupLogChangeStatus,
        MixOrderDetailsGroupLogEdit,
        MixOrderDetailsGroupLogUnConfirm, MixOrderDetailsGroupLogConfirm, ReportLoader},
    mixins: [iconsMixin, functionsMixin, mobileCheckMixin],
    props: ['mixOrderId', 'tab', 'height'],
    inject: ['api'],
    data() {
        return {
            logs: [],
            loading: true
        }
    },
    created() {

    },
    methods: {
        getLogs() {
            this.loading = true
            httpService().post(this.api.mixOrder.getLogs + '/' + this.mixOrderId, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {

                let data = response.data

                if (data.success) {
                    this.logs = []
                    data.logs.forEach(el => {

                        let color = '#8a909a'
                        let icon = null
                        let timestamp = moment(el.created_at).format('DD.MM HH:mm')

                        if (el.author === 'user') {
                            let role = null
                            if (el.user?.role_id === 5) {
                                role = 'Водитель миксера'
                            } else if (el.user?.role_id === 6) {
                                role = 'Оператор БСУ'
                            } else if (el.user?.role_id === 7) {
                                role = 'Водитель насоса'
                            }
                            timestamp += ' - ' + el.user?.name + (role ? ' (' + role + ')' : '');
                        } else if (el.author === 'gps') {
                            timestamp += ' - GPS'
                        } else if (el.author === 'mix') {
                            timestamp += ' - обработка данных с АСУ'
                        }

                        switch (el.event) {
                            case 'confirm':
                                color = '#009272';
                                icon = this.icons.check;
                                break;
                            case 'unConfirm':
                                color = '#afb3ba';
                                icon = this.icons.close;
                                break;
                            case 'edit':
                                color = '#ecaf02';
                                icon = this.icons.edit;
                                break;
                        }

                        let log = {
                            color: color,
                            timestamp: timestamp,
                            content: el,
                            event: el.event,
                            icon: icon
                        }

                        this.logs.push(log)
                    })

                    this.loading = false


                }
            }).catch(() => {
                this.loading = false
            })
        }
    },
    watch: {
        tab() {
            if (this.tab === 'log' && this.logs.length === 0) {
                this.getLogs()
            }
        }
    }
}
</script>

<style scoped>

</style>
