<template>
    <Auth title="Добавление интеграции" :reloadNav="reloadNav">
        <div class="card create-module">
            <div class="card-body">
                <el-form ref="form" :model="selectIntegration" size="small" label-position="top">
                    <el-form-item class="modulesList">
                        <h3 class="w-100">1. Выберите интеграцию с внешним источником данных:</h3>
                        <div class="integration-container">
                            <el-radio class="integrationRadio" v-model="selectIntegration.integration"
                                      @change="clearSelectIntegration"
                                      v-for="(integration, index) in integrations" :key="index"
                                      :label="integration" border>
                                <div class="icon">
                                    <VesySoftLogo v-if="integration.key === 'VesySoft'"/>
                                    <TTSSmartWeightLogo v-if="integration.key === 'SmartWeight'"/>
                                    <TTSSmartMixLogo v-if="integration.key === 'SmartMix'"/>
                                    <OneCBuhLogo v-if="integration.key === '1C'"/>
                                    <KIPConceptLogo v-if="integration.key === 'ConceptBCY'"/>
                                    <WialonLogo v-if="integration.key === 'WialonMix'"/>
                                    <GlonassSoftIcon v-if="integration.key === 'GlonassSoft'"/>
                                    <WhatsAppLogo v-if="integration.key === 'Wappi'" />
                                    <h2 class="mt-0 pt-0" v-if="integration.key === 'Trekerserver'" style="text-transform:uppercase;">Trekerserver</h2>
                                    <h2 class="mt-0 pt-0" v-if="integration.key === 'Traccar'" style="text-transform:uppercase;">GPS</h2>
                                </div>
                                <span class="title">{{ integration.name }}</span>
                                <span class="description">{{ integration.description }}</span>
                            </el-radio>
                        </div>
                    </el-form-item>

                    <el-form-item v-if="selectIntegration.integration.key && (selectIntegration.integration.key === 'GlonassSoft' || selectIntegration.integration.key === 'Trekerserver' || selectIntegration.integration.key === 'Traccar' || selectIntegration.integration.key === 'WialonMix' || selectIntegration.integration.key === '1C')">
                        <h3 class="w-100">2. Укажите настройки, необходимые для интеграции:</h3>
                        <div
                            v-if="selectIntegration.integration.key === 'SmartWeight' || selectIntegration.integration.key === 'SmartMix' || selectIntegration.integration.key === 'ConceptBCY'">
                            <el-form-item label="Внутренний идентификатор вашего завода:">
                                <el-input v-model="selectIntegration.settings.objectId" type="text"
                                          placeholder="Н-р: 111111 Рога и Ко 2 Автовесов"></el-input>
                            </el-form-item>
                        </div>
                        <div
                            style="min-width: 250px"
                            v-if="selectIntegration.integration.key === 'WialonMix'">
                            <FormInput
                                label="Логин от системы Wialon"
                                placeholder="Логин"
                                v-model="selectIntegration.settings.login"
                                style="margin-bottom: 15px"
                            />
                            <FormInput
                                label="Пароль от системы Wialon"
                                placeholder="Пароль"
                                v-model="selectIntegration.settings.password"
                            />
                        </div>
                        <div
                            style="min-width: 250px"
                            v-if="selectIntegration.integration.key === 'GlonassSoft'">
                            <FormInput
                                label="Логин от системы GlonassSoft"
                                placeholder="Логин"
                                v-model="selectIntegration.settings.login"
                                style="margin-bottom: 15px"
                            />
                            <FormInput
                                label="Пароль от системы GlonassSoft"
                                placeholder="Пароль"
                                v-model="selectIntegration.settings.password"
                                style="margin-bottom: 15px"
                            />
                            <FormInput
                                label="Адрес хостинга"
                                placeholder="https://hosting.glonasssoft.ru/"
                                v-model="selectIntegration.settings.host"
                            />
                        </div>
                        <div
                            style="min-width: 250px"
                            v-if="selectIntegration.integration.key === 'Trekerserver'">
                            <FormInput
                                label="Логин от системы Trekerserver"
                                placeholder="Логин"
                                v-model="selectIntegration.settings.login"
                                style="margin-bottom: 15px"
                            />
                            <FormInput
                                label="Пароль от системы Trekerserver"
                                placeholder="Пароль"
                                v-model="selectIntegration.settings.password"
                                style="margin-bottom: 15px"
                            />
                        </div>
                        <div
                            style="min-width: 250px"
                            v-if="selectIntegration.integration.key === 'Traccar'">
                            <FormInput
                                label="Логин от системы GPS"
                                placeholder="Логин"
                                v-model="selectIntegration.settings.login"
                                style="margin-bottom: 15px"
                            />
                            <FormInput
                                label="Пароль"
                                placeholder="Пароль"
                                v-model="selectIntegration.settings.password"
                                style="margin-bottom: 15px"
                            />
                        </div>
                        <div v-if="selectIntegration.integration.key === '1C'">
                            <el-form-item label="Модуль денежного учета (необязательно):" v-if="modules.filter(el => el.module_key === 'cashbox').length > 0">
                                <el-radio-group v-model="selectIntegration.settings.moduleId" class="inlineRadio" size="medium">
                                    <el-radio v-for="(module, index) in modules.filter(el => el.module_key === 'cashbox')" :key="index"
                                              :label="module.id" border>{{ module.name }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="Модули бетонных заводов (необязательно):" v-if="modules.filter(el => el.module_key === 'mix').length > 0">
                                <el-checkbox-group v-model="selectIntegration.settings.mixIds" size="large" class="mb-15">
                                    <el-checkbox v-for="(module, index) in modules.filter(el => el.module_key === 'mix')" :key="index"
                                                 :label="module.module_reserve_id">{{ module.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>

                            <FormSelect
                                v-model="selectIntegration.settings.mode"
                                label="Способ добавления реализации"
                                placeholder="Выберите метод"
                                :items="oneCModes"
                            />

                            <FormSelect
                                v-model="selectIntegration.settings.actionTrigger"
                                label="Когда создавать задание на реализацию"
                                placeholder="Выберите вариант"
                                :items="oneCActionTriggers"
                            />

                            <el-form-item label="Обязательные поля:">
                                <el-checkbox-group v-model="selectIntegration.settings.attributes" size="large" class="mb-15">
                                    <el-checkbox
                                        v-for="(attribute, index) in oneCAttributes"
                                        :key="index"
                                        :label="attribute.id">{{ attribute.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>

                            <el-form-item label="С каким типом оплаты выгружать данные?:">
                                <el-checkbox-group v-model="selectIntegration.settings.paymentMethods" size="large" class="mb-15">
                                    <el-checkbox
                                        v-for="(attribute, index) in paymentMethods"
                                        :key="index"
                                        :label="attribute.id">{{ attribute.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>

                            <FormSelect
                                v-model="selectIntegration.settings.companyId"
                                label="К какой компании привязана интерграция"
                                placeholder="Выберите компанию"
                                new-item="company"
                                :new-item-data="selectIntegration.settings.companyId"
                            />
                        </div>
                    </el-form-item>

                    <el-row :gutter="20" v-else>

                        <el-col :sm="8">

                            <el-form-item v-if="selectIntegration.integration.key && selectIntegration.integration.key !== 'GlonassSoft' && selectIntegration.integration.key !== 'Trekerserver' && selectIntegration.integration.key !== 'Traccar' && selectIntegration.integration.key !== 'WialonMix' && selectIntegration.integration.key !== '1C' && selectIntegration.integration.key !== 'Wappi'">
                                <h3 class="w-100">2. Выберите модуль, который будет получать данные:</h3>
                                <el-radio-group class="inlineRadio" v-model="selectIntegration.module"
                                                @change="clearSelectModule" size="medium">
                                    <el-radio v-for="(module, index) in selectIntegration.integration.available_modules"
                                              :disabled="checkIntegrationsForModule(module.integrations)" :key="index"
                                              :label="module.id" border>{{ module.name }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>

                        </el-col>

                        <el-col :sm="8" v-if="selectIntegration.module">
                            <h3 class="w-100">3. Укажите настройки, необходимые для интеграции:</h3>
                            <FormInput
                                v-model="selectIntegration.settings.objectId"
                                label="Внутренний идентификатор вашего завода:"
                                placeholder="Н-р: 6sgpW1FL"
                            />

                            <FormSelect
                                v-if="selectIntegration.integration.key === 'SmartMix'"
                                :items="TTSProducts"
                                label="Програмный продукт"
                                placeholder="Выберите"
                                v-model="selectIntegration.settings.product"
                            />
                        </el-col>

                    </el-row>


                </el-form>
                <div class="footer">
                    <el-button :loading="loadingButton" :disabled="disableButton" type="primary"
                               :icon="icons.plus"
                               size="large"
                               @click.prevent="doCreateIntegration">Активировать интеграцию
                    </el-button>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import VesySoftLogo from '../../components/Logo/VesySoftLogo'
import TTSSmartWeightLogo from '../../components/Logo/TTSSmartWeightLogo'
import TTSSmartMixLogo from '@/views/components/Logo/TTSSmartMixLogo'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import OneCBuhLogo from "@/views/components/Logo/OneCBuhLogo";
import KIPConceptLogo from "@/views/components/Icons/KIPConceptLogo.vue";
import WialonLogo from "@/views/components/Icons/WialonLogo.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import GlonassSoftIcon from "@/views/components/Icons/GlonassSoftIcon.vue";
import WhatsAppLogo from "@/views/components/Logo/WhatsAppLogo.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import oneCInfo from "@/mixins/oneCInfo";
import paymentMethods from "@/mixins/paymentMethods";
import TTSProducts from "@/mixins/TTSProducts";

export default {
    name: 'IntegrationCreate',
    data() {
        return {
            loading: true,
            loadingButton: true,
            integrations: [],
            selectIntegration: {
                integration: {
                    name: null,
                    description: null,
                    key: null,
                },
                module: null,
                settings: {
                    objectId: null,
                    login: null,
                    password: null,
                    mixId: null,
                    warehouse: null,
                    mixIds: [],
                    paymentMethods: [],
                    mode: 'by_one',
                    actionTrigger: 'orderCompletion',
                    attributes: [],
                    product: null
                },
            },
            modules: []
        }
    },
    components: {
        FormSelect,
        WhatsAppLogo,
        GlonassSoftIcon,
        FormInput,
        WialonLogo,
        KIPConceptLogo,
        OneCBuhLogo,
        TTSSmartMixLogo,
        TTSSmartWeightLogo,
        VesySoftLogo,
        Auth
    },
    mixins: [iconsMixin, oneCInfo, paymentMethods, TTSProducts],
    inject: [
        'api'
    ],
    created() {
        httpService().post(this.api.settings.getAllIntegrations).then((response) => {
            let data = response.data

            if (data.success)
                this.integrations = data.integrations
            this.loadingButton = false

        })
        this.getModules()
    },
    computed: {
        disableButton: function () {
            if (this.selectIntegration.integration.key === 'Wappi') return false;

            if (this.selectIntegration.integration.key !== '1C' && this.selectIntegration.integration.key !== 'WialonMix' && this.selectIntegration.integration.key !== 'GlonassSoft' && this.selectIntegration.integration.key !== 'Trekerserver' && this.selectIntegration.integration.key !== 'Traccar') {
                if (!this.selectIntegration.settings.objectId) {
                    return true
                }
            }
            if ((!this.selectIntegration.settings.login && !this.selectIntegration.settings.password) && (this.selectIntegration.integration.key === 'WialonMix' || this.selectIntegration.integration.key === 'GlonassSoft' || this.selectIntegration.integration.key === 'Trekerserver' || this.selectIntegration.integration.key === 'Traccar')) {
                return true
            }
            return false
        },
    },
    methods: {
        getModules() {
            this.loading = true
            httpService().post(this.api.account_module.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.modules = data.modules

            })
        },
        doCreateIntegration() {
            this.loadingButton = true
            httpService().post(this.api.settings.createIntegration, {
                integration: this.selectIntegration,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    if (this.selectIntegration.integration.key === 'Wappi') {
                        httpService().post(this.api.whatsapp.addProfile)
                    }

                    if (data.count) {
                        this.$message({
                            message: 'Интеграция добавлена. В нашей базе найдено ' + data.count + ' записей, они будут добавлены в ближайшие 5 минут',
                            showClose: true,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: 'Интеграция добавлена',
                            showClose: true,
                            type: 'success'
                        })
                    }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loadingButton = false
                this.$router.push({ name: 'IntegrationsIndex' })
            })
        },
        clearSelectIntegration() {
            this.selectIntegration.module = null
            this.selectIntegration.settings = {
                objectId: null,
                login: null,
                password: null,
                mixId: null,
                warehouse: null,
                product: null
            }
        },
        clearSelectModule() {
            this.selectIntegration.settings = {
                objectId: null,
                login: null,
                password: null,
                mixId: null,
                warehouse: null,
                product: null
            }
        },
        checkIntegrationsForModule(integrations) {
            let check = 0
            integrations.forEach(element => {
                if (element.integration_id === this.selectIntegration.integration.id) {
                    check = 1
                }
            })

            return check === 1
        }
    }
}
</script>


<style scoped>
</style>
