<template>
<div>
    <el-row v-for="(material, index) in materials" :key="index"
            :gutter="15">

        <el-col :sm="8">

            <FormSelectGoods
                v-model="material.good_id"
                label="Материал"
            />

        </el-col>

        <el-col :sm="4">

            <FormInput
                v-model="material.totalFormula"
                label="По рецепту"
                placeholder="50"
            />

        </el-col>

        <el-col :sm="4">

            <FormInput
                v-model="material.totalFact"
                label="Факт"
                placeholder="55"
            />

        </el-col>

        <el-col :sm="4">

            <FormInput
                v-model="material.inManual"
                label="В ручном"
                placeholder="16"
            />

        </el-col>

        <el-col :sm="3">
            <el-form-item class="mt-30" :label-width="0">
                <el-button type="danger" :icon="icons.delete"
                           style="margin-top: -1px"
                           @click.prevent="deleteRow(index)"></el-button>
            </el-form-item>
        </el-col>

        <el-col :sm="24" style="margin-top: -20px" v-show="!material.details">
            <el-button class="color-grey" @click="material.details = 'show'" type="text">+ Данные о корректировке и погрешности</el-button>
        </el-col>

        <el-col :sm="24" class="expendMaterials" v-if="material.details === 'show'">

            <h4 class="mb-0 color-primary">Данные о корректировке</h4>

            <el-row :gutter="15">

                <el-col :sm="8">

                    <FormNumber
                        v-model="material.adjustmentMoisture"
                        label="Корректировка по влажности"
                        placeholder="2"
                    />

                </el-col>

                <el-col :sm="8">

                    <FormNumber
                        v-model="material.adjustmentAuto"
                        label="Корректировка авто"
                        placeholder="14"
                    />

                </el-col>

                <el-col :sm="8">

                    <FormNumber
                        v-model="material.adjustmentManual"
                        label="Корректировка ручная"
                        placeholder="15"
                    />

                </el-col>

            </el-row>

            <h4 class="mb-0 mt-0 color-primary">Данные о погрешности</h4>

            <el-row :gutter="15">

                <el-col :sm="8">

                    <FormNumber
                        v-model="material.errorAuto"
                        label="Погрешность авто"
                        placeholder="4"
                    />

                </el-col>

                <el-col :sm="8">

                    <FormNumber
                        v-model="material.errorManual"
                        label="Погрешность ручная"
                        placeholder="2"
                    />

                </el-col>

            </el-row>

            <el-button
                class="color-grey"
                @click="material.details = false"
                style="margin-top: -20px"
                type="text">
                - Скрыть блок настроек
            </el-button>

        </el-col>
    </el-row>
    <el-form-item label="" class="mb-0">
        <el-button :loading="loading" :icon="icons.plus" type="info"
                   @click.prevent="addNewRow()" plain>Добавить
            материал
        </el-button>
    </el-form-item>
</div>
</template>

<script>
import FormSelectGoods from '@/views/components/Form/FormSelectGoods'
import FormInput from '@/views/components/Form/FormInput'
import FormNumber from "@/views/components/Form/FormNumber";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "MixPageOrderMixCreateMaterials",
    components: {FormNumber, FormInput, FormSelectGoods},
    props: [
        'modelValue'
    ],
    mixins: [
        iconsMixin
    ],
    data () {
        return {
            materials: this.modelValue
        }
    },
    methods: {
        deleteRow(index) {
            console.log(index)
            this.materials.splice(index, 1)

            if (this.materials.length === 0) {
                this.addNewRow()
            }
        },
        addNewRow() {
            this.materials.push({
                'good_id': null,
                'totalFormula': null,
                'totalFact': null,
                'inManual': null,
                'adjustmentMoisture': null,
                'adjustmentAuto': null,
                'adjustmentManual': null,
                'errorAuto': null,
                'errorManual': null,
                'details': null,
            })
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.materials = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'materials': {
            handler: function () {
                this.$emit('update:modelValue', this.materials)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
