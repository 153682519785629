<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle :title="factory ? factory.name : 'Создание объекта'"/>

            <el-form ref="form" :model="createFactoryForm" size="small" label-position="top">

                <FormInput
                    v-model="createFactoryForm.name"
                    label="Название объекта"
                    placeholder="Н-р: Бетонный завод Якутск"
                />

                <FormSelect
                    v-model="createFactoryForm.type_id"
                    label="Тип объекта"
                    placeholder="Выберите тип"
                    :items="types"
                />

                <FormSelect
                    v-model="createFactoryForm.timezone"
                    label="Часовой пояс"
                    placeholder="Выберите часовой пояс"
                    :items="timezones"
                />

                <div class="map">
                    <YandexMap
                        :coords="mapCenter"
                        :zoom="mapZoom"
                        @onChangeCenterPosition="handlePosition"
                        @onChangeZoom="handleZoom"
                    />
                </div>

                <FormInput
                    v-model="createFactoryForm.latitude"
                    label="Широта"
                    placeholder="-"
                />

                <FormInput
                    v-model="createFactoryForm.longitude"
                    label="Долгота"
                    placeholder="-"
                />

                <FormInput
                    v-model="createFactoryForm.object_radius"
                    label="Радиус объекта (км)"
                    placeholder="-"
                />

                <el-form-item label="Логотип для документов">
                    <FileUploader v-model="createFactoryForm.image" :preview="createFactoryForm.image_path" @setIsDeleteImage="setIsDeleteImage" />
                </el-form-item>

            </el-form>
        </template>
        <template v-if="!factory" v-slot:footerOneButton>
            <DrawerCreateButton :loading="loading" title="Создать объект" @action="createValue"/>
        </template>
        <template v-if="factory" v-slot:footer>
            <DrawerCreateButton :loading="loading" title="Сохранить изменения" @action="createValue"/>
            <DrawerDeleteButton :loading="loading" @action="removeValue"/>
        </template>
    </DrawerFramework>
</template>

<script>
import FormSelect from '@/views/components/Form/FormSelect'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import FormInput from '@/views/components/Form/FormInput'
import timezonesMixin from '@/mixins/timezonesMixin'
import DrawerDeleteButton from '@/views/components/Drawer/Buttons/DrawerDeleteButton'
import { httpService } from '@/services/http.service'
import { useCommonStore } from '@/store/common'
import YandexMap from '@/views/widgets/map/YandexMap';
import FileUploader from "@/components/FileUploader.vue";

const INITIAL_ZOOM = 10;

export default {
    name: 'FactoryCreate',
    components: {
        FileUploader,
        YandexMap,
        DrawerDeleteButton,
        FormSelect, DrawerHeadTitle, DrawerCreateButton, DrawerFramework, FormInput
    },
    props: [
        'factory'
    ],
    inject: [
        'api'
    ],
    mixins: [
        timezonesMixin
    ],
    computed: {
        mapCenter() {
            if (!this.createFactoryForm.latitude || !this.createFactoryForm.longitude) {
                return [55.75165053565251, 37.61758420869977];
            }

            return [parseFloat(this.createFactoryForm.latitude), parseFloat(this.createFactoryForm.longitude)];
        },
    },
    created() {
        this.loading = true

        httpService().post(this.api.settings.getFactoryTypes).then((response) => {
            let data = response.data

            if (data.success)
                this.types = data.types
            this.loading = false
        })
    },
    data() {
        return {
            loading: false,
            mapZoom: INITIAL_ZOOM,
            createFactoryForm: {
                name: null,
                type_id: null,
                timezone: null,
                latitude: null,
                longitude: null,
                object_radius: null,
                image: null,
                is_delete_image: null,
                image_path: null,
            },
            types: [],
        }
    },
    methods: {
        setIsDeleteImage(value) {
            this.createFactoryForm.is_delete_image = value
        },
        handlePosition({ lat, lng, address }) {
            this.createFactoryForm.longitude = lng;
            this.createFactoryForm.latitude = lat;
            console.log(address)
        },
        handleZoom(value) {
            this.mapZoom = value;
        },
        handleClear() {
            this.createFactoryForm.longitude = null;
            this.createFactoryForm.latitude = null;
        },
        createValue() {
            this.loading = true

            const formData = new FormData();

            formData.append('id', this.createFactoryForm.id);
            formData.append('name', this.createFactoryForm.name);
            formData.append('type_id', this.createFactoryForm.type_id);
            formData.append('account_id', this.createFactoryForm.account_id ?? null);
            formData.append('timezone', this.createFactoryForm.timezone);
            formData.append('latitude', this.createFactoryForm.latitude ?? null);
            formData.append('longitude', this.createFactoryForm.longitude ?? null);
            formData.append('object_radius', this.createFactoryForm.object_radius ?? null);
            formData.append('is_delete_image', this.createFactoryForm.is_delete_image ?? 0);

            if (this.createFactoryForm.image && typeof this.createFactoryForm.image !== 'string') {
                formData.append('image', this.createFactoryForm.image);
            }

            if (this.factory) {
                httpService().post(this.api.settings.updateFactory, formData).then((response) => {
                    let answer = response.data

                    if (answer.success)
                        this.$message({
                            message: 'Изменения сохранены',
                            showClose: true,
                            type: 'success'
                        })

                    this.$emit('closeDrawer')
                    this.$emit('moduleGet')
                    useCommonStore().pullFactories();
                    this.loading = false
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            } else {
                httpService().post(this.api.settings.createFactory, formData).then((response) => {
                    let answer = response.data

                    if (answer.success)
                        this.$message({
                            message: 'Объект добавлен',
                            showClose: true,
                            type: 'success'
                        })

                    this.$emit('closeDrawer')
                    this.$emit('moduleGet')
                    useCommonStore().pullFactories();
                    this.loading = false
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        },
        removeValue() {
            {
                this.loading = true

                this.$confirm(
                    'Вы действительно хотите удалить объект?',
                    'Подтвердите удаление',
                    {
                        confirmButtonText: 'Удалить',
                        cancelButtonText: 'Отмена',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        httpService().post(this.api.settings.removeFactory, {
                            form: this.createFactoryForm
                        }).then((response) => {
                            let answer = response.data

                            if (answer.success)
                                this.$message({
                                    message: 'Объект удален',
                                    showClose: true,
                                    type: 'success'
                                })

                            this.$emit('closeDrawer')
                            this.$emit('moduleGet')
                            useCommonStore().pullFactories();
                            this.loading = false
                        }).catch((error) => {
                            this.$message({
                                message: error.response.data.message,
                                showClose: true,
                                type: 'error'
                            })
                            this.loading = false
                        })
                    }).catch(() => {
                    this.$message({
                        message: 'Удаление отменено',
                        showClose: true,
                        type: 'info'
                    })
                    this.loading = false
                })
            }
        }
    },
    watch: {
        'factory': {
            handler: function () {
                if (this.factory) {
                    this.createFactoryForm = this.factory
                } else {
                    this.createFactoryForm = {
                        name: null,
                        type_id: null,
                        timezone: null,
                    }
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 220px;
    margin-bottom: 15px;
}
</style>
