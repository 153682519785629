<template>
    <span class="textSM textRegular neutral400">{{ label }}</span>

    <template v-if="loading">
        <div class="text-center w-100 mt-30 mb-30">
            <ReportLoader/>
        </div>
    </template>
    <template v-else>
        <div class="manager-items" v-if="managers.length">
            <div class="manager-item" v-for="(manager, index) in managers" :key="index">
                <div class="d-flex-full mb-15">
                    <div class="cutOverflow textMD textMedium neutral900">
                        {{ manager.name }}
                    </div>
                    <div>
                        <el-button :disabled="loadingButton" type="text" @click="remove()" :icon="icons.delete"/>
                    </div>
                </div>
                <div>
                    <CRMInfoText
                        head="Телефон"
                        :text="manager.phone ?? '-'"
                    />
                    <CRMInfoText
                        head="E-mail"
                        :text="manager.email ?? '-'"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center w-100 mt-30 mb-30 d-flex-center" style="min-height: 106px">
                <div>
                <el-button
                    class="mb-15"
                    size="small"
                    :disabled="loadingButton"
                    @click="openCreateDriver"
                >
                    Добавить представителя
                </el-button>
                <div class="textXS textRegular neutral200">
                    не указан
                </div>
                </div>
            </div>
        </div>

    </template>

    <el-dialog
        v-model="createManagerDialog"
        title="Выберите представителя"
        :width="mobile ? '100%' : '40%'"
        :destroy-on-close="true"
    >
        <template v-if="newManager">
            <el-form ref="form" label-position="top">
                <FormInput
                    v-model="manager.name"
                    label="Имя представителя"
                    placeholder="Введите название"
                />
                <FormInput
                    v-model="manager.phone"
                    label="Телефон клиента"
                    placeholder="+7 900 500-09-09"
                    inputMask="+7 ### ###-##-##"
                />
            </el-form>
        </template>
        <template v-else>
            <el-table :data="managersData" style="width: 100%">
                <el-table-column prop="name" label="Имя" />
                <el-table-column prop="phone" label="Телефон" />
                <el-table-column prop="">
                    <template v-slot="scope">
                        <el-button size="small" @click="createManager(scope.row.id)">Выбрать</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>

        <hr class="mb-15"/>
        <div>
            <el-button :disabled="loadingButton" v-if="newManager" type="primary" @click="createManager(null)">Добавить
                представителя
            </el-button>
            <el-button :disabled="loadingButton" @click="() => newManager = !newManager">
                {{ newManager ? 'К списку представителей' : 'Создать нового' }}
            </el-button>
        </div>
    </el-dialog>

</template>

<script>

import iconsMixin from "@/mixins/iconsMixin";
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: 'CompanyManagers',
    components: {FormInput, ReportLoader, CRMInfoText},
    mixins: [iconsMixin, mobileCheckMixin, functionsMixin],
    props: ['loading', 'managers', 'companyId'],
    inject: ['api'],
    data() {
        return {
            loadingButton: false,
            createManagerDialog: false,
            newManager: false,
            manager: {
                name: null,
                phone: null
            },
            managersData: []
        }
    },
    computed: {
        label() {
            return this.managers.length > 1 ? 'Представители' : 'Представитель'
        },
        ...mapState(useCommonStore, {
            companies: 'getCompanies'
        }),
    },
    methods: {
        edit(id) {
            console.log(id)
        },
        openCreateDriver() {
            this.getManagers()
            this.newManager = false
            this.createManagerDialog = true
        },
        getManagers() {
            httpService().post(this.api.company.getAttribute, {
                attribute: 'managers',
                companyId: this.companyId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.managersData = data.attribute
                }
            })
        },
        createManager(id) {
            this.loadingButton = true

            httpService().post(this.api.mixOrderGroup.addCompanyManager, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.$route.params.groupId,
                manager: this.manager,
                managerId: id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Представитель добавлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.createManagerDialog = false
                    this.manager = {
                        name: null,
                        phone: null
                    }
                    this.newManager = false
                    this.pullCompanies()
                    this.$emit('getOrderFull')
                    this.loadingButton = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        remove() {
            this.$confirm('Вы действительно хотите удалить представителя клиента в этом заказе"?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loadingButton = true

                httpService().post(this.api.mixOrderGroup.removeCompanyManager, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    orderId: this.$route.params.groupId
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Представитель удален из заказа',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('getOrderFull')
                        this.loadingButton = false
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loadingButton = false
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
