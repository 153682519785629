<template>
    <div
        @click="taskShow(order.id, order?.confirm)"
        :class="[
            'card',
            {
                'pointer': order?.confirm
            }
        ]"
        style="margin-bottom: 0"
    >
        <div :class="[
                'card-body',
                {
                    'uncomfirm': !order?.confirm
                }
            ]"
        >
            <div class="d-flex justify-between task">
                <div v-if="order?.mix ?? false" class="textXS textMedium neutral900 task__title">
                    <div class="task__title__item">{{ tableTitle(order.mix, order.mixer) }}</div>
<!--                    <div class="task__title__separator">-->
<!--                        - -->
<!--                    </div>-->
<!--                    <div class="task__title__item">Объект назва Объект назва Объект назва</div>-->
                </div>
                <div
                    v-if="order?.status == 'new' || order?.status == 'done'"
                    :class="['textSM', 'textMedium', 'primary600', { 'neutral900': order?.status == 'new' || order?.status == 'done' }]"
                >
                    {{getDateFromUnix(order?.timeline.find(el => el.key === 'start_at').date, 'HH:mm', (tz ?? order?.tz)) }}
                </div>
                <div v-else class="d-flex justify-between status">
                    <div class="textXS textRegular neutral400"></div>
                    <div
                        class="textXS textRegular primary600 status__title"
                        v-if="order?.status !== 'new' && order?.status !== 'done'"
                    >
                        {{ status }}
                    </div>
                </div>
            </div>
            <div class="d-flex justify-between status" style="margin-top: 5px">
                <div class="textXS textRegular neutral400">{{ order?.vehicle }}</div>
<!--                <div-->
<!--                    class="textXS textRegular primary600 status__title"-->
<!--                    v-if="order?.status !== 'new' && order?.status !== 'done'"-->
<!--                >-->
<!--                    {{ status }}-->
<!--                </div>-->
            </div>

            <div class="textXS textRegular neutral400 d-flex justify-between" style="margin-top: 13px">
                <span>{{ order?.total }} м³ - {{ order?.goods[0]?.good?.good?.name }}</span>
                <span class="status-text" v-show="!order?.confirm">
                    Неподтверждена
                </span>
            </div>
            <div class="d-flex justify-between pump" style="margin-top: 3px">
                <div class="textSM textMedium neutral900">{{ getData(order?.companyId, 'company') }}</div>
                <div
                    class="textXS textRegular warning300 pump__title"
                    v-if="order?.pump"
                >
                    Под насос
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'MixDriverTaskActual',
    props: ['order', 'text', 'loading', 'tz'],
    mixins: [functionsMixin],
    data() {
        return {
            // order: this.order,
            buttonText: this.text,
            statusState: new Map([
                ['new', 'Очередь'],
                ['loading', 'Идёт загрузка'],
                ['delivery', 'Доставка'],
                ['object', 'На объекте'],
                ['pouring', 'Заливка'],
                ['return', 'Возвращение'],
                ['done', 'Завершена'],
            ])
        }
    },
    methods:{
        tableTitle(mix, mixer){
            if (mix?.mixers.length > 1) return mix.module.name + ' - ' + mixer.name
            console.log('tableTitle mix', mix)
            console.log('tableTitle mixer', mixer)
            return mix.module.name
        },
        taskShow(orderId, confirm){
            if (confirm){
                this.$router.push({
                    name: 'tasks.show',
                    params: { id: orderId }
                })
            }
        }
    },
    beforeCreate: function () {
        document.body.className = 'driver-bg'
    },
    beforeUnmount: function () {
        document.body.className = ''
    },
    computed: {
        status: function () {
            return this.statusState.get(this.order?.status)
        }
    }
}
</script>

<style scoped lang="scss">
.status-text{
    font-size: 11px;
    border: 1px solid;
    border-color: #c9ccd0;
    padding: 4px 8px;
    text-align: center;
    background: #F9F9F9;
    border-radius: 4px;
}
.status{
    &__title{
        color: #1984E6;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}
.pump{
    &__title{
        color: #ECAF02;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}
.task{
    &__title{
        display: flex;

        &__item{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        &__separator{
            margin: 0 2px;
        }
    }
}
.uncomfirm{
    opacity: 0.5;
}
</style>
