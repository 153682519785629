<template>
    <BaseErrorComponent :title="'CRM не подключена к Диспетчерской'"/>
</template>
<script>
import BaseErrorComponent from "@/views/dispatch/components/distributeErrors/BaseErrorComponent.vue";

export default {
    name: "NotConnectedCRM",
    components: {BaseErrorComponent},
    props:['items'],
    methods:{
    }
}
</script>
<style scoped lang="scss">
.orders-list{
    padding: 0px 27px;
}
</style>
