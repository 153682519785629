<template>
    <Auth title="Настройки">
        <div class="card ">
            <div class="card-body">
                <h3>Общие настройки</h3>
                <div class="settings">

                    <router-link :to="{ name: 'UsersEdit', params: { id: user.id } }" class="link">
                        <div class="icon">
                            <UserProfileIcon/>
                        </div>
                        <span class="title">
                    Мой профиль
                    </span>
                        <span class="description">
                        Изменение ваших данных и сброс пароля
                    </span>
                    </router-link>

                    <router-link v-if="user.role_id !== 3 && user.email !== 'demo@dg-plat.ru' && user.email !== 'demo@cifra.ai'" :to="{ name: 'PaymentIndex' }" class="link">
                        <div class="icon">
                            <PaymentIcon/>
                        </div>
                        <span class="title">
                    Оплата
                    </span>
                        <span class="description">
                        Управление тарифом, просмотр баланса и пополнение счета
                    </span>

                    </router-link>

                    <router-link v-if="user.role_id !== 3" :to="{ name: 'ModulesIndex' }" class="link">
                        <div class="icon">
                            <ModulesIcon/>
                        </div>
                        <span class="title">
                    Модули
                    </span>
                        <span class="description">
                        Создание, редактирование модулей и выдача доступов к ним
                    </span>
                    </router-link>

                    <router-link v-if="user.role_id !== 3" :to="{ name: 'RelationsIndex' }" class="link">
                        <div class="icon">
                            <RelationsIcon/>
                        </div>
                        <span class="title">
                    Автоматизация
                    </span>
                        <span class="description">
                        Управляйте обменом данным между модулями
                    </span>
                    </router-link>

                    <router-link v-if="user.role_id !== 3" :to="{ name: 'IntegrationsIndex' }" class="link">
                        <div class="icon">
                            <IntegrationsIcon/>
                        </div>
                        <span class="title">
                    Интеграции
                    </span>
                        <span class="description">
                        Получение данных из внешних источников
                    </span>
                    </router-link>

                    <router-link v-if="user.role_id === 1" :to="{ name: 'EmailReportSettingsIndex' }" class="link">
                        <div class="icon">
                            <EmailReportIcon/>
                        </div>
                        <span class="title">
                    Отчет на почту
                    </span>
                        <span class="description">
                        Настройка отчета на ваш e-mail
                    </span>
                    </router-link>

                    <router-link :to="{ name: 'AccountSettingsIndex' }" class="link">
                        <div class="icon">
                            <img :src="require('@/assets/icons/apps.svg')"/>
                        </div>
                        <span class="title">
                    Приложения
                    </span>
                        <span class="description">
                        Настройка приложений для водителей, операторов и тд
                    </span>
                    </router-link>

                    <router-link :to="{ name: 'GroupsIndex' }" class="link" v-if="!disable">
                        <div class="icon">
                            <UserGroupsIcon/>
                        </div>
                        <span class="title">
                    Группы
                    </span>
                        <span class="description">
                        Создание и управление группами пользователей
                    </span>
                    </router-link>
                </div>


                <h3>Справочники</h3>
                <div class="settings">
                    <router-link v-if="user.role_id !== 3" :to="{ name: 'FactoriesIndex' }" class="link">
                        <div class="icon">
                            <FactoryIcon/>
                        </div>
                        <span class="title">
                    Объекты
                    </span>
                        <span class="description">
                        Управление объектами и их часовыми поясами
                    </span>
                    </router-link>

                    <router-link v-if="user.role_id !== 3" :to="{ name: 'UsersIndex' }" class="link">
                        <div class="icon">
                            <UsersIcon/>
                        </div>
                        <span class="title">
                    Пользователи
                    </span>
                        <span class="description">
                        Приглашение и управление пользователями системы
                    </span>
                    </router-link>
                    <router-link :to="{ name: 'CompaniesIndex' }" class="link">
                        <div class="icon">
                            <CompaniesIcon/>
                        </div>
                        <span class="title">
                    Компании
                    </span>
                        <span class="description">
                        Добавление и просмотр компаний из всех модулей
                    </span>
                    </router-link>
                    <router-link :to="{ name: 'goods' }" class="link">
                        <div class="icon">
                            <GoodsIcon/>
                        </div>
                        <span class="title">
                    Товары
                    </span>
                        <span class="description">
                        Добавление и просмотр товаров/грузов из всех модулей
                    </span>
                    </router-link>
                    <router-link :to="{ name: 'vehicles' }" class="link">
                        <div class="icon">
                            <VehiclesIcon/>
                        </div>
                        <span class="title">
                    Единицы техники
                    </span>
                        <span class="description">
                        Добавление и просмотр единиц техники из всех модулей
                    </span>
                    </router-link>
                    <router-link :to="{ name: 'DocumentsIndex' }" class="link">
                        <div class="icon">
                            <img :src="require('@/assets/icons/documents.svg')"/>
                        </div>
                        <span class="title">
                    Документы
                    </span>
                        <span class="description">
                        Добавление и просмотр договоров и прочих документов
                    </span>
                    </router-link>
                    <router-link :to="{ name: 'ServiceTariffs' }" class="link">
                        <div class="icon">
                            <img :src="require('@/assets/icons/service-tariffs.svg')"/>
                        </div>
                        <span class="title">
                    Тарифы услуг
                    </span>
                        <span class="description">
                        Настройка тарифов услуг по доставке бетона и прочих
                    </span>
                    </router-link>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import RelationsIcon from '../components/Icons/RelationsIcon'
import IntegrationsIcon from '../components/Icons/IntegrationsIcon'
import UsersIcon from '../components/Icons/UsersIcon'
import UserGroupsIcon from '../components/Icons/UserGroupsIcon'
import CompaniesIcon from '../components/Icons/CompaniesIcon'
import PaymentIcon from '../components/Icons/PaymentIcon'
import GoodsIcon from '../components/Icons/GoodsIcon'
import VehiclesIcon from '../components/Icons/VehiclesIcon'
import ModulesIcon from '../components/Icons/ModulesIcon'
import UserProfileIcon from '../components/Icons/UserProfileIcon'
import FactoryIcon from '@/views/components/Icons/FactoryIcon'
import EmailReportIcon from '@/views/components/Icons/EmailReportIcon'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'

export default {
    name: 'Settings',
    components: {
        EmailReportIcon,
        FactoryIcon,
        UserProfileIcon,
        ModulesIcon,
        VehiclesIcon,
        GoodsIcon,
        PaymentIcon,
        CompaniesIcon,
        UserGroupsIcon,
        UsersIcon,
        IntegrationsIcon,
        RelationsIcon,
        Auth
    },
    data() {
        return {
            disable: true
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser'
        })
    }
}
</script>

<style scoped>

</style>
