<template>
    <div class="order-quantity__progress">
        <div class="order-quantity__progress__unallocated" :style="{ width: progress.unallocated }"></div>
        <div class="order-quantity__progress__distributed" :style="{ width: progress.distributed }"></div>
        <div class="order-quantity__progress__delivered" :style="{ width: progress.delivered }"></div>
    </div>
</template>
<script>
export default {
    name: 'OrderProgress',
    props:{
        total: Number,
        orders: Array,
    },
    computed: {
        progress() {

            const deliveredTotal = this.orders.reduce((accumulator, currentItem) => {
                return accumulator + parseInt(currentItem.done, 10);
            }, 0);

            const ordersTotal = this.orders.reduce((accumulator, currentItem) => {
                return accumulator + parseInt(currentItem.total, 10);
            }, 0) - deliveredTotal;

            let distributed = (ordersTotal / this.total) * 100
            let delivered = (deliveredTotal / this.total) * 100
            let unallocated = 100 - (distributed + delivered)

            return {
                unallocated: `${unallocated}%`,
                distributed: `${distributed}%`,
                delivered: `${delivered}%`,
            }
        },
    },
}
</script>
<style scoped lang="scss">

</style>
