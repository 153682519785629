<template>
    <div>
        <div>
            <OperatorOrderDetails
                :order="form"
            />
        </div>

        <div v-if="form.status === 'loading'">
            <p>Укажите или подтвердите данные:</p>
            <el-form v-loading="loading" ref="form" label-position="top" class="operator-form">

                <el-row :gutter="20">

                    <el-col :sm="8">
                        <FormInput
                            v-model="form.doc"
                            label="Номер отгрузки из программы БСУ"
                            placeholder="Например: 54"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <el-form-item label="Время начала загрузки">
                            <el-input
                                v-model="form.timeStartAt"
                                placeholder="00:00"
                                type="time"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :sm="8">
                        <el-form-item label="Время окончания загрузки">
                            <el-input
                                v-model="form.timeLoadAt"
                                placeholder="00:00"
                                type="time"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </div>

        <hr class="mb-30 mt-30"/>

        <el-button v-if="form.status === 'new'" type="primary" plain @click="changeStatus('loading')"
                   :class="'operator-button operator-button-' + form.status" size="large"
                   :icon="icons.videoPlay" :disabled="loading">
            Подтвердить начало загрузки
        </el-button>

        <el-button v-else-if="form.status === 'loading'" @click="makeDoneOrder()"
                   class="operator-button operator-button-confirm" size="large" :icon="icons.check"
                   :disabled="loading">
            Подтвердить данные и завершить
        </el-button>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import mixOrderStatuses from "@/mixins/mixOrderInfo";
import OperatorOrderDetails from "@/views/components/Dashboards/Operator/OperatorOrderDetails.vue";
import moment from "moment";

export default {
    name: "OperatorOrderAction",
    components: {OperatorOrderDetails, FormInput},
    props: ['order', 'mixId'],
    mixins: [iconsMixin, functionsMixin, mixOrderStatuses],
    inject: ['api'],
    data() {
        return {
            loading: false,
            form: {
                id: null,
                doc: null,
                status: null,
                type: null,
                good: null,
                goodId: null,
                total: 0,
                done: 0,
                vehicle: null,
                companyId: null,
                createdAt: null,
                vehicleId: null,
                mixGoodId: null,
                startAt: null,
                loadAt: null,
                timeStartAt: null,
                timeLoadAt: null
            },
            vehicles: [],
            mixGoods: []
        }
    },
    created() {
        if (this.order) this.form = {...this.order}

        this.getOrder()

        httpService().post(this.api.mix.getGoods, {
            account_module_reserve_id: this.mixId,
            module_key: 'mix',
        }).then((response) => {
            let data = response.data

            if (data.success)
                this.mixGoods = data.values
        })

        httpService().post(this.api.vehicle.get).then((response) => {
            let data = response.data

            if (data.success)
                this.vehicles = data.vehicles
            this.loading = false
        })
    },
    methods: {
        getOrder() {
            httpService().post(this.api.mix.getOneOrder, {
                account_module_reserve_id: this.mixId,
                module_key: "mix",
                orderId: this.form.id,
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.form.mixGoodId = data.order.goodMixId
                    this.form.timeStartAt = moment(this.form.startAt).format('HH:mm')
                    this.form.timeLoadAt = moment().format('HH:mm')
                }

                this.loading = false
            });
        },
        changeStatus(status) {
            this.loading = true

            httpService().post(this.api.operator.changeStatus, {
                account_module_reserve_id: this.mixId,
                module_key: 'mix',
                order: this.form,
                status: status,
                tz: moment.tz.guess()
            }).then((response) => {
                let answer = response.data

                if (answer.success) {
                    this.$message({
                        message: 'Статус отгрузки изменен',
                        showClose: true,
                        type: 'success'
                    })

                    if (this.form.status === 'new') {
                        this.$emit('update')
                        window.setTimeout(() => {
                            this.$emit('close')
                        }, 250)
                    } else {
                        this.form.status = answer.order.order.status
                        this.loading = false
                        this.$emit('update')
                        window.setTimeout(() => {
                            this.$emit('close')
                        }, 250)
                    }

                }

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        makeDoneOrder() {
            let status = this.order.type === 'take-away' ? 'done' : 'delivery'
            this.form.done = this.form.total
            this.changeStatus(status)
        }
    }
}
</script>

<style scoped>

</style>
