<template>
    <div class="techDetailsTires">

        <div class="mb-15">
            <el-button :disabled="axles.length === 0" @click="setTireCreateDialog()" type="primary" :icon="icons.plus">
                Добавить шины
            </el-button>
            <el-button @click="setAxleCreateDialog()" type="info" plain :icon="icons.plus">Добавить ось</el-button>
        </div>
        <template v-if="axles.length > 0">
            <div class="techDetailsTiresItem" v-for="(axle, index) in axles" :key="index">
                <div class="d-flex-full">
                    <div>
                        <span class="textMD textMedium neutral900">{{ axle.name }}</span>
                    </div>
                    <div>
                        <el-button :disabled="true" size="small" :icon="icons.edit"/>
                        <el-button @click="removeAxle(axle.id)" size="small" :icon="icons.delete"/>
                    </div>
                </div>

                <div v-if="axle.tires.length > 0">
                    <el-table
                        v-loading="loading"
                        ref="table"
                        empty-text="Нет информации"
                        :data="axle.tires"
                        class="mt-10 w-100">
                        <el-table-column
                            prop="sku"
                            label="Артикул"
                            width="140"
                            sortable
                        >
                            <template v-slot="scope">
                                <template v-if="scope.row.sku">
                                <span :class="'textMedium ' + (scope.row.sku ? 'primary600' : 'neutral300')">{{
                                        scope.row.sku ? '№' + scope.row.sku : '-'
                                    }}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="Шина"
                            width="320"
                            sortable
                        >
                            <template v-slot="scope">
                                <span class="textMedium textSM neutral900">{{ scope.row.name ?? '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="mileage"
                            label="Пробег (общ.)"
                            sortable
                        >
                            <template v-slot="scope">
                            <span class="textMedium textSM success400">{{
                                    scope.row.mileage ? formatNumber(scope.row.mileage) + ' км.' : '-'
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="axleMileage"
                            label="Пробег (ось)"
                            sortable
                        >
                            <template v-slot="scope">
                            <span class="textMedium textSM warning300">{{
                                    scope.row.axleMileage ? formatNumber(scope.row.axleMileage) : 0
                                }} км.</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="position"
                            label="Сторона"
                            width="110"
                            sortable
                        >
                            <template v-slot="scope">
                            <span
                                class="textRegular textSM neutral600">{{
                                    scope.row.position ? this.positions.find(el => el.id === scope.row.position).name : 'Не указана'
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label=""
                            width="110px"
                        >
                            <template v-slot="scope">
                                <div class="text-right">
                                    <el-tooltip class="item" effect="dark" content="Настроить" placement="top">
                                        <el-button @click="setTireEditDialog(scope.row)" plain size="small"
                                                   :icon="icons.operation"/>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="Удалить" placement="top">
                                        <el-button @click="removeTire(scope.row.id)" plain size="small"
                                                   :icon="icons.delete"/>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="w-100 text-center mt-15" v-else>
                    <div id="emptyStateMix">
                        <EmptyListIcon :height="120"/>
                        <div>
                            <h3>Список шин на данной оси пуст</h3>
                            <el-button @click="setTireCreateDialog()" type="primary" plain>Добавить шину</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="w-100 text-center mt-15">
                <div id="emptyStateMix">
                    <EmptyListIcon :height="120"/>
                    <div>
                        <h3>Список осей для данной машины пуст</h3>
                        <el-button @click="setAxleCreateDialog()" type="primary" plain>Добавить ось</el-button>
                    </div>
                </div>
            </div>
        </template>
    </div>

    <el-dialog
        v-model="axleCreateDialog"
        title="Новая ось"
        :width="mobile ? '100%' : '45%'"
        :destroy-on-close="true"
    >
        <el-form label-position="top">
            <FormInput
                v-model="axle.name"
                label="Название оси"
                placeholder="Н-р: передняя"
                :required="true"
            />

            <FormNumber
                v-model="axle.max_mileage"
                label="Максимальный пробег шины на оси (км.)"
                placeholder="45000"
                :required="true"
            />

            <el-button type="primary" @click="createAxle()" class="w-100"
                       :disabled="!axle.name || !axle.max_mileage">Добавить ось
            </el-button>

        </el-form>
    </el-dialog>

    <el-dialog
        v-model="tireCreateDialog"
        title="Добавить шину"
        :width="mobile ? '100%' : '75%'"
        :destroy-on-close="true"
    >
        <div class="techDetailsTiresItem" v-for="(axle, index) in axlesData" :key="index">
            <div class="textMD textMedium neutral900 mb-15">{{ axle.name }}</div>
            <div class="techDetailsTiresRow" v-for="(tire, tireIndex) in axle.tires" :key="tireIndex">
                <template v-if="tire.id">
                    <el-row :gutter="10">
                        <el-col :sm="3">
                            <div :class="'textMedium cutOverflow ' + (tire.sku ? 'primary600' : 'neutral300')">
                                {{
                                    tire.sku ? '№' + tire.sku : '-'
                                }}
                            </div>
                        </el-col>
                        <el-col :sm="6">
                            <div :class="'textMedium cutOverflow ' + (tire.sku ? 'primary600' : 'neutral300')">
                                <span class="textMedium textSM neutral900">{{ tire.label ?? tire.name }}</span>
                            </div>
                        </el-col>
                        <el-col :sm="4">
                            <div class="textMedium textSM success400 cutOverflow">
                                {{
                                    tire.mileage ? formatNumber(tire.mileage) + ' км.' : '-'
                                }}
                            </div>
                        </el-col>
                        <el-col :sm="4">
                            <div class="textMedium textSM warning300 cutOverflow">
                                {{
                                    tire.axleMileage ? formatNumber(tire.axleMileage) + ' км.' : '-'
                                }}
                            </div>
                        </el-col>
                        <el-col :sm="3">
                            <div class="textRegular textSM neutral600 cutOverflow">
                                {{
                                    tire.position ? this.positions.find(el => el.id === tire.position).name : 'Не указана'
                                }}
                            </div>
                        </el-col>
                        <el-col :sm="4">
                            <div class="text-right">
                                <el-tooltip class="item" effect="dark" content="Скопировать" placement="left">
                                    <el-button plain size="small"
                                               @click="copyTire(tire, index)"
                                               :icon="icons.copy"/>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="Удалить" placement="top">
                                    <el-button plain size="small" :disabled="tire.id"
                                               :icon="icons.delete"/>
                                </el-tooltip>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <template v-else>

                    <template v-if="!tire.type">
                        <div class="w-100">
                            <el-button
                                type="primary"
                                :icon="icons.plus"
                                @click="() => tire.type = 'new'">Создать новую шину
                            </el-button>
                            <el-button
                                type="info"
                                :icon="icons.search"
                                :disabled="tires.filter(el => !el.use).length === 0"
                                @click="() => tire.type = 'old'"
                            >Добавить шину из справочника
                            </el-button>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="tire.type === 'old'">
                            <el-form label-position="top">
                                <el-row :gutter="10">
                                    <el-col :sm="20">
                                        <el-row :gutter="10">
                                            <el-col :sm="8">
                                                <el-form-item label="Шина">
                                                    <el-select v-model="tire.tireId" placeholder="Выберите шину" filterable>
                                                        <el-option
                                                            v-for="item in tires.filter(el => !el.use)"
                                                            :key="item.id"
                                                            :label="item.name"
                                                            :value="item.id">
                                                            <span style="float: left;" class="textSM textMedium primary600">{{ item.name }}</span>
                                                            <span style="float: right;" class="textXS textRegular neutral300">{{
                                                                    item.sku
                                                                }}</span>
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :sm="8">

                                                <FormNumber
                                                    v-model="tire.mileage"
                                                    label="Текущий пробег шины на оси"
                                                    placeholder="0"
                                                />

                                            </el-col>

                                            <el-col :sm="8">
                                                <FormSelect
                                                    v-model="tire.position"
                                                    label="Расположение"
                                                    placeholder="Выберите расположение"
                                                    :items="positions"
                                                />
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :sm="4">
                                        <div class="text-right">
                                            <el-tooltip class="item" effect="dark" content="Удалить" placement="top">
                                                <el-button @click="removeTireFromData(index, tireIndex, tire)" plain
                                                           size="small" :disabled="tire.id"
                                                           :icon="icons.delete"/>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </template>
                        <template v-else>
                            <el-row :gutter="10">
                                <el-col :sm="20">
                                    <el-form ref="form" :model="tire" label-width="120px" label-position="top">

                                        <FormSelect
                                            v-model="tire.brandId"
                                            label="Бренд"
                                            placeholder="Выберите производителя"
                                            :items="brands"
                                            :required="true"
                                        />

                                        <el-row :gutter="10">

                                            <el-col :sm="8">
                                                <FormInput
                                                    v-model="tire.sku"
                                                    label="Уникальный номер (артикул)"
                                                    placeholder="Введите номер"
                                                    :required="true"
                                                />
                                            </el-col>
                                            <el-col :sm="8">
                                                <FormInput
                                                    v-model="tire.name"
                                                    label="Дополнительное название"
                                                    placeholder="Н-р: pilot sport"
                                                />
                                            </el-col>
                                            <el-col :sm="8">
                                                <FormSelect
                                                    v-model="tire.season"
                                                    label="Сезонность"
                                                    placeholder="Выберите сезонность"
                                                    :items="seasons"
                                                />
                                            </el-col>

                                        </el-row>

                                        <el-row :gutter="10">
                                            <el-col :sm="8">
                                                <FormNumber
                                                    v-model="tire.mileage"
                                                    label="Текущий пробег шины"
                                                    placeholder="Введите пробег"
                                                    :required="true"
                                                />
                                            </el-col>
                                            <el-col :sm="8">
                                                <FormNumber
                                                    v-model="tire.axleMileage"
                                                    label="Текущий пробег шины на оси"
                                                    placeholder="0"
                                                />
                                            </el-col>
                                            <el-col :sm="8">
                                                <FormNumber
                                                    v-model="tire.mileageMax"
                                                    label="Максимальный пробег шины"
                                                    placeholder="Введите пробег"
                                                    :required="true"
                                                />
                                            </el-col>

                                        </el-row>

                                        <el-row :gutter="10">

                                            <el-col :sm="8">
                                                <FormNumber
                                                    v-model="tire.radius"
                                                    label="Радиус (число)"
                                                    placeholder="18"
                                                    :precision="1"
                                                    :step="1"
                                                />
                                            </el-col>
                                            <el-col :sm="8">
                                                <FormNumber
                                                    v-model="tire.width"
                                                    label="Ширина"
                                                    placeholder="220"
                                                    :precision="0"
                                                />
                                            </el-col>
                                            <el-col :sm="8">
                                                <FormNumber
                                                    v-model="tire.height"
                                                    label="Высота"
                                                    placeholder="65"
                                                    :precision="0"
                                                />
                                            </el-col>
                                        </el-row>
                                        <el-row :gutter="10">
                                            <el-col :sm="8">
                                                <FormSelect
                                                    v-model="tire.position"
                                                    label="Расположение"
                                                    placeholder="Выберите расположение"
                                                    :items="positions"
                                                />
                                            </el-col>
                                        </el-row>

                                    </el-form>
                                </el-col>
                                <el-col :sm="4">
                                    <div class="text-right">
                                        <el-tooltip class="item" effect="dark" content="Скопировать"
                                                    placement="left">
                                            <el-button plain size="small"
                                                       @click="copyTire(tire, index)"
                                                       :icon="icons.copy"/>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Удалить" placement="top">
                                            <el-button @click="removeTireFromData(index, tireIndex, tire)" plain
                                                       size="small" :disabled="tire.id"
                                                       :icon="icons.delete"/>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-row>
                        </template>
                    </template>
                </template>
            </div>
            <div class="techDetailsTiresAddTire pointer" @click="addTireToAxle(axle, index)">
                <span class="textSM textMedium success400">+ Добавить шину для оси</span>
            </div>
        </div>
        <el-button @click="createTires()" :loading="loading" class="w-100" type="primary">Сохранить</el-button>
    </el-dialog>

    <el-dialog
        v-model="tireEditDialog"
        :title="tireDetails ? tireDetails.name : 'Шина'"
        :width="mobile ? '100%' : '45%'"
        :destroy-on-close="true"
    >
        <div class="textRegular textSM neutral300 mb-10">Пробег (общий): <span class="textMedium textSM success400">{{
                tireDetails.mileage ? formatNumber(tireDetails.mileage) : 0
            }} км.</span></div>
        <div class="textRegular textSM neutral300 mb-10">Пробег (на оси): <span class="textMedium textSM warning300">{{
                tireDetails.axleMileage ? formatNumber(tireDetails.axleMileage) : 0
            }} км.</span></div>
        <el-table
            v-loading="loading"
            ref="table"
            empty-text="Нет информации"
            :data="tireDetails.history"
            class="w-100">
            <el-table-column
                prop="date"
                label="Дата"
                width="150"
                sortable
            >
                <template v-slot="scope">
                            <span class="textMedium textSM neutral300">{{
                                    getDateFormDB(scope.row.date, 'shortDate')
                                }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="Значение"
                sortable
            >
                <template v-slot="scope">
                            <span class="textMedium textSM primary600">+ {{
                                    formatNumber(scope.row.value)
                                }} км.</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label=""
                width="100px"
                fixed="right"
            >
                <template v-slot="scope">
                    <el-button @click="removeTireHistory(scope.row.id, scope.row.tire_id)" :icon="icons.delete"/>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

</template>

<script>

import iconsMixin from '@/mixins/iconsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import FormInput from '@/views/components/Form/FormInput.vue'
import FormNumber from '@/views/components/Form/FormNumber.vue'
import {httpService} from '@/services/http.service'
import FormSelect from "@/views/components/Form/FormSelect.vue";
import functionsMixin from "@/mixins/functionsMixin";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon.vue";

export default {
    name: 'TechDetailsTires',
    components: {EmptyListIcon, FormSelect, FormNumber, FormInput},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [iconsMixin, mobileCheckMixin, functionsMixin],
    data() {
        return {
            loading: false,
            axleCreateDialog: false,
            axle: {
                name: null,
                max_mileage: null
            },
            tire: {
                tire_id: null,
                axle_id: null,
                mileage: null,
                position: 'left'
            },
            axles: [],
            tireCreateDialog: false,
            tireEditDialog: false,
            tireDetails: null,
            tires: [],
            positions: [{
                id: 'left',
                name: 'Слева'
            }, {
                id: 'right',
                name: 'Справа'
            }],
            brands: [],
            seasons: [{
                id: 'all-season',
                name: 'Всесезонная'
            }, {
                id: 'summer',
                name: 'Летняя'
            }, {
                id: 'winter',
                name: 'Зимняя'
            }],
            axlesData: [],
        }
    },
    created() {
        this.getTires()
        this.getAxles()
        this.getBrands()
    },
    methods: {
        getBrands() {
            this.loading = true

            httpService().post(this.api.tires.getBrands).then((response) => {
                let data = response.data

                if (data.success) {
                    this.brands = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        removeTireHistory(id, tireId) {
            this.$confirm(
                'Вы действительно хотите произвести удаление?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.tech.removeTireHistory, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'tech',
                        id: id,
                        tireId: tireId
                    }).then(() => {
                        this.$message({
                            message: 'Удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAxles()
                        this.tireEditDialog = !this.tireEditDialog
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        getTires() {
            httpService().post(this.api.tires.getAll).then((response) => {
                let data = response.data

                if (data.success)
                    this.tires = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        setAxleCreateDialog() {
            this.axleCreateDialog = !this.axleCreateDialog
        },
        getAxles() {
            this.loading = true

            httpService().post(this.api.vehicle.getAxles, {
                vehicleId: this.vehicleId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.axles = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        removeTireFromData(index, tireIndex, tire) {
            var idx = this.axlesData[index].tires.indexOf(tire)
            if (idx > -1) {
                this.axlesData[index].tires.splice(idx, 1)
            }
        },
        addTireToAxle(axle, index) {
            console.log(axle)
            this.axlesData[index].tires.push({
                id: null,
                sku: null,
                name: null,
                type: null,
                brandId: null,
                axleId: this.axles[index].id,
                mileage: 0,
                mileageMax: 0,
                axleMileage: 0,
                position: null,
                radius: null,
                width: null,
                height: null,
                season: null
            })
        },
        copyTire(tire, axleIndex) {
            this.axlesData[axleIndex].tires.push({
                id: null,
                sku: null,
                name: tire.name,
                type: 'new',
                brandId: tire.brandId,
                axleId: this.axles[axleIndex].id,
                mileage: tire.mileage ?? 0,
                mileageMax: tire.mileageMax ?? 0,
                axleMileage: tire.axleMileage ?? 0,
                position: tire.position,
                radius: tire.radius,
                width: tire.width,
                height: tire.height,
                season: tire.season
            })
        },
        createAxle() {
            this.loading = true

            httpService().post(this.api.vehicle.createAxle, {
                vehicleId: this.vehicleId,
                techId: this.$route.params.id,
                form: this.axle,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.setAxleCreateDialog()
                    this.getAxles()
                    this.axle = {
                        name: null,
                        max_mileage: null
                    }
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        setTireCreateDialog() {
            // попытка убрать реактивность у массива
            let arr = []
            for (let i = 0; i < this.axles.length; i++) {
                arr.push(Object.assign({}, this.axles[i]));
            }
            this.axlesData = Array.from(arr)
            this.tireCreateDialog = !this.tireCreateDialog
        },
        setTireEditDialog(tire) {
            if (tire) this.tireDetails = tire
            this.tireEditDialog = !this.tireEditDialog
        },
        removeAxle(id) {
            this.$confirm(
                "Вы действительно хотите удалить ось?",
                "Подтвердите удаление",
                {
                    confirmButtonText: "Удалить",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            )
                .then(() => {
                    this.loading = true;

                    httpService()
                        .post(this.api.vehicle.removeAxle, {
                            vehicleId: this.vehicleId,
                            axleId: id
                        })
                        .then((response) => {
                            let data = response.data;

                            if (data.success) {
                                this.getAxles()
                                this.$message({
                                    message: "Ось удалена",
                                    showClose: true,
                                    type: "success",
                                })
                                this.loading = false
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        message: "Действие отменено",
                        showClose: true,
                        type: "info",
                    });
                    this.loading = false;
                });
        },
        removeTire(id) {
            this.$confirm(
                "Вы действительно хотите убрать шину?",
                "Подтвердите удаление",
                {
                    confirmButtonText: "Удалить",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            )
                .then(() => {
                    this.loading = true;

                    httpService()
                        .post(this.api.vehicle.removeTire, {
                            vehicleId: this.vehicleId,
                            tireId: id
                        })
                        .then((response) => {
                            let data = response.data;

                            if (data.success) {
                                this.getAxles()
                                this.$message({
                                    message: "Шина убрана",
                                    showClose: true,
                                    type: "success",
                                })
                                this.loading = false
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        message: "Действие отменено",
                        showClose: true,
                        type: "info",
                    });
                    this.loading = false;
                });
        },
        createTires() {
            this.loading = true

            httpService().post(this.api.vehicle.createTires, {
                vehicleId: this.vehicleId,
                axles: this.axlesData
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.setTireCreateDialog()
                    this.getAxles()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
