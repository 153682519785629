<template>
    <template v-if="!noHeader">
        <TableHeadBlock
            v-model="search"
            :loading="loading"
            create-button-label="Создать отгрузку"
            @moduleGet="moduleGet"
            @openFilterBlock="openFilterBlock"
            @openCreateValueDrawer="openCreateMixOrderDrawer"
        />

        <Filter
            :filter="filter"
            :loading="loading"
            :filter-show="filterShow"
        />
    </template>

    <EmptyState
        :values="displayData"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="заявок"
    />

    <div v-if="displayData.length > 0">

        <div v-if="displayData">
            <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                <template v-slot:body>

                    <MobileTableDate
                        :date="item.date"
                    />

                    <MobileTableInfo
                        title="Номер"
                        :body="'Отгрузка №' + item.doc"
                    />

                    <MobileTableInfo
                        title="Объем"
                    >
                        <template v-slot:body>
                            {{ formatNumber(item.total, 1) }}
                            <good-unit :unit="getData(item.goodId, 'good','unit')"/>
                        </template>
                    </MobileTableInfo>

                    <MobileTableInfo
                        title="Выполнено"
                    >
                        <template v-slot:body>
                            {{ formatNumber(item.done, 1) }}
                            <good-unit :unit="getData(item.goodId, 'good','unit')"/>
                        </template>
                    </MobileTableInfo>

                    <MobileTableInfo
                        title="Контрагент"
                        :body="item.companyId ? getData(item.companyId, 'company') : '-'"
                    />

                    <MobileTableInfo
                        title="Рецепт"
                        :no-border="true"
                    >
                        <template v-slot:body>
                            {{ getGoodName(item) }}
                        </template>
                    </MobileTableInfo>

                    <MobileTableButton
                        @action="openMixOrderDetails(index, item)"
                    />

                </template>
            </MobileTableFramework>
        </div>

        <el-table
            v-loading="loading"
            v-if="!smallMobile"
            ref="table"
            empty-text="Нет информации"
            :data="displayData"
            :summary-method="getSummaries"
            show-summary
            class="mt-15 w-100">
            <el-table-column
                prop="id"
                label="#"
                v-if="windowWidth < 1500"
            >
                <template v-slot="scope">
                    <div class="d-flex align-center mb-5">
                        <div class="group-orders-status-text group-orders-status-text-small"
                             :class="'group-orders-status-text-' + scope.row?.status">
                            {{ getStatusLabel(scope.row.status) }}
                        </div>
                        <div class="ml-15">
                            <span class="textRegular textXS neutral300">{{ scope.row.showDate }}</span>
                        </div>
                        <div class="ml-15">
                        <span class="textMedium textXS success400 pointer" v-if="scope.row.orderGroupId"
                              @click="$router.push({name: 'DispatchOrderGroupsDetails', params: { groupId: scope.row.orderGroupId }})">
                             Заказ #{{ scope.row.orderGroupId }}
                                </span>
                        </div>
                    </div>
                    <div class="textXSRegular textSM neutral900 d-flex-full-stroke" style="text-wrap: nowrap; gap: 5px">
                        <span>№ {{ scope.row?.id }}</span>
                        <svg class="buyer-circle" width="5" height="4" viewBox="0 0 5 4" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                                fill="black"/>
                        </svg>
                        <span class="cutOverflow">
                                {{
                                scope.row.companyId ? getData(scope.row.companyId, 'company') : '-'
                            }}
                            </span>
                    </div>
                    <div class="address textXS textRegular mb-5 neutral400">
                        <Truncate
                            :inputText="scope.row.type === 'delivery' ? (scope.row.address ?? '-') : 'Самовывоз'"
                        />
                    </div>
                    <div class="d-flex-full-stroke" style="gap: 10px">
                        <div style=" height: 16px; ">
                            <svg class="good-icon" width="16" height="16" viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.6665 6V10.3333C2.6665 11.9902 4.00965 13.3333 5.66651 13.3333H10.3332C11.99 13.3333 13.3332 11.9902 13.3332 10.3333V6"
                                    stroke="#22252C" stroke-width="1.5"/>
                                <path
                                    d="M8 2.66669H12.8419C13.0707 2.66669 13.2827 2.78955 13.4004 2.99036L14.5728 4.99036C14.8333 5.43471 14.5206 6.00002 14.0143 6.00002H10.6916C10.234 6.00002 9.80996 5.75429 9.57454 5.35268L8 2.66669Z"
                                    stroke="#22252C" stroke-width="1.5"/>
                                <path
                                    d="M8.00016 2.66669H3.15825C2.92946 2.66669 2.71744 2.78955 2.59973 2.99036L1.42732 4.99036C1.16684 5.43471 1.47956 6.00002 1.98584 6.00002H5.30859C5.76617 6.00002 6.1902 5.75429 6.42562 5.35268L8.00016 2.66669Z"
                                    stroke="#22252C" stroke-width="1.5"/>
                            </svg>
                        </div>
                        <div class="textRegular textSM neutral900 cutOverflow">
                            {{ getGoodName(scope.row) }}
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="id"
                label="#"
                width="105px"
                v-if="windowWidth >= 1500"
            >
                <template #header>
                    <el-tooltip effect="dark" content="Внутренний номер отгрузки в Цифре" placement="right">
                        ID
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span class="textMedium"
                          :class="[{primary600: scope.row.confirm}, {neutral200: !scope.row.confirm}]">#{{
                            formatNumber(scope.row.id)
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="doc"
                label="Отгрузка"
                width="105px"
                v-if="columns.includes('doc') && windowWidth >= 1500"
            >
                <template #header>
                    <el-tooltip effect="dark" content="Номер отгрузки с оборудования завода" placement="right">
                        #
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span :class="'textMedium ' + getColor(scope.row)">{{
                            scope.row.doc ? '#' + scope.row.doc : '-'
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="date"
                label="Дата"
                width="150"
                v-if="windowWidth >= 1500"
            >
                <template v-slot="scope">
                    <span class="textRegular neutral300">{{ scope.row.showDate }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="dispatch && windowWidth >= 1500"
                prop="orderGroupId"
                label="Заказ"
                width="90"
            >
                <template v-slot="scope">
                    <span class="textMedium primary600 pointer"
                          @click="$router.push({name: 'DispatchOrderGroupsDetails', params: { groupId: scope.row.orderGroupId }})">{{
                            scope.row.orderGroupId ? '№' + scope.row.orderGroupId : '-'
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="mixer"
                label="Пост"
                width="140"
                v-if="windowWidth >= 1500"
            >
                <template v-slot="scope">

                    <span class="textRegular neutral400 cutOverflow">{{ scope.row.mixer ?? '-' }}</span>

                </template>
            </el-table-column>
            <el-table-column
                v-if="dispatch && windowWidth >= 1500"
                prop="status"
                label="Статус"
                width="130"
            >
                <template v-slot="scope">
                    <template v-if="scope.row.confirm">
                        <span class="textMedium success400">{{ getStatusLabel(scope.row.status) }}</span>
                    </template>
                    <template v-else>
                        <span class="textRegular neutral200">Не подтвержд.</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="Контрагент"
                v-if="windowWidth >= 1500"
            >
                <template v-slot="scope">

                    <span class="textRegular neutral700 cutOverflow">{{
                            scope.row.companyId ? getData(scope.row.companyId, 'company') : '-'
                        }}</span>

                </template>
            </el-table-column>
            <el-table-column
                prop="category"
                label="Рецепт"
                width="280px"
                v-if="windowWidth >= 1500"
            >
                <template v-slot="scope">
                    <div class="textSM textMedium neutral900 cutOverflow">
                        {{ getGoodName(scope.row) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                v-if="columns.includes('type')"
                prop="type"
                label="Тип доставки"
                width="130px"
            >
                <template v-slot="scope">

                    <span class="textRegular neutral400 cutOverflow">{{
                            scope.row.type === 'delivery' ? 'Доставка' : 'Самовывоз'
                        }}</span>

                </template>
            </el-table-column>
            <el-table-column
                prop="vehicle"
                label="Авто"
                v-if="windowWidth >= 1500"
                width="130"
            >
                <template v-slot="scope">

                    <span class="textMedium primary600 cutOverflow">{{ scope.row.vehicle ?? '-' }}</span>

                </template>
            </el-table-column>
            <el-table-column
                prop="vehicle"
                label="Пост / ТС"
                v-if="windowWidth < 1500"
                width="200px"
            >
                <template v-slot="scope">

                    <div class="textRegular neutral200 cutOverflow mb-5">{{ scope.row.mixer ?? '-' }}</div>
                    <div class="textMedium primary600 cutOverflow">{{ scope.row.vehicle ?? '-' }}</div>

                </template>
            </el-table-column>
            <el-table-column
                v-if="columns.includes('address')"
                prop="address"
                label="Адрес"
                width="200px"
            >
                <template v-slot="scope">

                    <template v-if="scope.row.address">
                        <span class="textMedium warning300 cutOverflow">{{ scope.row.address ?? '-' }}</span>
                    </template>
                    <template v-else>
                        <span class="textRegular neutral400 cutOverflow">-</span>
                    </template>

                </template>
            </el-table-column>
            <el-table-column
                v-if="columns.includes('driver')"
                prop="vehicle"
                label="Водитель"
            >
                <template v-slot="scope">

                    <span class="textRegular neutral400 cutOverflow">{{ scope.row.driver ?? '-' }}</span>

                </template>
            </el-table-column>
            <el-table-column
                v-if="columns.includes('clientTotal')"
                prop="clientTotal"
                label="Объём (З.)"
                width="120px"
            >
                <template v-slot="scope">
                    <template v-if="scope.row.total < scope.row.clientTotal">
                        <span class="textMedium textSM success400">{{ formatNumber(scope.row.clientTotal, 2) }} <good-unit
                            :unit="getData(scope.row.goodId, 'good','unit')"/></span>
                    </template>
                    <template v-else-if="scope.row.total >= scope.row.clientTotal">
                        <span class="textMedium textSM neutral900">{{ formatNumber(scope.row.clientTotal, 2) }} <good-unit
                            :unit="getData(scope.row.goodId, 'good','unit')"/></span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                prop="done"
                label="Объём"
                width="140px"
                fixed="right"
            >
                <template v-slot="scope">
                    <template v-if="scope.row.done < scope.row.total">
                        <span class="textMedium textSM neutral400">{{
                                scope.row.done ? formatNumber(scope.row.done, 2) : 0
                            }} <good-unit :padding-unit="true"
                                          :unit="scope.row.goodUnit ?? getData(scope.row.goodId, 'good','unit')"/> / {{
                                formatNumber(scope.row.total, 2)
                            }} <good-unit
                                :unit="scope.row.goodUnit ?? getData(scope.row.goodId, 'good','unit')"/></span>
                    </template>
                    <template v-else-if="scope.row.done >= scope.row.total">
                        <span class="textMedium textSM primary600">{{ formatNumber(scope.row.done, 2) }} <good-unit
                            :unit="scope.row.goodUnit ?? getData(scope.row.goodId, 'good','unit')"/></span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                label=""
                width="118px"
                fixed="right"
            >
                <template #header>
                    <el-popover
                        placement="bottom"
                        :width="200"
                        trigger="click"
                    >
                        <span class="textMedium textXS neutral900 d-block mb-10">Доп. столбцы</span>
                        <el-checkbox-group v-model="columns" size="small">
                            <el-checkbox v-for="(column, index) in columnsData" :key="index" :label="column.id">
                                {{ column.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                        <template #reference>
                            <div style="display: flex; align-items: center; justify-content: flex-end; gap: 10px">
                                <el-tooltip content="Настройка столбцов"
                                            placement="top">
                                    <div @click="openMixOrderDetails" class="settingsButton">
                                        <svg width="16" height="16" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.0013 13.3337C11.8423 13.3337 13.3346 11.8413 13.3346 10.0003C13.3346 8.15938 11.8423 6.66699 10.0013 6.66699C8.16035 6.66699 6.66797 8.15938 6.66797 10.0003C6.66797 11.8413 8.16035 13.3337 10.0013 13.3337Z"
                                                stroke="#49515F" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path
                                                d="M15.9727 6.61719L15.9179 6.52231C15.7667 6.26063 15.6895 5.96278 15.6946 5.66062L15.7196 4.17827C15.7247 3.87533 15.5649 3.59349 15.3024 3.44222L13.0219 2.12805C12.76 1.97715 12.437 1.97986 12.1777 2.13511L10.9118 2.8931C10.6531 3.04802 10.3571 3.12984 10.0556 3.12984H9.94583C9.64361 3.12984 9.34708 3.04766 9.08797 2.89211L7.81625 2.12863C7.55605 1.97242 7.2315 1.97002 6.96901 2.12236L4.69569 3.44177C4.43445 3.59339 4.27571 3.87451 4.28079 4.17653L4.30576 5.66062C4.31084 5.96278 4.23367 6.26063 4.08252 6.52231L4.02844 6.61592C3.8772 6.87774 3.65758 7.09349 3.39311 7.24005L2.09737 7.95811C1.83179 8.10528 1.66728 8.38526 1.66797 8.6889L1.67394 11.3154C1.67462 11.6172 1.83842 11.895 2.10215 12.0418L3.39167 12.7591C3.65704 12.9068 3.87704 13.1241 4.02789 13.3876L4.08583 13.4889C4.23486 13.7492 4.31085 14.045 4.30579 14.3449L4.28084 15.8225C4.27573 16.1255 4.43546 16.4074 4.69799 16.5586L6.97852 17.8728C7.24037 18.0237 7.5634 18.021 7.82269 17.8657L9.08859 17.1077C9.34732 16.9528 9.64323 16.871 9.94479 16.871H10.0545C10.3568 16.871 10.6533 16.9532 10.9124 17.1087L12.1841 17.8722C12.4443 18.0284 12.7689 18.0308 13.0314 17.8785L15.3047 16.5591C15.5659 16.4075 15.7247 16.1263 15.7196 15.8243L15.6946 14.3402C15.6895 14.0381 15.7667 13.7402 15.9179 13.4785L15.9719 13.3849C16.1232 13.1231 16.3428 12.9074 16.6073 12.7608L17.903 12.0427C18.1686 11.8956 18.3331 11.6156 18.3324 11.312L18.3264 8.68543C18.3258 8.38364 18.162 8.1058 17.8982 7.95909L16.6056 7.24C16.3422 7.09346 16.1234 6.87821 15.9727 6.61719Z"
                                                stroke="#49515F" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-popover>
                </template>
                <template v-slot="scope">
                    <el-button
                        size="small"
                        @click="openMixOrderDetails(scope.$index, scope.row)">Подробнее
                    </el-button>
                </template>
            </el-table-column>

        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>

    <el-dialog
        :title="orderEdit ? 'Редактирование отгрузки' + (orderEdit.doc ? ' #' + orderEdit.doc : '') : 'Создание отгрузки'"
        v-model="createMixOrderDrawer"
        :width="mobile ? '100%' : '60%'"
        direction="rtl"
        :size="drawerSize"
        @close="clearOrderEdit"
        :destroy-on-close="true">

        <MixPageOrderCreate
            :goods="goods"
            :doc="nextDoc"
            :order="orderEdit"
            :mixes="mixes"
            :dispatch="dispatch"
            @closeDrawer="closeMixOrderDrawer"
            @moduleGet="moduleGet"
        />

    </el-dialog>

    <el-drawer
        title=""
        v-model="orderDetailsDrawer"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <MixPageOrderDetails
            :order-id="orderDetails.id"
            :tz="tz"
            @closeDrawer="orderDetailsDrawer = false"
            @moduleGet="moduleGet"
            @openEdit="openEdit"
        />

    </el-drawer>
</template>

<script>
import TablePagination from '../../Table/TablePagination'
import Filter from '../../Table/Filter/Filter'
import TableHeadBlock from '../../Table/TableHeadBlock'
import tableMixin from '../../../../mixins/tableMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import MixPageOrderCreate from './MixPageOrderCreate'
import MixPageOrderDetails from './OrderDetails/MixPageOrderDetails'
import GoodUnit from '@/views/components/goodUnit'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableDate from '@/views/components/Mobile/Table/MobileTableDate'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import {mapState, mapStores} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import Truncate from "@/views/components/Truncate";
import EmptyState from "@/views/components/EmptyState.vue";
import mixTabs from "@/mixins/tabs/mixTabs";
import moment from "moment/moment";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import {useEchoStore} from "@/store/Echo";

export default {
    name: 'MixPageOrdersTable',
    props: [
        'tab',
        'tz',
        'noHeader',
        'date',
        'dispatch',
        'mixes',
        'needUpdate'
    ],
    components: {
        EmptyState,
        Truncate,
        MobileTableButton,
        MobileTableInfo,
        MobileTableDate,
        MobileTableFramework,
        GoodUnit,
        MixPageOrderDetails,
        MixPageOrderCreate,
        TablePagination,
        Filter,
        TableHeadBlock,
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin, getUnitFromGoodsMixin, iconsMixin, mixTabs, mixOrderInfo
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: true,
            loadOrders: false,
            createMixOrderDrawer: false,
            createMixOrderMixDialog: false,
            orderDetailsDrawer: false,
            orderEdit: null,
            orderDetails: {
                createMix: false,
            },
            nextDoc: null,
            tableData: [],
            filter: {
                dateStart: {
                    value: moment().startOf('day'),
                    type: 'dateStart'
                },
                dateEnd: {
                    value: moment().endOf('day'),
                    type: 'dateEnd'
                }
            },
            dateFilter: moment(),
            categories: [],
            firstLoad: true,
            columnsData: [{
                id: 'clientTotal',
                name: 'Объем для клиента'
            }, {
                id: 'driver',
                name: 'Водитель'
            }, {
                id: 'address',
                name: 'Адрес доставки'
            }, {
                id: 'type',
                name: 'Тип доставки'
            }, {
                id: 'doc',
                name: 'Номер на БСУ'
            }],
            columns: [],
            pageSize: 30
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        }),
        ...mapStores(useEchoStore),
    },
    created() {
        this.moduleGet()
        this.firstLoad = false
    },
    methods: {
        getGoodName(good) {
            // получаем или имя спецификации или товар из заказа или из отгрузки
            let goodName = null

            if (good.good) {
                goodName = good.good
            }

            if (!goodName && good.specGoodId) {
                goodName = this.getData(good.specGoodId, 'good')
            }

            if (!goodName && good.orderGoodId) {
                goodName = this.getData(good.orderGoodId, 'good')
            }
            return goodName
        },
        openEdit(order) {
            this.orderEdit = order
            this.orderDetailsDrawer = false
            this.createMixOrderDrawer = true
        },
        closeMixOrderDrawer() {
            this.createMixOrderDrawer = false
        },
        clearOrderEdit() {
            this.orderEdit = null
        },
        checkVehicleColumn() {
            let result = false
            if (this.tableData.find(el => el.vehicle)) result = true
            return result
        },
        getColor(order) {
            if (order.status === 'wait' || order.status === 'new' || !order.doc || order.type === 'manual') {
                return 'neutral300'
            } else if (order.status !== 'done') {
                return 'success400'
            } else {
                return 'primary600'
            }
        },
        async moduleGet() {
            if (!this.loadOrders) {
                this.loadOrders = true
                this.loading = true
                let moduleKey = this.$route.meta.moduleKey
                let url = this.api.mix.getOrders

                if (moduleKey === 'dispatch' || moduleKey === 'crm') {
                    url = this.api.dispatch.orders.index
                }

                try {
                    const response = await httpService().post(url, {
                        account_module_reserve_id: this.$route.params.id,
                        account_modules_id: this.$route.params.id,
                        module_key: moduleKey,
                        perPage: this.pageSize,
                        page: this.page,
                        search: this.search,
                        filter: this.filter,
                        date: this.date
                    });

                    let data = response.data;
                    if (data.success) {
                        this.values = data.values.data;
                        this.valueLength = data.values.total;
                        this.$emit('updateCountOrders', this.values)
                        this.loading = false;
                    }

                } catch (error) {
                    this.loading = false;
                    console.log(error);
                }

                this.getLastOrder();
                this.loadOrders = false
            }
        },
        getLastOrder() {
            if (this.values.length > 0) {
                var maxDoc = 0

                this.values.map(function (obj) {
                    if (obj.doc > maxDoc) maxDoc = obj.doc
                })
                this.nextDoc = ++maxDoc
            } else {
                this.nextDoc = 1
            }
        },
        openCreateMixOrderDrawer() {
            this.createMixOrderDrawer = true
        },
        openMixOrderDetails(index, row) {
            this.orderDetails = []
            this.orderDetailsDrawer = true
            this.orderDetails = row
        },
        openMixOrderCreateMix(index, row) {
            this.orderDetails = []
            this.createMixOrderMixDialog = true
            this.orderDetails = row
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        getSummaries(param) {
            const {columns} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }

                if (index === columns.length - 2) {
                    let totalDone = 0
                    let goodsIds = []

                    this.displayData.forEach(function (value) {
                        totalDone += parseFloat(value.done)
                        if (value.goodId) goodsIds.push(value.goodId)
                    })

                    goodsIds = goodsIds.filter((value, index, array) => array.indexOf(value) === index)

                    sums[index] = this.formatNumber(totalDone, 1) + ' ' + this.getUnitFromIds(goodsIds)
                    return
                }
            })

            return sums
        }
    },
    watch: {
        'tableData': {
            handler: function (value) {

                value.forEach((item) => {
                    this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderDelete.${item.id}`);
                    this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderUpdate.${item.id}`);

                    this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderDelete.${item.id}`)
                        .listen('.MixOrderDeleted', () => {
                            this.moduleGet()
                        })

                    this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderUpdate.${item.id}`)
                        .listen('.MixOrderUpdate', () => {
                            this.moduleGet()
                        });
                })
            },
            deep: true,
            immediate: false
        },
        'mixes': {
            handler: function () {
                this.mixes.forEach((mix) => {
                    this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderCreate.${mix.module_id}`);

                    this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderCreate.${mix.module_id}`)
                        .listen('.MixOrderCreate', () => {
                            this.moduleGet()
                        });
                })
            },
            deep: true,
            immediate: false
        },
        'date': {
            handler: function () {
                if (this.date) {
                    this.moduleGet()
                }
            },
            deep: true,
            immediate: true
        },
        'needUpdate': {
            handler: function () {
                this.moduleGet()
            },
            deep: false,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
