<template>
    <Auth title="Спецификации" :tabs="tabs">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    :new="true"
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    @moduleGet="moduleGet()"
                    create-button-label="Создать спецификацию"
                    @openCreateValueDrawer="setCreateDrawerOpen()"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="спецификаций"
                />

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile && displayData.length > 0"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="120"
                    />
                    <el-table-column
                        prop="name"
                        label="Название"
                    >
                        <template v-slot="scope">
                            <div class="textMedium primary600 cutOverflow">{{ scope.row.name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150"
                    >
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="$router.push({ name: 'GoodDetails', params: { id: scope.row.id } })">
                                Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

            </div>
        </div>

        <el-drawer
            v-model="createDrawerOpen"
            title="Новая спецификация"
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">

            <div style="padding: 20px">

                <el-form label-position="top">

                    <FormInput
                        v-model="form.name"
                        label="Название"
                        placeholder="Например, Спецификация 1"
                        :required="true"
                    />

                    <FormSelect
                        v-model="form.good_id"
                        label="Продукция"
                        placeholder="Выберите продукцию"
                        new-item="good"
                        :required="true"
                    />

                </el-form>

            </div>


            <el-button type="primary" class="w-100" @click="doCreate()">Создать спецификацию</el-button>

        </el-drawer>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import moment from "moment";
import {httpService} from "@/services/http.service";
import LabTabs from "@/mixins/tabs/labTabs";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";

export default {
    name: 'LabGoods',
    computed: {
        moment() {
            return moment
        }
    },
    mixins: [LabTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin],
    data() {
        return {
            loading: false,
            date: moment(),
            createDrawerOpen: false,
            form: {
                id: null,
                name: null,
                good_id: null
            }
        }
    },
    components: {
        FormSelect,
        FormInput,
        TablePagination, EmptyState,
        TableHeadBlock,
        Auth,
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.lab.getSpecGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        doCreate() {
            this.loading = true

            httpService().post(this.api.lab.createSpecGood, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Спецификация создана',
                        showClose: true,
                        type: 'success'
                    })
                    this.moduleGet()
                    this.createDrawerOpen = false
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        setCreateDrawerOpen() {
            this.createDrawerOpen = true
        },
        changeDate(date) {
            this.date = moment(date).utcOffset(0, true).startOf('day')
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
