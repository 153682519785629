import { useCommonStore } from '@/store/common'
import router from '..';

export default function moduleAvailable({next}) {
    const commonStore = useCommonStore()

    if (commonStore.getUser.id === 1) {
        return next();
    }

    router.push('/dashboard');
}
