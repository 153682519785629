<template>
    <div v-if="order">
        <p>
            <span class="textSM textRegular neutral400 mr-5">
                Тип отгрузки:
            </span>
            <span class="textSM textMedium neutral900 cutOverflow">
                {{ getTypeLabel(order.type) }}
            </span>
        </p>
        <p>
            <span class="textSM textRegular neutral400 mr-5">
                Статус:
            </span>
            <span class="textSM textMedium neutral900 cutOverflow">
                {{ getStatusLabel(order.status) }}
            </span>
        </p>
        <p>
            <span class="textSM textRegular neutral400 mr-5">
                Рецепт:
            </span>
            <span class="textSM textMedium neutral900 cutOverflow">
                {{ order.good ?? (order.specGood ?? order.orderGood) }}
            </span>
        </p>
        <p>
            <span class="textSM textRegular neutral400 mr-5">
                Контрагент:
            </span>
            <span class="textSM textMedium neutral900 cutOverflow">
                {{ order.company ?? '-' }}
            </span>
        </p>
        <p>
            <span class="textSM textRegular neutral400 mr-5">
                Транспортное средство:
            </span>
            <span class="textSM textMedium neutral900 cutOverflow">
                {{ order.vehicle ?? '-' }}
            </span>
        </p>
        <p>
            <span class="textSM textRegular neutral400 mr-5">
                Объем:
            </span>
            <span class="textSM textMedium neutral900 cutOverflow">
                {{ formatNumber(order.total, 2) }}  <good-unit :unit="order.goodUnit"/>
            </span>
        </p>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderStatuses from "@/mixins/mixOrderInfo";
import GoodUnit from "@/views/components/goodUnit.vue";
import mixOrderInfo from "@/mixins/mixOrderInfo";

export default {
    name: "OperatorOrderDetails",
    components: {GoodUnit},
    props: ['order'],
    mixins: [functionsMixin, mixOrderStatuses, mixOrderInfo]
}
</script>

<style scoped>

</style>
