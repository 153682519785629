import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Forgot from '../views/Forgot.vue'
import Password from '../views/Password.vue'
import Settings from '../views/settings/SettingsIndex'
import Dashboard from '../views/Dashboard'
import ModulesIndex from '../views/settings/modules/ModulesIndex'
import ModulesCreate from '../views/settings/modules/ModuleCreate'
import ModulesEdit from '../views/settings/modules/ModuleEdit'
import CashboxShow from '../views/cashboxes/CashboxShow'
import UsersIndex from '../views/settings/users/UsersIndex'
import UsersCreate from '../views/settings/users/UsersCreate'
import UsersEdit from '../views/settings/users/UsersEdit'
import GroupsIndex from '../views/settings/groups/GroupsIndex'
import GoodsIndex from '../views/settings/goods/GoodsIndex'
import ContractIndex from '../views/contract/ContractIndex'
import ContractCreate from '../views/contract/ContractCreate'
import ContractShow from '../views/contract/ContractShow'
import WeighingShow from '../views/weighing/WeighingShow'
import StorehouseIndex from '../views/storehouse/StorehouseIndex'
import Template from '../views/Template'
import OrderIndex from '../views/order/OrderIndex'
import OrderCreate from '../views/order/OrderCreate'
import PaymentIndex from '../views/settings/payment/PaymentIndex'
import CompaniesIndex from '../views/settings/companies/CompaniesIndex'
import CompanyEdit from '../views/settings/companies/CompanyEdit'
import CompanyDetails from "@/views/settings/companies/CompanyDetails.vue";
import SettingsVehicleIndex from '../views/settings/vehicle/SettingsVehicleIndex'
import FaqIndex from '../views/faq/FaqIndex'
import RelationsIndex from '../views/settings/relations/RelationsIndex'
import RelationEdit from '../views/settings/relations/RelationEdit'
import RelationCreate from '../views/settings/relations/RelationCreate'
import IntegrationsIndex from '../views/settings/integrations/IntegrationsIndex'
import IntegrationCreate from '../views/settings/integrations/IntegrationCreate'
import GodModeIndex from '../views/godmode/GodModeIndex'
import TTSIndex from '../views/tts/TTSIndex'
import MixIndex from '../views/mix/MixIndex'
import FactoriesIndex from '@/views/settings/factories/FactoriesIndex'
import Setup from '@/views/layouts/Setup'
import EmailReportSettings from '@/views/settings/emailReport/EmailReportSettings'
import ReportIndex from '@/views/report/ReportIndex'
import auth from './middleware/auth'
import guest from './middleware/guest'
import setup from './middleware/setup'
import {API_ROUTES} from '@/const'
import {httpService} from '@/services/http.service'
import TechServiceCreateWork from "@/views/tech/TechServiceCreateWork";
import TechServiceDetails from "@/views/tech/TechServiceDetails";
import TechGarageDetails from "@/views/tech/TechGarageDetails";
import FaqView from "@/views/faq/FaqView";
import CustomShow from '@/views/custom/CustomShow'
import LogisticsIndex from '@/views/logistics/LogisticsIndex'
import GoodDetails from '@/views/settings/goods/GoodDetails.vue'
import PaymentPlansIndex from "@/views/settings/payment/PaymentPlansIndex.vue";
import {useCommonStore} from "@/store/common";
import middlewarePipeline from "./middleware/middlewarePipeline";
import adminAvailable from "./middleware/adminAvailable";
import moduleIdAvailable from "./middleware/moduleIdAvailable";
import moduleAvailable from "./middleware/moduleAvailable";
import analyticsAvailable from "./middleware/analyticsAvailable";
import reportsAvailable from "./middleware/reportsAvailable";
import AnalyticsIndex from '@/views/analytics/AnalyticsIndex'
import AnalyticsModule from '@/views/analytics/AnalyticsModule.vue'
import NotificationsIndex from '../views/notifications/NotificationsIndex.vue'
import FuelIndex from '@/views/fuel/FuelIndex.vue'
import MixPageOrderGroupCreate from '@/views/components/MixPage/OrdersGroup/MixPageOrderGroupCreate.vue'
import MixDriveTaskDetail from '../views/components/Dashboards/MixDriveTaskDetail.vue'
import SettingsVehicleDetails from '@/views/settings/vehicle/SettingsVehicleDetails.vue'
import ProductionGoodsCatalogCreate
    from '@/views/components/ProductionPage/GoodsCatalog/ProductionGoodsCatalogCreate.vue'
import SettingsTiresIndex from '@/views/settings/tires/SettingsTiresIndex.vue'
import MixPageOrdersGroup from "@/views/components/MixPage/OrdersGroup/MixPageOrdersGroup.vue";
import MixPageOrdersGroupOrders from "@/views/components/MixPage/OrdersGroup/MixPageOrdersGroupOrders.vue";
import MixPageOrdersGroupMixes from "@/views/components/MixPage/OrdersGroup/MixPageOrdersGroupMixes.vue";
import MixPageInfo from "@/views/components/MixPage/Info/MixPageInfo.vue";
import MixPageGoods from "@/views/components/MixPage/Goods/MixPageGoods.vue";
import ProductionOrdersIndex from "@/views/components/ProductionPage/Orders/ProductionOrdersIndex.vue";
import ProductionValuesTab from "@/views/components/ProductionPage/Values/ProductionValuesTab.vue";
import ProductionControlIndex from "@/views/components/ProductionPage/Control/ProductionControlIndex.vue";
import ProductionReportsIndex from "@/views/components/ProductionPage/Reports/ProductionReportsIndex.vue";
import ProductionShiftsTab from "@/views/components/ProductionPage/Shifts/ProductionShiftsTab.vue";
import ProductionIndex from "@/views/production/ProductionIndex.vue";
import ProductionGoodsCatalogIndex
    from "@/views/components/ProductionPage/GoodsCatalog/ProductionGoodsCatalogIndex.vue";
import TechGarageTab from "@/views/components/TechPage/Garage/TechGarageTab.vue";
import TechServiceTab from "@/views/components/TechPage/Service/TechServiceTab.vue";
import TechStoreTab from "@/views/components/TechPage/Store/TechStoreTab.vue";
import ReportGoods from "@/views/components/ReportPage/ReportGoods.vue";
import ReportVehicles from "@/views/components/ReportPage/ReportVehicles.vue";
import ReportMixDrivers from "@/views/components/ReportPage/ReportMixDrivers.vue";
import ReportWeighingValues from "@/views/components/ReportPage/ReportWeighingValues.vue";
import ReportMixGroupOrders from "@/views/components/ReportPage/ReportMixGroupOrders.vue";
import ReportMixOrders from "@/views/components/ReportPage/ReportMixOrders.vue";
import ReportProductionGoods from "@/views/components/ReportPage/ReportProductionGoods.vue";
import MixOrderOnePage from "@/views/mix/MixOrderOnePage.vue";
import DispatchIndex from "@/views/dispatch/DispatchIndex.vue";
import TechIndex from "@/views/tech/TechIndex.vue";
import TechTiresIndex from "@/views/tech/TechTiresIndex.vue";
import UsersLogs from "@/views/settings/users/UsersLogs.vue";
import DispatchOrders from "@/views/dispatch/DispatchOrders.vue";
import DispatchOrderGroups from "@/views/dispatch/DispatchOrderGroups.vue";
import DistributeOrderGroup from "@/views/dispatch/DistributeOrderGroup.vue";
import DispatchDistributeOrderGroup from "@/views/dispatch/DispatchDistributeOrderGroup.vue";
import CRMDistributeOrderGroup from "@/views/crm/CRMDistributeOrderGroup.vue";
import DispatchOrderGroupsCreate from "@/views/dispatch/DispatchOrderGroupsCreate.vue";
import DispatchOrderGroupsUpdate from "@/views/dispatch/DispatchOrderGroupsUpdate.vue";
import CRMOrderGroupsUpdate from "@/views/crm/CRMOrderGroupsUpdate.vue"
import DispatchMixers from "@/views/dispatch/DispatchMixers.vue";
import DispatchMixes from "@/views/dispatch/DispatchMixes.vue";
import CRMIndex from "@/views/crm/CRMIndex.vue";
import LabIndex from "@/views/lab/LabIndex.vue";
import CRMOrders from "@/views/crm/CRMOrders.vue";
import CRMTasks from "@/views/crm/CRMTasks.vue";
import CRMContracts from "@/views/crm/CRMContracts.vue";
import CRMCompanies from "@/views/crm/CRMCompanies.vue";
import LabOrders from "@/views/lab/LabOrders.vue";
import LabGoods from "@/views/lab/LabGoods.vue";
import CRMOrderCreate from "@/views/crm/CRMOrderCreate.vue";
import CRMOrderDetails from "@/views/crm/CRMOrderDetails.vue";
import DocumentsIndex from "@/views/settings/documents/DocumentsIndex.vue";
import DispatchOrderGroupsDetails from "@/views/dispatch/DispatchOrderGroupsDetails.vue";
import ServiceTariffsIndex from "@/views/settings/serviceTariffs/ServiceTariffsIndex.vue";
import AccountSettingsIndex from "@/views/settings/settings/AccountSettingsIndex.vue";
import ProductionPostsIndex from "@/views/components/ProductionPage/Posts/ProductionPostsIndex.vue";
import MixPageOrdersNew from "@/views/components/MixPage/Orders/MixPageOrdersNew.vue";

const routes = [
    {
        path: '/',
        name: 'index',
        redirect: {
            name: 'login'
        },
        meta: {
            title: 'Авторизация'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: [
                guest
            ],
            title: 'Авторизация',
        }
    },
    {
        path: '/demo',
        name: 'Demo',
        component: Login,
        meta: {
            middleware: [
                guest
            ],
            title: 'Авторизация',
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            middleware: [
                guest
            ],
            title: 'Регистрация',
        }
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: Forgot,
        meta: {
            middleware: [
                guest
            ],
            title: 'Восстановление пароля',
        }
    },
    {
        path: '/password',
        name: 'password',
        component: Password,
        meta: {
            middleware: [
                guest
            ],
            title: 'Восстановление пароля',
        }
    },
    {
        path: '/verify',
        name: 'verify',
        component: {
            beforeRouteEnter(to, from, next) {
                httpService().post(API_ROUTES.account.verify, {
                    token: to.query.token
                }).then((response) => {
                    let data = response.data

                    alert(data.message)
                    router.push('/')
                    return next({
                        name: '/'
                    })
                })
            }
        },
        meta: {
            middleware: [
                auth
            ],
            title: 'Подтверждение',
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: [
                auth
            ],
            title: 'Цифра',
        }
    },
    {
        path: '/setup',
        name: 'Setup',
        component: Setup,
        meta: {
            middleware: [
                setup
            ],
            title: 'Цифра',
        }
    },
    {
        path: '/settings',
        name: 'SettingsIndex',
        component: Template,
        meta: {
            title: 'Настройки',
        },

        children: [
            {
                path: '',
                name: 'Settings',
                component: Settings,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Настройки',
                }
            },
            {
                path: 'companies',
                name: 'CompaniesIndex',
                component: CompaniesIndex,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Компании',
                }
            },
            {
                path: 'company/create',
                name: 'CompanyCreate',
                component: CompanyEdit,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Создание компании',
                }
            },
            {
                path: 'company/edit/:id',
                name: 'CompanyEdit',
                component: CompanyDetails,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Редактирование компании',
                }
            },
            {
                path: 'documents',
                name: 'DocumentsIndex',
                component: DocumentsIndex,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Документы',
                }
            },
            {
                path: 'service-tariffs',
                name: 'ServiceTariffs',
                component: ServiceTariffsIndex,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Тарифы услуг',
                }
            },
            {
                path: 'payment',
                name: 'Payment',
                component: Template,
                meta: {
                    title: 'Оплата',
                },
                children: [
                    {
                        path: '',
                        name: 'PaymentIndex',
                        component: PaymentIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Оплата',
                        }
                    },
                    {
                        path: 'plans',
                        name: 'PlansIndex',
                        component: PaymentPlansIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Тарифы',
                        }
                    },
                ]
            },
            {
                path: 'factories',
                name: 'FactoriesIndex',
                component: FactoriesIndex,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Объекты',
                }
            },
            {
                path: 'apps',
                name: 'AccountSettingsIndex',
                component: AccountSettingsIndex,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Настройки приложений',
                }
            },
            {
                path: 'email-report',
                name: 'EmailReportSettingsIndex',
                component: EmailReportSettings,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Отчет на почту',
                }
            },
            {
                path: 'goods',
                name: 'goodsIndex',
                component: Template,
                meta: {
                    title: 'Товары и грузы',
                },
                children: [
                    {
                        path: '',
                        name: 'goods',
                        component: GoodsIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Товары и грузы',
                        }
                    },
                    {
                        path: ':id',
                        name: 'GoodDetails',
                        component: GoodDetails,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Подробности товара',
                        }
                    },
                    {
                        path: ':module/:key',
                        name: 'FaqViewQuestion',
                        component: FaqView,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Справка',
                        }
                    },
                ],
            },
            {
                path: 'vehicles',
                name: 'SettingsVehicleIndex',
                component: Template,
                meta: {
                    title: 'Единицы техники',
                },
                children: [
                    {
                        path: '',
                        name: 'vehicles',
                        component: SettingsVehicleIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Единицы техники',
                        },
                    },
                    {
                        path: ':id',
                        name: 'VehicleDetails',
                        component: SettingsVehicleDetails,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Редактирование единицы техники',
                        }
                    },
                ]
            },
            {
                path: 'tires',
                name: 'SettingsTiresIndex',
                component: Template,
                meta: {
                    title: 'Шины',
                },
                children: [
                    {
                        path: '',
                        name: 'TiresIndex',
                        component: SettingsTiresIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Шины',
                        },
                    },
                    {
                        path: ':id',
                        name: 'TireDetails',
                        component: SettingsVehicleDetails,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Шина',
                        }
                    },
                ]
            },
            {
                path: 'relations',
                name: 'relations.index',
                component: Template,
                meta: {
                    title: 'Автоматизация'
                },
                children: [
                    {
                        path: '',
                        name: 'RelationsIndex',
                        component: RelationsIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Автоматизация',
                        }
                    },
                    {
                        path: 'create',
                        name: 'RelationCreate',
                        component: RelationCreate,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Создание автоматизации',
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'RelationEdit',
                        component: RelationEdit,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Настройки автоматизации',
                        }
                    },
                ],
            },
            {
                path: 'integrations',
                name: 'integrations.index',
                component: Template,
                meta: {
                    title: 'Интеграции'
                },
                children: [
                    {
                        path: '',
                        name: 'IntegrationsIndex',
                        component: IntegrationsIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Интеграции',
                        }
                    },
                    {
                        path: 'create',
                        name: 'IntegrationCreate',
                        component: IntegrationCreate,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Создание интеграции',
                        }
                    },
                ],
            },
            {
                path: 'modules',
                name: 'modules.index',
                component: Template,
                meta: {
                    title: 'Модули'
                },

                children: [
                    {
                        path: '',
                        name: 'ModulesIndex',
                        component: ModulesIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Модули',
                        }
                    },
                    {
                        path: 'create',
                        name: 'ModuleCreate',
                        component: ModulesCreate,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Создание модуля',
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'ModuleEdit',
                        component: ModulesEdit,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Настройки модуля',
                        }
                    },
                ],
            },
            {
                path: 'users',
                name: 'users.index',
                component: Template,
                meta: {
                    title: 'Пользователи'
                },
                children: [
                    {
                        path: '',
                        name: 'UsersIndex',
                        component: UsersIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Список',
                        }
                    },
                    {
                        path: 'logs',
                        name: 'UsersLogs',
                        component: UsersLogs,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Действия пользователей',
                        }
                    },
                    {
                        path: 'create',
                        name: 'UsersCreate',
                        component: UsersCreate,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Приглашение пользователя',
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'UsersEdit',
                        component: UsersEdit,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Редактирование пользователя',
                        }
                    },
                    {
                        path: 'groups',
                        name: 'GroupsIndex',
                        component: GroupsIndex,
                        meta: {
                            middleware: [
                                auth
                            ],
                            title: 'Группы пользователей',
                        }
                    },
                ],
            },
        ],
    },
    {
        path: '/production/:id',
        name: 'ProductionIndex',
        component: Template,

        children: [
            {
                path: '',
                name: 'ProductionIndex',
                component: ProductionIndex,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Производство',
                }
            },
            {
                path: 'orders',
                name: 'ProductionOrders',
                component: ProductionOrdersIndex,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Заказы',
                }
            },
            {
                path: 'values',
                name: 'ProductionValues',
                component: ProductionValuesTab,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Произведено',
                }
            },
            {
                path: 'control',
                name: 'ProductionControl',
                component: ProductionControlIndex,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Контроль качества',
                }
            },
            {
                path: 'reports',
                name: 'ProductionReport',
                component: ProductionReportsIndex,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Отчеты о работе',
                }
            },
            {
                path: 'shifts',
                name: 'ProductionShifts',
                component: ProductionShiftsTab,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Смены',
                }
            },
            {
                path: 'goods',
                name: 'ProductionGoods',
                component: ProductionGoodsCatalogIndex,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Продукция',
                }
            },
            {
                path: 'posts',
                name: 'ProductionPosts',
                component: ProductionPostsIndex,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Посты',
                }
            },
            {
                path: 'create/good',
                name: 'ProductionCreateGood',
                component: ProductionGoodsCatalogCreate,
                meta: {
                    moduleKey: 'production',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Добавление продукции',
                }
            },
        ],
    },
    {
        path: '/order/:id',
        name: 'OrderIndex',
        component: Template,

        children: [
            {
                path: '',
                name: 'order.index',
                component: OrderIndex,
                meta: {
                    moduleKey: 'order',
                    middleware: [
                        auth
                    ],
                    title: 'Заказы',
                }
            },
            {
                path: 'create',
                name: 'orderCreate',
                component: OrderCreate,
                meta: {
                    moduleKey: 'order',
                    middleware: [
                        auth
                    ],
                    title: 'Новый заказ',
                }
            },
        ],
    },
    {
        path: '/tech/:id',
        name: 'TechIndex',
        component: Template,

        children: [
            {
                path: '',
                name: 'tech.index',
                component: TechIndex,
                meta: {
                    moduleKey: 'tech',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Техника',
                }
            },
            {
                path: 'vehicles',
                name: 'techVehiclesIndex',
                component: Template,

                children: [
                    {
                        path: '',
                        name: 'techVehicles',
                        component: TechGarageTab,
                        meta: {
                            moduleKey: 'tech',
                            middleware: [
                                auth,
                                moduleAvailable
                            ],
                            title: 'Техника',
                        }
                    },
                    {
                        path: ':vehicleId',
                        name: 'garageDetails',
                        component: TechGarageDetails,
                        meta: {
                            moduleKey: 'tech',
                            middleware: [
                                auth,
                                moduleAvailable
                            ],
                            title: 'Подробности техники',
                        }
                    },
                ]
            },
            {
                path: 'service',
                name: 'techServiceIndex',
                component: Template,

                children: [
                    {
                        path: '',
                        name: 'techService',
                        component: TechServiceTab,
                        meta: {
                            moduleKey: 'tech',
                            middleware: [
                                auth,
                                moduleAvailable
                            ],
                            title: 'Сервис',
                        }
                    },
                    {
                        path: ':serviceId',
                        name: 'serviceDetails',
                        component: TechServiceDetails,
                        meta: {
                            moduleKey: 'tech',
                            middleware: [
                                auth,
                                moduleAvailable
                            ],
                            title: 'Подробности заказ-наряда',
                        }
                    },
                    {
                        path: 'createService',
                        name: 'techCreateService',
                        component: TechServiceCreateWork,
                        meta: {
                            moduleKey: 'tech',
                            middleware: [
                                auth,
                                moduleAvailable
                            ],
                            title: 'Новый заказ-наряд',
                        }
                    },
                ]
            },
            {
                path: 'goods',
                name: 'techGoods',
                component: TechStoreTab,
                meta: {
                    moduleKey: 'tech',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Склад запчастей',
                }
            },
            {
                path: 'tires',
                name: 'techTires',
                component: TechTiresIndex,
                meta: {
                    moduleKey: 'tech',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Шины',
                }
            },
        ],
    },
    {
        path: '/fuel/:id',
        name: 'FuelIndex',
        component: Template,

        children: [
            {
                path: '',
                name: 'Fuel',
                component: FuelIndex,
                meta: {
                    moduleKey: 'fuel',
                    middleware: [
                        auth
                    ],
                    title: 'Учет ГСМ',
                }
            }
        ],
    },
    {
        path: '/cashbox/:id',
        name: 'cashbox.show',
        component: CashboxShow,
        meta: {
            moduleKey: 'cashbox',
            middleware: [
                auth,
                moduleAvailable
            ],
            title: 'Денежный учет',
        }
    },
    {
        path: '/custom/:key/:id',
        name: 'customShow',
        component: CustomShow,
        meta: {
            moduleKey: 'custom',
            middleware: [
                auth
            ],
            title: 'Модуль',
        }
    },
    {
        path: '/contract/:id',
        name: 'contract.index',
        component: Template,

        children: [
            {
                path: '',
                name: 'ContractIndex',
                component: ContractIndex,
                meta: {
                    moduleKey: 'contract',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Контракт',
                }
            },
            {
                path: 'create',
                name: 'contractCreate',
                component: ContractCreate,
                meta: {
                    moduleKey: 'contract',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Создание контракта',
                }
            },
            {
                path: ':unitId',
                name: 'contractShow',
                component: ContractShow,
                meta: {
                    moduleKey: 'contract',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Подробности контракта',
                }
            },
        ],
    },
    {
        path: '/logistics/:id',
        name: 'logistics.index',
        component: Template,

        children: [
            {
                path: '',
                name: 'LogisticsIndex',
                component: LogisticsIndex,
                meta: {
                    moduleKey: 'logistics',
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    title: 'Логистика',
                }
            },
        ],
    },
    {
        path: '/mix/:id',
        name: 'mix.index',
        component: Template,
        meta: {
            title: 'Бетонный завод',
        },

        children: [
            {
                path: '',
                name: 'MixIndex',
                component: MixIndex,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                }
            },
            {
                path: 'orders',
                name: 'MixOrders',
                component: MixPageOrdersNew,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Отгрузки',
                }
            },
            {
                path: 'orders/:orderId',
                name: 'MixOrderOnePage',
                component: MixOrderOnePage,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Отгрузка',
                }
            },
            {
                path: 'groups',
                name: 'MixOrdersGroups',
                component: MixPageOrdersGroup,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Заказы',
                }
            },
            {
                path: 'group-orders',
                name: 'MixOrdersGroupsOrders',
                component: MixPageOrdersGroupOrders,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Отгрузки',
                }
            },
            {
                path: 'orders-mixers',
                name: 'MixOrdersMixers',
                component: MixPageOrdersGroupMixes,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Миксера',
                }
            },
            {
                path: 'mixers',
                name: 'MixMixers',
                component: MixPageOrdersGroupMixes,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Миксера',
                }
            },
            {
                path: 'goods',
                name: 'MixGoods',
                component: MixPageGoods,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Рецепты',
                }
            },
            {
                path: 'group-goods',
                name: 'MixGroupGoods',
                component: MixPageGoods,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Рецепты',
                }
            },
            {
                path: 'info',
                name: 'MixInfo',
                component: MixPageInfo,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Сводка',
                }
            },
            {
                path: 'createOrder',
                name: 'MixOrderGroupCreate',
                component: MixPageOrderGroupCreate,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Создание заказа',
                }
            },
            {
                path: 'editOrder/:orderId',
                name: 'MixOrderGroupEdit',
                component: MixPageOrderGroupCreate,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'mix',
                    title: 'Редактирование заказа',
                }
            },
        ],
    },
    {
        path: '/dispatch/:id',
        name: 'dispatch.index',
        component: Template,
        meta: {
            title: 'Диспетчеризация',
        },
        children: [
            {
                path: '',
                name: 'DispatchIndex',
                component: DispatchIndex,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'groups',
                name: 'DispatchOrderGroups',
                component: DispatchOrderGroups,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'groups/:groupId/distribute',
                name: 'DistributeOrderGroup',
                component: DistributeOrderGroup,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'groups/create/:change?',
                name: 'DispatchOrderGroupsCreate',
                component: DispatchOrderGroupsCreate,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'groups/:groupId/update',
                name: 'DispatchOrderGroupsUpdate',
                component: DispatchOrderGroupsUpdate,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'groups/:groupId/details',
                name: 'DispatchOrderGroupsDetails',
                component: DispatchOrderGroupsDetails,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'orders',
                name: 'DispatchOrders',
                component: DispatchOrders,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'posts',
                name: 'DispatchPosts',
                component: DispatchMixers,
                meta: {
                    title: 'Посты',
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'mixers',
                name: 'DispatchMixers',
                component: DispatchMixers,
                meta: {
                    title: 'Миксеры',
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                }
            },
            {
                path: 'pumps',
                name: 'DispatchPumps',
                component: DispatchMixers,
                meta: {
                    title: 'Насосы',
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                    type: 'pumps'
                }
            },
            {
                path: 'mixes',
                name: 'DispatchMixes',
                component: DispatchMixes,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'dispatch',
                    type: 'mixes'
                }
            },
            {
                path: 'orders/:hash/distribute',
                name: 'DispatchDistributeOrderGroup',
                component: DispatchDistributeOrderGroup,
                meta: {
                    middleware: [
                        auth
                    ],
                    moduleKey: 'dispatch',
                }
            },
        ],
    },
    {
        path: '/crm/:id',
        name: 'crm.index',
        component: Template,
        meta: {
            title: 'CRM',
        },

        children: [
            {
                path: '',
                name: 'CRMIndex',
                component: CRMIndex,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                }
            },
            {
                path: 'orders',
                name: 'CRMOrders',
                component: CRMOrders,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                    title: 'Заказы',
                }
            },
            {
                path: 'orders/create/:change?',
                name: 'CRMOrderCreate',
                component: CRMOrderCreate,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                }
            },
            {
                path: 'orders/:groupId',
                name: 'CRMOrderDetails',
                component: CRMOrderDetails,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                }
            },
            {
                path: 'orders/:hash/distribute',
                name: 'CRMDistributeOrderGroup',
                component: CRMDistributeOrderGroup,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                }
            },
            {
                path: 'tasks',
                name: 'CRMTasks',
                component: CRMTasks,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                    title: 'Задачи',
                }
            },
            {
                path: 'contracts',
                name: 'CRMContracts',
                component: CRMContracts,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                    title: 'Договора',
                }
            },
            {
                path: 'groups/:groupId/update',
                name: 'CRMOrderGroupsUpdate',
                component: CRMOrderGroupsUpdate,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                }
            },
            {
                path: 'companies',
                name: 'CRMCompanies',
                component: CRMCompanies,
                meta: {
                    middleware: [
                        auth,
                        moduleIdAvailable
                    ],
                    moduleKey: 'crm',
                    title: 'Контрагенты',
                }
            }
        ],
    },
    {
        path: '/lab/:id',
        name: 'lab.index',
        component: Template,
        meta: {
            title: 'Лаборатория',
        },

        children: [
            {
                path: '',
                name: 'LabIndex',
                component: LabIndex,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'lab',
                }
            },
            {
                path: 'orders',
                name: 'LabOrders',
                component: LabOrders,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'lab',
                    title: 'Заказы',
                }
            },
            {
                path: 'goods',
                name: 'LabGoods',
                component: LabGoods,
                meta: {
                    middleware: [
                        auth,
                        moduleAvailable
                    ],
                    moduleKey: 'lab',
                    title: 'Спецификации',
                }
            },
        ],
    },
    {
        path: '/weighing/:id',
        name: 'WeighingShow',
        component: WeighingShow,
        meta: {
            moduleKey: 'weighing',
            middleware: [
                auth,
                moduleAvailable
            ],
            title: 'Весовой терминал',
        }
    },
    {
        path: '/storehouse/:id',
        name: 'StorehouseIndex',
        component: StorehouseIndex,
        meta: {
            moduleKey: 'storehouse',
            middleware: [
                auth,
                moduleAvailable
            ],
            title: 'Складской учет',
        }
    },
    {
        path: '/godmode/:id',
        name: 'GodModeIndex',
        component: GodModeIndex,
        meta: {
            moduleKey: 'godmode',
            middleware: [
                auth,
                adminAvailable
            ],
            title: 'Управление',
        }
    },
    {
        path: '/tts/:id',
        name: 'TTSControlIndex',
        component: TTSIndex,
        meta: {
            moduleKey: 'tts',
            middleware: [
                auth,
                moduleAvailable
            ],
            title: 'ТТС.Контроль',
        }
    },
    {
        path: '/faq/',
        name: 'Faq',
        component: Template,

        children: [
            {
                path: '',
                name: 'FaqIndex',
                component: FaqIndex,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Справка',
                }
            },
            {
                path: ':module',
                name: 'FaqView',
                component: FaqView,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Справка',
                }
            },
            {
                path: ':module/:key',
                name: 'FaqViewQuestion',
                component: FaqView,
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Справка',
                }
            },
        ],
    },
    {
        path: '/notifications/',
        name: 'NotificationsIndex',
        component: NotificationsIndex,
        meta: {
            middleware: [
                auth
            ],
            title: 'Уведомления',
        }
    },
    {
        path: '/report/',
        name: 'report',
        component: Template,
        meta: {
            title: 'Отчеты',
        },

        children: [
            {
                path: '',
                name: 'ReportIndex',
                component: ReportIndex,
                meta: {
                    key: 'index',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    // title: 'Отчеты',
                },
            },
            {
                path: 'goods',
                name: 'ReportGoods',
                component: ReportGoods,
                meta: {
                    key: 'goods',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по расходу сырья'
                }
            },
            {
                path: 'drivers',
                name: 'ReportMixDrivers',
                component: ReportMixDrivers,
                meta: {
                    key: 'mixDrivers',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по работе водителей'
                }
            },
            {
                path: 'vehicles',
                name: 'ReportVehicles',
                component: ReportVehicles,
                meta: {
                    key: 'mixVehicles',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по автомобилям'
                }
            },
            {
                path: 'weight',
                name: 'ReportWeightValues',
                component: ReportWeighingValues,
                meta: {
                    key: 'weighingValues',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по взвешиваниям'
                }
            },
            {
                path: 'mix-group-orders',
                name: 'ReportMixGroupOrders',
                component: ReportMixGroupOrders,
                meta: {
                    key: 'mixGroupOrders',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по заказам',
                }
            },
            {
                path: 'mix-orders',
                name: 'ReportMixOrders',
                component: ReportMixOrders,
                meta: {
                    key: 'mixOrders',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по отгрузкам'
                }
            },
            {
                path: 'production-goods',
                name: 'ReportProductionGoods',
                component: ReportProductionGoods,
                meta: {
                    key: 'productionGoods',
                    middleware: [
                        auth,
                        reportsAvailable
                    ],
                    title: 'Отчет по продукции'
                }
            }
        ]
    },
    {
        path: '/analytics/',
        name: 'Analytics',
        component: Template,

        children: [
            {
                path: '',
                name: 'AnalyticsIndex',
                component: AnalyticsIndex,
                meta: {
                    middleware: [
                        auth,
                        analyticsAvailable
                    ],
                    title: 'Аналитика',
                }
            },
            {
                path: ':moduleKey/:moduleId',
                name: 'AnalyticsModule',
                component: AnalyticsModule,
                meta: {
                    middleware: [
                        auth,
                        analyticsAvailable
                    ],
                    title: 'Аналитика',
                }
            },
        ],
    },
    {
        path: '/tasks/:id',
        name: 'tasks.show',
        component: MixDriveTaskDetail,
        meta: {
            middleware: [
                auth
            ],
            title: 'Отгрузка',
        }
    }
]

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    document.body.style.overflow = 'unset';
    const commonStore = useCommonStore();
    commonStore.setNotAvailableModuleAlert(false);
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
