<template>
    <TableHeadBlockMixer
        v-if="!orderCreateView"
        :loading="loading"
        :getDate="getDate"
        :filters="filters"
        :showDate="switcher"
        :editView="editView"
        @changeDate="changeDate"
        @moduleGet="getMixersShift()"
    >
        <template #switchBlock>
            <div class="d-flex switcher textSM textMedium neutral500">
                <div :class="switcher ? 'active' : ''" @click="toggleSwitch(true)">На день</div>
                <div :class="switcher ? '' : 'active'" @click="toggleSwitch(false)">По умолчанию</div>
            </div>
        </template>
        <template #scheduleDefault v-if="getFilteredDisplayData().length === 0 && !editView">
            <img :src="require('@/assets/warning-2.svg')" class="order-groups__icon mr-5"/>
            <span class="warning300 textSM textRegular400">График по умолчанию</span>
        </template>
        <template #buttonsBlock>
            <button
                v-if="!editView"
                @click="setEditView()"
                class="ci-btn ci-btn_blue br-8"
                :disabled="disableEdit"
            >
                Редактировать
            </button>
            <button
                v-if="editView"
                @click="setCreateMixerDrawer()"
                class="ci-btn ci-btn_light br-8"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                        d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                        stroke="#1984e6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path d="M5.3335 8.5H10.6668" stroke="#1984e6" stroke-width="1.2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M8 11.1666L8 5.83325" stroke="#1984e6" stroke-width="1.2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
                Добавить авто
            </button>
        </template>
    </TableHeadBlockMixer>

    <EmptyState
        :values="getFilteredDisplayData().length > 0 ? getFilteredDisplayData() : defaultMixers"
        :filter="filter"
        :search="search"
        :loading="loading"
        :list-types="type === 'mix' ? 'миксеров' : 'насосов'"
        action-text="Добавьте ТС в график работы"
    />

    <el-table
        v-loading="loading"
        :data="getFilteredDisplayData().length > 0 ? getFilteredDisplayData() : defaultMixers"
        v-if="!mobile && getFilteredDisplayData().length > 0 ? getFilteredDisplayData() : defaultMixers.length"
        class="w-100 mt-15 mb-15">
        <el-table-column
            prop="selected"
            label=""
            :width="(orderCreateView && type === 'mix') ? 40 : 1"
        >
            <template v-slot="scope">
                <el-checkbox
                    v-show="(orderCreateView && type === 'mix')"
                    v-model="scope.row.selected"
                    @change="selectMixers"
                />
            </template>
        </el-table-column>

        <el-table-column
            prop="active"
            label=""
            :width="editView ? 40 : 1"
        >
            <template v-slot="scope">
                <el-checkbox v-show="editView && switcher" v-model="scope.row.active"/>
            </template>
        </el-table-column>

        <el-table-column
            prop="rent"
            label=""
            width="36"
        >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.rent ? 'Арендованное ТС' : 'Собственное ТС'"
                    placement="top"
                >
                    <span :class="'textSM textMedium ' + ( scope.row.rent ? 'neutral500' : 'neutral100' )">А</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column
            prop="gidrolotok"
            label=""
            width="36"
            v-if="type === 'mix'"
        >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'"
                    placement="top"
                >
                    <span
                        :class="'textSM textMedium ' + ( scope.row.gidrolotok ? 'primary500' : 'neutral100' )">Г</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column
            prop="number"
            label="Номер"
            width="140"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ scope.row.number }}</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="driverId"
            label="Водитель"
        >
            <template v-slot="scope">
                <template v-if="!editView">
                    <span class="textSM textRegular400 neutral900">
                        {{ scope.row.driver ? scope.row.driver?.name : (getDriverName(scope.row.driverId) ?? '-') }}
                    </span>
                </template>
                <template v-if="editView">
                    <el-button :disabled="!scope.row.active" @click="openChangeDriverDialog(scope.row)"
                               class="p-0" style="height: auto !important;" type="text">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             style="position: relative;top: -1px;margin-right: 5px;">
                            <path d="M2.66602 13.3334H13.3327" stroke="#1984E6" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M2.66602 13.3333H5.33268L12.8613 5.80468C13.1216 5.54433 13.1216 5.12222 12.8613 4.86187L11.1374 3.13801C10.8771 2.87766 10.455 2.87766 10.1946 3.13801L2.66602 10.6666V13.3333Z"
                                stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="textSM textRegular400 primary600"
                              v-if="!scope.row.driverId">Укажите водителя</span>
                        <span class="textSM textRegular400 deep-blue900"
                              v-else>{{ getDriverName(scope.row.driverId) ?? '-' }}</span>
                    </el-button>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            prop="mixers"
            label="Заводы"
        >
            <template v-slot="scope">
                <template v-if="!editView && scope.row.mixers">
                    <span class="textSM textRegular400 neutral900 cutOverflow"
                          v-for="(mixId, index) in scope.row.mixers" :key="index"
                    >
                        {{ getInfo(mixId, mixMixers, 'name') }}<span
                        v-if="index < scope.row.mixers.length - 1">, </span>
                    </span>
                </template>
                <template v-if="editView">
                    <el-button :disabled="!scope.row.active" @click="openChangeMixesDialog(scope.row)"
                               class="p-0" style="height: auto !important;" type="text">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             style="position: relative;top: -1px;margin-right: 5px;">
                            <path d="M2.66602 13.3334H13.3327" stroke="#1984E6" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M2.66602 13.3333H5.33268L12.8613 5.80468C13.1216 5.54433 13.1216 5.12222 12.8613 4.86187L11.1374 3.13801C10.8771 2.87766 10.455 2.87766 10.1946 3.13801L2.66602 10.6666V13.3333Z"
                                stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="textSM textRegular400 primary600"
                              v-if="scope.row.mixers && scope.row.mixers.length === 0">Укажите заводы</span>
                        <template v-else>
                            <span class="textSM textRegular400 deep-blue900 cutOverflow"
                                  v-for="(mixId, index) in scope.row.mixers" :key="index"
                            >
                                {{ getInfo(mixId, mixMixers, 'name') }}<span v-if="index < scope.row.mixers.length - 1">, </span>
                            </span>
                        </template>
                    </el-button>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            prop="volume"
            label="Вместимость"
            width="130"
            v-if="type === 'mix'"
        >
            <template v-slot="scope">
                            <span class="textSM textRegular400 primary600"
                                  v-if="scope.row.volume && scope.row.volume > 0">{{ scope.row.volume }} <good-unit
                                unit="м3."/></span>
                <span class="textSM textMedium neutral700" v-else>-</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="axle"
            label="Оси"
            width="70"
        >
            <template v-slot="scope">
                <span class="textSM textMedium"
                      :class="scope.row.axle ? 'warning300' : 'neutral700'">{{ scope.row.axle ?? '-' }}</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="pumpLength"
            label="Длина"
            width="80"
            v-if="type === 'pump'"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ scope.row.pumpLength ?? '-' }}</span>
            </template>
        </el-table-column>

        <el-table-column
            class=""
            prop="startTime"
            label="График"
            width="260"
        >
            <template v-slot="scope">
                <template v-if="!editView">
                    <template v-if="scope.row.startTime">
                        <span class="textSM textRegular400 neutral800">
                            {{ scope.row.startTime }} - {{ scope.row.endTime }}
                        </span>
                    </template>
                </template>
                <template v-else>
                    <div class="d-flex-full-stroke time-input-select"
                         :class="scope.row.startTime === scope.row.endTime ? 'error-border': ''">
                        <el-input
                            :disabled="!scope.row.active"
                            v-model="scope.row.startTime"
                            placeholder="00:00"
                            type="time"
                        />
                        <span class="ml-10 mr-10">-</span>
                        <el-input
                            :disabled="!scope.row.active"
                            v-model="scope.row.endTime"
                            placeholder="00:00"
                            type="time"
                        />
                    </div>
                    <div class="error textXS textRegular400" v-if="scope.row.startTime === scope.row.endTime">
                        Время не может быть одинаковым
                    </div>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            prop="load"
            label="Загрузка"
            width="80"
            v-if="!editView"
        >
            <template v-slot="scope">
                <span class="textSM textMedium"
                      :class="[
                                {neutral700: !scope.row.load},
                                {warning300: scope.row.load >= 80},
                                {success400: scope.row.load < 80}
                            ]"
                >{{ scope.row.load ?? '-' }}</span>
            </template>
        </el-table-column>

        <el-table-column
            fixed="right"
            label=""
            width="120px">
            <template v-slot="scope">
                <el-button
                    size="small"
                    v-if="orderCreateView && type === 'pump'"
                    @click="$emit('update:modelValue', scope.row.vehicleId)"
                >
                    Выбрать
                </el-button>
                <a class="el-button el-button--small el-button text-none"
                    size="small"
                    v-else
                    :href="'/settings/vehicles/' + scope.row.vehicleId"
                    target="_blank">
                    Подробнее
                </a>
            </template>
        </el-table-column>

        <el-table-column
            fixed="right"
            label=""
            width="60px">
            <template v-slot="scope" v-if="editView && !switcher">
                <el-tooltip effect="dark" placement="top">
                    <template #content>
                        <div class="text-center">
                            <div class="textMedium mb-5">Убрать из графика</div>
                            <div class="textXS">Убрать машину из <br/> графика по умолчанию</div>
                        </div>
                    </template>
                    <el-button
                        size="small"
                        @click="removeDefaultMixer(scope.row)"
                    >
                        Х
                    </el-button>
                </el-tooltip>
            </template>
        </el-table-column>

    </el-table>


    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <template v-if="editView">
        <div class="text-right button-LG">
            <el-button class="br-8" @click="setEditView">
                Вернуться назад
            </el-button>
            <el-button v-if="editView && !switcher"
                       class="br-8" type="primary" @click="updateDefaultSchedules">
                Сохранить график
            </el-button>
            <el-button v-else
                       class="br-8" type="primary" @click="changeDayShifts">
                Сохранить график
            </el-button>
        </div>
    </template>


    <el-drawer
        title=""
        v-model="createMixerDrawer"
        direction="rtl"
        class="mixer-create"
        :size="600"
        :destroy-on-close="true"
    >

        <MixerCreate
            :displayDataFromList="getAllMixersId"
            :mixes="mixes"
            :drivers="drivers"
            :type="type"
            @closeDrawer="closeCreateMixerDrawer"
            @getAllMixers="getAllMixers"
        />

    </el-drawer>

    <el-dialog
        title="Выбор водителя"
        v-model="changeDriverDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <SelectDialogDrivers
            :items="drivers"
            :type="type"
            @changeDriver="changeDriver"
        />

    </el-dialog>

    <el-dialog
        title="Выбор заводов"
        v-model="changeMixesDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <SelectDialogMixes
            :items="mixes"
            :selected="driverMixer"
            @changeMixes="changeMixes"
        />

    </el-dialog>

    <el-dialog
        :title="'Подробности ' + (type === 'mix' ? 'миксера' : 'насоса')"
        v-model="mixerDetailsDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <div class="info-container">
            <el-row class="info-container__body" :gutter="20">
                <el-col :sm="12">
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Номер
                        </div>
                        <div class="info-container__body__item__content">
                            {{ driverMixer.number }}
                        </div>
                    </div>
                    <div class="info-container__body__item" v-if="type === 'mix'">
                        <div class="info-container__body__item__label">
                            Вместимость
                        </div>
                        <div class="info-container__body__item__content">
                            {{ driverMixer.volume }}
                            <good-unit
                                unit="м3."/>
                        </div>
                    </div>
                </el-col>
                <el-col :sm="12">
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Водитель
                        </div>
                        <div class="info-container__body__item__content">
                            {{ getDriverName(driverMixer.driverId) }}
                        </div>
                    </div>
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Телефон водителя
                        </div>
                        <div class="info-container__body__item__content">
                            {{ getInfo(driverMixer.driverId, users, 'phone') }}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

    </el-dialog>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import TableHeadBlockMixer from "@/views/dispatch/components/TableHeadBlockMixer.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import {httpService} from "@/services/http.service";
import MixerCreate from "@/views/dispatch/components/MixerCreate.vue";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import GoodUnit from "@/views/components/goodUnit.vue";
import SelectDialogDrivers from "@/views/dispatch/components/SelectDialogDrivers.vue";
import SelectDialogMixes from "@/views/dispatch/components/SelectDialogMixes.vue";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";

export default {
    name: 'DispatchMixersTable',
    mixins: [dispatchTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin],
    props: ['modelValue', 'type', 'dateOrder', 'orderCreateView', 'filterData', 'selectedMixerIds'],
    data() {
        return {
            loading: false,
            switcher: true,
            createMixerDrawer: false,
            editView: false,
            multipleSelection: [],
            allMixers: [],
            removedDefaultMixers: [],
            defaultMixers: [],
            shiftMixers: [],
            mixers: [],
            mixes: [],
            users: [],
            driverMixer: null,
            changeDriverDialog: false,
            changeMixesDialog: false,
            isDefaultMixer: false,
            disableEdit: true,
            drivers: [],
            mixerDetailsDialog: false
        }
    },
    components: {
        SelectDialogMixes,
        SelectDialogDrivers,
        GoodUnit,
        MixerCreate,
        TablePagination, EmptyState,
        TableHeadBlockMixer,
    },
    inject: ['api'],
    created() {
        this.getAllMixers(true)
        this.getMixes()
    },
    computed: {
        mixMixers() {
            let mixers = []
            let mixes = JSON.parse(JSON.stringify(this.mixes))

            mixes.forEach((i) => {
                mixers = [...mixers, ...i.mixers.map((mixer) => {
                    mixer.name = this.tableTitle(i, mixer)
                    return mixer
                })]
                // mixers = [...mixers, ...i.mixers]
            })

            return mixers
        },
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
        }),
        date() {
            return this.dateOrder ?? this.getDate
        },
        getAllMixersId() {
            return this.allMixers.map(i => i.vehicleId);
        }
    },
    methods: {
        tableTitle(mix, mixer) {
            if (mix?.mixers.length > 1) return mix.name + ' - ' + mixer.name
            return mix.name
        },
        setSelected() {
            if (this.selectedMixerIds?.length) {
                this.displayData.forEach((item) => {
                    if (this.selectedMixerIds.includes(item.vehicleId)) {
                        item.selected = true
                    }
                })
            }
        },
        selectMixers() {
            let data = this.displayData.length ? this.displayData : this.defaultMixers
            const mixers = data.filter((e) => e.selected)
            this.$emit('selectMixers', mixers)
        },
        getMixersShift(loadDrivers) {
            this.loading = true
            httpService().post(this.api.dispatch.mixers.getMixersShift, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.getDate.format('YYYY-MM-DD'),
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.shiftMixers = data.values
                    this.getMixers()
                    this.loading = false
                    this.disableEdit = false
                    if (loadDrivers) this.getUsers()
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка загрузки данных',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getAllMixers(firstLoad) {
            this.loading = true

            httpService().post(this.api.dispatch.mixers.getAllMixers, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.allMixers = data.values
                    this.loading = false
                    this.getDefaultMixers();
                    if (firstLoad) {
                        this.getMixersShift(true)
                    }
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },


        removeDefaultMixer(currentMixer){
            this.removedDefaultMixers.push(this.allMixers.find(mixer => mixer.id === currentMixer.id))
            this.allMixers = this.allMixers.filter(mixer => mixer.id !== currentMixer.id)
            this.getDefaultMixers();
        },
        openChangeDriverDialog(mixer) {
            this.driverMixer = mixer
            this.isDefaultMixer = this.editView && !this.switcher
            this.changeDriverDialog = true
        },
        openMixerDetailsDialog(mixer) {
            this.driverMixer = mixer
            this.mixerDetailsDialog = true
        },
        openChangeMixesDialog(mixer) {
            this.driverMixer = mixer
            this.isDefaultMixer = this.editView && !this.switcher
            this.changeMixesDialog = true
        },
        changeMixes(arr) {
            let mixes = []

            arr.forEach(el => mixes.push(el.id))

            if (this.isDefaultMixer) {
                this.defaultMixers.forEach(mixer => mixer.vehicleId === this.driverMixer.vehicleId ? mixer.mixers = mixes : '')
            } else {
                this.mixers.forEach(mixer => mixer.vehicleId === this.driverMixer.vehicleId ? mixer.mixers = mixes : '')
                this.values.forEach(mixer => mixer.vehicleId === this.driverMixer.vehicleId ? mixer.mixers = mixes : '')
            }

            this.changeMixesDialog = false

            this.$message({
                message: arr.length > 0 ? 'Заводы добавлены' : 'Выбор отменен',
                showClose: true,
                type: 'success'
            })
            this.driverMixer = null
        },
        changeDriver(id) {
            if (this.isDefaultMixer) {
                this.defaultMixers.forEach(mixer => mixer.vehicleId === this.driverMixer.vehicleId ? mixer.driverId = id : '')
            } else {
                this.mixers.forEach(mixer => mixer.vehicleId === this.driverMixer.vehicleId ? mixer.driverId = id : '')
                this.values.forEach(mixer => mixer.vehicleId === this.driverMixer.vehicleId ? mixer.driverId = id : '')
            }

            // if (this.driverMixer.shiftId) this.changeShift(this.driverMixer.shiftId, id, 'driver')
            this.changeDriverDialog = false
            this.$message({
                message: id ? 'Водитель добавлен' : 'Выбор отменен',
                showClose: true,
                type: 'success'
            })
            this.driverMixer = null
            this.getUsers()
        },
        getDriverName(id) {
            let driver = null
            if (id) {
                driver = this.getInfo(id, this.users, 'name')
                if (!driver && this.users.length > 0) {
                    window.setTimeout(() => {
                        this.getUsers()
                        driver = this.getInfo(id, this.users, 'name')
                    }, 150)
                }
            }
            return driver
        },
        // changeShift(id, data, type) {
        //     httpService().post(this.api.dispatch.mixers.changeShift, {
        //         account_modules_id: this.$route.params.id,
        //         module_key: this.$route.meta.moduleKey,
        //         shiftId: id,
        //         data: data,
        //         type: type
        //     }).then().catch((error) => {
        //         this.$message({
        //             message: error.response.data.message,
        //             showClose: true,
        //             type: 'error'
        //         })
        //         this.loading = false
        //     })
        // },
        changeDayShifts() {
            let shifts = this.getFilteredDisplayData().length > 0 ? this.getFilteredDisplayData() : this.defaultMixers

            const errorSE = shifts.filter(dS => dS.startTime === dS.endTime)
            const errorNo = shifts.filter(dS => !dS.startTime || !dS.endTime)
            if (errorSE.length) {
                this.errorsMessage('errorSE')
                return;
            }

            if (errorNo.length) {
                this.errorsMessage('errorNo')
                return;
            }

            this.loading = true

            httpService().post(this.api.dispatch.mixers.changeDayShifts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.getDate.format('YYYY-MM-DD'),
                shifts: shifts.filter(el => el.active),
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'График сохранен',
                        showClose: true,
                        type: 'success'
                    })
                    this.loading = false
                    this.setEditView()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        updateDefaultSchedules() {
            const errorSE = this.defaultMixers.filter(dS => dS.startTime === dS.endTime)
            const errorNo = this.defaultMixers.filter(dS => !dS.startTime || !dS.endTime)
            if (errorSE.length) {
                this.errorsMessage('errorSE')
                return;
            }

            if (errorNo.length) {
                this.errorsMessage('errorNo')
                return;
            }
            let DS = [...this.defaultMixers]

            if (this.removedDefaultMixers.length) {
                this.removedDefaultMixers.forEach((element) => {
                    element.active = false
                    DS.push(element)
                })
            }

            this.loading = true

            httpService().post(this.api.dispatch.mixers.updateDefaultSchedules, {
                account_modules_id: this.$route.params.id,
                module_key: 'dispatch',
                default_schedules: DS,
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'График сохранен',
                        showClose: true,
                        type: 'success'
                    })
                    this.removedDefaultMixers = []
                    this.getAllMixers(true)
                    this.setEditView()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })

            }).finally(() =>this.loading = false)
        },
        getDefaultMixers() {
            this.defaultMixers = this.allMixers.filter(el => !el.rent).filter(el => el.type === this.type)
            this.defaultMixers.forEach(item => {
                item.active = !item.rent;
            });
        },
        getFilteredDisplayData() {
            if(this.editView && !this.switcher) {
                return []
            }
            let data = this.displayData.filter(el => el.type === this.type)
            if (!data.find(item => item.active === true)) {
                data.forEach(item => {
                    item.active = !item.rent;
                });
            }
            return data
        },
        getMixers() {
            // соединяем массивы миксеров и смен по vehicleId
            this.mixers = []

            this.shiftMixers.forEach(el => {
                el.active = true
                this.mixers.push(el)
            })

            this.allMixers.forEach(el => {
                let mixer = this.shiftMixers.find(m => m.vehicleId === el.vehicleId)

                if (!mixer) {
                    this.mixers.push({
                        id: el.id,
                        number: el.number,
                        vehicleId: el.vehicleId,
                        type: el.type,
                        rent: el.rent,
                        axle: el.axle,
                        pumpLength: el.pumpLength,
                        gidrolotok: el.gidrolotok,
                        driverId: el.driverId,
                        driver: null,
                        volume: Math.floor(el.volume),
                        mixes: el.mixes,
                        mixers: el.mixers,
                        startTime: el.startTime,
                        endTime: el.endTime,
                        shiftId: null,
                        active: false,
                        selected: false
                    })
                }
            })

            this.values = this.mixers.filter(el => el.active)
        },
        getUsers() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success) {
                    this.users = data.users
                    this.drivers = []
                    let roleKey = this.type === 'mix' ? 'mixDriver' : 'pumpDriver'
                    this.users.filter(el => el.roleKey === roleKey).forEach(user => {
                        let mixer = this.shiftMixers.find(m => m.driverId === user.id)

                        this.drivers.push({
                            id: user.id,
                            name: user.name,
                            free: !mixer
                        })
                    })
                }
            })
        },
        setEditView() {
            if (this.editView) {
                this.values = this.mixers.filter(el => el.active)
                this.getAllMixers(true)
            } else {
                this.values = this.mixers
            }
            this.editView = !this.editView
            this.switcher = true
        },
        setCreateMixerDrawer() {
            this.createMixerDrawer = !this.createMixerDrawer
        },
        getMixes() {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                const {data} = response.data
                this.mixes = data
            })
        },
        closeCreateMixerDrawer(mixer) {
            this.createMixerDrawer = false
            this.values.push(mixer)
        },
        changeDate(date) {
            this.filters['filter[date]'] = moment(date).format('YYYY-MM-DD')
            this.getMixersShift()
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.number.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        toggleSwitch(value) {
            this.switcher = value;
        },
        errorsMessage(type) {
            if (type === 'errorSE') {
                this.$message({
                    message: 'Время начала и конца не должны быть одинаковыми',
                    showClose: true,
                    type: 'error'
                })
            }

            if (type === 'errorNo') {
                this.$message({
                    message: 'Время должно быть указанно',
                    showClose: true,
                    type: 'error'
                })
            }
        }
    },
    watch: {
        $route() {
            this.editView = false
            this.createMixerDrawer = false
            this.changeDriverDialog = false
            this.changeMixesDialog = false
            this.disableEdit = true
            this.allMixers = []
            this.mixers = []
            this.mixes = []
            this.users = []
            this.driverMixer = []
            this.drivers = []
            this.driverMixer = null
            this.shiftMixers = []
            this.getUsers()
            this.getAllMixers(true)
            if (!this.mixes.length) this.getMixes()
        },
        // 'filterData': {
        //     handler: function () {
        //         let selectIds = []
        //         if (this.filterData.gidrolotok || this.filterData.volume || this.filterData.axle) {
        //             this.values
        //                 .filter(el => this.filterData.gidrolotok ? el.gidrolotok === this.filterData.gidrolotok : true)
        //                 .filter(el => this.filterData.volume ? el.volume >= Math.abs(this.filterData.volume) : true)
        //                 .filter(el => this.filterData.axle ? el.axle === Math.abs(this.filterData.axle) : true)
        //                 .forEach(el => selectIds.push(el.vehicleId))
        //
        //             this.displayData.forEach(el => {
        //                 let findElem = selectIds.find(mixer => mixer === el.vehicleId)
        //                 el.selected = !!findElem;
        //             })
        //         }
        //     },
        //     deep: true,
        //     immediate: false
        // },
        'displayData': {
            handler: function (newValue, oldValue) {
                console.log(newValue, oldValue)
                if (oldValue.length === 0) {
                    this.setSelected()
                }
            },
            deep: true,
            immediate: false
        },

    },
}
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";

.button-LG button {
    height: 44px !important;
    padding: 12px 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.switcher {
    border: 1px solid #EEEEEE;
    background: #F9F9F9;
    border-radius: 12px;

    div {
        padding: 12px 24px;
        cursor: pointer;

        &.active {
            color: $primary600;
            border: 1px solid #eeeef0;
            background-color: white;
            border-radius: 12px;
        }
    }
}
</style>
