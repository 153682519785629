<template>
    <div>
        <DistributeOrderGroupTable
            v-if="mixers.length && vehicles.length"
            :distribute="createNew ? distribute : orders"
            :mixers="mixers"
            :time="time"
            :date="dateFilter"
            :extraOrders="extraOrders"
            :vehicles="vehicles"
        />
    </div>
</template>
<script>
import DistributeOrderGroupTable from "@/views/dispatch/DistributeOrderGroupTable.vue";
import moment from "moment/moment";
import {httpService} from "@/services/http.service";
import {useEchoStore} from "@/store/Echo";
import {mapStores} from "pinia";

export default {
    name: 'MixOrderGroupBigTimelineOrders',
    components: {
        DistributeOrderGroupTable,
    },
    props: {
        distribute: {default: []},
        extraOrders: {default: []},
        createNew: {default: false},
        mixers: {default: []},
        time: {default: ''},
        date: {default: moment()},
        account_module_mix_ids: {default: []},
        mixIds: {default: []},
    },
    inject: [
        'api'
    ],
    data() {
        return {
            vehicles: [],
            orders: [],
        }
    },
    computed: {
        dateFilter() {
            return moment(this.date)
        },
        ...mapStores(useEchoStore),
    },
    beforeUnmount() {
        window.Echo.leave(`laravel_database_ChannelMixOrderCreateNew`);
        window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
    },
    async created() {
        console.log();
        if (!this.createNew) {
            Object.values(this.account_module_mix_ids).forEach(value => {
                window.Echo.channel('laravel_database_ChannelMixOrderCreateNew')
                    .listen(`.MixOrderCreate.${value}`, () => {
                        this.getOrders()
                    });
            })
            await this.getOrders()
        } else {
            await this.getVehicles()
        }

    },
    methods: {
        async getVehicles(){
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.date
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicles = data.schedules
                }
            })
        },
        async getOrders() {
            window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
            window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
            const {data} = await httpService().get(this.api.dispatch.orders.timeline, {
                params: {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    mixes: Object.values(this.mixIds),
                    dateFilter: this.date,
                }
            });

            this.vehicles = data.vehicles
            this.orders = data.orders

            this.orders.map(item => item.id).forEach(item => {
                window.Echo.channel('laravel_database_ChannelMixOrderDeletedNew')
                    .listen(`.MixOrderDeleted.${item}`, () => {
                        this.getOrders()
                    })

                window.Echo.channel('laravel_database_ChannelMixOrderUpdateNew')
                    .listen(`.MixOrderUpdate.${item}`, () => {
                        this.getOrders()
                    });
            })
        }
    },
    watch: {
        'date': {
            handler: function () {
                if (!this.createNew) {
                    this.getOrders()
                }
            },
            deep: false,
            immediate: false
        },
    }
}
</script>
<style scoped>

</style>
