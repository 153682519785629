<template>
    <div class="info-container">
        <div class="info-container__header">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <circle cx="7" cy="7.5" r="7" fill="#1984E6"/>
            </svg>
            <div class="info-container__header__number">
                Заказ № {{ orderGroupItem.id }}
            </div>
        </div>
        <el-row class="info-container__body" :gutter="20">
            <el-col :sm="12">
                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Контрагент
                    </div>
                    <div class="info-container__body__item__content">
                        {{ orderGroupItem.buyer_company.name }}
                    </div>
                </div>

                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Контактное лицо
                    </div>
                    <div class="info-container__body__item__content">
                        {{ orderGroupItem?.company_manager?.name ?? '-' }}
                    </div>
                </div>

                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Телефон
                    </div>
                    <div class="info-container__body__item__content">
                        {{ orderGroupItem?.company_manager?.phone ?? '-' }}
                    </div>
                </div>

                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Объект
                    </div>
                    <div class="info-container__body__item__content">
                        {{ orderGroupItem.delivery_address?.address ?? '-' }}
                    </div>
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Тип доставки
                    </div>
                    <div class="info-container__body__item__content">
                        {{ deliveryType(orderGroupItem.delivery_type) }}
                    </div>
                </div>
                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Продукция
                    </div>
                    <div class="info-container__body__item__content">
                        {{ orderGroupItem.good.name }}
                    </div>
                </div>
                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Стоимость заказа
                    </div>
                    <div class="info-container__body__item__content">
                        {{ orderGroupItem.delivery_price }} ₽
                    </div>
                </div>
                <div class="info-container__body__item">
                    <div class="info-container__body__item__label">
                        Заводы
                    </div>
                    <div class="info-container__body__item__content">
                        {{ getObjectsName(orderGroupItem.mixes) }}
                    </div>
                </div>
                <div class="d-flex">
                    <div class="info-container__body__item">
                        <div class="info-container__body__item__label">
                            Дата доставки
                        </div>
                        <div class="info-container__body__item__content">
                            {{ deliveryDate(orderGroupItem.date_shipment) }}
                        </div>
                    </div>
                    <div class="info-container__body__item" style="margin-left: 24px;">
                        <div class="info-container__body__item__label">
                            Время доставки
                        </div>
                        <div class="info-container__body__item__content">
                            {{ timeAddTimezone(orderGroupItem.first_order_time_delivery, timezone) }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import moment from 'moment/moment'
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'OrderGroupInfo',
    mixins: [functionsMixin],
    inject: ['timezone'],
    props:{
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
    },
    methods: {
        getObjectsName(mixes)
        {
            const moduleNames = mixes.map(mix => mix.name);
            return moduleNames.join(", ")
        },
        deliveryType(type){
            const typeState = new Map([
                ['take-away', 'Самовывоз'],
                ['delivery', 'Доставка'],
            ])

            return typeState.get(type)
        },
        deliveryDate(first_delivery)
        {
            return moment(first_delivery).format('YYYY-MM-DD')
        }
    },
}
</script>
<style scoped lang="scss">
.info-container{
    &__body{
        &__item{
            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
}
</style>
