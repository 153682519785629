<template>
    <Auth title="Заказы" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body dispatchCardBody">
                <Summary
                    :summary="summary"
                    @changeDate="changeDate"
                />

                <MixOrderGroupTable
                    :loading="loading"
                    :mixes="mixes"
                    :order-groups="displayData"
                    @showActionDialog="showActionDialog"
                    @showAdd="showAdd"
                    @get="moduleGet"
                />
            </div>
        </div>

        <el-dialog
            class="group-order-action-dialog"
            v-model="openActionDialog"
            :destroy-on-close="true"
            width="992"
        >
            <Action
                @close="() => openActionDialog = false"
                :order-group-item-id="selectedGroupId"
                :start-active="isAdd ? 'add-shipping': 'main'"
                :openActionDialog="openActionDialog"
            />
        </el-dialog>
    </Auth>
</template>
<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import {httpService} from "@/services/http.service";
import Summary from "@/views/dispatch/components/Summary.vue";
import Action from "@/views/dispatch/components/Action.vue";
import {useOrderGroupsStore} from '@/store/orderGroups'
import {mapActions, mapState, mapStores} from 'pinia'
import {useEchoStore} from "@/store/Echo";
import MixOrderGroupTable from "@/views/components/MixOrderGroup/MixOrderGroupTable.vue";

export default {
    name: 'DispatchOrderGroups',
    provide() {
        return {
            // timezone: this.timezone
            timezone: 'Europe/Moscow'
        }
    },
    components: {
        MixOrderGroupTable,
        Summary,
        Action,
        Auth,
    },
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            loading: true,
            selectedGroupId: null,
            openActionDialog: false,
            filter: {
                date: {
                    value: moment(),
                    type: 'date'
                },
            },
            mixes: [],
            multipleSelection: [],
            createNewOrderShow: false,
            actionsModalShow: true,
            isAdd: false,
            firstLoad: true
        }
    },
    async created() {
        this.moduleGet(true)
        this.getSummary(this.filters)

        // this.counterInterval = setInterval(
        //     function () {
        //         if (!this.openActionDialog) {
        //             this.moduleGet()
        //             this.getSummary(this.filters)
        //         }
        //     }.bind(this), 30000)

    },
    beforeUnmount: function () {
        // clearInterval(this.counterInterval)
    },
    computed: {
        ...mapStores(useEchoStore),
        ...mapState(useOrderGroupsStore, {
            orderGroups: 'getOrderGroups',
            timezone: 'getTimezone',
            pagination: 'getPagination',
            summary: 'getSummary',
            filters: 'getFilters',
            getDate: 'getDate',
        }),
        selectedGroup() {
            return this.orderGroups.find((item) => item.id == this.selectedGroupId)
        }
    },
    watch: {
        'displayData': {
            handler: function (orderGroups) {

                orderGroups.forEach((orderGroup) => {
                    this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderGroupUpdate.${orderGroup.id}`);

                    this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderGroupUpdate.${orderGroup.id}`)
                        .listen('.MixOrderGroupUpdate', () => {
                            setTimeout(() => {
                                this.moduleGet()
                                this.getSummary(this.filters)
                            }, 500);
                        });

                    orderGroup.orders.forEach((order) => {
                        this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderDelete.${order.id}`);
                        this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderUpdate.${order.id}`);

                        this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderDelete.${order.id}`)
                            .listen('.MixOrderDeleted', () => {
                                setTimeout(() => {
                                    this.moduleGet()
                                    this.getSummary(this.filters)
                                }, 500);
                            });

                        this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderUpdate.${order.id}`)
                            .listen('.MixOrderUpdate', () => {
                                setTimeout(() => {
                                    this.moduleGet()
                                    this.getSummary(this.filters)
                                }, 500);
                            });
                    })
                })
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        moduleGet(loading) {
            if (this.$route.params.id === null) {
                return
            }

            if (loading) this.loading = true;

            httpService().post(this.api.dispatch.orderGroups.getOrderGroups, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                params: this.filters
            }).then((response) => {

                let data = response.data

                if (data.success) {

                    this.values = data.values.sort((a, b) => {
                        const statusGroups = {
                            confirmed: 1,
                            work: 1,
                            done: 2,
                            completed: 2,
                            pause: 3,
                            failed: 4,
                        };

                        if (statusGroups[a.status] !== statusGroups[b.status]) {
                            return statusGroups[a.status] - statusGroups[b.status];
                        }

                        return a.firstOrderTimeDelivery - b.firstOrderTimeDelivery;
                    })

                    this.mixes = data.mixes
                    this.loading = false

                    this.mixes.forEach((mix) => {
                        this.echoStore.Echo.leave(`laravel_database_ChannelMixOrderGroupCreate.${mix.module.id}`);

                        this.echoStore.Echo.channel(`laravel_database_ChannelMixOrderGroupCreate.${mix.module.id}`)
                            .listen('.MixOrderGroupCreate', () => {
                                setTimeout(() => {
                                    this.moduleGet()
                                    this.getSummary(this.filters)
                                }, 500);
                            });
                        // this.firstLoad = false
                    })
                }
            }).catch(() => {
                // this.$message({
                //     message: error.response.data.message,
                //     showClose: true,
                //     type: 'error'
                // })
                this.loading = false
            })
        },
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups', 'pullSummary', 'changeOrderGroup']),
        colorState(state) {
            const mappedState = new Map([
                ['unconfirmed', '#BD2626'],
                ['unallocated', '#FFB500'],
                ['partially_distributed', '#C6AAF7'],
                ['distributed', '#B8E8AA'],
                ['done', '#2BD8B3'],
            ])

            return mappedState.get(state)
        },
        showActionDialog(orderGroupItem) {
            this.selectedGroupId = orderGroupItem.id
            this.isAdd = false
            this.openActionDialog = true
        },
        showAdd(orderGroupItem) {
            this.isAdd = true
            this.selectedGroupId = orderGroupItem.id
            this.openActionDialog = true
        },
        selectStatuses(statuses) {
            this.filters['filter[status]'] = statuses

            this.getOrders(this.filters)
            this.getSummary(this.filters)
        },
        selectMixes(mixes) {
            this.filters['filter[mix_ids]'] = mixes.map(e => e.module_id)

            this.getOrders(this.filters)
            this.getSummary(this.filters)
        },
        // //TODO не используется, в 385 удалили запускающий
        // async getMixes(accountModulesId) {
        //     await httpService().post(this.api.mix.listByAccount, {
        //         account_modules_id: accountModulesId,
        //         module_key: 'mix',
        //     }).then((response) => {
        //         const {data} = response.data
        //         this.mixes = data
        //     })
        // },
        paginationChange(value) {
            this.getOrders({...this.filters, page: value})
        },
        changeDate(value) {
            this.filters['filter[date]'] = moment(value).format('YYYY-MM-DD')
            this.values = []
            this.moduleGet(true)
            this.getSummary(this.filters)
        },
        getOrders(filter) {
            if (this.$route.params.id){
                this.loading = true
                this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, filter)
                setTimeout(() => this.loading = false, 250)
            }
        },
        getSummary(filter) {
            if (this.$route.params.id){
                this.pullSummary(this.$route.params.id, filter)
            }
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getAddress(companyId, addressId) {
            if (companyId && addressId) {
                let company = this.companies.find(el => el.id === companyId)
                if (company) {
                    let address = company.addresses.find(el => el.id === addressId)
                    if (address) {
                        return address.name
                    } else {
                        this.pullCompanies()
                        window.setTimeout(() => {
                            address = company.addresses.find(el => el.id === addressId)
                            if (address) {
                                return address.name
                            } else {
                                return null
                            }
                        }, 150)
                    }
                }
                return null
            }
            return null
        },
    }
}
</script>

<style lang="scss">
.group-order-action-dialog {
    border-radius: 12px !important;

    &__footer {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }

    .el-dialog__header {
        margin-right: 0;
    }
}
</style>
<style scoped lang="scss">
.dialog-header {
    display: flex;
    justify-content: end;

    .el-icon {
        cursor: pointer;
    }
}

.action-button {
    margin-left: 14px;
}

.card-body {
    &__filters {
        padding: 12px 0;
    }
}

.group-order {
    &__pagination {
        display: flex;
        justify-content: center;
    }
}
</style>
<style lang="scss" scoped>
.buyer-circle {
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
}

.good-icon {
    vertical-align: middle;
    margin-right: 4px;
    width: 16px;
    height: 16px;
}


</style>
<style lang="scss">
.total-count .cell {
    padding: 0;
}


.cell-container {
    display: flex;
    justify-content: center;
}

.cell-padding {

    padding: 0 !important;

    .cell {
        padding: 6px 0 !important;
    }
}

.actions {
    display: flex;
    justify-content: space-between;
    height: 28px;

    button {
        min-height: auto;
        font-size: 12px;
        border-radius: 4px;
    }

    .plus-btn {
        border: none;
        width: 32px;
        height: 28px;
        padding: 0;
        justify-content: center;
    }

    .action-btn {
        padding: 0 12px;
    }
}

.address {
    font-size: 12px;
}
</style>
