<template>
    <div class="card dispatch-order-groups-create">
        <div class="card-body">

            <div class="primary500 textLG textMedium mt-15 mb-15">
                Заказ
            </div>

            <MixOrderGroupCreateForm
                v-model="form"
                :update-mode="true"
                :mixers="mixers"
            />
            <el-button
                type="primary"
                @click="updateOrderGroup"
            >
                Обновить
            </el-button>
        </div>
    </div>
</template>

<script>
import MixOrderGroupCreateForm from "@/views/components/MixOrderGroup/MixOrderGroupCreateForm.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import paymentMethods from "@/mixins/paymentMethods";
import {httpService} from "@/services/http.service";
import {mapState, mapStores} from "pinia";
import {useCommonStore} from "@/store/common";
import {useMixStore} from "@/store/mix";
import moment from "moment";

export default {
    name: "MixOrderGroupUpdate",
    components: {MixOrderGroupCreateForm},
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, paymentMethods],
    inject: ['api'],
    data() {
        return {
            mixers: [],
            moreInfo: true,
            selectedMixes: [],
            address: [],
            companyManagers: [],
            form: {},
            step: 0,
            activeTab: 'delivery',
            timezone: 'Europe/Moscow',
        }
    },
    created() {
        this.getGroup(this.$route.params.groupId)
        this.pullGoods()
    },
    methods: {
        setAddress(companyId) {
            httpService().post(this.api.company.getAttribute, {
                'companyId': companyId,
                'attribute': 'addresses',
            }).then((response) => {
                const {attribute} = response.data
                this.address = attribute
            })
        },
        setCompanyManagers(companyId) {
            httpService().post(this.api.company.getAttribute, {
                'companyId': companyId,
                'attribute': 'managers',
            }).then((response) => {
                const {attribute} = response.data
                this.companyManagers = attribute
            })
        },
        getGroup(groupId) {
            httpService().post(this.api.dispatch.getOrderGroupById, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                group_id: groupId
            }).then((response) => {
                const {data, timezone} = response.data

                data.pumpInfo = {
                    company_id: data?.pump_vehicle?.rent_company_id ?? null,
                    vehicle_number: data?.pump_vehicle?.number ?? null,
                    driver_name: data?.pump_vehicle?.pump_driver_name ?? null,
                    driver_phone: data?.pump_vehicle?.pump_driver_phone ?? null,
                    company_phone: data?.pump_vehicle?.rent_company?.phone ?? null,
                    price: 0,
                }

                this.timezone = timezone
                this.form = data

                if (this.form.first_order_time_delivery) {
                    this.form.first_order_time_delivery = this.timeAddTimezone(this.form.first_order_time_delivery, timezone)
                }

                this.getSchedules(data)
            })
        },
        updateOrderGroup() {
            let formData = {...this.form}

            if (!formData.pump) {
                delete formData.pumpInfo;
            }

            // formData.mix_ids = this.selectedMixes

            httpService().post(this.api.dispatch.orderGroups.updateOrderGroups, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                group_id: this.$route.params.groupId,
                form: formData
            }).then(() => {
                if (this.$route.meta.moduleKey === 'crm') {
                    this.$router.push({name: 'CRMOrders', params: {id: this.$route.params.id}})
                } else {
                    this.$router.push({name: 'DispatchOrderGroups', params: {id: this.$route.params.id}});
                }

            }).catch((error) => {

                const {errors} = error.response.data

                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            })
        },
        getSchedules(orderGroup) {
            httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: moment(orderGroup.date_shipment).format('YYYY-MM-DD')
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.mixers = response.data.posts
                    this.form.mixer_ids = orderGroup.mixers.map(id => id.id)
                    this.form.mix_ids = orderGroup.mixes.map(id => id.id)
                    this.form.account_module_ids = orderGroup.mixes.map(id => id.id)
                }
            })
        },
        getMixes(accountModulesId) {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: accountModulesId,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let {data} = response.data
                this.mixes = data
            })
        },
        setTimezone(hour, timezone) {
            if (hour) {
                return this.timeAddTimezone(`${hour}:00:00`, timezone)
            }
            return ''
        },
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            user: 'getUser',
            goods: 'getGoods',
        }),
        ...mapStores(useMixStore),
        selectedBuyerName: function () {
            return this.companies?.find(({id}) => id === this.form.buyer_company_id)?.name;
        }
    },
    watch: {
        'form': {
            handler: function () {
                if (this.form.buyer_company_id && this.form.company_manager_id) {
                    this.pullCompanies()
                }
            },
            deep: true,
            immediate: true
        },
        'form.buyer_company_id': {
            handler: function (value) {
                if (value) {
                    this.setAddress(value)
                    this.setCompanyManagers(value)
                }
            },
            deep: true,
            immediate: true
        },
    },
}
</script>
<style scoped lang="scss">
.selectedMixes {
    color: #393F4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.mix-action {
    align-items: center;
}

.mix-section {
    display: flex;
    justify-content: space-between;
}

.mix-load {
    color: #BD2626;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-right: 14px;
}
</style>
