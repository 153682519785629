<template>
    <div class="servicePeriodDialog">

        <div v-if="step===1">
            <el-radio-group v-model="periodSettings.type">
                <el-radio label="mileage">
                    <h3>Настроить повтор, основываясь на показателях пробега</h3>
                    <p>Позволяет настроить проведение заказ-наряда с определенным интервалом пробега. Например, каждые
                        500
                        км или каждую 1 000 мото-часов</p>
                </el-radio>
                <el-radio label="calendar">
                    <h3>Настроить повтор по определенному расписанию</h3>
                    <p>Позволяет настроить проведение заказ-наряда по календарю. Можно выбрать регулярность в годах,
                        месяцах, неделях и днях. Так же можно настроить на определенное число каждого месяца или день
                        недели</p>
                </el-radio>
            </el-radio-group>

            <el-button class="mt-15" type="primary" @click="nextStep()">Продолжить</el-button>
        </div>

        <div v-if="step===2">

            <div v-if="periodSettings.type==='mileage'">

                <FormNumber
                    :required="true"
                    v-model="periodSettings.mileage.countPeriod"
                    label="Периодичность повторения"
                    placeholder="Укажите периодичность повторения"
                />

                <p class="formInfoText" style="margin-top: -10px">Введите 500, если вам необходимо проводить ТО для
                    техники каждые 500 {{ unit }}</p>

                <FormNumber
                    v-model="periodSettings.mileage.start"
                    label="Начиная с пробега"
                    placeholder="Укажите начальный пробег для отсчета"
                />

                <FormNumber
                    v-model="periodSettings.mileage.remember"
                    label="Напомнить за"
                    :placeholder="'Укажите кол-во ' + unit"
                />

                <p class="color-blue" v-if="periodSettings.mileage.countPeriod">
                    <span>При указанных настройках заказ-наряд будет повторяться каждые {{
                            formatNumber(periodSettings.mileage.countPeriod)
                        }} {{ unit }}</span>
                    <span v-if="periodSettings.mileage.start">, начиная отсчет пробега с {{
                            formatNumber(periodSettings.mileage.start)
                        }} {{ unit }}</span>
                    <span v-if="periodSettings.mileage.countPeriod">.</span>
                    <span v-if="periodSettings.mileage.remember"> Напомним о ТО за {{
                            formatNumber(periodSettings.mileage.remember)
                        }} {{ unit }} до начала.</span>
                </p>

            </div>

            <div v-if="periodSettings.type==='calendar'" class="mb-15">

                <FormDateTimePicker
                    v-model="periodSettings.calendar.dateStart"
                    label="Начиная с даты"
                    placeholder="Укажите дату первого заказ-наряда"
                />

                <el-radio-group v-model="periodSettings.calendar.type">

                    <el-radio label="week">
                        <h3>Каждую неделю в определенные дни</h3>
                    </el-radio>

                    <div class="radioContent" v-if="periodSettings.calendar.type === 'week'">

                        <p>Будет повторять работу еженедельно в выбранные дни. Например, в каждые понедельник и
                            пятницу</p>


                        <el-checkbox-group v-model="periodSettings.calendar.weekDaysList">
                            <el-checkbox v-for="(item, index) in weekDays" :label="item.id" border
                                         :key="index">{{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>

                    </div>

                    <el-radio label="month">
                        <h3>Каждый месяц в определенные числа</h3>
                    </el-radio>

                    <div class="radioContent" v-if="periodSettings.calendar.type === 'month'">
                        <p>Будет повторять работу ежемесячно в выбранные числа. Например, в каждый месяц 1 и 25
                            числа</p>


                        <el-checkbox-group v-model="periodSettings.calendar.monthDaysList">
                            <el-checkbox class="radioContentMonth" v-for="(item, index) in 31" :label="item" border
                                         :key="index"></el-checkbox>
                        </el-checkbox-group>
                    </div>

                    <el-radio label="year">
                        <h3>Один раз в год</h3>
                    </el-radio>

                    <div class="radioContent" v-if="periodSettings.calendar.type === 'year'">
                        <p>Выберите дату, когда будет происходить ежегдоное повторение работы. Например, 25 декабря</p>

                        <FormDateTimePicker
                            v-model="periodSettings.calendar.yearDay"
                            label=""
                            formatDate="DD.MM"
                            placeholder="Выберите день"
                        />

                    </div>

                </el-radio-group>

                <p class="color-blue mb-30" v-if="periodSettings.calendar.type">

                    <span
                        v-if="periodSettings.calendar.type === 'week' && periodSettings.calendar.weekDaysList.length > 0">
                        При указанных настройках заказ-наряд будет повторяться еженедельно в <span
                        v-for="(day, index) in periodSettings.calendar.weekDaysList"
                        :key="index">{{ getInfo(day, weekDays, 'shortName') }}<span
                        v-if="index !== periodSettings.calendar.weekDaysList.length - 1">, </span></span>
                    </span>

                    <span
                        v-if="periodSettings.calendar.type === 'month' && periodSettings.calendar.monthDaysList.length > 0">
                        При указанных настройках заказ-наряд будет повторяться ежемесячно <span
                        v-for="(day, index) in periodSettings.calendar.monthDaysList.sort((a, b) => a - b)"
                        :key="index">{{ day }}<span
                        v-if="index !== periodSettings.calendar.monthDaysList.length - 1">, </span></span> числа
                    </span>

                    <span
                        v-if="periodSettings.calendar.type === 'year' && periodSettings.calendar.yearDay">
                        При указанных настройках заказ-наряд будет повторяться ежегодно {{ getYearDay() }}
                    </span>

                </p>

            </div>

            <FormAddButton
                title="Сохранить настройки повторения"
                @action="saveSettings()"
            />
        </div>


    </div>
</template>

<script>
// Техника -> Сервис -> Создание заказ-наряда
// содержание модалки настройки периодичности выполнения работы

import moment from 'moment'
import FormNumber from '@/views/components/Form/FormNumber'
import functionsMixin from '@/mixins/functionsMixin'
import FormAddButton from "@/views/components/Form/FormAddButton";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker";

export default {
    name: 'ServicePeriodDialog',
    components: {FormDateTimePicker, FormAddButton, FormNumber},
    props: ['modelValue', 'teches', 'techId', 'startDate', 'mileage'],
    mixins: [functionsMixin],
    data() {
        return {
            periodSettings: this.modelValue,
            openDialog: false,
            step: 2,
            metric: this.getInfo(this.techId, this.teches, 'metric'),
            unit: 'км',
            intervalTypes: [{
                id: 'day',
                name: 'День'
            }, {
                id: 'week',
                name: 'Неделя'
            }, {
                id: 'year',
                name: 'Год'
            }],
            weekDays: [{
                id: 1,
                name: 'Понедельник',
                shortName: 'пн'
            }, {
                id: 2,
                name: 'Вторник',
                shortName: 'вт'
            }, {
                id: 3,
                name: 'Среда',
                shortName: 'ср'
            }, {
                id: 4,
                name: 'Четверг',
                shortName: 'чт'
            }, {
                id: 5,
                name: 'Пятница',
                shortName: 'пт'
            }, {
                id: 6,
                name: 'Суббота',
                shortName: 'сб'
            }, {
                id: 7,
                name: 'Воскресенье',
                shortName: 'вс'
            }],
        }
    },
    created() {
        if (this.mileage) {
            console.log(this.mileage)
            this.periodSettings.mileage.start = Number(this.mileage)
        }
    },
    methods: {
        nextStep() {
            if (this.step === 1) {
                if (this.periodSettings.type) {
                    this.step++
                } else {
                    this.$message({
                        message: 'Выберите тип периодичности',
                        showClose: true,
                        type: 'error'
                    })
                }
            }
        },
        saveSettings() {
            if (this.periodSettings.type === 'mileage') {
                if (!this.periodSettings.mileage.countPeriod) {
                    this.$message({
                        message: 'Укажите периодичность повторения',
                        showClose: true,
                        type: 'error'
                    })
                } else {
                    this.$emit('closeDialog')
                }
            }
            if (this.periodSettings.type === 'calendar') {

                if (this.periodSettings.calendar.type === 'week') {
                    if (this.periodSettings.calendar.weekDaysList.length === 0) {
                        this.$message({
                            message: 'Выберите хотя бы один день недели',
                            showClose: true,
                            type: 'error'
                        })
                    } else {
                        this.$emit('closeDialog')
                    }
                }

                if (this.periodSettings.calendar.type === 'month') {
                    if (this.periodSettings.calendar.monthDaysList.length === 0) {
                        this.$message({
                            message: 'Выберите хотя бы одно число месяца',
                            showClose: true,
                            type: 'error'
                        })
                    } else {
                        this.$emit('closeDialog')
                    }
                }

                if (this.periodSettings.calendar.type === 'year') {
                    if (!this.periodSettings.calendar.yearDay) {
                        this.$message({
                            message: 'Выберите дату',
                            showClose: true,
                            type: 'error'
                        })
                    } else {
                        this.$emit('closeDialog')
                    }
                }

            }
        },
        getYearDay() {
            return moment(this.periodSettings.calendar.yearDay).format('DD.MM')
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.periodSettings = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'periodSettings': {
            handler: function () {
                this.$emit('update:modelValue', this.periodSettings)

                if (!this.periodSettings.mileage.start && this.techId) this.periodSettings.mileage.start = this.getInfo(this.techId, this.teches, 'mileage_start')
                if (!this.periodSettings.calendar.dateStart) this.periodSettings.calendar.dateStart = this.startDate
            },
            deep: true,
            immediate: true
        },
        'metric': {
            handler: function () {
                if (this.metric === 'mileage') {
                    this.unit = 'км'
                } else {
                    this.unit = 'мтч'
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
