import { API_ROUTES } from '@/const'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'
import router from '@/router'
import { ElNotification } from 'element-plus'
import { sendCookies } from '@/mixins/cookiesManager'

export function authService() {
    const commonStore = useCommonStore()
    let roleId = null

    const getMe = async () => {
        commonStore.setToken(localStorage.getItem('api_token'))

        return await httpService().post(API_ROUTES.account.me, sendCookies()).then((response) => {
            roleId = response.data.user.role_id
            commonStore.setUser(response.data.user)
            commonStore.setNav(response.data.links)
            commonStore.setModules(response.data.modules)
            commonStore.setWidgets(response.data.widgets)
            commonStore.setReports(response.data.reports)
            commonStore.setReportFunctions(response.data.reportFunctions)
            commonStore.setAnalyticsAvailable(response.data.analyticsAvailable)
            commonStore.setReportsAvailable(response.data.reportsAvailable)
            commonStore.setUserModuleAccess(response.data.userModuleAccess)
            commonStore.setUserReportAccess(response.data.userReportAccess)
            commonStore.pullFactories();
        })
    }

    const initApp = async () => {
        return await getMe().then(() => {
            if (roleId !== 5 && roleId !== 6 && roleId !== 7) {
                commonStore.pullGoods()
                commonStore.pullCompanies()
                commonStore.pullIntegrations()
                commonStore.pullGoodsFirstRequest()
                commonStore.pullCompaniesFirstRequest()
            }
        })
    }

    const doLogin = async (username, password) => {
        return await httpService().post(API_ROUTES.account.login, {
            username: username,
            password: password,
        }).then((response) => {
            commonStore.setToken(response.data.accessToken)
            commonStore.setUser(response.data.user)
            getMe().then(() => {
                if (roleId !== 5 && roleId !== 6 && roleId !== 7) {
                    commonStore.pullGoods()
                    commonStore.pullCompanies()
                    commonStore.pullIntegrations()
                    commonStore.pullGoodsFirstRequest()
                    commonStore.pullCompaniesFirstRequest()
                }
            })
            router.push({
                name: 'dashboard'
            })
        }).catch((error) => {
            ElNotification({
                message: error.response.data.message,
                type: 'error'
            })
        })
    }

    const doLoginByToken = async (token) => {
        return await httpService().post(API_ROUTES.account.loginByToken, {
            token: token
        }).then((response) => {
            commonStore.setToken(response.data.accessToken)
            commonStore.setUser(response.data.user)
            getMe()
            if (roleId !== 5 && roleId !== 6 && roleId !== 7) {
                commonStore.pullGoods()
                commonStore.pullCompanies()
                commonStore.pullIntegrations()
                commonStore.pullGoodsFirstRequest()
                commonStore.pullCompaniesFirstRequest()
            }
            router.push({
                name: 'dashboard'
            })
        }).catch(() => {
            ElNotification({
                message: 'Срок действия токена истек',
                type: 'error'
            })
        })
    }

    const doLogout = () => {
        commonStore.clearToken()
        router.push({
            name: 'login'
        })
        commonStore.reset()
    }

    return {
        getMe,
        initApp,
        doLogin,
        doLoginByToken,
        doLogout
    }
}
