<template>

    <Auth title="Миксеры" :tabs="tabs">
        <div class="card">
            <div class="card-body">
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        create-button-label="Добавить миксер"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="openCreateValueDrawer"
        :disable-filter="true"
    />

    <Filter
        :filter="filter"
        :loading="loading"
        :filter-show="filterShow"
    />

    <EmptyState
        :values="displayData"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="миксеров"
    />

    <template v-if="displayData.length > 0">
        <div class="logisticsInfoMain mt-15">
            <div class="logisticsInfoMainBlock">

                <div class="logisticsInfoMainMap">
                    <div class="map">
                        <YandexMap
                            :coords="mapCenter"
                            :zoom="mapZoom"
                            @onChangeCenterPosition="handlePosition"
                            @onChangeZoom="handleZoom"
                        />
                    </div>
                </div>

                <div class="logisticsInfoMainItems">
                    <div class="logisticsItem"
                         v-bind:class="{ active: activeItem === index }"
                         @click="change(item, index)"
                         v-for="(item, index) in displayData"
                         :key="index">
                        <div class="logisticsItemBody">
                            <TechImage :image="item.image ?? null"/>

                            <div class="logisticsItemInfo">
                                <div class="logisticsItemHead">
                                    <h2>#{{ index + 1 }} {{ item.name }} <EditPen @click="openDialog(item)"/></h2>
                                    <span
                                        class="logisticsItemHeadStatus"
                                        @click="$emit('openChangeStatus')">
        <svg width="6" height="6" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" :fill="item.status === 'work' ? '#00cdc4' : '#ff5253'"/>
        </svg>
        <span class="logisticsItemHeadStatusText">{{ item.status === 'work' ? 'В пути' : 'Не работает' }}</span>
    </span>
                                </div>
                                <div class="logisticsItemDetails textXS textRegular neutral300">
                                    {{ item.driver ?? 'Водитель не выбран' }}
                                </div>
                            </div>
                        </div>

                        <div class="logisticsItemActiveBody" v-if="item.status === 'work'">
                            <hr/>

                            <div class="logisticsItemActiveBodyInfo">
                                <el-row :gutter="20">
                                    <el-col :sm="8">
                                        <div>
                                            <span>Отгрузка</span>
                                            <p class="cutOverflow">№{{ item.doc ?? '?' }} <template v-if="item.companyId"> для {{ getData(item.companyId, 'company') }}</template></p>
                                        </div>
                                    </el-col>
                                    <el-col :sm="8">
                                        <div>
                                            <span>Продукция</span>
                                            <p class="cutOverflow">
                                                {{ item.total }} {{ getData(item.goodId, 'good', 'unit') }} - {{ getData(item.goodId, 'good') }}
                                            </p>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </template>

    <el-drawer
        title=""
        v-model="drawerCreateMixer"
        direction="rtl"
        :size="!mobile ? '40%' : '100%'"
        :destroy-on-close="true">


        <DrawerFramework :loading="loading">
            <template v-slot:body>
                <DrawerHeadTitle title="Добавление миксера"/>

                <el-form ref="form" size="small" label-position="top">

                    <FormInputSuggestions
                        v-model="form.vehicleNumber"
                        label="Гос номер ТС"
                        placeholder="Введите номер"
                        :items="vehicles"
                    />

                    <FormNumber
                        v-model="form.volume"
                        label="Объем миксера"
                        placeholder="0"
                    />

                </el-form>

            </template>
            <template v-if="!form.id" v-slot:footerOneButton>

                <DrawerCreateButton
                    :loading="loading"
                    title="Добавить миксер"
                    @action="createMixer()"
                />

            </template>
            <template v-else v-slot:footer>
                <DrawerCreateButton
                    :loading="loading"
                    title="Сохранить"
                    @action="createMixer()"
                />
                <DrawerDeleteButton :loading="loading" @action="deleteMixer()"/>
            </template>
        </DrawerFramework>

    </el-drawer>
            </div>
        </div>
    </Auth>
</template>

<script>
import Filter from '../../Table/Filter/Filter'
import TableHeadBlock from '../../Table/TableHeadBlock'
import tableMixin from '../../../../mixins/tableMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import YandexMap from '@/views/widgets/map/YandexMap';
import {httpService} from '@/services/http.service'
import DrawerFramework from "@/views/components/Drawer/DrawerFramework.vue";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle.vue";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton.vue";
import FormInputSuggestions from "@/views/components/Form/FormInputSuggestions.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TechImage from "@/views/components/TechPage/Garage/TechDetails/TechImage.vue";
import {EditPen} from "@element-plus/icons-vue";
import DrawerDeleteButton from "@/views/components/Drawer/Buttons/DrawerDeleteButton.vue";
import Auth from "@/views/layouts/Auth.vue";

const INITIAL_ZOOM = 13;

export default {
    name: 'MixPageOrdersGroupMixes',
    components: {
        Auth,
        DrawerDeleteButton,
        EditPen,
        TechImage,
        EmptyState,
        FormNumber,
        FormInputSuggestions,
        DrawerCreateButton,
        DrawerHeadTitle, DrawerFramework,
        Filter,
        TableHeadBlock, YandexMap
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            mapZoom: INITIAL_ZOOM,
            activeItem: 0,
            latitude: 0,
            longitude: 0,
            title: 1,
            form: {
                id: null,
                vehicleNumber: null,
                volume: null
            },
            loading: false,
            drawerCreateMixer: false,
            selectedMixer: {
                number: null,
                x: 0,
                y: 0
            },
            createOrderGroupDialog: false,
            values: [],
            mixers: [],
            doc: 0,
            defaultSeller: 0,
            filter: {},
            vehicles: [],
            tabs: [{
                route: 'MixOrdersGroups',
                name: 'Заказы'
            }, {
                route: 'MixOrdersGroupsOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixOrdersMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGroupGoods',
                name: 'Рецепты'
            }]
        }
    },
    computed: {
        mapCenter() {
            if (!this.latitude || !this.longitude) {
                return [55.75165053565251, 37.61758420869977];
            }

            return [parseFloat(this.latitude), parseFloat(this.longitude)];
        },
    },
    created() {
        this.moduleGet()
        if (this.mixers.length > 0) this.selectedMixer = this.mixers[0]
        httpService().post(this.api.vehicle.get).then((response) => {
            let data = response.data

            if (data.success)
                this.vehicles = data.vehicles
            this.loading = false
        })
        if (this.$route.name === 'MixOrdersMixers') {
            this.tabs = [{
                route: 'MixOrdersGroups',
                name: 'Заказы'
            }, {
                route: 'MixOrdersGroupsOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixOrdersMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGroupGoods',
                name: 'Рецепты'
            }]
        } else {
            this.tabs = [{
                route: 'MixInfo',
                name: 'Сводка'
            }, {
                route: 'MixOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGoods',
                name: 'Рецепты'
            }]
        }
    },
    methods: {
        handlePosition({lat, lng, address}) {
            this.longitude = lng;
            this.latitude = lat;
            console.log(address)
        },
        openCreateValueDrawer() {
            this.drawerCreateMixer = true
        },
        handleZoom(value) {
            this.mapZoom = value;
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.mix.getMixers, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix'
            }).then((response) => {
                let data = response.data
                console.log(data)

                if (data.success) {
                    this.values = data.mixers
                    this.loading = false

                    if (this.values.length) {
                        this.activeItem = 0
                        this.latitude = this.values[0].latitude
                        this.longitude = this.values[0].longitude
                    }
                }
            })
        },
        openDialog(mixer) {
            this.selectedMixer = mixer
            this.drawerCreateMixer = true
            this.form = {
                id: mixer.id,
                volume: mixer.volume,
                vehicleNumber: mixer.number
            }
        },
        change(item, index) {
            this.activeItem = index
            this.latitude = item.latitude
            this.longitude = item.longitude
            this.title = index + 1
        },
        createMixer() {
            this.loading = true

            httpService().post(this.api.mix.createMixer, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.drawerCreateMixer = false
                    this.form = {
                        id: null,
                        vehicleNumber: null,
                        volume: null
                    }
                    this.moduleGet()
                } else {
                    this.loading = false
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        deleteMixer() {
            this.loadingButton = true

            this.$confirm(
                'Вы действительно хотите удалить миксер?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.mix.removeMixer, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'mix',
                        form: this.form
                    }).then((response) => {
                        let data = response.data

                        if (data.success) {
                            this.loading = false
                            this.drawerCreateMixer = false
                            this.form = {
                                id: null,
                                vehicleNumber: null,
                                volume: null
                            }
                            this.moduleGet()
                        }
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.number.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        }
    },
    watch: {

    }
}
</script>

<style scoped>
.vehicleFree {
    padding: 14px 5px;
    min-width: 109px;
    border: 1px solid #EEEEF0;
    text-align: center;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 10px;
}

.map {
    width: 100%;
    height: 100%;
}
</style>
