<template>
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        :disable-create-button="true"
        :disable-update-button="true"
        :disable-filter="true"
    />

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 defaultTableHeight"
        v-loading="loading"
        @current-change="handleCurrentChange"
        :data="displayData">

        <el-table-column
            prop="id"
            label="#"
            class-name="pointer"
            width="100"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.vehicle.id) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Название"
            class-name="pointer"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral900 cutOverflow">{{
                        scope.row.vehicle.number ?? '-'
                    }}</span>
            </template>
        </el-table-column>

    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";

export default {
    name: "MixOrderDetailsGroupEditSelectVehicles",
    components: {TableHeadBlock, TablePagination},
    mixins: [tableMixin, functionsMixin],
    props: ['date'],
    inject: ['api'],
    data() {
        return {
            loading: true,
        }
    },
    created() {
        this.getSchedules()
    },
    methods: {
        getSchedules(){
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.date
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.schedules
                    this.valueLength = data.schedules.length
                    this.loading = false
                }
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || (data.vehicle.name.toLowerCase().includes(this.search.toLowerCase()) || data.vehicle.number.toLowerCase().includes(this.search.toLowerCase())))
            this.valueLength = this.filtered.length

            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                this.$emit('updateItem', val)
            }
        },
    }
}
</script>
