<template>
    <h3>
        {{ title }}
        <slot name="title"></slot>
    </h3>
</template>

<script>
export default {
    name: "DrawerHeadTitle",
    props: [
        'title'
    ]
}
</script>

<style scoped>

</style>
