<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <div v-if="!loading" class="distribute">
                    <div
                        v-if="errorComponentsMap.has(error.code)"
                        class="distribute__error-section"
                    >
                        <component
                            :is="errorComponentsMap.get(error.code)"
                        />
                    </div>
                    <div
                        v-if="unallocated.length"
                        class="distribute__error-section"
                    >
                        <NotEnoughVehicles :items="unallocated" />
                    </div>

                    <MixOrderGroupBigTimelineOrders
                        :distribute="distribute"
                        :extraOrders="extraOrders"
                        :mixers="mixers"
                        :time="time"
                        :date="date"
                        :createNew="true"
                    />

                    <div class="distribute__footer mt-30">
                        <div
                            class="ci-btn ci-btn_default mr-10 distribute__footer"
                            style="display:inline-flex;"
                            type="button"
                            @click="changeOrders"
                        >
                            Изменить заказ
                        </div>
                        <button
                            class="ci-btn ci-btn_blue"
                            @click="createOrders"
                        >
                            Создать заказ
                        </button>
                    </div>
                </div>
                <div v-else-if="loading" class="text-center mt-30">
                    <ReportLoader/>
                    <h3>{{ currentLoadingTitle }}...</h3>
                    <p class="textXS textRegular neutral300">Это может занять некоторое время...</p>
                </div>
            </div>
        </div>
    </Auth>
</template>
<script>
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import MixOrderGroupBigTimelineOrders from "@/views/components/MixPage/OrdersGroup/MixOrderGroupBigTimelineOrders.vue";
import Auth from "@/views/layouts/Auth.vue";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import paymentMethods from "@/mixins/paymentMethods";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import moment from "moment";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import NoVehiclesAvailable from "@/views/dispatch/components/distributeErrors/NoVehiclesAvailable.vue"
import NoMixesAvailable from "@/views/dispatch/components/distributeErrors/NoMixesAvailable.vue"
import NotEnoughVehicles from "@/views/dispatch/components/distributeErrors/NotEnoughVehicles.vue"
import EntireVolumeIsDistributed from "@/views/dispatch/components/distributeErrors/EntireVolumeIsDistributed.vue"

export default {
    name: "DistributeOrderGroup",
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs, paymentMethods],
    inject: ['api'],
    components: {
        ReportLoader,
        Auth,
        MixOrderGroupBigTimelineOrders,
        NoVehiclesAvailable,
        NoMixesAvailable,
        NotEnoughVehicles,
        EntireVolumeIsDistributed
    },
    data() {
        return {
            error:{
                code:0,
                message: '',
            },
            errorComponentsMap: new Map([
                [1, NoVehiclesAvailable],
                [2, NoMixesAvailable],
                [4, EntireVolumeIsDistributed],
            ]),
            distribute: [],
            extraOrders: [],
            loading: false,
            mixers: [],
            date: moment(),
            hash: '',
            time: '',
            vehicles: [],
            unallocated: [],
            loadingTitles: [
                "Идет загрузка",
                "Определяем местоположение миксеров",
                "Проверяем загрузку заводов",
                "Запрашиваем пробки с Яндекс.Карт",
                "Рисуем красивый график",
                "Учитываем пожелания клиента по интервалам доставки"
            ],
            currentLoadingTitle: "Формирование графика на день"
        }
    },
    mounted() {
        this.startLoadingAnimation();
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    async created() {
        await this.getDistribute(this.$route.params.groupId)

        if (this.hash){
            await this.getDistributeData(this.$route.params.groupId, this.hash)
        }else {
            await this.getOrdersByGroupsId(this.$route.params.groupId)
        }

        await this.getSchedules()
    },
    methods: {
        startLoadingAnimation() {
            let index = 0;
            this.counterInterval = setInterval(() => {
                this.currentLoadingTitle = this.loadingTitles[index];
                index = (index + 1) % this.loadingTitles.length;
            }, 1150);
        },
        orders(distribute) {
            let orders = []
            distribute.forEach((item) => {
                orders.push({
                    mix_id: item.mix_id,
                    total: item.total,
                    vehicle_id: item.vehicle_id,
                    driver_id: item.driver_id,
                    arrive_at: item.arrive_at,
                    start_at: item.start_at,
                })
            })

            return orders
        },
        createOrders() {
            this.loading = true
            this.filters['filter[date]'] = this.date.format('YYYY-MM-DD');

            setTimeout(this.$router.push({name: 'DispatchOrderGroups', params: {id: this.$route.params.id}}), 500)
        },
        changeOrders() {
            this.$router.push(
                {
                    name: 'DispatchOrderGroupsUpdate',
                    params: {
                        groupId: this.$route.params.groupId,
                    }
                }
            );
        },
        async getDistribute(orderGroupsId) {
            this.loading = true
            await httpService().post(this.api.dispatch.orderGroups.distributeById, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_groups_id: orderGroupsId,
                save: 1
            }).then((response) => {
                this.hash = response.data.jobHash
            }).catch((error) => {
                this.error.code = error.response.data.code;
                this.error.message = error.response.data.message;
                console.log('error.response.data', error.response.data)
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        },
        async getOrdersByGroupsId(orderGroupsId) {
            await httpService().post(this.api.dispatch.orderGroups.getOrdersByGroupsid, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_groups_id: orderGroupsId,
            })
                .then(({data}) => {

                    if (data.status === 'distributed') {
                        this.distribute = data.calculate;
                        this.extraOrders = data.orders;
                        this.vehicles = data.vehicles;
                        this.unallocated = data.unallocated;
                        this.date = moment(data.date);
                        this.loading = false;
                        clearInterval(this.counterInterval)
                    }
                }).catch((error) => {
                    this.error.code = error.response.data.code;
                    this.error.message = error.response.data.message;
                })
        },
        async getDistributeData(orderGroupsId, hash) {
            this.loading = true;

            await httpService().post(this.api.dispatch.orderGroups.getDistributeDataById, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_groups_id: orderGroupsId,
                hash: hash
            }).then((response)=>{
                    if (response.data.status === 'distributed') {
                        this.distribute = response.data.calculate;
                        this.extraOrders = response.data.orders;
                        this.vehicles = response.data.vehicles;

                        this.unallocated = response.data.unallocated;
                        this.error.code = response.data.errorCode;
                        this.error.message = response.data.error;

                        this.date = moment(response.data.date);
                        this.loading = false;
                        clearInterval(this.counterInterval)
                    } else {
                        this.wait(1000);
                        this.getDistributeData(orderGroupsId, hash);
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        type: 'error',
                        showClose: true,
                    })
                })
        },
        wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async getSchedules() {
            httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.mixers = response.data.posts
                    this.time = response.data.time
                }
            })
        },
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
        }),
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
        }),
    },
}
</script>
<style scoped lang="scss">
.distribute {
    &__footer {
        display: flex;
        height: 47px;
    }

    &__error-section{
        gap: 16px;
        display: flex;
        padding: 12px 24px 24px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px;
        background: rgba(247, 69, 57, 0.05);

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}
</style>
