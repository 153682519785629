<template>
    <number
        ref="count"
        class="animateNumber"
        :delay="0"
        :duration="1"
        :format="formatNumber"
        :to="value"
        easing="Power4.easeOut"
    />
</template>

<script>

export default {
    name: 'AnimateNumber',
    props: ['count', 'fraction'],
    data () {
        return {
            value: this.count,
            fractionValue: this.fraction ?? 0
        }
    },
    methods: {
        formatNumber(value) {
            // Функция добавляет тысячные пробелы
            var fractionNumber = this.fractionValue ?? 0
            const f = x => ((x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0))

            if (f(value) === 0) {
                let val = (value / 1).toFixed(0).replace('.', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            } else {
                let val = (value / 1).toFixed(fractionNumber).replace('.', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
        },
    },
    watch: {
        'count': {
            handler: function () {
                if (this.count) {
                    this.value = this.count
                } else {
                    this.value = 0
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
