<template>
    <div :class="'mixTimelineItem' + (completed ? ' mixTimelineItemCompleted' : '') + (current ? ' mixTimelineItemCurrent' : '')">
        <div class="mixTimelineItemLine">
            <el-progress color="#1b91fd" :show-text="false" :percentage="percentage" />
        </div>
        <div class="mixTimelineItemData" v-if="status !== 'done'">
            <div class="d-flex-full" v-if="(order.type === 'take-away' ^ status === 'delivery') || order.type !== 'take-away'">
                <div class="textXS" :class="[
                    {textMedium: completed || current},
                    {textRegular: !completed && !current},
                    {primary500: completed || current},
                    {neutral300: !completed && !current}
                ]">
                    {{ name }}
                </div>
                <div class="textXS textRegular neutral300">
                    {{ time ? (!completed ? '≈' : '') + time : '--:--' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import moment from "moment/moment";

export default {
    name: 'MixPageOrderTimelineItem',
    components: {},
    mixins: [functionsMixin],
    props: [
        'status',
        'name',
        'completed',
        'current',
        'time',
        'order'
    ],
    data () {
      return {
          now: moment()
      }
    },
    created() {
        this.counterInterval = setInterval(
            function () {
                if (this.current) {
                    this.now = moment()
                }
            }.bind(this), 8000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    computed: {
        percentage() {
            let per = 0

            if (this.completed) {
                return per = 100
            }

            let dateStart = this.order.start_at
            let dateEnd = this.order.load_at
            let current = this.now

            switch (this.status) {
                case 'loading':
                    break
                case 'delivery':
                    dateStart = this.order.load_at
                    dateEnd = this.order.arrive_at
                    break
                case 'object':
                    dateStart = this.order.arrive_at
                    dateEnd = this.order.unload_at
                    break
                case 'return':
                    dateStart = this.order.unload_at
                    dateEnd = this.order.return_at
                    break
            }

            dateStart = moment(dateStart)
            dateEnd = moment(dateEnd)

            const totalDuration = moment.duration(dateEnd.diff(dateStart));
            const currentDuration = moment.duration(current.diff(dateStart));

            const totalSeconds = totalDuration.asSeconds();
            const currentSeconds = currentDuration.asSeconds();

            per = ((currentSeconds / totalSeconds) * 100).toFixed(2);

            return per;
        }
    }
}
</script>

<style scoped>

</style>
