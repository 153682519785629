<template>
    <div v-if="loading" class="w-100 text-center pt-30 pb-30" style="min-height: 700px">
        <ReportLoader/>

        <h3>Загрузка данных о заказе</h3>
        <p class="textXS textRegular neutral300">Пожалуйта, подождите...</p>
    </div>
    <div v-else>
        <div class="order-groups-action" v-if="orderGroupItem !== null">
            <template v-if="activeAction == 'main'">
                <OrderProgress :orders="orderGroupItem.orders" :total="orderGroupItem.total"/>
                <OrderStatistics :orders="orderGroupItem.orders" :total="orderGroupItem.total"
                                 class="order-statistics"/>
                <OrderGroupInfo :orderGroupItem="orderGroupItem" class="order-group-info"/>
                <OrderGroupActions :order-group-item="orderGroupItem" :groupId="orderGroupItem.id"
                                   @setActiveAction="setActiveAction" @close="close" @getGroup="getGroup"
                                   class="order-group-action"/>
                <div class="order-groups-action__cancel">
                    <el-button size="large" @click="close">Отмена</el-button>
                </div>
            </template>

            <template v-if="activeAction == 'manage-deliveries'">
                <OrderProgress :orders="orderGroupItem.orders" :total="orderGroupItem.total"/>
                <OrderStatistics :orders="orderGroupItem.orders" :total="orderGroupItem.total"
                                 class="order-statistics"/>
                <ManageDeliveries @setActiveAction="setActiveAction" :orderGroupItem="orderGroupItem"
                                  class="manage-deliveries"/>
            </template>

            <template v-if="activeAction == 'change-volume'">
                <OrderProgress :orders="orderGroupItem.orders" :total="orderGroupItem.total"/>
                <OrderStatistics :orders="orderGroupItem.orders" :total="orderGroupItem.total"
                                 class="order-statistics"/>
                <ChangeVolume @goBack="setActiveAction(goBack)" :orderGroupItem="orderGroupItem" class="change-volume"/>
            </template>

            <template v-if="activeAction == 'add-shipping'">
                <AddShipping @setActiveAction="setActiveAction" :orderGroupItem="orderGroupItem"
                        @close="() => activeAction = 'manage-deliveries'"/>
            </template>

            <template v-if="activeAction == 'add-manual-shipping'">
                <AddManualDelivery :orderGroupItem="orderGroupItem" @goBack="setActiveAction(goBack)"/>
            </template>

            <template v-if="activeAction == 'print'">
                <PrintDocs :orderGroupItem="orderGroupItem" @close="close" :orders="orderGroupItem.orders"/>
            </template>

        </div>
    </div>
</template>
<script>
import OrderProgress from "@/views/dispatch/components/OrderProgress.vue";
import ManageDeliveries from "@/views/dispatch/components/actions/ManageDeliveries.vue";
import OrderStatistics from "@/views/dispatch/components/OrderStatistics.vue";
import ChangeVolume from "@/views/dispatch/components/ChangeVolume.vue";
import AddShipping from "@/views/dispatch/components/AddShipping.vue";
import AddManualDelivery from "@/views/dispatch/components/AddManualDelivery.vue";
import OrderGroupInfo from "@/views/dispatch/components/OrderGroupInfo.vue";
import OrderGroupActions from "@/views/dispatch/components/OrderGroupActions.vue";
import {httpService} from "@/services/http.service";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import PrintDocs from "@/views/dispatch/components/PrintDocs.vue";

export default {
    name: 'Action',
    components: {
        PrintDocs,
        ReportLoader,
        OrderProgress,
        OrderGroupInfo,
        OrderStatistics,
        OrderGroupActions,
        ManageDeliveries,
        ChangeVolume,
        AddShipping,
        AddManualDelivery
    },
    inject: ['api'],
    props: {
        orderGroupItemId: {
            type: Number,
            default: null
        },
        startActive: {
            type: String,
            default: null
        },
        openActionDialog: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            loading: true,
            activeAction: 'main',
            goBack: '',
            orderGroupItem: null
        }
    },
    created() {
        this.getGroup()
    },
    watch: {
        activeAction(newValue, oldValue) {
            if (oldValue) {
                this.goBack = oldValue
            }
        },
        openActionDialog(value) {
            if (!value) {
                this.activeAction = this.startActive
            }
        },
        startActive: {
            immediate: true,
            handler(newValue) {
                this.activeAction = newValue
            }
        }
    },
    methods: {
        getGroup() {
            httpService().post(this.api.dispatch.getOrderGroupById, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                group_id: this.orderGroupItemId
            }).then((response) => {
                const {data} = response.data

                data.pumpInfo = {
                    company_id: data?.pump_vehicle?.rent_company_id ?? null,
                    vehicle_number: data?.pump_vehicle?.number ?? null,
                    driver_name: data?.pump_vehicle?.pump_driver_name ?? null,
                    driver_phone: data?.pump_vehicle?.pump_driver_phone ?? null,
                    company_phone: data?.pump_vehicle?.rent_company?.phone ?? null,
                    price: 0,
                }

                this.orderGroupItem = data

                this.loading = false
            })
        },
        setActiveAction(name) {
            this.activeAction = name
        },
        close() {
            this.$emit('close')
        }
    },
}
</script>
<style scoped lang="scss">
.order-group-info {
    margin-top: 24px;
    padding: 10px 20px;
}

.manage-deliveries {
    margin-top: 24px;
}

.change-volume {
    margin-top: 24px;
}

.order-group-action {
    margin-top: 24px;

    &__footer {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }
}

.order-groups-action {
    &__cancel {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: 16px;
    }
}

.order-statistics {
    margin-top: 8px;
    padding: 0 20px;
}
</style>
<script setup>
</script>
