<template>
    <template v-if="order">
        <div @click="openMixOrderDetails"
             class="dispatchOrderItemTable"
             :class="[
                 {pointer: !current},
                 {dispatchOrderItemTableNext: !current},
                 {orderNotConfirm: !order.confirm},
                 {orderNotConfirmActual: !order.confirm && order.diff < 60}
             ]">
            <div class="dispatchOrderItemTableBody">
                <div class="dispatchOrderItemTableHeader textXS textRegular" v-if="!order.confirm">

                    <el-tooltip effect="dark" placement="top" :show-after="500">
                        <template #content>
                            <div class="text-center">
                                <div class="textMedium mb-5">Необходимо подтвердить отгрузку</div>
                                <div class="textXS mb-10">Неподтверждённые отгрузки<br/>невозможно запустить в<br/>работу
                                    оператору и водителю
                                </div>
                                <div class="textXS">Не подтверждайте отгрузки<br/>сильно заранее, так алгоритм<br/>будет
                                    менять их подбирая<br/>наилучшее время и миксер
                                </div>
                            </div>
                        </template>
                        <div class="d-flex-full-stroke">
                            <svg class="mr-5 orderNotConfirmIcon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                                    fill="#ECAF02"/>
                                <path d="M7.96606 10.3H8.03273V10.3667H7.96606V10.3Z" stroke="#F9F9F9"
                                      stroke-width="1.33333"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M7.96205 8.57877V8.57877C7.79036 8.57877 7.66038 8.41729 7.67583 8.24629C7.69274 8.05905 7.72421 7.90134 7.77022 7.77315C7.83915 7.58109 7.93711 7.42558 8.06409 7.30661C8.19108 7.18763 8.34346 7.07801 8.52124 6.97773C8.62826 6.91655 8.72441 6.84431 8.80967 6.76103C8.89493 6.67605 8.96205 6.57832 9.01103 6.46785C9.06182 6.35737 9.08722 6.235 9.08722 6.10073C9.08722 5.93417 9.0455 5.7897 8.96205 5.66733C8.8786 5.54496 8.76704 5.45063 8.62736 5.38435C8.48768 5.31806 8.33257 5.28492 8.16205 5.28492C8.0133 5.28492 7.86999 5.31381 7.73212 5.3716C7.59425 5.42938 7.47906 5.52031 7.38654 5.64439C7.34837 5.69557 7.31684 5.75326 7.29195 5.81747C7.22638 5.98661 7.08552 6.13132 6.90412 6.13132V6.13132C6.7062 6.13132 6.54325 5.96312 6.59773 5.77285C6.63804 5.63207 6.69871 5.50427 6.77974 5.38944C6.92668 5.18379 7.11988 5.02658 7.35933 4.9178C7.6006 4.80903 7.86817 4.75464 8.16205 4.75464C8.48133 4.75464 8.75888 4.81413 8.9947 4.9331C9.23235 5.05207 9.41557 5.21523 9.54436 5.42259C9.67498 5.62994 9.74028 5.86618 9.74028 6.13132C9.74028 6.31828 9.70944 6.48739 9.64777 6.63866C9.5879 6.78992 9.50083 6.92504 9.38654 7.04402C9.27407 7.16299 9.13802 7.26837 8.97838 7.36014C8.81874 7.45362 8.69085 7.5522 8.5947 7.65588C8.49856 7.75785 8.42872 7.87938 8.38518 8.02044C8.3621 8.09522 8.34463 8.17979 8.33277 8.27415C8.31319 8.42982 8.19722 8.56056 8.04119 8.57705V8.57705C8.03034 8.57819 8.01946 8.57877 8.00855 8.57877C7.99649 8.57877 7.97925 8.57877 7.96205 8.57877Z"
                                    fill="#F9F9F9" stroke="#F9F9F9" stroke-width="0.500002"/>
                            </svg>
                            <div class="orderNotConfirmText">
                                Не подтверждена
                            </div>
                        </div>
                    </el-tooltip>
                </div>
                <div class="dispatchOrderItemTableHeader">
                    <div class="d-flex-full-stroke">
                        <div class="d-flex-full-stroke">
                            <svg class="mr-5" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.21338 7.46053C5.21338 7.10081 5.52118 6.80921 5.90088 6.80921H8.65088C9.03058 6.80921 9.33838 7.10081 9.33838 7.46053C9.33838 7.82024 9.03058 8.11184 8.65088 8.11184H5.90088C5.52118 8.11184 5.21338 7.82024 5.21338 7.46053Z"
                                      fill="#22252C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10.0259 7.46053C10.0259 6.7411 10.6415 6.15789 11.4009 6.15789H14.1509C14.9103 6.15789 15.5259 6.7411 15.5259 7.46053V13.9737C15.5259 14.3334 15.2181 14.625 14.8384 14.625H10.7134C10.3337 14.625 10.0259 14.3334 10.0259 13.9737V7.46053ZM14.1509 7.46053H11.4009V13.3224H14.1509V7.46053Z"
                                      fill="#22252C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.21338 5.50658C5.21338 5.14687 5.52118 4.85526 5.90088 4.85526L8.65088 4.85526C9.03058 4.85526 9.33838 5.14687 9.33838 5.50658C9.33838 5.86629 9.03058 6.15789 8.65088 6.15789H5.90088C5.52118 6.15789 5.21338 5.86629 5.21338 5.50658Z"
                                      fill="#22252C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M3.15088 3.55263C3.15088 2.83321 3.76649 2.25 4.52588 2.25H10.0259C10.7853 2.25 11.4009 2.83321 11.4009 3.55263V13.9737C11.4009 14.3334 11.0931 14.625 10.7134 14.625H3.83838C3.45868 14.625 3.15088 14.3334 3.15088 13.9737V3.55263ZM10.0259 3.55263H4.52588V13.3224H10.0259V3.55263Z"
                                      fill="#22252C"/>
                            </svg>
                            <div>{{ order.time }}</div>
                        </div>
                        <svg class="mr-10 ml-10" width="5" height="4" viewBox="0 0 5 4" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                                fill="black"/>
                        </svg>
                        <div class="d-flex-full-stroke">
                            <svg class="mr-5" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.61993 1.65336L3.05993 3.62669C2.25326 4.07336 1.59326 5.19336 1.59326 6.11336V9.88002C1.59326 10.8 2.25326 11.92 3.05993 12.3667L6.61993 14.3467C7.37993 14.7667 8.6266 14.7667 9.38659 14.3467L12.9466 12.3667C13.7533 11.92 14.4133 10.8 14.4133 9.88002V6.11336C14.4133 5.19336 13.7533 4.07336 12.9466 3.62669L9.38659 1.64669C8.61993 1.22669 7.37993 1.22669 6.61993 1.65336Z"
                                    fill="#22252C"/>
                                <path d="M2.11328 4.95996L7.99995 8.36663L13.8466 4.97996" stroke="white"
                                      stroke-width="1.2"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 14.4065V8.35986" stroke="white" stroke-width="1.2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <div>{{ order.volume }} м³</div>
                        </div>
                    </div>
                    <div class="neutral100 textRegular pr-5">#{{ formatNumber(order.id) }}</div>
                </div>
                <div class="dispatchOrderItemTableFooter">
                    <div class="dispatchOrderItemTableStatus mr-5">
                        <div class="dispatchOrderItemTableStatusBody">
                            {{ order.status }} - {{ order.timeStatus }}
                        </div>
                    </div>

                    <div class="dispatchOrderItemTableStatus">
                        <div class="dispatchOrderItemTableStatusBody">
                            <div class="d-flex-full-stroke" style="gap: 5px; width: 85px">
                                <div class="cutOverflow">{{ order.number ? getVehicleNumber(order.number) : '-' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        -
    </template>

</template>

<script>
import mixOrderInfo from "@/mixins/mixOrderInfo";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    name: 'MixOrderGroupTableOrderItem',
    props: ['orders', 'current', 'orderGroupId', 'timezone'],
    mixins: [mixOrderInfo, functionsMixin],
    data() {
        return {}
    },
    created() {

    },
    methods: {
        openMixOrderDetails() {
            this.$emit('openMixOrderDetails', this.order.id, this.orderGroupId)
        },
    },
    computed: {
        order() {
            let time = null
            let timeStatus = null
            let orderData = null
            let currentData = null
            let ordersData = []
            let nextData = null

            // ищем текущую
            ordersData = this.orders.filter(el => el.status !== 'wait' && el.status !== 'done' && el.confirm).sort((a, b) => new Date(b.start_at) - new Date(a.start_at)) ?? []
            if (ordersData.length) {
                currentData = ordersData[0]
            }

            // ищем следующую
            ordersData = this.orders.filter(el => el.status === 'new').sort((a, b) => new Date(a.start_at) - new Date(b.start_at)) ?? []
            if (ordersData.length) {
                nextData = ordersData[0]
                if (currentData && nextData.id === currentData.id) {
                    if (ordersData.length > 1) {
                        nextData = ordersData[1]
                    } else {
                        nextData = null
                    }
                }
            }

            if (currentData && currentData.confirm && nextData && nextData.confirm) {
                let c = currentData
                let n = nextData
                currentData = n
                nextData = c
            }

            if (this.current) {
                orderData = currentData
            } else {
                orderData = nextData
            }

            if (orderData) {
                time = orderData.arrive_at

                if (orderData.status === 'new' || orderData.status === 'wait') {
                    timeStatus = orderData.start_at
                } else if (orderData.status === 'loading') {
                    timeStatus = orderData.load_at
                } else if (orderData.status === 'delivery') {
                    timeStatus = orderData.arrive_at
                } else if (orderData.status === 'object' || orderData.status === 'pouring') {
                    time = orderData.return_at
                    timeStatus = orderData.unload_at
                } else if (orderData.status === 'return') {
                    time = orderData.return_at
                    timeStatus = orderData.return_at
                }

                let status = this.getStatusLabel(orderData.status)

                if (status === 'На объекте') {
                    status = 'Объект'
                } else if (status === 'Возвращается') {
                    status = 'Возвращ.'
                }

                let diff = moment(timeStatus).utc().tz(this.timezone).diff(moment().utc().tz(this.timezone), 'minutes')

                let number = null

                if (orderData.type === 'delivery' && orderData.vehicle_id) {
                    number = orderData.number
                } else {
                    number = 'Самовывоз'
                }

                return {
                    id: orderData.id,
                    time: moment(time).format('HH:mm'),
                    volume: orderData.total,
                    status: status,
                    timeStatus: moment(timeStatus).format('HH:mm'),
                    number: number,
                    confirm: orderData.confirm,
                    diff: diff
                }
            } else {
                return null
            }
        }
    },
}
</script>

<style scoped>

</style>
