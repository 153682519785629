<template>
    <Auth title="Документы" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disableFilter="true"
                    create-button-label="Создать договор"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="openCreateDialog(null)"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-select
                            v-model="filter.status.value"
                            :placeholder="this.filter.status.placeholder"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="moduleGet"
                            clearable>
                            <el-option
                                v-for="item in documentStatuses"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"/>
                        </el-select>
                        <TableIntegrationFilter
                            v-model="filter.integrations.value"
                            @moduleGet="moduleGet"
                            :table="true"
                        />
                        <FormSelectContragents
                            @click="openContragentsFilterDialog"
                            @clear="clearContragentsFilter"
                            :contragents="contragentsCheckboxesActive" />
                        <ResetFormButton
                            :isVisible="isResetButtonActive"
                            @resetFilter="resetFilters" />
                    </template>
                </TableHeadBlock>

                <MobileTableFramework v-for="(item, index) in values" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="openDetailsDialog(item.id)"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        :width="windowWidth > 1540 ? 400 : windowWidth > 1300 ?300 : 200"
                        sortable>
                        <template v-slot="scope">
                            <div class="group-orders-status-text group-orders-status-text-small mb-5"
                                 :class="'group-orders-status-text-' + scope.row?.status">
                                {{ getInfo(scope.row?.status, documentStatuses) }}
                            </div>
                            <div class="textSM textMedium neutral900 maxLines3">
                                <Truncate :input-text="scope.row.name + (scope.row.doc ? ' - ' + scope.row.doc : '')"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="Дата"
                        width="120"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date ? getDateFormDB(scope.row.date, 'date') : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="140px"

                        prop="company_id"
                        label=""
                    >
                     <template #header>
                            <span>Контрагенты</span>
                </template>
                        <template v-slot="scope">
                            <span class="textMedium neutral700 cutOverflow maxLines3 counterparty-row">{{
                                    scope.row.company_id ? scope.row.company?.name : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Сумма"
                        width="200"
                    >
                        <template v-slot="scope">
                            <span class="textMedium primary600 cutOverflow">{{
                                    scope.row.total ? formatNumber(scope.row.total) + ' руб.' : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration_unit_id"
                        label="Интеграция"
                        width="150">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo :integration-unit-id="scope.row.integration_unit_id"/>
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="180">
                        <template v-slot="scope">
                            <div>
                                <el-button
                                    size="small"
                                    @click="openDetailsDialog(scope.row.id)">Подробнее
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="remove(scope.row.id)"
                                    :icon="icons.delete"
                                />
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-dialog
            title=""
            v-model="detailsDialog"
            :width="dialogSize"
            :destroy-on-close="true"
        >

            <CRMContractDetails
                :id="selectedContractId"
                @remove="remove"
                @update="moduleGet"
                @edit="openEditDialog"
            />

        </el-dialog>

        <el-dialog
            v-model="createDialog"
            :title="details.id ?  'Редактирование договора' : 'Создание договора'"
            :width="dialogSize"
            :destroy-on-close="true"
        >
            <el-form label-position="top">
                <el-row :gutter="15">
                    <el-col :sm="8">
                        <FormInput
                            v-model="details.name"
                            label="Название"
                            placeholder="Введите название"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <FormInput
                            v-model="details.doc"
                            label="№ договора"
                            placeholder="Введите номер"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <FormDateTimePicker
                            v-model="details.date"
                            label="Дата договора"
                            size="default"
                        />
                    </el-col>
                </el-row>

                <el-row :gutter="15">
                    <el-col :sm="12">
                        <FormSelect
                            v-model="details.seller_company_id"
                            label="Поставщик"
                            placeholder="Выберите поставщика"
                            company-type="seller"
                            new-item="company"
                            :new-item-data="details.seller_company_id"
                            size="large"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <FormSelect
                            v-model="details.company_id"
                            label="Контрагент"
                            placeholder="Выберите контрагента"
                            new-item="company"
                            :new-item-data="details.company_id"
                            size="large"
                        />
                    </el-col>
                </el-row>

                <el-button :icon="icons.plus" type="text" class="textMedium primary600"
                           @click.prevent="addNewRow" plain>Добавить продукцию
                </el-button>

                <hr/>

                <el-row :gutter="15" class="mb-5" v-if="details.document_goods.length > 0">
                    <el-col :sm="6">
                        <span class="textXS neutral700 textMedium">Продукция</span>
                    </el-col>
                    <el-col :sm="5">
                        <span class="textXS neutral700 textMedium">Стоимость (за ед., без НДС)</span>
                    </el-col>
                    <el-col :sm="3">
                        <span class="textXS neutral700 textMedium">Количество</span>
                    </el-col>
                    <el-col :sm="4">
                        <span class="textXS neutral700 textMedium">% НДС <el-checkbox class="vatCheckbox"
                                                                                      v-model="details.vat_in_price"
                                                                                      label="Включен в цену"
                                                                                      size="small"/></span>
                    </el-col>
                    <el-col :sm="4">
                        <span class="textXS neutral700 textMedium">Итоговая стоимость</span>
                    </el-col>
                </el-row>

                <el-scrollbar height="200px">

                    <div v-for="(good, index) in details.document_goods" :key="index">
                        <el-row :gutter="15">
                            <el-col :sm="6">
                                <FormSelect
                                    v-model="good.good_id"
                                    label=""
                                    placeholder="Выберите продукцию"
                                    new-item="good"
                                />
                            </el-col>
                            <el-col :sm="5">
                                <FormNumber
                                    v-model="good.price"
                                    label=""
                                    placeholder="0"
                                    :precision="2"
                                    :disabled="!good.good_id"
                                />
                            </el-col>
                            <el-col :sm="3">
                                <FormNumber
                                    v-model="good.total"
                                    label=""
                                    placeholder="0"
                                    :precision="2"
                                    :disabled="!good.good_id"
                                />
                            </el-col>
                            <el-col :sm="4">
                                <el-form-item label="">
                                    <el-input
                                        class="custom-input"
                                        v-model="good.vat"
                                        type="number"
                                        placeholder="0"
                                        :disabled="!good.good_id"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :sm="4">
                                <FormNumber
                                    v-model="good.full_price"
                                    label=""
                                    placeholder="0"
                                    :precision="2"
                                    :disabled="!good.good_id"
                                />
                            </el-col>
                            <el-col :sm="2">
                                <el-form-item label-width="0">
                                    <el-button type="danger" :icon="icons.delete"
                                               @click.prevent="deleteRow(index, good)"></el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                </el-scrollbar>

                <hr/>

                <el-button @click="create()" :loading="loadingButton" type="primary" size="large">Сохранить</el-button>
                <el-button v-if="details.id" @click="remove(details.id)" :loading="loadingButton" size="large">Удалить</el-button>
            </el-form>
    </el-dialog>
    <ModalWithScroll
        @update-data="handleUpdate"
        :contragentsModel="contragentsCheckboxesActive"
        v-model="contragentsFilterDialog"
    />
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import iconsMixin from "@/mixins/iconsMixin";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import Truncate from "@/views/components/Truncate.vue";
import CRMContractDetails from "@/views/crm/components/CRMContractDetails.vue";
import { mapState } from "pinia"
import { useCommonStore } from "@/store/common"
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import ModalWithScroll from "@/views/components/ModalWithScroll";
import ResetFormButton from '@/views/components/Form/ResetFormButton'
import FormSelectContragents from '@/views/components/Form/FormSelectContragents';


export default {
    name: 'CRMContracts',
    data() {
        return {
            loading: true,
            loadingButton: false,
            detailsDialog: false,
            valueLength: 0,
            details: {
                id: null,
                doc: null,
                name: null,
                company_id: null,
                date: null,
                goods: [],
                document_goods: [],
                type: 'contract',
            },
            filtered: [],
            filter: {
                contragents: {
                    placeholder: 'Контрагент',
                    value: null,
                },
                type: {
                    placeholder: 'Тип документа',
                    value: null,

                },
                status: {
                    placeholder: 'Статус',
                    value: null,

                },
                integrations: {
                    placeholder: 'Интеграция',
                    value: null,
                },
            },
            search: '',
            vat_in_price: false,
            createDialog: false,
            full_price: 0,
            contragentsFilterDialog: false,
            selectedContractId: null,
            contragentsCheckboxesActive: [], // все выбранные контрагенты
        };
    },
    components: {
        CRMContractDetails,
        Truncate,
        IntegrationLogo,
        FormDateTimePicker,
        FormNumber,
        FormSelect,
        FormInput,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth,
        TableIntegrationFilter,
        ModalWithScroll,
        ResetFormButton,
        FormSelectContragents
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin, iconsMixin, CRMTabs, documentStatusMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet();
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
        }),
        isResetButtonActive() {
            return this.filter.contragents.value.length
            || this.filter.type.value
            || this.filter.status.value
            || this.filter.integrations.value
            || this.contragentsCheckboxesActive.length
            || !!this.search
        }
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.document.getAll, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                contragents: this.filter.contragents.value,
                status: this.filter.status.value,
                integrationUnitId: this.filter.integrations.value,
                type: 'contract'
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.values = data.items.data
                    this.valueLength = data.items.total
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },

        filterValues() {},
        addNewRow() {
            this.details.document_goods.push({
                good_id: null,
                total: null,
                price: null,
                vat: null,
                full_price: null
            })
        },
        deleteRow(index, good) {
            var idx = this.details.document_goods.indexOf(good)
            if (idx > -1) {
                this.details.document_goods.splice(idx, 1)
            }
            if(this.details.id && good?.id){
                httpService().post(this.api.document.removeGood + '/' + good.id).then((response) => {
                    let data = response.data
                    if(data.success){
                        console.log('Удалено')
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        },
        create() {
            this.loadingButton = true
            httpService().post(this.api.document.create, {
                details: this.details
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Информация сохранена',
                        showClose: true,
                        type: 'success'
                    })
                    this.detailsDialog = false
                    this.details = {
                        id: null,
                        doc: null,
                        name: null,
                        company_id: null,
                        date: null,
                        document_goods: [],
                        type: 'contract'
                    }
                    this.loadingButton = false
                    this.createDialog = false
                    this.moduleGet()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        remove(id) {
            this.$confirm('Вы действительно удалить документ?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loading = true

                httpService().post(this.api.document.remove + '/' + id).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.detailsDialog = false
                        this.$message({
                            message: 'Документ удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.moduleGet()
                        this.selectedContractId = null
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
                this.createDialog = false
                this.detailsDialog = false
            })
        },
        openDetailsDialog(id) {
            this.selectedContractId = id
            this.detailsDialog = true
        },
        openEditDialog(details){
            if (details) {
                this.details = details
                if (this.details) {
                    this.detailsDialog = false
                    this.createDialog = true
                }
            }
        },
        setPage(val) {
            // переключает страницу пагинации
            this.page = val
        },
        openCreateDialog() {
            this.details = {
                id: null,
                doc: null,
                name: null,
                company_id: null,
                company: null,
                date: null,
                integration_unit_id: null,
                document_goods: [],
                type: 'contract'
            }
            this.createDialog = true
        },
        changeFullPrice() {
            if (this.details?.document_goods?.length > 0) {
                this.details.document_goods.forEach((el) => {
                    if (el.price && el.total) {
                        let fullPrice = 0
                        fullPrice = Math.abs(el.price)
                        if (!this.details.vat_in_price) {
                            fullPrice = Math.abs(el.price) + (Math.abs(el.price) * (Math.abs(el.vat) / 100));
                        }
                        el.full_price = fullPrice * Math.abs(el.total)
                    }
                })
            }
        },
        clearContragentsFilter() {
            this.contragentsCheckboxesActive = [];
        },
        openContragentsFilterDialog() {
            this.contragentsFilterDialog = true;
        },
        handleUpdate(data) {
            this.contragentsCheckboxesActive = data;
        },
        resetFilters() {
            this.filter.contragents.value = null;
            this.filter.type.value = null;
            this.filter.status.value = null;
            this.filter.integrations.value = null;
            this.contragentsFilterDialog = false;
            this.contragentsCheckboxesActive = [];
            this.search = '';
        }
    },
    watch: {
        'details': {
            handler: function () {
                this.changeFullPrice()
            },
            deep: true,
            immediate: true
        },
        'vat_in_price': {
            handler: function () {
                this.changeFullPrice()
            },
            deep: true,
            immediate: true
        },
        contragentsCheckboxesActive: {
            handler: function () {
                this.filter.contragents.value = this.contragentsCheckboxesActive.length ?
                this.contragentsCheckboxesActive.map((el) => el.id) : []
                this.moduleGet();
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        },
    }
}
</script>

<style scoped>
.integrationLogos svg {
    height: 20px;
    position: relative;
    top: 3px;
}

.counterparty-row {
    line-height: 1.2;
}
</style>

<style lang="scss">
@import "@/sass/_variables.scss";
.integration-table.integration-table .el-table__header .cell{
    padding-top: 0;
    font-weight: 500;
    font-size: 16px;
}
.integration-table-row.integration-table-row .cell {
    color: $neutral700;
    font-weight: 500;
}

.crm-contacts {
    .header-filter-button {
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        color: $neutral300 !important;
        width: 100%;
        justify-content: flex-start;

        svg {
            width: 10px;
            height: 10px;
            margin-left: 10px;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);

            path {
                fill: currentColor;
            }
        }
    }
}

</style>
