<template>
    <div class="mb-30">
        <el-button @click="print('passport')" :loading="loading" :disabled="multipleSelection.length === 0" class="mb-15" plain>Распечатать
            паспорта качества
        </el-button>
        <el-button @click="generateWord()" :loading="loading" :disabled="multipleSelection.length === 0" class="mb-15" plain>Печать ТТН </el-button>
        <br/>
        <el-button @click="print('invoice')" :loading="loading" :disabled="multipleSelection.length === 0" plain>Распечатать транспортные
            накладные
        </el-button>
        <el-button @click="print('daily')" :loading="loading" plain>Печать графика на день </el-button>
        <br/>
    </div>
    <el-checkbox class="w-100" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
        Выбрать все
    </el-checkbox>
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group v-model="multipleSelection" @change="handleCheckedCitiesChange">
        <el-checkbox class="w-100" v-for="order in tableData" :label="order.id" :key="order.id">
            Заявка{{ order.doc ? ' №' + order.doc : '' }} для {{ getData(order.companyId, 'company') }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";

export default {
    name: "MixOrderGroupOrdersPrinter",
    props: ['tableData','date'],
    mixins: [functionsMixin],
    data() {
        return {
            checkAll: false,
            multipleSelection: [],
            orders: this.tableData,
            dateSelect: this.date,
            isIndeterminate: false,
            loading: false,
        }
    },
    methods: {
        handleCheckAllChange(val) {
            this.multipleSelection = val ? this.tableData.map(item => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.orders.length;
            // this.isIndeterminate = checkedCount > 0 && checkedCount < this.orders.length;
            this.isIndeterminate = false;
        },
        print(template) {
            httpService().post(this.api.mix.printDocs, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                dateSelect: this.date,
                orders: this.multipleSelection,
                template: template
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                let type = 'Паспорта качества'
                if (template === 'invoice') {
                    type = 'Транспортные накладные'
                } else if (template === 'daily') {
                    type = 'График заявок'
                }
                link.href = url
                link.setAttribute('download', type + '.html')
                document.body.appendChild(link)
                link.click()

                let data = response.data

                if (data.success)
                    this.loadingButton = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        generateWord(){
            for(let i = 0; i < this.multipleSelection.length; i++){
                httpService().post(this.api.exportDocument.generateTTN, {
                orderId : this.multipleSelection[i]
            }, {
                responseType: 'blob'
            }).then((response) => {
                let fileName = 'Товарно-транспортная накладная для заявки №' + this.multipleSelection[i] + '.docx';
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            })
            }
        }
    }
}
</script>

<style scoped>

</style>
